import { IonLabel } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewBusiness } from "redux/container/businessSettingsSlice";

export default function CustomSelect({
  places,
  setOption,
  mainLabel,
  extraLabel,
  labelImage,
  star,
  option,
  selectPlaceholder,
  type,
  disable,
  selectPlaceholderWarning,
  localLanguage,
  handleDistrictUpazilla,
  bgColor
}) {
  const dispatch = useDispatch();

  return (
    <>
      <IonLabel className="flex items-center mb-[11px]  mt-5">
        <img className="mr-[10px]" src={labelImage} alt=""></img>
        <span className="text-16 font-bold">{mainLabel}</span>
        {star && <span className="text-primary mx-1">*</span>}
        {extraLabel && (
          <p className="ml-1 text-12 text-[#00000080]">({extraLabel})</p>
        )}
      </IonLabel>

      <select
        disabled={disable}
        onChange={(e) => {
          setOption(e.target.value);
          if (type === "role-selection") {
            dispatch(setNewBusiness({ role: e.target.value }));
          }
          if (type === "zilla-selection") {
            handleDistrictUpazilla(e.target.value);
          }
        }}
        className={`w-full h-[50px] pl-[10px] ${bgColor ? bgColor:"bg-white"} rounded-md text-gray-700 leading-tight focus:outline-none focus:shadow-outline sm:w-full appearance-none`}
        style={{
          backgroundImage: 'url("/assets/images/ekkhero/Icons/dropdown.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 15px center",
        }}
        value={option}
      >
        <option className="text-14" value="" disabled hidden>
          {!disable ? selectPlaceholder : selectPlaceholderWarning}
        </option>
        {type === "zilla-selection" &&
          places.map((item) => (
            <option className="text-14" key={item.id} value={item.name}>
              {localLanguage === "bn" ? item.bn_name : item.name}
            </option>
          ))}
        {type === "upazilla-selection" &&
          places.map((item) => (
            <option className="text-14" key={item.id} value={item.name}>
              {localLanguage === "bn" ? item.bn_name : item.name}
            </option>
          ))}
        {(type === "role-selection" ||
          type === "role-selection2" ||
          type === "orderType-selection" ||
          type === "status-selection") &&
          places.map((item) => (
            <option className="text-14" key={item.value} value={item.value}>
              {localLanguage === "bn" ? item.label : item.label}
            </option>
          ))}
        {(type === "serviceType-selection" ||
          type === "revenueType-selection") &&
          places.map((item) => (
            <option className="text-14" key={item.id} value={item.id}>
              {localLanguage === "bn" ? item.title : item.title}
            </option>
          ))}
      </select>
    </>
  );
}

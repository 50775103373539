import React, { useRef, useState } from "react";
import {
	withGoogleMap,
	GoogleMap,
	withScriptjs,
	Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { useEffect } from "react";
import { getUser } from "config/user";
import { COUNTRYINFO } from "data/country";
import { useDispatch, useSelector } from "react-redux";
import { setMapLocation } from "redux/container/userSlice";
import Search from "assets/images/icon/svg/Search";
import { IonButton } from "@ionic/react";

import { baseUrl } from "config/apiUrl";
import { setCustomerLocation } from "redux/container/customerSlice";
import { number } from "prop-types";
import { setNewTask } from "redux/container/taskSlice";

Geocode.setApiKey(baseUrl.mapKey);
// Geocode.enableDebug();

export default function MapFunctional({
	center,
	height,
	zoom,
	location,
	from,
	to,
}) {
	let user = getUser();
	let _u = localStorage.getItem("setUser");
	let userParse = JSON.parse(_u)?.user;
	let dispatch = useDispatch();
	let autocompleteRef = useRef(null);
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [area, setArea] = useState("");
	const [state, setState] = useState("");
	const myLocation = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	const [mapPosition, setMapPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});
	const [markerPosition, setMarkerPosition] = useState({
		lat: center.lat,
		lng: center.lng,
	});
	let userC = user ? user?.Country : userParse?.Country;
	let userCountry = Object.values(COUNTRYINFO).find(
		(el) => el.name === userC
	)?.countryShortName;

	const parseAddress = (formattedAddress) => {
		const addressParts = formattedAddress.split(",");
		console.log(addressParts);
		if (addressParts.length >= 5) {
			const city = addressParts[addressParts.length - 4].trim();
			const area = addressParts[addressParts.length - 5].trim();
			const zipCodeFind = addressParts[addressParts.length - 3].split(" ");
			const zipCode = zipCodeFind[zipCodeFind.length - 3];
			if (typeof zipCode === "number") {
				dispatch(setNewTask({ postalCode: zipCode }));
			}
			dispatch(setNewTask({ address: area }));
			dispatch(setNewTask({ city: city }));
			return {
				city,
				area,
				zipCode,
			};
		} else if (addressParts.length === 4) {
			const city = addressParts[addressParts.length - 3].trim();
			const area = addressParts[addressParts.length - 4].trim();
			const zipCodeFind = addressParts[addressParts.length - 2].split(" ");
			const zipCode = zipCodeFind[zipCodeFind.length - 2];
			if (typeof zipCode === "number") {
				dispatch(setNewTask({ postalCode: zipCode }));
			}
			dispatch(setNewTask({ address: area }));
			dispatch(setNewTask({ city: city }));
			return {
				city,
				area,
				zipCode,
			};
		} else {
			const city = addressParts[addressParts.length - 2].trim();
			const area = addressParts[addressParts.length - 3].trim();
			const zipCodeFind = city.split(" ");
			const zipCode =
				typeof Number(zipCodeFind[zipCodeFind.length - 1]) === "number"
					? zipCodeFind[zipCodeFind.length - 1]
					: "";
			if (typeof zipCode === "number") {
				dispatch(setNewTask({ postalCode: zipCode }));
			}
			dispatch(setNewTask({ address: area }));
			dispatch(setNewTask({ city: city }));
			return {
				city,
				area,
				zipCode,
			};
		}
	};

	useEffect(() => {
		console.log(address);
		Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);

				// console.log("city", city, area, state);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				dispatch(setNewTask({ address: address }));
				dispatch(setNewTask({ city: city }));
				dispatch(setNewTask({ address: area }));
				dispatch(setNewTask({ state: state }));
			},
			(error) => {
				console.error(error);
			}
		);
		if (from === "pickLocation") {
			dispatch(setNewTask({ coordinates: [myLocation[0], myLocation[1]] }));
			dispatch(setNewTask({ address }));
		}
	}, []);

	//get city
	const getCity = (addressArray) => {
		let city = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (
				addressArray[i].types[0] &&
				"administrative_area_level_2" === addressArray[i].types[0]
			) {
				city = addressArray[i].long_name;
				return city;
			}
		}
	};

	//get area
	const getArea = (addressArray) => {
		let area = "";
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0]) {
				for (let j = 0; j < addressArray[i].types.length; j++) {
					if (
						"sublocality_level_1" === addressArray[i].types[j] ||
						"locality" === addressArray[i].types[j]
					) {
						area = addressArray[i].long_name;
						return area;
					}
				}
			}
		}
	};

	//get state
	const getState = (addressArray) => {
		let state = "";
		for (let i = 0; i < addressArray.length; i++) {
			for (let i = 0; i < addressArray.length; i++) {
				if (
					addressArray[i].types[0] &&
					"administrative_area_level_1" === addressArray[i].types[0]
				) {
					state = addressArray[i].long_name;
					return state;
				}
			}
		}
	};

	// onPlace selece
	const onPlaceSelected = (place) => {
		const address = place.formatted_address,
			addressArray = place.address_components,
			city = getCity(addressArray),
			area = getArea(addressArray),
			state = getState(addressArray),
			latValue = place.geometry.location.lat(),
			lngValue = place.geometry.location.lng();
		// Set these values in the state.
		setAddress(address ?? "");
		setCity(city ?? "");
		setArea(area ?? "");
		setState(state ?? "");
		setMapPosition({
			lat: latValue,
			lng: lngValue,
		});
		setMarkerPosition({
			lat: latValue,
			lng: lngValue,
		});
		let locationBuilder = {
			city,
			area,
			state,
			lat: latValue,
			lng: lngValue,
			address: `${city} ${area} ${state}`,
		};

		if (from === "pickLocation") {
			dispatch(setNewTask({ address }));
			dispatch(setNewTask({ coordinates: [latValue, lngValue] }));
		} else {
			dispatch(setMapLocation(locationBuilder));
		}
	};

	//on marker dragend
	const onMarkerDragEnd = (event) => {
		let newLat = event.latLng.lat(),
			newLng = event.latLng.lng(),
			addressArray = [];

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				setMapPosition({
					lat: newLat,
					lng: newLng,
				});
				setMarkerPosition({
					lat: newLat,
					lng: newLng,
				});
				let locationBuilder = {
					city,
					area,
					state,
					lat: newLat,
					lng: newLng,
					address: `${city} ${area} ${state}`,
				};
				if (from === "pickLocation") {
					console.log(to);
					if (to === "my-distributor") {
						parseAddress(response.results[0].formatted_address);
						dispatch(
							setCustomerLocation({ coordinates: [newLat, newLng] })
						);
					} else {
						dispatch(setNewTask({ coordinates: [newLat, newLng] }));
					}
				} else {
					dispatch(setMapLocation(locationBuilder));
				}
			},
			(error) => {
				console.error(error);
			}
		);
		dispatch(setNewTask({ address }));
	};

	function handleClick(event) {
		var newLat = event.latLng.lat(),
			newLng = event.latLng.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			(response) => {
				const address = response.results[0].formatted_address,
					addressArray = response.results[0].address_components,
					city = getCity(addressArray),
					area = getArea(addressArray),
					state = getState(addressArray);
				setAddress(address ?? "");
				setCity(city ?? "");
				setArea(area ?? "");
				setState(state ?? "");
				setMapPosition({
					lat: newLat,
					lng: newLng,
				});
				setMarkerPosition({
					lat: newLat,
					lng: newLng,
				});
				let locationBuilder = {
					city,
					area,
					state,
					lat: newLat,
					lng: newLng,
					address: `${city} ${area} ${state}`,
				};

				if (from === "pickLocation") {
					dispatch(setNewTask({ coordinates: [newLat, newLng] }));
				} else {
					dispatch(setMapLocation(locationBuilder));
				}
			},
			(error) => {
				console.error(error);
			}
		);
	}

	const AsyncMap = withScriptjs(
		withGoogleMap((props) => (
			<GoogleMap
				// google={this.props.google}
				onClick={(e) => handleClick(e)}
				onRightClick={props.onMapClick}
				defaultZoom={zoom}
				defaultOptions={{ fullscreenControl: false, mapTypeControl: false }}
				defaultCenter={{
					lat: mapPosition.lat,
					lng: mapPosition.lng,
				}}>
				{/* For Auto complete Search Box */}
				<div className='flex top-[66px] left-0 px-4 absolute w-full'>
					<Autocomplete
						ref={autocompleteRef}
						style={{
							width: "100%",
							height: "50px",
							paddingLeft: "16px",
							marginTop: "5px",
							marginBottom: "0px",
							borderRadius: "7px",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
							marginRight: "10px",
						}}
						onPlaceSelected={onPlaceSelected}
						options={{
							// types: [""],
							types: ["geocode"],
							componentRestrictions: { country: userCountry },
						}}
						defaultValue={`${address ? address : location.address}`}
					/>
					<IonButton
						color='primary'
						className='h-[50px] w-[50px] rounded-[7px]'>
						<Search />
					</IonButton>
				</div>

				{/*Marker*/}
				<Marker
					// google={this.props.google}
					name={"Dolores park"}
					draggable={true}
					onDragEnd={onMarkerDragEnd}
					position={{
						lat: markerPosition.lat,
						lng: markerPosition.lng,
					}}
					icon={{
						url: require("assets/images/locationPin.png"),
					}}
				/>
				<Marker />
			</GoogleMap>
		))
	);
	let map;
	if (center.lat !== undefined) {
		map = (
			<div>
				{/* <div>
					<div className='form-group'>
						<label htmlFor=''>City</label>
						<input
							type='text'
							name='city'
							className='form-control'
							onChange={onChange}
							readOnly='readOnly'
							value={city}
						/>
					</div>
					<div className='form-group'>
						<label htmlFor=''>Area</label>
						<input
							type='text'
							name='area'
							className='form-control'
							onChange={onChange}
							readOnly='readOnly'
							value={area}
						/>
					</div>
					<div className='form-group'>
						<label htmlFor=''>State</label>
						<input
							type='text'
							name='state'
							className='form-control'
							onChange={onChange}
							readOnly='readOnly'
							value={state}
						/>
					</div>
					<div className='form-group'>
						<label htmlFor=''>Address</label>
						<input
							type='text'
							name='address'
							className='form-control'
							onChange={onChange}
							readOnly='readOnly'
							value={address}
						/>
					</div>
				</div> */}
				<AsyncMap
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${baseUrl.mapKey}&libraries=places`}
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: height }} />}
					mapElement={<div style={{ height: `100%` }} />}
				/>
			</div>
		);
	} else {
		map = <div style={{ height: height }} />;
	}
	return map;
}

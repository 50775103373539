import LeftArrow from "assets/images/icon/svg/LeftArrow";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "redux/container/authSlice";

export default function BackButton({ title, sticky, dotMenu }) {
	const navigate = useNavigate();
	let dispatch = useDispatch();
	return (
		<div className={`header-wrapper ${sticky && "sticky top-0 z-10"}`}>
			{/* <div
				className='short-nav absolute left-4 top-7 rounded-xl z-10 '
				style={{
					height: 40,
					width: 40,
					boxShadow: "0 5px 10px rgba(0,0,0,.1)",
				}}
				onClick={() => navigate(-1)}
			>
				<div className=' h-full flex items-center justify-center'>
					<LeftArrow />
				</div>
			</div> */}
			<div className='long-nav w-full bg-primary'>
				<div
					className='flex justify-between items-center p-4 '
					style={{ boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)" }}
				>
					<div className='flex items-center w-full'>
						<div
							className='left-4 top-7  rounded-xl z-10 backIcon' //bg-E6E6E6
							style={{
								height: 30,
								width: 40,
							}}
							onClick={() => {
								if (title === "Select one or more") {
									// localStorage.setItem("setUser", null);
									// localStorage.setItem("auth", false);
									// localStorage.setItem("masterToken", null); //_grecaptcha
									// localStorage.setItem("_grecaptcha", null);
									// navigate("/login");
									dispatch(logout());
								} else {
									navigate(-1);
								}
							}}
						>
							<div className='h-full flex items-center justify-center'>
								<LeftArrow color={sticky ? "white" : "white"} />
							</div>
						</div>
						<div className='flex w-full justify-center'>
							{title && <p className='text-[#FFFFFF] font-bold'>{title}</p>}
						</div>
					</div>
					{/* <div>
						{dotMenu === "referDotMenu" && (
							<div className='mr-2'>
								<span
									onClick={() => {
										dispatch(setReferBottomSheet(true));
									}}
								>
									<DotMenu />
								</span>
							</div>
						)}
					</div> */}
				</div>
			</div>
		</div>
	);
}

import React from "react";
import SupplierCard from "./SupplierCard";
import { IonCol } from "@ionic/react";

export default function SupplierSelect({
  ekkLanguage,
  supplierList,
  selectedSupplier,
}) {
  return (
    <div className="grid grid-cols-2 gap-3 mt-3">
      {supplierList?.length > 0 ? (
        supplierList.map((supplier) => {
          return (
            <SupplierCard
              key={supplier?.id}
              id={supplier?.id}
              supplierName={supplier?.business_name}
              distance={supplier?.distance}
              address={supplier?.address_line}
              selectedSupplier={selectedSupplier}
              ekkLanguage={ekkLanguage}
            />
          );
        })
      ) : (
        <>
          <IonCol size="2"></IonCol>
          <IonCol size="8">
            <div
              className="text-center flex items-center justify-center"
              style={{ height: "calc(100vh - 420px)" }}
            >
              <div>
                <img
                  className="m-auto mb-[10px] mt-5"
                  src="/assets/images/not-found-file.png"
                  alt="emptyCard"
                />
                <p className="text-16 font-normal text-222222 mb-[35px]">
                  {ekkLanguage.customerList.notFound}
                </p>
              </div>
            </div>
          </IonCol>
        </>
      )}
    </div>
  );
}

import { IonApp, IonContent, IonPage, IonButton } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { toast } from "react-toastify";
import { setAllInfo } from "redux/container/userSlice";
import { Input } from "components/global-components/common/Input";
import CustomRadioButton from "components/global-components/common/CustomRadioButton";
import CustomRadioButton2 from "components/global-components/common/CustomRadioButton2";
import User from "services/User";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function DrivingVerify() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const ekkUser = useSelector((state) => state.user.allInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [driving, setDriving] = useState(false);
  const [notDriving, setNotDriving] = useState(false);
  const [drivingNumber, setDrivingNumber] = useState(
    ekkUser?.driving_license_number ? ekkUser?.driving_license_number : null
  );
  const [vehicle, setVehicle] = useState(false);
  const [notVehicle, setNotVehicle] = useState(false);
  const [haveVehicle, setHaveVehicle] = useState(
    ekkUser?.have_vehicle !== null ? ekkUser?.have_vehicle : null
  );
  const [typeOfVehicle, setTypeOfVehicle] = useState(
    ekkUser?.type_of_vehicle ? ekkUser?.type_of_vehicle?.toLowerCase() : null
  );
  const [truck, setTruck] = useState(false);
  const [car, setCar] = useState(false);
  const [bike, setBike] = useState(false);
  const [escooter, setEscooter] = useState(false);
  const [cycle, setCycle] = useState(false);
  const [loadAmount, setLoadAmount] = useState(
    ekkUser?.vehicle_load_amount ? ekkUser?.vehicle_load_amount : null
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    ekkUser?.vehicle_number ? ekkUser?.vehicle_number : null
  );
  const [drivingLicense, setDrivingLicense] = useState(
    ekkUser?.have_driving_license !== null
      ? ekkUser?.have_driving_license
      : null
  );
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (drivingLicense !== null) {
      if (haveVehicle !== null) {
        setError("");
        const drivingData = {
          have_driving_license: drivingLicense,
          driving_license_number: drivingNumber,
          have_vehicle: haveVehicle,
          type_of_vehicle:
            typeOfVehicle?.charAt(0)?.toUpperCase() + typeOfVehicle?.slice(1),
          vehicle_load_amount: loadAmount,
          vehicle_number: vehicleNumber,
        };
        User.putKycInfo(ekkUser?.id, drivingData).then((res) => {
          if (res.success) {
            let res = User.getKycInfo(ekkUser?.id);
            res.then((r) => {
              if (r.status === 200) {
                dispatch(setAllInfo(r.results));
                toast.success(ekkLanguage.drivingVerify.successMessage);
                // navigate("/submit-refferel-code");
                navigate("/home");
              }
            });
          }
        });
      } else {
        setError(ekkLanguage.drivingVerify.vehicleError);
      }
    } else {
      setError(ekkLanguage.drivingVerify.drivingError);
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.addressVerify.mainTitle}
            sticky={true}
          ></BackButtonTitle>
          <div className="pt-20 px-5">
            <h6 className="text-18 text-center font-bold text-success mb-[20px]">
              {ekkLanguage.drivingVerify.drivingTitle}{" "}
            </h6>
            <CustomRadioButton
              currentState1={driving}
              currentState2={notDriving}
              value1={true}
              value2={false}
              setCurrentState1={setDriving}
              setCurrentState2={setNotDriving}
              title1={ekkLanguage.workVerify.previousWorkExperienceOption1}
              title2={ekkLanguage.workVerify.previousWorkExperienceOption2}
              label={ekkLanguage.drivingVerify.haveDrivingLabel}
              labelImg="/assets/images/ekkhero/Icons/driving-verify/driving.png"
              star
              setTypeOfState={setDrivingLicense}
              typeOfState={drivingLicense}
            ></CustomRadioButton>
            <div>
              <Input
                label={{
                  label: ekkLanguage.drivingVerify.licenceNumberLabel,
                  source:
                    "/assets/images/ekkhero/Icons/driving-verify/driving-number.png",
                }}
                onChange={(event) => {
                  setDrivingNumber(event.target.value);
                  console.log(event.target.value);
                }}
                type="text"
                name="driving"
                value={drivingNumber}
                placeholder={ekkLanguage.drivingVerify.licenceNumberPlaceholder}
                style={{
                  zIndex: 100,
                  position: "relative",
                }}
              ></Input>
            </div>
            <CustomRadioButton
              currentState1={vehicle}
              currentState2={notVehicle}
              value1={true}
              value2={false}
              setCurrentState1={setVehicle}
              setCurrentState2={setNotVehicle}
              title1={ekkLanguage.workVerify.previousWorkExperienceOption1}
              title2={ekkLanguage.workVerify.previousWorkExperienceOption2}
              label={ekkLanguage.drivingVerify.haveVehicleLabel}
              labelImg="/assets/images/ekkhero/Icons/driving-verify/vehicle.png"
              star
              setTypeOfState={setHaveVehicle}
              typeOfState={haveVehicle}
            ></CustomRadioButton>
            <CustomRadioButton2
              setCurrentState={{
                setCurrentState1: setTruck,
                setCurrentState2: setCar,
                setCurrentState3: setBike,
                setCurrentState4: setEscooter,
                setCurrentState5: setCycle,
              }}
              currentState={{
                currentState1: truck,
                currentState2: car,
                currentState3: bike,
                currentState4: escooter,
                currentState5: cycle,
              }}
              value={{
                value1: "truck",
                value2: "car",
                value3: "bike",
                value4: "escooter",
                value5: "cycle",
              }}
              title={{
                title1: ekkLanguage.drivingVerify.truck,
                title2: ekkLanguage.drivingVerify.car,
                title3: ekkLanguage.drivingVerify.motorcycle,
                title4: ekkLanguage.drivingVerify.escooter,
                title5: ekkLanguage.drivingVerify.bicycle,
              }}
              imgSource={{
                imgSource1:
                  "/assets/images/ekkhero/Icons/driving-verify/truck.png",
                imgSource2:
                  "/assets/images/ekkhero/Icons/driving-verify/car.png",
                imgSource3:
                  "/assets/images/ekkhero/Icons/driving-verify/motorcycle.png",
                imgSource4:
                  "/assets/images/ekkhero/Icons/driving-verify/escooter.png",
                imgSource5:
                  "/assets/images/ekkhero/Icons/driving-verify/bicycle.png",
              }}
              setTypeOfState={setTypeOfVehicle}
              typeOfState={typeOfVehicle}
              label1={ekkLanguage.drivingVerify.vehicleTypeLabel}
              labelImg="/assets/images/ekkhero/Icons/driving-verify/vehicle-type.png"
            ></CustomRadioButton2>

            <Input
              label={{
                label: ekkLanguage.drivingVerify.loadAmountLabel,
                source: "/assets/images/ekkhero/Icons/driving-verify/load.png",
              }}
              onChange={(event) => {
                setLoadAmount(event.target.value);
                console.log(event.target.value);
              }}
              extraLabel={ekkLanguage.drivingVerify.loadAmountLabel2}
              type="number"
              name="load"
              value={loadAmount}
              placeholder={ekkLanguage.drivingVerify.loadAmountPlaceholder}
            ></Input>
            <Input
              label={{
                label: ekkLanguage.drivingVerify.vehicleNumberLabel,
                source:
                  "/assets/images/ekkhero/Icons/driving-verify/vehicle-number.png",
              }}
              onChange={(event) => {
                setVehicleNumber(event.target.value);
                console.log(event.target.value);
              }}
              type="text"
              name="vehicle"
              value={vehicleNumber}
              placeholder={ekkLanguage.drivingVerify.vehicleNumberPlaceholder}
            ></Input>
            {error && (
              <p className="font-semibold text-12 text-primary mt-5">{error}</p>
            )}
            <IonButton
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              color="success"
              expand="full"
              className="font-bold h-10 w-full mt-10"
            >
              <span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
              <span className="pl-2">
                <ArrayRight />
              </span>
            </IonButton>

            <IonButton
              onClick={() => {
                // navigate("/submit-refferel-code");
                navigate("/home");
              }}
              fill="outline"
              color="primary"
              className="font-bold h-10 my-5 w-full  border-primary"
            >
              {ekkLanguage.addressVerify.cancel}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

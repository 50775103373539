import React from "react";

export default function Scanner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4375 14.4436H1.1875C0.67 14.4436 0.25 14.0236 0.25 13.5061C0.25 12.9886 0.67 12.5686 1.1875 12.5686H27.4375C27.955 12.5686 28.375 12.9886 28.375 13.5061C28.375 14.0236 27.955 14.4436 27.4375 14.4436Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1 9.18125C24.5825 9.18125 24.1625 8.76125 24.1625 8.24375V6.35125C24.1625 4.2975 22.49 2.625 20.4337 2.625H18.9275C18.41 2.625 17.99 2.205 17.99 1.6875C17.99 1.17 18.41 0.75 18.9275 0.75H20.4337C23.5237 0.75 26.0375 3.26375 26.0375 6.35125V8.24375C26.0375 8.76125 25.6175 9.18125 25.1 9.18125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52515 9.18125C3.00765 9.18125 2.58765 8.76125 2.58765 8.24375V6.35125C2.58765 3.26375 5.1014 0.75 8.1914 0.75H9.7364C10.2539 0.75 10.6739 1.17 10.6739 1.6875C10.6739 2.205 10.2539 2.625 9.7364 2.625H8.1914C6.13515 2.625 4.46265 4.2975 4.46265 6.35125V8.24375C4.46265 8.76125 4.04265 9.18125 3.52515 9.18125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4337 24.2008H18.9275C18.41 24.2008 17.99 23.7808 17.99 23.2633C17.99 22.7458 18.41 22.3258 18.9275 22.3258H20.4337C22.49 22.3258 24.1625 20.6533 24.1625 18.5983V13.5045C24.1625 12.987 24.5825 12.567 25.1 12.567C25.6175 12.567 26.0375 12.987 26.0375 13.5045V18.5983C26.0375 21.687 23.5237 24.2008 20.4337 24.2008Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73615 24.2008H8.19115C5.10115 24.2008 2.5874 21.687 2.5874 18.5983V13.5045C2.5874 12.987 3.0074 12.567 3.5249 12.567C4.0424 12.567 4.4624 12.987 4.4624 13.5045V18.5983C4.4624 20.6533 6.1349 22.3258 8.19115 22.3258H9.73615C10.2537 22.3258 10.6737 22.7458 10.6737 23.2633C10.6737 23.7808 10.2537 24.2008 9.73615 24.2008Z"
        fill="white"
      />
    </svg>
  );
}

import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setNewTask } from "redux/container/taskSlice";

export default function TaskImageUploader({
	files,
	setFiles,
	bHeight,
	imgSource,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			dispatch(
				setNewTask({
					files: acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					),
				})
			);
		},
	});

	return (
		<>
			{files?.length ? (
				<>
					<div
						style={{ width: "100%", height: bHeight }}
						className='flex justify-center items-center relative overflow-hidden '>
						<div
							style={{
								height: bHeight,
							}}
							className='text-center'>
							<img
								style={{ height: bHeight }}
								src={files[0].preview ?? files}
								alt='addImage'
								className='m-auto mb-2 object-contain'
							/>

							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
							</div>
						</div>
					</div>
				</>
			) : (
				<div
					className='flex justify-center items-center rounded-[10px]'
					style={{
						cursor: "pointer",
						borderRadius: 10,
						width: "100%",
						height: bHeight,
						background: "#E9EFFF",
						border: "1px dashed rgba(0, 0, 0, 0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					{...getRootProps({ className: "dropzone" })}>
					<div className='text-center'>
						<input {...getInputProps()} />
						<img
							src={imgSource}
							alt='addImage'
							className='m-auto mb-[10px]'
						/>
						<p
							className='text-18 font-bold py-[5px] text-center px-4'
							style={{ color: "#4285F4" }}>
							{ekkLanguage.task.addImage}
						</p>
					</div>
				</div>
			)}
		</>
	);
}

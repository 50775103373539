import React from "react";
import Tik from "assets/images/icon/svg/Tik";
import { useDispatch } from "react-redux";
import { setSelectedBrands } from "redux/container/customerSlice";
import { motion } from "framer-motion";

export default function BrandCard({
  brandId,
  brandName,
  imgSource,
  brand,
  selectedBrandList,
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    const list = selectedBrandList || [];
    const exists = list.some((selectedBrand) => selectedBrand.id === brandId);
    if (exists) {
      dispatch(setSelectedBrands(list.filter((brand) => brand.id !== brandId)));
    } else {
      dispatch(setSelectedBrands([...list, brand]));
    }
  };

  return (
    <div
      className={`flex items-center bg-[#F2F3F7] py-5 border-b-[#0000001A] border-b-2 cursor-pointer`}
      onClick={handleClick}
    >
      <img className="w-10 h-10 rounded-full" src={imgSource} alt="emptyCard" />
      <p className="font-bold text-16 text-[#222222] uppercase pl-4">
        {brandName}
      </p>

      <div className="flex justify-end w-full mr-6">
        {selectedBrandList?.length > 0 &&
          selectedBrandList.some(
            (selectedBrand) => selectedBrand.id === brandId
          ) && (
            <motion.div
              initial="hidden"
              animate={{ scale: [0.75, 1, 0.75] }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              className="p-1.5 rounded-full bg-success"
            >
              <Tik height={20} width={20} color={"white"} />
            </motion.div>
          )}
      </div>
    </div>
  );
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BusinessInfo from "components/mobile-components/BusinessInfo";
import {
  setBusinessCreation,
  setSelectedBrands,
} from "redux/container/customerSlice";
import Contract from "services/Contract";
import { getUser } from "config/user";
import { toast } from "react-toastify";
import { setCreateCustomerBS } from "redux/container/userSlice";

export default function BusinessInfoPage() {
  const selectedBrandList = useSelector(
    (state) => state.customer.selectedBrands
  );
  const [menuCollapse, setMenuCollapse] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const createdInfo = useSelector((state) => state.customer.businessCreation);
  const businessInterestedArea = useSelector(
    (state) => state.customer.businessCreation.interested_area
  );
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localUser = getUser();

  const handleSubmit = async (query) => {
    let response = await Contract.createBusinessInfo({ business_info: query });
    if (response.status === 200 || response.status === 201) {
      dispatch(setBusinessCreation({ business_info: query }));
      dispatch(setSelectedBrands([]));
      toast.success(ekkLanguage.createBusinessInfo.successMessage);
      navigate("/create-contract");
    } else {
      toast.error(response?.message);
      dispatch(setCreateCustomerBS(false));
      dispatch(setBusinessCreation({}));
      navigate("/contract-management");
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.createBusinessInfo.pageTitle}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="businessInfoPage"
            isHide={true}
          />
          <section className="pt-20 px-4 ">
            <BusinessInfo
              handleSubmit={handleSubmit}
              ekkLanguage={ekkLanguage}
              createdInfo={createdInfo}
              user={localUser}
              selectedBrandList={selectedBrandList}
              interestedArea={businessInterestedArea}
            ></BusinessInfo>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

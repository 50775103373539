import { IonCol } from "@ionic/react";
import Star from "assets/images/icon/svg/Star";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { digitChanger } from "helpers/helper";

export default function CustomerCard({
	ekkLanguage,
	link,
	customerName,
	imgSource,
	rating,
	customerId,
	customerAddress,
}) {
	const navigate = useNavigate();

	return (
		<>
			<IonCol
				style={{
					boxShadow: " rgba(0, 0, 0, 0.05) 0px 3px 15px",
				}}
				className='p-[10px] rounded-[10px] bg-white'
				size='5.78'
			>
				<div
					className='w-full'
					onClick={() => navigate(`/${link}/${customerId}`)}
				>
					{/* <div className='w-full flex justify-center'>
						{imgSource?.length ? (
							<img
								className='w-full h-[100px] rounded-[10px]'
								src={imgSource}
								alt=''
							/>
						) : (
							<div className='w-full h-[100px] flex justify-center items-center bg-808080 rounded-full'>
								<h1 className='font-bold text-5xl text-white'>
									{customerName.slice(0, 1)}
								</h1>
							</div>
						)}
					</div> */}
					<h5 className='font-bold text-12 text-[#000] pb-[2px]'>
						{customerName}
					</h5>
					<p className='text-10 font-medium text-[#00000080]'>
						{customerAddress}
					</p>
					{/* <div className='flex justify-between items-center my-1'>
						<span className='flex'>
							<img
								className='w-3 h-3'
								src='/assets/images/Icon/pin.png'
								alt=''
							/>
							<span className='text-10 font-bold ml-1  text-primary'>
								{digitChanger(3.5)} {ekkLanguage.customerList.km}
							</span>
						</span>
						<span className='flex'>
							<Star width={12} height={12} color='#FFC107' />
							<span className='text-10 font-bold ml-1 '>
								{digitChanger(rating)}
							</span>
						</span>
					</div> */}
				</div>
			</IonCol>
		</>
	);
}

import { useSelector } from "react-redux";

function RegisterProcess() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='mt-3 px-5'>
			<div className='flex flex-col items-start'>
				<h6 className='font-bold text-16'>{ekkLanguage.home.step1Title}</h6>
				<p>{ekkLanguage.home.step1Content}</p>
			</div>
			<div className='flex flex-col items-start mt-3'>
				<h6 className='font-bold text-16'>{ekkLanguage.home.step2Title}</h6>
				<p>{ekkLanguage.home.step2Content}</p>
			</div>
			<div className='flex flex-col items-start mt-3'>
				<h6 className='font-bold text-16'>{ekkLanguage.home.step3Title}</h6>
				<p>{ekkLanguage.home.step3Content}</p>
			</div>
		</div>
	);
}

export default RegisterProcess;

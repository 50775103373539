import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const User = {
	getBasicInfoUpdate: () => {
		return SuperFetch(`${baseUrl.pass}/v4/users/custom/user-profile`);
	},
	basicInfoUpdate: (id, query) => {
		return SuperFetch(`${baseUrl.pass}/v4/users/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	postKycInfo: (query) => {
		return SuperFetch(`${baseUrl.kyc}/ekkhero/user_kyc/`, {
			method: "POST",
			data: query,
		});
	},
	putKycInfo: (id, query) => {
		return SuperFetch(`${baseUrl.kyc}/ekkhero/user_kyc/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getKycInfo: (id) => {
		return SuperFetch(`${baseUrl.kyc}/ekkhero/user_kyc/${id}/`);
	},
	checkKycInfo: () => {
		return SuperFetch(`${baseUrl.kyc}/ekkhero/user_kyc/check/`);
	},
	toBucketImage: (query) => {
		return SuperFetch(`${baseUrl.bucket}/files/upload/`, {
			method: "POST",
			data: query,
		});
	},
	checkUserType: (id) => {
		return SuperFetch(`${baseUrl.pass}/v5/users/user-type/${id}`);
	},
};

export default User;

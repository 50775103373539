import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";
import React from "react";

export default function CustomRadioButton({
	currentState1,
	currentState2,
	value1,
	value2,
	setCurrentState1,
	setCurrentState2,
	imgSource1,
	imgSource2,
	title1,
	title2,
	label,
	labelImg,
	star,
	typeOfState,
	setTypeOfState,
}) {
	return (
		<>
			<IonLabel className='flex mb-1 text-16 font-bold '>
				<img className='mr-[10px]' src={labelImg} alt='lblImg'></img> {label}
				{star && <span className='text-primary ml-1'>*</span>}
			</IonLabel>
			<IonRadioGroup
				value={typeOfState}
				onIonChange={(e) => {
					const selectedOption = e.detail.value;
					setCurrentState1(selectedOption === value1);
					setCurrentState2(selectedOption === value2);
					setTypeOfState(e.detail.value);
				}}
			>
				<div className='mb-4 flex items-center h-[50px]'>
					<IonItem
						lines='none'
						className={`border w-1/2 mr-1 relative ${!currentState1 && "opacity-60"} ${
							currentState1 && "border-success font-bold opacity-100"
						}`}
					>
						<IonLabel className='text-14 text-black-400 absolute left-8 w-[100%]'>
							<div
								className={`flex items-center  ${
									!imgSource1 && ""
								}`}
							>
								<img src={imgSource1} alt='' />
								<span
									className={`${!imgSource1 && "mr-3"} ${imgSource1 && "ml-1"}`}
								>
									{title1}
								</span>
							</div>
						</IonLabel>
						<IonRadio  value={value1} color='success' />
					</IonItem>
					<IonItem
						lines='none'
						className={`border w-1/2 mr-1 relative ${!currentState2 && "opacity-60"} ${
							currentState2 && "border-success font-bold opacity-100"
						}`}
					>
						<IonLabel className='text-14 text-black-400 absolute left-8 w-[100%]'>
							<div
								className={`flex items-center  ${
									!imgSource2 && ""
								}`}
							>
								<img src={imgSource2} alt='' />
								<span
									className={`${!imgSource1 && "mr-3"} ${imgSource1 && "ml-1"}`}
								>
									{title2}
								</span>
							</div>
						</IonLabel>
						<IonRadio  value={value2} color='success' />
					</IonItem>
				
				</div>
			</IonRadioGroup>
		</>
	);
}

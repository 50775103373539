import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function CustomerNameTitle({ businessData }) {
  return (
    <div className="mb-5">
      <h4 className="text-20 text-black-1000 font-bold px-1 mb-[5px]">
        {businessData?.business_name}
      </h4>
      {/* <h6 className="text-14 text-[#00000080] px-1 font-semibold mb-[5px]">
        {businessData?.business_name}
      </h6> */}
      <h6 className="text-14 text-[#00000080] px-1 font-semibold mb-[5px]">
        {digitChanger(businessData?.phone_number)}
      </h6>
      <h6 className="text-14 text-[#00000080] px-1 font-semibold">
        {businessData?.address_line}
      </h6>
    </div>
  );
}

import EditBusinessProfile from "assets/images/icon/svg/EditBusinessProfile";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

export default function ImageUploader({
	files,
	setFiles,
	bHeight,
	placeHolder,
	placeHolder2,
	placeHolder3,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [isPdfWarning, setIsPdfWarning] = useState(false);

	let dropzoneOptions = {};

	if (placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder) {
		dropzoneOptions = {
			accept: ".pdf",
		};
	} else {
		dropzoneOptions = {
			accept: "image/*",
		};
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => {
			if (placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder) {
				if (acceptedFiles[0].type === "application/pdf") {
					const reader = new FileReader();
					reader.onload = () => {
						setFiles([
							Object.assign(acceptedFiles[0], {
								preview: reader.result,
							}),
						]);
						setIsPdfWarning(false);
					};
					reader.readAsDataURL(acceptedFiles[0]);
				} else {
					setIsPdfWarning(true);
				}
			} else {
				// if (files) {
				// 	console.log(files)
				// 	setFiles(files);

				// }
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
				setIsPdfWarning(false);
			}
		},
		...dropzoneOptions,
	});

	return (
		<>
			{files?.length ? (
				<>
					<div className='flex justify-between relative items-center mb-[10px]'>
						<p className='text-16 font-bold text-black-1000'>{placeHolder}</p>
						<div
							onClick={() => {
								setFiles([]);
							}}
							className={`${
								placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder &&
								"absolute right-0 bottom-0"
							} cursor-pointer bg-white p-2 rounded`}
						>
							<EditBusinessProfile />
						</div>{" "}
					</div>

					<div
						style={{ width: "100%", height: bHeight }}
						className='flex justify-center items-center bg-E6E6E6 relative overflow-hidden'
					>
						{placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder ? (
							<div className='text-center w-full bg-white'>
								{files[0] && (
									<iframe
										className='w-full'
										src={files[0].preview}
										title='PDF Viewer'
										width='100%'
										height='100%'
									/>
								)}
							</div>
						) : (
							<div style={{ height: bHeight }} className='text-center bg-white'>
								<img
									style={{ height: bHeight }}
									src={typeof files === "string" ? files : files[0].preview}
									alt='addingImage'
									className='m-auto mb-2 object-contain'
								/>
							</div>
						)}
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
						</div>
					</div>
				</>
			) : (
				<>
					<p className='text-16 font-bold mb-[10px] text-black-1000 mb-2'>
						{placeHolder}
					</p>
					<div
						style={{ width: "100%", height: bHeight }}
						className='flex justify-center items-center bg-[#E9EFFF] border-2 border-[#00000054] border-dashed'
					>
						<div className='text-center py-6 px-6 rounded-[5px] cursor-pointer'>
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								{placeHolder2 === ekkLanguage.workVerify.addFilePlaceholder ? (
									<img
										src='assets/images/add-file.png'
										alt='addImage'
										className='m-auto mb-2'
									/>
								) : (
									<img
										src='assets/images/add-image.png'
										alt='addImage'
										className='m-auto mb-2'
									/>
								)}

								<p className='text-12 font-bold text-[#4285F4]'>
									{placeHolder2}
								</p>
								<p className='text-12 font-normal text-[#00000054]'>
									{placeHolder3}
								</p>
							</div>
							{isPdfWarning && (
								<p className='text-red-500 mt-2'>
									{ekkLanguage.workVerify.addFileLabel2}
								</p>
							)}
						</div>
					</div>
				</>
			)}
		</>
	);
}

import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectors, setAppUrl, setPreUrl } from "redux/container/authSlice";
import BannerArea from "./BannerArea";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { actions } from "redux/container/authSlice";
import { selectLan } from "redux/container/languageSlice";
import { authBox_Block } from "Constants";
import { baseUrl } from "config/apiUrl";

function Landing() {
  const [scrolling, setScrolling] = useState(false);
  const { app, link } = useParams();
  const [blockIndex, setBlockIndex] = useState(
    authBox_Block.PHONE_NUMBER_BLOCK
  );
  const [scrollTop, setScrollTop] = useState(0);
  const { loginStatus } = useSelector(selectors.getAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    function onScroll() {
      let currentPosition = document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    var parts = location?.search?.split("?");
    console.log(parts);

    if (app === "ekkhero_pilot_log_in") {
      navigate(loginStatus ? "/home" : `/${app}`);
      dispatch(setAppUrl({ app: app, link: baseUrl.ehp }));
    } else if (parts[1]) {
      dispatch(actions.logout());
      localStorage.removeItem("master_token");
      localStorage.removeItem("_u");
      localStorage.removeItem("_b");
      localStorage.removeItem("cb");
      localStorage.setItem("auth", "false");
      dispatch(setPreUrl(parts[1]));
      navigate(loginStatus ? "/home" : `/${parts[1] ? `?${parts[1]}` : ""}`);
    } else {
      console.log("Login true");
      dispatch(setPreUrl(""));
      navigate(loginStatus ? "/home" : "/");
    }
  }, [loginStatus]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  } else {
    dispatch(selectLan());
  }

  return (
    <section>
      <Navigation scrolling={scrolling ? "isScrolled" : ""} />
      <BannerArea blockIndex={blockIndex} setBlockIndex={setBlockIndex} />
      <div
        className={`${
          blockIndex === authBox_Block.REGISTRATION_BLOCK
            ? "mt-10"
            : "mt-24 md:mt-10 lg:mt-10"
        }`}
      >
        <Footer blockIndex={blockIndex} />
      </div>
    </section>
  );
}
export default Landing;

import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import AddBusiness from "assets/images/icon/svg/AddBusiness";
import { useNavigate } from "react-router-dom";
import { setBusinessCreation } from "redux/container/customerSlice";
import Contract from "services/Contract";
import { toast } from "react-toastify";
import { setCreateCustomerBS } from "redux/container/userSlice";
import { setBusinessCreationType } from "config/localContract";

export default function OldCustomers({ oldCustomerList, newCustomerForm, to }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [showModal, setShowModal] = React.useState(false);
  const navigate = useNavigate();
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const dispatch = useDispatch();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };

  const onAssignBusiness = async () => {
    let response = await Contract.checkBusinessInfo(
      oldCustomerList?.business_id
    );
    if (response.status === 200 || response.status === 201) {
      toast.error(ekkLanguage.newCustomer.alreayExist);
      dispatch(setCreateCustomerBS(false));
      navigate(`/contract-management`);
    } else {
      dispatch(setBusinessCreation({ business: selectedItem }));
      navigate(`/interested-area`);
      setBusinessCreationType("old");
    }
  };

  return (
    <>
      <div className="pt-5">
        <div
          className="text-12 font-semibold p-[10px] rounded-[5px]"
          style={{
            background: "#FFFFCC",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
            color: "#808080",
          }}
        >
          {ekkLanguage.newCustomer.customerFindText}
        </div>
        <div className="pt-[30px]">
          <IonRow>
            {/* {oldCustomerList.length &&
							oldCustomerList.map((el, i) => {
								return ( */}
            <IonCol
              key={oldCustomerList.business_id}
              size="6"
              onClick={() => {
                openModal();
                setSelectedItem(oldCustomerList);
              }}
            >
              <div
                className="bg-white rounded-[10px] px-[10px] py-[10px]"
                style={{
                  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)",
                }}
              >
                <h5 className="font-bold text-14 text-black-1000 pb-[5px]">
                  {oldCustomerList?.business_name}
                </h5>
                <p className="font-normal text-12 text-black-500">
                  {oldCustomerList?.address_line}
                </p>
              </div>
            </IonCol>
            {/* );
							})} */}
            {/* */}
          </IonRow>
        </div>

        {Object.keys(oldCustomerList).length < 0 && (
          <div className="pt-24">
            <button
              onClick={() => {
                newCustomerForm();
              }}
              style={{ background: "#00B224" }}
              className={`text-18 sm:text-18 font-semibold text-white h-[50px] sm:h-[50px] rounded-[5px] w-full`}
            >
              {ekkLanguage?.newCustomer.pageTitle}
            </button>
          </div>
        )}
      </div>

      <Modal
        style={customStyles}
        closeTimeoutMS={200}
        isOpen={showModal}
        onRequestClose={closeModal}
        classNames="bottom-sheet-modal"
        ariaHideApp={false}
      >
        <div className="p-5">
          <div className="flex items-center">
            <span className="inline-block mx-2">
              <AddBusiness />
            </span>
            <h6 className="text-16 font-bold">
              {ekkLanguage.newCustomer.addBusiness}
            </h6>
          </div>

          <h6 className="text-16 font-semibold py-[20px]">
            {ekkLanguage.newCustomer.addBusinessInfo}
          </h6>

          <div className="flex justify-end">
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-black-500 text-black-500"
              style={{ border: "2px solid" }}
              onClick={closeModal}
            >
              {ekkLanguage.newCustomer.noBtn}
            </button>
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-paid text-white"
              onClick={onAssignBusiness}
            >
              {ekkLanguage.createBusinessInfo.okBtnLabel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import TaskImageUploader from "./TaskImageUploader";
import TaskStartTimeCard from "./TaskStartTimeCard";
import TaskEndTimeCard from "./TaskEndTimeCard";
import location from "assets/images/icon/location.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNewTask } from "redux/container/taskSlice";
import Delete from "assets/images/icon/svg/Delete";
import { IonButton, IonCheckbox, IonSpinner } from "@ionic/react";
import moment from "moment";
import { getLocalUser, getUser } from "config/user";
import Task from "services/Task";
import { toast } from "react-toastify";
import User from "services/User";
import { emptyTask } from "redux/container/taskSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";

export default function TaskCreate() {
	const taskList = useSelector((state) => state.task.newTask);
	let [title, setTitle] = useState(taskList?.title ?? "");
	let [description, setDescription] = useState(taskList?.description ?? "");
	const [files, setFiles] = useState(taskList?.files ?? null);
	const [address, setAddress] = useState(taskList?.address ?? "");
	const [count, setCount] = useState(taskList?.count ?? "");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = getLocalUser();
	const loginUser = getUser();
	const [loader, setLoader] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const [locationModal, setLocationModal] = useState(false);
	const onOpenLocationModal = () => setLocationModal(true);
	const onCloseLocationModal = () => setLocationModal(false);

	const [titleModal, setTitleModal] = useState(false);
	const onOpenTitleModal = () => setTitleModal(true);
	const onCloseTitleModal = () => setTitleModal(false);

	let submitTask = async () => {
		if (!taskList?.title) {
			onOpenTitleModal();
			return false;
		}
		if (!taskList?.coordinates?.length) {
			onOpenLocationModal();
			return false;
		}
		setLoader(true);
		if (taskList?.files?.length) {
			let formData = new FormData();
			formData.append("key", taskList?.files[0]);
			let response = await User.toBucketImage(formData);

			if (response.status == 201) {
				let volunteer = {
					name: loginUser?.DisplayName
						? loginUser?.DisplayName
						: user?.user?.full_name,
					phone: loginUser?.PhoneNumber
						? loginUser?.PhoneNumber
						: user?.user?.PhoneNumber,
					role: "Creator",
					user_id: loginUser?._id ? loginUser?._id : user?.user?._id,
				};

				let task = {
					title: taskList?.title,
					description: taskList?.description,
					required_volunteer: taskList?.count,
					latitude: taskList?.coordinates?.length
						? taskList?.coordinates[0]
						: 0.0,
					longitude: taskList?.coordinates?.length
						? taskList?.coordinates[1]
						: 0.0,
					images: response?.results,
					start_date: moment(taskList?.startDate)?.toISOString(),
					end_date: taskList?.endDate
						? moment(taskList?.endDate)?.toISOString()
						: moment(taskList?.startDate)
								.add(30, "minutes")
								.toISOString(),
					is_approval_required:
						(taskList?.checked === undefined) |
						(taskList?.checked === false)
							? false
							: true,
				};
				let payload = {
					task: { ...task },
					volunteer: { ...volunteer },
				};

				let res = await Task.createTask(payload);
				if ((res.status === 201) | (res.status === 200)) {
					setLoader(false);
					toast.success(ekkLanguage.task.successCreate);
					dispatch(emptyTask());
					navigate("/my-task");
				}
			} else {
				toast.error(ekkLanguage.task.createFailed);
				setLoader(false);
			}
		} else {
			let volunteer = {
				name: loginUser?.DisplayName
					? loginUser?.DisplayName
					: user?.user?.full_name,
				phone: loginUser?.PhoneNumber
					? loginUser?.PhoneNumber
					: user?.user?.PhoneNumber,
				role: "Creator",
				user_id: loginUser?._id ? loginUser?._id : user?.user?._id,
			};

			let task = {
				title: taskList?.title,
				description: taskList?.description,
				required_volunteer: taskList?.count,
				latitude: taskList?.coordinates?.length
					? taskList?.coordinates[0]
					: 0.0,
				longitude: taskList?.coordinates?.length
					? taskList?.coordinates[1]
					: 0.0,
				start_date: moment(taskList?.startDate)?.toISOString(),
				end_date: taskList?.endDate
					? moment(taskList?.endDate)?.toISOString()
					: moment(taskList?.startDate).add(30, "minutes").toISOString(),
				is_approval_required:
					(taskList?.checked === undefined) | (taskList?.checked === false)
						? false
						: true,
			};

			let payload = {
				task: { ...task },
				volunteer: { ...volunteer },
			};

			let response = await Task.createTask(payload);
			if ((response.status === 201) | (response.status === 200)) {
				setLoader(false);
				toast.success(ekkLanguage.task.successCreate);
				navigate("/my-task");
			} else {
				toast.error(ekkLanguage.task.failedTaskCreate);
				setLoader(false);
			}
		}
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div>
			<div className='mb-5'>
				<div className='flex justify-between items-center mb-3'>
					<h4 className='font-bold text-18'>
						{ekkLanguage.task.displayImage}
					</h4>
					<div
						onClick={() => {
							dispatch(setNewTask({ ...taskList, files: null }));
							setFiles(null);
						}}
						className='bg-white h-9 w-9 flex justify-center items-center rounded-md'>
						<span>
							<Delete />
						</span>
					</div>
				</div>

				<div>
					<TaskImageUploader
						files={files}
						setFiles={setFiles}
						bHeight={200}
						imgSource={"/assets/images/add-image.png"}
					/>
				</div>
			</div>
			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{" "}
					{ekkLanguage.task.title}
				</h4>
				<input
					className='w-full h-11 rounded-[7px] pl-5 search-input border'
					placeholder={ekkLanguage.task.titlePlaceholder}
					style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
					value={title}
					onChange={(e) => {
						setTitle(e.target.value);
						dispatch(setNewTask({ title: e.target.value }));
					}}></input>
			</div>
			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{ekkLanguage.task.description}
				</h4>
				<textarea
					className='w-full h-20 rounded-[7px] pl-5 pt-2 search-input border'
					placeholder={ekkLanguage.task.descriptionPlaceholder}
					style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
						dispatch(setNewTask({ description: e.target.value }));
					}}></textarea>
			</div>

			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{ekkLanguage.task.startDateTime}
				</h4>
				<div>
					<TaskStartTimeCard />
				</div>
			</div>
			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{ekkLanguage.task.endDateTime}
				</h4>
				<div>
					<TaskEndTimeCard />
				</div>
			</div>
			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{ekkLanguage.task.numberOfVolunteers}
				</h4>
				<input
					type='number'
					className='w-full h-11 rounded-[7px] pl-5 search-input border'
					placeholder={ekkLanguage.task.volunteersPlaceholder}
					style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
					value={count}
					onChange={(e) => {
						setCount(e.target.value);
						dispatch(setNewTask({ count: e.target.value }));
					}}></input>
			</div>
			<div className='mb-5'>
				<div className='flex items-center'>
					<h4 className='font-bold text-18 mr-4'>
						{ekkLanguage.task.approvalRequired}
					</h4>
					<IonCheckbox
						checked={taskList?.checked}
						onIonChange={(e) => {
							dispatch(setNewTask({ checked: e.target.checked }));
						}}></IonCheckbox>
				</div>
			</div>
			<div className='mb-5'>
				<h4 className='mb-3 font-bold text-18'>
					{ekkLanguage.task.location}
				</h4>
				<div>
					<div className='flex items-center justify-between'>
						<input
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
							}}
							className='custom-input'
							value={address}
							placeholder={ekkLanguage.task.addressPlaceholder}
							onChange={(e) => {
								dispatch(setNewTask({ address: e.target.value }));
								setAddress(e.target.value);
							}}></input>
						<span
							onClick={() => navigate(`/pick-location/task-create`)}
							className='flex h-[50px] w-[61px] bg-white ml-[10px] rounded-[5px] cursor-pointer'
							style={
								taskList?.coordinates?.length
									? {
											border: "2px solid rgba(52, 168, 83, 0.50)",
											boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
									  }
									: {
											border: "2px solid rgba(249,75, 65, .50)",
											boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
									  }
							}>
							<img src={location} className='m-auto' alt='location' />
						</span>
					</div>
				</div>
			</div>

			<div>
				{" "}
				<IonButton
					disabled={loader ? true : false}
					onClick={!loader && submitTask}
					fill='outline'
					color='success'
					className='mt-7 font-bold bg-success h-10 my-5 w-full  text-white rounded-md'>
					{loader ? (
						<>
							<IonSpinner />
						</>
					) : (
						<span className='font-bold text-white text-18'>
							{" "}
							{ekkLanguage.task.createTask}
						</span>
					)}
				</IonButton>
			</div>
			<Modal
				ariaHideApp={false}
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={locationModal}
				onRequestClose={onCloseLocationModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.task.noLocation}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onCloseLocationModal}>
							{ekkLanguage.task.cancel}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				ariaHideApp={false}
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={titleModal}
				onRequestClose={onCloseTitleModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[20px]'>
						{ekkLanguage.task.titleWarning}
					</h6>

					<div className='flex justify-end'>
						<button
							color='primary'
							className='font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white'
							onClick={onCloseTitleModal}>
							{ekkLanguage.task.cancel}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

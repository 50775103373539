// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-page {
  margin-left: 300px;
  background-color: rgb(240, 240, 247);
}

.iq-top-navbar {
  box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.2);
  display: inline-block;
  left: auto;
  line-height: 4.563rem;
  margin: 0;
  min-height: 4.563rem;
  padding: 0 0.938rem;
  position: sticky;
  right: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 1000;
}

.iq-top-navbar h4 {
  font-size: 24px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/global-components/style.scss"],"names":[],"mappings":"AACA;EACC,kBAAA;EACA,oCAAA;AAAD;;AAEA;EACC,4CAAA;EACA,qBAAA;EACA,UAAA;EACA,qBAAA;EACA,SAAA;EACA,oBAAA;EACA,mBAAA;EAEA,gBAAA;EACA,QAAA;EACA,MAAA;EACA,gCAAA;EACA,WAAA;EACA,aAAA;AACD;;AACA;EACC,eAAA;EACA,gBAAA;AAED","sourcesContent":["// This is test file\n.content-page {\n\tmargin-left: 300px;\n\tbackground-color: rgb(240, 240, 247);\n}\n.iq-top-navbar {\n\tbox-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.2);\n\tdisplay: inline-block;\n\tleft: auto;\n\tline-height: 4.563rem;\n\tmargin: 0;\n\tmin-height: 4.563rem;\n\tpadding: 0 0.938rem;\n\tposition: -webkit-sticky;\n\tposition: sticky;\n\tright: 0;\n\ttop: 0;\n\ttransition: all 0.3s ease-in-out;\n\twidth: 100%;\n\tz-index: 1000;\n}\n.iq-top-navbar h4 {\n\tfont-size: 24px;\n\tfont-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

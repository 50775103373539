import React from "react";
export default function EyeOn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_1653_570)">
        <path
          d="M19.3923 7.84916C18.0998 5.74416 15.1598 2.21249 9.9998 2.21249C4.8398 2.21249 1.8998 5.74416 0.607299 7.84916C0.207739 8.49542 -0.00390625 9.2402 -0.00390625 9.99999C-0.00390625 10.7598 0.207739 11.5046 0.607299 12.1508C1.8998 14.2558 4.8398 17.7875 9.9998 17.7875C15.1598 17.7875 18.0998 14.2558 19.3923 12.1508C19.7919 11.5046 20.0035 10.7598 20.0035 9.99999C20.0035 9.2402 19.7919 8.49542 19.3923 7.84916ZM17.9715 11.2783C16.8615 13.0833 14.349 16.1208 9.9998 16.1208C5.65063 16.1208 3.13813 13.0833 2.02813 11.2783C1.79074 10.8942 1.66501 10.4516 1.66501 9.99999C1.66501 9.54843 1.79074 9.10579 2.02813 8.72166C3.13813 6.91666 5.65063 3.87916 9.9998 3.87916C14.349 3.87916 16.8615 6.91333 17.9715 8.72166C18.2089 9.10579 18.3346 9.54843 18.3346 9.99999C18.3346 10.4516 18.2089 10.8942 17.9715 11.2783Z"
          fill="black"
          fill-opacity="0.5"
        />
        <path
          d="M10.0002 5.83333C9.17608 5.83333 8.37049 6.0777 7.68529 6.53554C7.00008 6.99338 6.46603 7.64412 6.15067 8.40548C5.8353 9.16684 5.75279 10.0046 5.91356 10.8129C6.07433 11.6211 6.47117 12.3636 7.05389 12.9463C7.63661 13.529 8.37903 13.9258 9.18729 14.0866C9.99554 14.2474 10.8333 14.1649 11.5947 13.8495C12.356 13.5341 13.0068 13.0001 13.4646 12.3149C13.9225 11.6297 14.1668 10.8241 14.1668 10C14.1655 8.89533 13.7261 7.83629 12.945 7.05518C12.1639 6.27406 11.1048 5.83465 10.0002 5.83333ZM10.0002 12.5C9.50571 12.5 9.02236 12.3534 8.61124 12.0787C8.20012 11.804 7.87968 11.4135 7.69047 10.9567C7.50125 10.4999 7.45174 9.99722 7.5482 9.51227C7.64466 9.02732 7.88277 8.58186 8.2324 8.23223C8.58203 7.8826 9.02749 7.64449 9.51244 7.54803C9.99739 7.45157 10.5001 7.50108 10.9569 7.6903C11.4137 7.87951 11.8041 8.19995 12.0788 8.61107C12.3535 9.02219 12.5002 9.50554 12.5002 10C12.5002 10.663 12.2368 11.2989 11.7679 11.7678C11.2991 12.2366 10.6632 12.5 10.0002 12.5Z"
          fill="black"
          fill-opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1653_570">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const Country = [
	{
		flag: "bd",
		key: "bd",
		text: "Bangladesh",
		value: "+880",
	},
	{
		flag: "sg",
		key: "sg",
		text: "Singapore",
		value: "+65",
	},
];

export const CountryList = [
	{
		name: "Afghanistan",
		countryShortName: "AF",
		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-afghanistan.png",
		code: "+93",
		currencyShortName: "AFN",
		currency: "؋",
	},
	{
		name: "India",
		countryShortName: "IN",
		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-india.png",
		code: "+91",
		currencyShortName: "INR",
		currency: "₹",
	},
	{
		name: "Pakistan",
		countryShortName: "PK",
		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-pakistan.png",
		code: "+92",
		currencyShortName: "PKR",
		currency: "₨",
	},
];
export const COUNTRYINFO = {
	Bangladesh: {
		name: "Bangladesh",
		countryShortName: "BD",
		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png",
		code: "+88",
		currencyShortName: "BDT",
		currency: "৳",
	},
	// Vietnam: {
	//   name: "Vietnam",
	//   countryShortName: "VN",
	//   flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-viet-nam.png",
	//   code: "+84",
	//   currencyShortName: "VND",
	//   currency: "₫",
	// },
	// Singapore: {
	//   name: "Singapore",
	//   countryShortName: "SG",
	//   flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-singapore.png",
	//   code: "+65",
	//   currencyShortName: "BND",
	//   currency: "$",
	// },
	// "United States": {
	//   name: "United States",
	//   countryShortName: "US",
	//   flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-of-america.png",
	//   code: "+1",
	//   currencyShortName: "USD",
	//   currency: "$",
	// },
};

// export const COUNTRYINFO = {
// 	Afghanistan: {
// 		name: "Afghanistan",
// 		countryShortName: "AF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-afghanistan.png",
// 		code: "+93",
// 		currencyShortName: "AFN",
// 		currency: "؋",
// 	},
// 	"Aland Islands": {
// 		name: "Aland Islands",
// 		countryShortName: "AX",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-aland-islands.png",
// 		code: "+358",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Albania: {
// 		name: "Albania",
// 		countryShortName: "AL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-albania.png",
// 		code: "+355",
// 		currencyShortName: "ALL",
// 		currency: "L",
// 	},
// 	Algeria: {
// 		name: "Algeria",
// 		countryShortName: "DZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-algeria.png",
// 		code: "+213",
// 		currencyShortName: "DZD",
// 		currency: "د.ج",
// 	},
// 	"American Samoa": {
// 		name: "American Samoa",
// 		countryShortName: "AS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-american-samoa.png",
// 		code: "+1684",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Andorra: {
// 		name: "Andorra",
// 		countryShortName: "AD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-andorra.png",
// 		code: "+376",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Angola: {
// 		name: "Angola",
// 		countryShortName: "AO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-angola.png",
// 		code: "+244",
// 		currencyShortName: "AOA",
// 		currency: "Kz",
// 	},
// 	Anguilla: {
// 		name: "Anguilla",
// 		countryShortName: "AI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-anguilla.png",
// 		code: "+1264",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	Antarctica: {
// 		name: "Antarctica",
// 		countryShortName: "AQ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-antarctica.png",
// 		code: "+672",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	"Antigua and Barbuda": {
// 		name: "Antigua and Barbuda",
// 		countryShortName: "AG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-antigua-and-barbuda.png",
// 		code: "+1268",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	Argentina: {
// 		name: "Argentina",
// 		countryShortName: "AR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-argentina.png",
// 		code: "+54",
// 		currencyShortName: "ARS",
// 		currency: "$",
// 	},
// 	Armenia: {
// 		name: "Armenia",
// 		countryShortName: "AM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-armenia.png",
// 		code: "+374",
// 		currencyShortName: "AMD",
// 		currency: null,
// 	},
// 	Aruba: {
// 		name: "Aruba",
// 		countryShortName: "AW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-aruba.png",
// 		code: "+297",
// 		currencyShortName: "AWG",
// 		currency: "ƒ",
// 	},
// 	Australia: {
// 		name: "Australia",
// 		countryShortName: "AU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-australia.png",
// 		code: "+61",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	Austria: {
// 		name: "Austria",
// 		countryShortName: "AT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-austria.png",
// 		code: "+43",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Azerbaijan: {
// 		name: "Azerbaijan",
// 		countryShortName: "AZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-azerbaijan.png",
// 		code: "+994",
// 		currencyShortName: "AZN",
// 		currency: null,
// 	},
// 	Bahamas: {
// 		name: "Bahamas",
// 		countryShortName: "BS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bahamas.png",
// 		code: "+1242",
// 		currencyShortName: "BSD",
// 		currency: "$",
// 	},
// 	Bahrain: {
// 		name: "Bahrain",
// 		countryShortName: "BH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bahrain.png",
// 		code: "+973",
// 		currencyShortName: "BHD",
// 		currency: ".د.ب",
// 	},
// 	Bangladesh: {
// 		name: "Bangladesh",
// 		countryShortName: "BD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png",
// 		code: "+88",
// 		currencyShortName: "BDT",
// 		currency: "৳",
// 	},
// 	Barbados: {
// 		name: "Barbados",
// 		countryShortName: "BB",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-barbados.png",
// 		code: "+1246",
// 		currencyShortName: "BBD",
// 		currency: "$",
// 	},
// 	Belarus: {
// 		name: "Belarus",
// 		countryShortName: "BY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-belarus.png",
// 		code: "+375",
// 		currencyShortName: "BYN",
// 		currency: "Br",
// 	},
// 	Belgium: {
// 		name: "Belgium",
// 		countryShortName: "BE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-belgium.png",
// 		code: "+32",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Belize: {
// 		name: "Belize",
// 		countryShortName: "BZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-belize.png",
// 		code: "+501",
// 		currencyShortName: "BZD",
// 		currency: "$",
// 	},
// 	Benin: {
// 		name: "Benin",
// 		countryShortName: "BJ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-benin.png",
// 		code: "+229",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Bermuda: {
// 		name: "Bermuda",
// 		countryShortName: "BM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bermuda.png",
// 		code: "+1441",
// 		currencyShortName: "BMD",
// 		currency: "$",
// 	},
// 	Bhutan: {
// 		name: "Bhutan",
// 		countryShortName: "BT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bhutan.png",
// 		code: "+975",
// 		currencyShortName: "BTN",
// 		currency: "Nu.",
// 	},
// 	Bolivia: {
// 		name: "Bolivia",
// 		countryShortName: "BO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bolivia-(plurinational-state-of).png",
// 		code: "+591",
// 		currencyShortName: "BOB",
// 		currency: "Bs.",
// 	},
// 	"Bonaire, Sint Eustatius and Saba": {
// 		name: "Bonaire, Sint Eustatius and Saba",
// 		countryShortName: "BQ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bonaire,-sint-eustatius-and-saba.png",
// 		code: "+5997",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	"Bosnia and Herzegovina": {
// 		name: "Bosnia and Herzegovina",
// 		countryShortName: "BA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bosnia-and-herzegovina.png",
// 		code: "+387",
// 		currencyShortName: "BAM",
// 		currency: null,
// 	},
// 	Botswana: {
// 		name: "Botswana",
// 		countryShortName: "BW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-botswana.png",
// 		code: "+267",
// 		currencyShortName: "BWP",
// 		currency: "P",
// 	},
// 	"Bouvet Island": {
// 		name: "Bouvet Island",
// 		countryShortName: "BV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bouvet-island.png",
// 		code: "+",
// 		currencyShortName: "NOK",
// 		currency: "kr",
// 	},
// 	Brazil: {
// 		name: "Brazil",
// 		countryShortName: "BR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-brazil.png",
// 		code: "+55",
// 		currencyShortName: "BRL",
// 		currency: "R$",
// 	},
// 	"British Indian Ocean Territory": {
// 		name: "British Indian Ocean Territory",
// 		countryShortName: "IO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-british-indian-ocean-territory.png",
// 		code: "+246",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	"United States Minor Outlying Islands": {
// 		name: "United States Minor Outlying Islands",
// 		countryShortName: "UM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-minor-outlying-islands.png",
// 		code: "+",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	"Virgin Islands (British)": {
// 		name: "Virgin Islands (British)",
// 		countryShortName: "VG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-virgin-islands-(british).png",
// 		code: "+1284",
// 		currencyShortName: null,
// 		currency: "$",
// 	},
// 	"Virgin Islands (U.S.)": {
// 		name: "Virgin Islands (U.S.)",
// 		countryShortName: "VI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-virgin-islands-(u.s.).png",
// 		code: "+1 340",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	"Brunei Darussalam": {
// 		name: "Brunei Darussalam",
// 		countryShortName: "BN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-brunei-darussalam.png",
// 		code: "+673",
// 		currencyShortName: "BND",
// 		currency: "$",
// 	},
// 	Bulgaria: {
// 		name: "Bulgaria",
// 		countryShortName: "BG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bulgaria.png",
// 		code: "+359",
// 		currencyShortName: "BGN",
// 		currency: "лв",
// 	},
// 	"Burkina Faso": {
// 		name: "Burkina Faso",
// 		countryShortName: "BF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-burkina-faso.png",
// 		code: "+226",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Burundi: {
// 		name: "Burundi",
// 		countryShortName: "BI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-burundi.png",
// 		code: "+257",
// 		currencyShortName: "BIF",
// 		currency: "Fr",
// 	},
// 	Cambodia: {
// 		name: "Cambodia",
// 		countryShortName: "KH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cambodia.png",
// 		code: "+855",
// 		currencyShortName: "KHR",
// 		currency: "៛",
// 	},
// 	Cameroon: {
// 		name: "Cameroon",
// 		countryShortName: "CM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cameroon.png",
// 		code: "+237",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	Canada: {
// 		name: "Canada",
// 		countryShortName: "CA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-canada.png",
// 		code: "+1",
// 		currencyShortName: "CAD",
// 		currency: "$",
// 	},
// 	"Cabo Verde": {
// 		name: "Cabo Verde",
// 		countryShortName: "CV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cabo-verde.png",
// 		code: "+238",
// 		currencyShortName: "CVE",
// 		currency: "Esc",
// 	},
// 	"Cayman Islands": {
// 		name: "Cayman Islands",
// 		countryShortName: "KY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cayman-islands.png",
// 		code: "+1345",
// 		currencyShortName: "KYD",
// 		currency: "$",
// 	},
// 	"Central African Republic": {
// 		name: "Central African Republic",
// 		countryShortName: "CF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-central-african-republic.png",
// 		code: "+236",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	Chad: {
// 		name: "Chad",
// 		countryShortName: "TD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-chad.png",
// 		code: "+235",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	Chile: {
// 		name: "Chile",
// 		countryShortName: "CL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-chile.png",
// 		code: "+56",
// 		currencyShortName: "CLP",
// 		currency: "$",
// 	},
// 	China: {
// 		name: "China",
// 		countryShortName: "CN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-china.png",
// 		code: "+86",
// 		currencyShortName: "CNY",
// 		currency: "¥",
// 	},
// 	"Christmas Island": {
// 		name: "Christmas Island",
// 		countryShortName: "CX",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-christmas-island.png",
// 		code: "+61",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	"Cocos (Keeling) Islands": {
// 		name: "Cocos (Keeling) Islands",
// 		countryShortName: "CC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cocos-(keeling)-islands.png",
// 		code: "+61",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	Colombia: {
// 		name: "Colombia",
// 		countryShortName: "CO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-colombia.png",
// 		code: "+57",
// 		currencyShortName: "COP",
// 		currency: "$",
// 	},
// 	Comoros: {
// 		name: "Comoros",
// 		countryShortName: "KM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-comoros.png",
// 		code: "+269",
// 		currencyShortName: "KMF",
// 		currency: "Fr",
// 	},
// 	Congo: {
// 		name: "Congo",
// 		countryShortName: "CG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-congo.png",
// 		code: "+242",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	"Congo (Democratic Republic of the)": {
// 		name: "Congo (Democratic Republic of the)",
// 		countryShortName: "CD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-congo-(democratic-republic-of-the).png",
// 		code: "+243",
// 		currencyShortName: "CDF",
// 		currency: "Fr",
// 	},
// 	"Cook Islands": {
// 		name: "Cook Islands",
// 		countryShortName: "CK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cook-islands.png",
// 		code: "+682",
// 		currencyShortName: "NZD",
// 		currency: "$",
// 	},
// 	"Costa Rica": {
// 		name: "Costa Rica",
// 		countryShortName: "CR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-costa-rica.png",
// 		code: "+506",
// 		currencyShortName: "CRC",
// 		currency: "₡",
// 	},
// 	Croatia: {
// 		name: "Croatia",
// 		countryShortName: "HR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-croatia.png",
// 		code: "+385",
// 		currencyShortName: "HRK",
// 		currency: "kn",
// 	},
// 	Cuba: {
// 		name: "Cuba",
// 		countryShortName: "CU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cuba.png",
// 		code: "+53",
// 		currencyShortName: "CUC",
// 		currency: "$",
// 	},
// 	Curaçao: {
// 		name: "Curaçao",
// 		countryShortName: "CW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-curacao.png",
// 		code: "+599",
// 		currencyShortName: "ANG",
// 		currency: "ƒ",
// 	},
// 	Cyprus: {
// 		name: "Cyprus",
// 		countryShortName: "CY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-cyprus.png",
// 		code: "+357",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Czech Republic": {
// 		name: "Czech Republic",
// 		countryShortName: "CZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-czech-republic.png",
// 		code: "+420",
// 		currencyShortName: "CZK",
// 		currency: "Kč",
// 	},
// 	Denmark: {
// 		name: "Denmark",
// 		countryShortName: "DK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-denmark.png",
// 		code: "+45",
// 		currencyShortName: "DKK",
// 		currency: "kr",
// 	},
// 	Djibouti: {
// 		name: "Djibouti",
// 		countryShortName: "DJ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-djibouti.png",
// 		code: "+253",
// 		currencyShortName: "DJF",
// 		currency: "Fr",
// 	},
// 	Dominica: {
// 		name: "Dominica",
// 		countryShortName: "DM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-dominica.png",
// 		code: "+1767",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	"Dominican Republic": {
// 		name: "Dominican Republic",
// 		countryShortName: "DO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-dominican-republic.png",
// 		code: "+1",
// 		currencyShortName: "DOP",
// 		currency: "$",
// 	},
// 	Ecuador: {
// 		name: "Ecuador",
// 		countryShortName: "EC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ecuador.png",
// 		code: "+593",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Egypt: {
// 		name: "Egypt",
// 		countryShortName: "EG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-egypt.png",
// 		code: "+20",
// 		currencyShortName: "EGP",
// 		currency: "£",
// 	},
// 	"El Salvador": {
// 		name: "El Salvador",
// 		countryShortName: "SV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-el-salvador.png",
// 		code: "+503",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	"Equatorial Guinea": {
// 		name: "Equatorial Guinea",
// 		countryShortName: "GQ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-equatorial-guinea.png",
// 		code: "+240",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	Eritrea: {
// 		name: "Eritrea",
// 		countryShortName: "ER",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-eritrea.png",
// 		code: "+291",
// 		currencyShortName: "ERN",
// 		currency: "Nfk",
// 	},
// 	Estonia: {
// 		name: "Estonia",
// 		countryShortName: "EE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-estonia.png",
// 		code: "+372",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Ethiopia: {
// 		name: "Ethiopia",
// 		countryShortName: "ET",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ethiopia.png",
// 		code: "+251",
// 		currencyShortName: "ETB",
// 		currency: "Br",
// 	},
// 	"Falkland Islands (Malvinas)": {
// 		name: "Falkland Islands (Malvinas)",
// 		countryShortName: "FK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-falkland-islands-(malvinas).png",
// 		code: "+500",
// 		currencyShortName: "FKP",
// 		currency: "£",
// 	},
// 	"Faroe Islands": {
// 		name: "Faroe Islands",
// 		countryShortName: "FO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-faroe-islands.png",
// 		code: "+298",
// 		currencyShortName: "DKK",
// 		currency: "kr",
// 	},
// 	Fiji: {
// 		name: "Fiji",
// 		countryShortName: "FJ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-fiji.png",
// 		code: "+679",
// 		currencyShortName: "FJD",
// 		currency: "$",
// 	},
// 	Finland: {
// 		name: "Finland",
// 		countryShortName: "FI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-finland.png",
// 		code: "+358",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	France: {
// 		name: "France",
// 		countryShortName: "FR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-france.png",
// 		code: "+33",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"French Guiana": {
// 		name: "French Guiana",
// 		countryShortName: "GF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-french-guiana.png",
// 		code: "+594",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"French Polynesia": {
// 		name: "French Polynesia",
// 		countryShortName: "PF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-french-polynesia.png",
// 		code: "+689",
// 		currencyShortName: "XPF",
// 		currency: "Fr",
// 	},
// 	"French Southern Territories": {
// 		name: "French Southern Territories",
// 		countryShortName: "TF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-french-southern-territories.png",
// 		code: "+",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Gabon: {
// 		name: "Gabon",
// 		countryShortName: "GA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-gabon.png",
// 		code: "+241",
// 		currencyShortName: "XAF",
// 		currency: "Fr",
// 	},
// 	Gambia: {
// 		name: "Gambia",
// 		countryShortName: "GM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-gambia.png",
// 		code: "+220",
// 		currencyShortName: "GMD",
// 		currency: "D",
// 	},
// 	Georgia: {
// 		name: "Georgia",
// 		countryShortName: "GE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-georgia.png",
// 		code: "+995",
// 		currencyShortName: "GEL",
// 		currency: "ლ",
// 	},
// 	Germany: {
// 		name: "Germany",
// 		countryShortName: "DE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-germany.png",
// 		code: "+49",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Ghana: {
// 		name: "Ghana",
// 		countryShortName: "GH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ghana.png",
// 		code: "+233",
// 		currencyShortName: "GHS",
// 		currency: "₵",
// 	},
// 	Gibraltar: {
// 		name: "Gibraltar",
// 		countryShortName: "GI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-gibraltar.png",
// 		code: "+350",
// 		currencyShortName: "GIP",
// 		currency: "£",
// 	},
// 	Greece: {
// 		name: "Greece",
// 		countryShortName: "GR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-greece.png",
// 		code: "+30",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Greenland: {
// 		name: "Greenland",
// 		countryShortName: "GL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-greenland.png",
// 		code: "+299",
// 		currencyShortName: "DKK",
// 		currency: "kr",
// 	},
// 	Grenada: {
// 		name: "Grenada",
// 		countryShortName: "GD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-grenada.png",
// 		code: "+1473",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	Guadeloupe: {
// 		name: "Guadeloupe",
// 		countryShortName: "GP",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guadeloupe.png",
// 		code: "+590",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Guam: {
// 		name: "Guam",
// 		countryShortName: "GU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guam.png",
// 		code: "+1671",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Guatemala: {
// 		name: "Guatemala",
// 		countryShortName: "GT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guatemala.png",
// 		code: "+502",
// 		currencyShortName: "GTQ",
// 		currency: "Q",
// 	},
// 	Guernsey: {
// 		name: "Guernsey",
// 		countryShortName: "GG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guernsey.png",
// 		code: "+44",
// 		currencyShortName: "GBP",
// 		currency: "£",
// 	},
// 	Guinea: {
// 		name: "Guinea",
// 		countryShortName: "GN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guinea.png",
// 		code: "+224",
// 		currencyShortName: "GNF",
// 		currency: "Fr",
// 	},
// 	"Guinea-Bissau": {
// 		name: "Guinea-Bissau",
// 		countryShortName: "GW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guinea-bissau.png",
// 		code: "+245",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Guyana: {
// 		name: "Guyana",
// 		countryShortName: "GY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-guyana.png",
// 		code: "+592",
// 		currencyShortName: "GYD",
// 		currency: "$",
// 	},
// 	Haiti: {
// 		name: "Haiti",
// 		countryShortName: "HT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-haiti.png",
// 		code: "+509",
// 		currencyShortName: "HTG",
// 		currency: "G",
// 	},
// 	"Heard Island and McDonald Islands": {
// 		name: "Heard Island and McDonald Islands",
// 		countryShortName: "HM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-heard-island-and-mcdonald-islands.png",
// 		code: "+",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	"Holy See": {
// 		name: "Holy See",
// 		countryShortName: "VA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-holy-see.png",
// 		code: "+379",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Honduras: {
// 		name: "Honduras",
// 		countryShortName: "HN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-honduras.png",
// 		code: "+504",
// 		currencyShortName: "HNL",
// 		currency: "L",
// 	},
// 	"Hong Kong SAR": {
// 		name: "Hong Kong SAR",
// 		countryShortName: "HK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-hong-kong.png",
// 		code: "+852",
// 		currencyShortName: "HKD",
// 		currency: "$",
// 	},
// 	Hungary: {
// 		name: "Hungary",
// 		countryShortName: "HU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-hungary.png",
// 		code: "+36",
// 		currencyShortName: "HUF",
// 		currency: "Ft",
// 	},
// 	Iceland: {
// 		name: "Iceland",
// 		countryShortName: "IS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-iceland.png",
// 		code: "+354",
// 		currencyShortName: "ISK",
// 		currency: "kr",
// 	},
// 	India: {
// 		name: "India",
// 		countryShortName: "IN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-india.png",
// 		code: "+91",
// 		currencyShortName: "INR",
// 		currency: "₹",
// 	},
// 	Indonesia: {
// 		name: "Indonesia",
// 		countryShortName: "ID",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-indonesia.png",
// 		code: "+62",
// 		currencyShortName: "IDR",
// 		currency: "Rp",
// 	},
// 	"Côte d'Ivoire": {
// 		name: "Côte d'Ivoire",
// 		countryShortName: "CI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ivory-coast.png",
// 		code: "+225",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Iran: {
// 		name: "Iran",
// 		countryShortName: "IR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-iran-(islamic-republic-of).png",
// 		code: "+98",
// 		currencyShortName: "IRR",
// 		currency: "﷼",
// 	},
// 	Iraq: {
// 		name: "Iraq",
// 		countryShortName: "IQ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-iraq.png",
// 		code: "+964",
// 		currencyShortName: "IQD",
// 		currency: "ع.د",
// 	},
// 	Ireland: {
// 		name: "Ireland",
// 		countryShortName: "IE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ireland.png",
// 		code: "+353",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Isle of Man": {
// 		name: "Isle of Man",
// 		countryShortName: "IM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-isle-of-man.png",
// 		code: "+44",
// 		currencyShortName: "GBP",
// 		currency: "£",
// 	},
// 	Israel: {
// 		name: "Israel",
// 		countryShortName: "IL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-israel.png",
// 		code: "+972",
// 		currencyShortName: "ILS",
// 		currency: "₪",
// 	},
// 	Italy: {
// 		name: "Italy",
// 		countryShortName: "IT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-italy.png",
// 		code: "+39",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Jamaica: {
// 		name: "Jamaica",
// 		countryShortName: "JM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-jamaica.png",
// 		code: "+1876",
// 		currencyShortName: "JMD",
// 		currency: "$",
// 	},
// 	Japan: {
// 		name: "Japan",
// 		countryShortName: "JP",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-japan.png",
// 		code: "+81",
// 		currencyShortName: "JPY",
// 		currency: "¥",
// 	},
// 	Jersey: {
// 		name: "Jersey",
// 		countryShortName: "JE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-jersey.png",
// 		code: "+44",
// 		currencyShortName: "GBP",
// 		currency: "£",
// 	},
// 	Jordan: {
// 		name: "Jordan",
// 		countryShortName: "JO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-jordan.png",
// 		code: "+962",
// 		currencyShortName: "JOD",
// 		currency: "د.ا",
// 	},
// 	Kazakhstan: {
// 		name: "Kazakhstan",
// 		countryShortName: "KZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-kazakhstan.png",
// 		code: "+7",
// 		currencyShortName: "KZT",
// 		currency: null,
// 	},
// 	Kenya: {
// 		name: "Kenya",
// 		countryShortName: "KE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-kenya.png",
// 		code: "+254",
// 		currencyShortName: "KES",
// 		currency: "Sh",
// 	},
// 	Kiribati: {
// 		name: "Kiribati",
// 		countryShortName: "KI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-kiribati.png",
// 		code: "+686",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	Kuwait: {
// 		name: "Kuwait",
// 		countryShortName: "KW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-kuwait.png",
// 		code: "+965",
// 		currencyShortName: "KWD",
// 		currency: "د.ك",
// 	},
// 	Kyrgyzstan: {
// 		name: "Kyrgyzstan",
// 		countryShortName: "KG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-kyrgyzstan.png",
// 		code: "+996",
// 		currencyShortName: "KGS",
// 		currency: "с",
// 	},
// 	"Lao People's Democratic Republic": {
// 		name: "Lao People's Democratic Republic",
// 		countryShortName: "LA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-lao-people's-democratic-republic.png",
// 		code: "+856",
// 		currencyShortName: "LAK",
// 		currency: "₭",
// 	},
// 	Latvia: {
// 		name: "Latvia",
// 		countryShortName: "LV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-latvia.png",
// 		code: "+371",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Lebanon: {
// 		name: "Lebanon",
// 		countryShortName: "LB",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-lebanon.png",
// 		code: "+961",
// 		currencyShortName: "LBP",
// 		currency: "ل.ل",
// 	},
// 	Lesotho: {
// 		name: "Lesotho",
// 		countryShortName: "LS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-lesotho.png",
// 		code: "+266",
// 		currencyShortName: "LSL",
// 		currency: "L",
// 	},
// 	Liberia: {
// 		name: "Liberia",
// 		countryShortName: "LR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-liberia.png",
// 		code: "+231",
// 		currencyShortName: "LRD",
// 		currency: "$",
// 	},
// 	Libya: {
// 		name: "Libya",
// 		countryShortName: "LY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-libya.png",
// 		code: "+218",
// 		currencyShortName: "LYD",
// 		currency: "ل.د",
// 	},
// 	Liechtenstein: {
// 		name: "Liechtenstein",
// 		countryShortName: "LI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-liechtenstein.png",
// 		code: "+423",
// 		currencyShortName: "CHF",
// 		currency: "Fr",
// 	},
// 	Lithuania: {
// 		name: "Lithuania",
// 		countryShortName: "LT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-lithuania.png",
// 		code: "+370",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Luxembourg: {
// 		name: "Luxembourg",
// 		countryShortName: "LU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-luxembourg.png",
// 		code: "+352",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Macao: {
// 		name: "Macao",
// 		countryShortName: "MO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-macao.png",
// 		code: "+853",
// 		currencyShortName: "MOP",
// 		currency: "P",
// 	},
// 	"Macedonia (the former Yugoslav Republic of)": {
// 		name: "Macedonia (the former Yugoslav Republic of)",
// 		countryShortName: "MK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-macedonia-(the-former-yugoslav-republic-of).png",
// 		code: "+389",
// 		currencyShortName: "MKD",
// 		currency: "ден",
// 	},
// 	Madagascar: {
// 		name: "Madagascar",
// 		countryShortName: "MG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-madagascar.png",
// 		code: "+261",
// 		currencyShortName: "MGA",
// 		currency: "Ar",
// 	},
// 	Malawi: {
// 		name: "Malawi",
// 		countryShortName: "MW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-malawi.png",
// 		code: "+265",
// 		currencyShortName: "MWK",
// 		currency: "MK",
// 	},
// 	Malaysia: {
// 		name: "Malaysia",
// 		countryShortName: "MY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-malaysia.png",
// 		code: "+60",
// 		currencyShortName: "MYR",
// 		currency: "RM",
// 	},
// 	Maldives: {
// 		name: "Maldives",
// 		countryShortName: "MV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-maldives.png",
// 		code: "+960",
// 		currencyShortName: "MVR",
// 		currency: ".ރ",
// 	},
// 	Mali: {
// 		name: "Mali",
// 		countryShortName: "ML",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mali.png",
// 		code: "+223",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Malta: {
// 		name: "Malta",
// 		countryShortName: "MT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-malta.png",
// 		code: "+356",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Marshall Islands": {
// 		name: "Marshall Islands",
// 		countryShortName: "MH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-marshall-islands.png",
// 		code: "+692",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Martinique: {
// 		name: "Martinique",
// 		countryShortName: "MQ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-martinique.png",
// 		code: "+596",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Mauritania: {
// 		name: "Mauritania",
// 		countryShortName: "MR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mauritania.png",
// 		code: "+222",
// 		currencyShortName: "MRO",
// 		currency: "UM",
// 	},
// 	Mauritius: {
// 		name: "Mauritius",
// 		countryShortName: "MU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mauritius.png",
// 		code: "+230",
// 		currencyShortName: "MUR",
// 		currency: "₨",
// 	},
// 	Mayotte: {
// 		name: "Mayotte",
// 		countryShortName: "YT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mayotte.png",
// 		code: "+262",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Mexico: {
// 		name: "Mexico",
// 		countryShortName: "MX",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mexico.png",
// 		code: "+52",
// 		currencyShortName: "MXN",
// 		currency: "$",
// 	},
// 	"Micronesia (Federated States of)": {
// 		name: "Micronesia (Federated States of)",
// 		countryShortName: "FM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-micronesia-(federated-states-of).png",
// 		code: "+691",
// 		currencyShortName: null,
// 		currency: "$",
// 	},
// 	"Moldova (Republic of)": {
// 		name: "Moldova (Republic of)",
// 		countryShortName: "MD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-moldova-(republic-of).png",
// 		code: "+373",
// 		currencyShortName: "MDL",
// 		currency: "L",
// 	},
// 	Monaco: {
// 		name: "Monaco",
// 		countryShortName: "MC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-monaco.png",
// 		code: "+377",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Mongolia: {
// 		name: "Mongolia",
// 		countryShortName: "MN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mongolia.png",
// 		code: "+976",
// 		currencyShortName: "MNT",
// 		currency: "₮",
// 	},
// 	Montenegro: {
// 		name: "Montenegro",
// 		countryShortName: "ME",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-montenegro.png",
// 		code: "+382",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Montserrat: {
// 		name: "Montserrat",
// 		countryShortName: "MS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-montserrat.png",
// 		code: "+1664",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	Morocco: {
// 		name: "Morocco",
// 		countryShortName: "MA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-morocco.png",
// 		code: "+212",
// 		currencyShortName: "MAD",
// 		currency: "د.م.",
// 	},
// 	Mozambique: {
// 		name: "Mozambique",
// 		countryShortName: "MZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-mozambique.png",
// 		code: "+258",
// 		currencyShortName: "MZN",
// 		currency: "MT",
// 	},
// 	Myanmar: {
// 		name: "Myanmar",
// 		countryShortName: "MM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-myanmar.png",
// 		code: "+95",
// 		currencyShortName: "MMK",
// 		currency: "Ks",
// 	},
// 	Namibia: {
// 		name: "Namibia",
// 		countryShortName: "NA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-namibia.png",
// 		code: "+264",
// 		currencyShortName: "NAD",
// 		currency: "$",
// 	},
// 	Nauru: {
// 		name: "Nauru",
// 		countryShortName: "NR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-nauru.png",
// 		code: "+674",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	Nepal: {
// 		name: "Nepal",
// 		countryShortName: "NP",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-nepal.png",
// 		code: "+977",
// 		currencyShortName: "NPR",
// 		currency: "₨",
// 	},
// 	Netherlands: {
// 		name: "Netherlands",
// 		countryShortName: "NL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-netherlands.png",
// 		code: "+31",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"New Caledonia": {
// 		name: "New Caledonia",
// 		countryShortName: "NC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-new-caledonia.png",
// 		code: "+687",
// 		currencyShortName: "XPF",
// 		currency: "Fr",
// 	},
// 	"New Zealand": {
// 		name: "New Zealand",
// 		countryShortName: "NZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-new-zealand.png",
// 		code: "+64",
// 		currencyShortName: "NZD",
// 		currency: "$",
// 	},
// 	Nicaragua: {
// 		name: "Nicaragua",
// 		countryShortName: "NI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-nicaragua.png",
// 		code: "+505",
// 		currencyShortName: "NIO",
// 		currency: "C$",
// 	},
// 	Niger: {
// 		name: "Niger",
// 		countryShortName: "NE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-niger.png",
// 		code: "+227",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Nigeria: {
// 		name: "Nigeria",
// 		countryShortName: "NG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-nigeria.png",
// 		code: "+234",
// 		currencyShortName: "NGN",
// 		currency: "₦",
// 	},
// 	Niue: {
// 		name: "Niue",
// 		countryShortName: "NU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-niue.png",
// 		code: "+683",
// 		currencyShortName: "NZD",
// 		currency: "$",
// 	},
// 	"Norfolk Island": {
// 		name: "Norfolk Island",
// 		countryShortName: "NF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-norfolk-island.png",
// 		code: "+672",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	"Korea (Democratic People's Republic of)": {
// 		name: "Korea (Democratic People's Republic of)",
// 		countryShortName: "KP",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-korea-(democratic-people's-republic-of).png",
// 		code: "+850",
// 		currencyShortName: "KPW",
// 		currency: "₩",
// 	},
// 	"Northern Mariana Islands": {
// 		name: "Northern Mariana Islands",
// 		countryShortName: "MP",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-northern-mariana-islands.png",
// 		code: "+1670",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Norway: {
// 		name: "Norway",
// 		countryShortName: "NO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-norway.png",
// 		code: "+47",
// 		currencyShortName: "NOK",
// 		currency: "kr",
// 	},
// 	Oman: {
// 		name: "Oman",
// 		countryShortName: "OM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-oman.png",
// 		code: "+968",
// 		currencyShortName: "OMR",
// 		currency: "ر.ع.",
// 	},
// 	Pakistan: {
// 		name: "Pakistan",
// 		countryShortName: "PK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-pakistan.png",
// 		code: "+92",
// 		currencyShortName: "PKR",
// 		currency: "₨",
// 	},
// 	Palau: {
// 		name: "Palau",
// 		countryShortName: "PW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-palau.png",
// 		code: "+680",
// 		currencyShortName: "(none)",
// 		currency: "$",
// 	},
// 	"Palestine, State of": {
// 		name: "Palestine, State of",
// 		countryShortName: "PS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-palestine,-state-of.png",
// 		code: "+970",
// 		currencyShortName: "ILS",
// 		currency: "₪",
// 	},
// 	Panama: {
// 		name: "Panama",
// 		countryShortName: "PA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-panama.png",
// 		code: "+507",
// 		currencyShortName: "PAB",
// 		currency: "B/.",
// 	},
// 	"Papua New Guinea": {
// 		name: "Papua New Guinea",
// 		countryShortName: "PG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-papua-new-guinea.png",
// 		code: "+675",
// 		currencyShortName: "PGK",
// 		currency: "K",
// 	},
// 	Paraguay: {
// 		name: "Paraguay",
// 		countryShortName: "PY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-paraguay.png",
// 		code: "+595",
// 		currencyShortName: "PYG",
// 		currency: "₲",
// 	},
// 	Peru: {
// 		name: "Peru",
// 		countryShortName: "PE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-peru.png",
// 		code: "+51",
// 		currencyShortName: "PEN",
// 		currency: "S/.",
// 	},
// 	Philippines: {
// 		name: "Philippines",
// 		countryShortName: "PH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-philippines.png",
// 		code: "+63",
// 		currencyShortName: "PHP",
// 		currency: "₱",
// 	},
// 	Pitcairn: {
// 		name: "Pitcairn",
// 		countryShortName: "PN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-pitcairn.png",
// 		code: "+64",
// 		currencyShortName: "NZD",
// 		currency: "$",
// 	},
// 	Poland: {
// 		name: "Poland",
// 		countryShortName: "PL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-poland.png",
// 		code: "+48",
// 		currencyShortName: "PLN",
// 		currency: "zł",
// 	},
// 	Portugal: {
// 		name: "Portugal",
// 		countryShortName: "PT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-portugal.png",
// 		code: "+351",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Puerto Rico": {
// 		name: "Puerto Rico",
// 		countryShortName: "PR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-puerto-rico.png",
// 		code: "+1",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Qatar: {
// 		name: "Qatar",
// 		countryShortName: "QA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-qatar.png",
// 		code: "+974",
// 		currencyShortName: "QAR",
// 		currency: "ر.ق",
// 	},
// 	"Republic of Kosovo": {
// 		name: "Republic of Kosovo",
// 		countryShortName: "XK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-republic-of-kosovo.png",
// 		code: "+383",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Réunion: {
// 		name: "Réunion",
// 		countryShortName: "RE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-reunion.png",
// 		code: "+262",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Romania: {
// 		name: "Romania",
// 		countryShortName: "RO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-romania.png",
// 		code: "+40",
// 		currencyShortName: "RON",
// 		currency: "lei",
// 	},
// 	"Russian Federation": {
// 		name: "Russian Federation",
// 		countryShortName: "RU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-russian-federation.png",
// 		code: "+7",
// 		currencyShortName: "RUB",
// 		currency: "₽",
// 	},
// 	Rwanda: {
// 		name: "Rwanda",
// 		countryShortName: "RW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-rwanda.png",
// 		code: "+250",
// 		currencyShortName: "RWF",
// 		currency: "Fr",
// 	},
// 	"Saint Barthélemy": {
// 		name: "Saint Barthélemy",
// 		countryShortName: "BL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-barthelemy.png",
// 		code: "+590",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Saint Helena, Ascension and Tristan da Cunha": {
// 		name: "Saint Helena, Ascension and Tristan da Cunha",
// 		countryShortName: "SH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-helena,-ascension-and-tristan-da-cunha.png",
// 		code: "+290",
// 		currencyShortName: "SHP",
// 		currency: "£",
// 	},
// 	"Saint Kitts and Nevis": {
// 		name: "Saint Kitts and Nevis",
// 		countryShortName: "KN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-kitts-and-nevis.png",
// 		code: "+1869",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	"Saint Lucia": {
// 		name: "Saint Lucia",
// 		countryShortName: "LC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-lucia.png",
// 		code: "+1758",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	"Saint Martin (French part)": {
// 		name: "Saint Martin (French part)",
// 		countryShortName: "MF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-martin-(french-part).png",
// 		code: "+590",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Saint Pierre and Miquelon": {
// 		name: "Saint Pierre and Miquelon",
// 		countryShortName: "PM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-pierre-and-miquelon.png",
// 		code: "+508",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"St. Vincent and the Grenadines": {
// 		name: "St. Vincent and the Grenadines",
// 		countryShortName: "VC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saint-vincent-and-the-grenadines.png",
// 		code: "+1784",
// 		currencyShortName: "XCD",
// 		currency: "$",
// 	},
// 	Samoa: {
// 		name: "Samoa",
// 		countryShortName: "WS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-samoa.png",
// 		code: "+685",
// 		currencyShortName: "WST",
// 		currency: "T",
// 	},
// 	"San Marino": {
// 		name: "San Marino",
// 		countryShortName: "SM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-san-marino.png",
// 		code: "+378",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Sao Tome and Principe": {
// 		name: "Sao Tome and Principe",
// 		countryShortName: "ST",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sao-tome-and-principe.png",
// 		code: "+239",
// 		currencyShortName: "STD",
// 		currency: "Db",
// 	},
// 	"Saudi Arabia": {
// 		name: "Saudi Arabia",
// 		countryShortName: "SA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-saudi-arabia.png",
// 		code: "+966",
// 		currencyShortName: "SAR",
// 		currency: "ر.س",
// 	},
// 	Senegal: {
// 		name: "Senegal",
// 		countryShortName: "SN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-senegal.png",
// 		code: "+221",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Serbia: {
// 		name: "Serbia",
// 		countryShortName: "RS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-serbia.png",
// 		code: "+381",
// 		currencyShortName: "RSD",
// 		currency: "дин.",
// 	},
// 	Seychelles: {
// 		name: "Seychelles",
// 		countryShortName: "SC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-seychelles.png",
// 		code: "+248",
// 		currencyShortName: "SCR",
// 		currency: "₨",
// 	},
// 	"Sierra Leone": {
// 		name: "Sierra Leone",
// 		countryShortName: "SL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sierra-leone.png",
// 		code: "+232",
// 		currencyShortName: "SLL",
// 		currency: "Le",
// 	},
// 	Singapore: {
// 		name: "Singapore",
// 		countryShortName: "SG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-singapore.png",
// 		code: "+65",
// 		currencyShortName: "BND",
// 		currency: "$",
// 	},
// 	"Sint Maarten (Dutch part)": {
// 		name: "Sint Maarten (Dutch part)",
// 		countryShortName: "SX",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sint-maarten-(dutch-part).png",
// 		code: "+1721",
// 		currencyShortName: "ANG",
// 		currency: "ƒ",
// 	},
// 	Slovakia: {
// 		name: "Slovakia",
// 		countryShortName: "SK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-slovakia.png",
// 		code: "+421",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	Slovenia: {
// 		name: "Slovenia",
// 		countryShortName: "SI",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-slovenia.png",
// 		code: "+386",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Solomon Islands": {
// 		name: "Solomon Islands",
// 		countryShortName: "SB",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-solomon-islands.png",
// 		code: "+677",
// 		currencyShortName: "SBD",
// 		currency: "$",
// 	},
// 	Somalia: {
// 		name: "Somalia",
// 		countryShortName: "SO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-somalia.png",
// 		code: "+252",
// 		currencyShortName: "SOS",
// 		currency: "Sh",
// 	},
// 	"South Africa": {
// 		name: "South Africa",
// 		countryShortName: "ZA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-south-africa.png",
// 		code: "+27",
// 		currencyShortName: "ZAR",
// 		currency: "R",
// 	},
// 	"South Georgia and the South Sandwich Islands": {
// 		name: "South Georgia and the South Sandwich Islands",
// 		countryShortName: "GS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-south-georgia-and-the-south-sandwich-islands.png",
// 		code: "+500",
// 		currencyShortName: "GBP",
// 		currency: "£",
// 	},
// 	"Korea (Republic of)": {
// 		name: "Korea (Republic of)",
// 		countryShortName: "KR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-korea-(republic-of).png",
// 		code: "+82",
// 		currencyShortName: "KRW",
// 		currency: "₩",
// 	},
// 	"South Sudan": {
// 		name: "South Sudan",
// 		countryShortName: "SS",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-south-sudan.png",
// 		code: "+211",
// 		currencyShortName: "SSP",
// 		currency: "£",
// 	},
// 	Spain: {
// 		name: "Spain",
// 		countryShortName: "ES",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-spain.png",
// 		code: "+34",
// 		currencyShortName: "EUR",
// 		currency: "€",
// 	},
// 	"Sri Lanka": {
// 		name: "Sri Lanka",
// 		countryShortName: "LK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sri-lanka.png",
// 		code: "+94",
// 		currencyShortName: "LKR",
// 		currency: "Rs",
// 	},
// 	Sudan: {
// 		name: "Sudan",
// 		countryShortName: "SD",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sudan.png",
// 		code: "+249",
// 		currencyShortName: "SDG",
// 		currency: "ج.س.",
// 	},
// 	Suriname: {
// 		name: "Suriname",
// 		countryShortName: "SR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-suriname.png",
// 		code: "+597",
// 		currencyShortName: "SRD",
// 		currency: "$",
// 	},
// 	"Svalbard and Jan Mayen": {
// 		name: "Svalbard and Jan Mayen",
// 		countryShortName: "SJ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-svalbard-and-jan-mayen.png",
// 		code: "+4779",
// 		currencyShortName: "NOK",
// 		currency: "kr",
// 	},
// 	Swaziland: {
// 		name: "Swaziland",
// 		countryShortName: "SZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-swaziland.png",
// 		code: "+268",
// 		currencyShortName: "SZL",
// 		currency: "L",
// 	},
// 	Sweden: {
// 		name: "Sweden",
// 		countryShortName: "SE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-sweden.png",
// 		code: "+46",
// 		currencyShortName: "SEK",
// 		currency: "kr",
// 	},
// 	Switzerland: {
// 		name: "Switzerland",
// 		countryShortName: "CH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-switzerland.png",
// 		code: "+41",
// 		currencyShortName: "CHF",
// 		currency: "Fr",
// 	},
// 	"Syrian Arab Republic": {
// 		name: "Syrian Arab Republic",
// 		countryShortName: "SY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-syrian-arab-republic.png",
// 		code: "+963",
// 		currencyShortName: "SYP",
// 		currency: "£",
// 	},
// 	Taiwan: {
// 		name: "Taiwan",
// 		countryShortName: "TW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-taiwan.png",
// 		code: "+886",
// 		currencyShortName: "TWD",
// 		currency: "$",
// 	},
// 	Tajikistan: {
// 		name: "Tajikistan",
// 		countryShortName: "TJ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tajikistan.png",
// 		code: "+992",
// 		currencyShortName: "TJS",
// 		currency: "ЅМ",
// 	},
// 	"Tanzania, United Republic of": {
// 		name: "Tanzania, United Republic of",
// 		countryShortName: "TZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tanzania,-united-republic-of.png",
// 		code: "+255",
// 		currencyShortName: "TZS",
// 		currency: "Sh",
// 	},
// 	Thailand: {
// 		name: "Thailand",
// 		countryShortName: "TH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-thailand.png",
// 		code: "+66",
// 		currencyShortName: "THB",
// 		currency: "฿",
// 	},
// 	"Timor-Leste": {
// 		name: "Timor-Leste",
// 		countryShortName: "TL",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-timor-leste.png",
// 		code: "+670",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Togo: {
// 		name: "Togo",
// 		countryShortName: "TG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-togo.png",
// 		code: "+228",
// 		currencyShortName: "XOF",
// 		currency: "Fr",
// 	},
// 	Tokelau: {
// 		name: "Tokelau",
// 		countryShortName: "TK",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tokelau.png",
// 		code: "+690",
// 		currencyShortName: "NZD",
// 		currency: "$",
// 	},
// 	Tonga: {
// 		name: "Tonga",
// 		countryShortName: "TO",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tonga.png",
// 		code: "+676",
// 		currencyShortName: "TOP",
// 		currency: "T$",
// 	},
// 	"Trinidad and Tobago": {
// 		name: "Trinidad and Tobago",
// 		countryShortName: "TT",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-trinidad-and-tobago.png",
// 		code: "+1868",
// 		currencyShortName: "TTD",
// 		currency: "$",
// 	},
// 	Tunisia: {
// 		name: "Tunisia",
// 		countryShortName: "TN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tunisia.png",
// 		code: "+216",
// 		currencyShortName: "TND",
// 		currency: "د.ت",
// 	},
// 	Turkey: {
// 		name: "Turkey",
// 		countryShortName: "TR",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-turkey.png",
// 		code: "+90",
// 		currencyShortName: "TRY",
// 		currency: null,
// 	},
// 	Turkmenistan: {
// 		name: "Turkmenistan",
// 		countryShortName: "TM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-turkmenistan.png",
// 		code: "+993",
// 		currencyShortName: "TMT",
// 		currency: "m",
// 	},
// 	"Turks and Caicos Islands": {
// 		name: "Turks and Caicos Islands",
// 		countryShortName: "TC",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-turks-and-caicos-islands.png",
// 		code: "+1649",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Tuvalu: {
// 		name: "Tuvalu",
// 		countryShortName: "TV",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-tuvalu.png",
// 		code: "+688",
// 		currencyShortName: "AUD",
// 		currency: "$",
// 	},
// 	Uganda: {
// 		name: "Uganda",
// 		countryShortName: "UG",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-uganda.png",
// 		code: "+256",
// 		currencyShortName: "UGX",
// 		currency: "Sh",
// 	},
// 	Ukraine: {
// 		name: "Ukraine",
// 		countryShortName: "UA",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-ukraine.png",
// 		code: "+380",
// 		currencyShortName: "UAH",
// 		currency: "₴",
// 	},
// 	"United Arab Emirates": {
// 		name: "United Arab Emirates",
// 		countryShortName: "AE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-arab-emirates.png",
// 		code: "+971",
// 		currencyShortName: "AED",
// 		currency: "د.إ",
// 	},
// 	"United Kingdom": {
// 		name: "United Kingdom",
// 		countryShortName: "GB",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-kingdom-of-great-britain-and-northern-ireland.png",
// 		code: "+44",
// 		currencyShortName: "GBP",
// 		currency: "£",
// 	},
// 	"United States": {
// 		name: "United States",
// 		countryShortName: "US",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-of-america.png",
// 		code: "+1",
// 		currencyShortName: "USD",
// 		currency: "$",
// 	},
// 	Uruguay: {
// 		name: "Uruguay",
// 		countryShortName: "UY",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-uruguay.png",
// 		code: "+598",
// 		currencyShortName: "UYU",
// 		currency: "$",
// 	},
// 	Uzbekistan: {
// 		name: "Uzbekistan",
// 		countryShortName: "UZ",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-uzbekistan.png",
// 		code: "+998",
// 		currencyShortName: "UZS",
// 		currency: null,
// 	},
// 	Vanuatu: {
// 		name: "Vanuatu",
// 		countryShortName: "VU",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-vanuatu.png",
// 		code: "+678",
// 		currencyShortName: "VUV",
// 		currency: "Vt",
// 	},
// 	Venezuela: {
// 		name: "Venezuela",
// 		countryShortName: "VE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-venezuela-(bolivarian-republic-of).png",
// 		code: "+58",
// 		currencyShortName: "VEF",
// 		currency: "Bs F",
// 	},
// 	"Viet Nam": {
// 		name: "Vietnam",
// 		countryShortName: "VN",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-viet-nam.png",
// 		code: "+84",
// 		currencyShortName: "VND",
// 		currency: "₫",
// 	},
// 	"Wallis and Futuna": {
// 		name: "Wallis and Futuna",
// 		countryShortName: "WF",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-wallis-and-futuna.png",
// 		code: "+681",
// 		currencyShortName: "XPF",
// 		currency: "Fr",
// 	},
// 	"Western Sahara": {
// 		name: "Western Sahara",
// 		countryShortName: "EH",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-western-sahara.png",
// 		code: "+212",
// 		currencyShortName: "MAD",
// 		currency: "د.م.",
// 	},
// 	Yemen: {
// 		name: "Yemen",
// 		countryShortName: "YE",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-yemen.png",
// 		code: "+967",
// 		currencyShortName: "YER",
// 		currency: "﷼",
// 	},
// 	Zambia: {
// 		name: "Zambia",
// 		countryShortName: "ZM",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-zambia.png",
// 		code: "+260",
// 		currencyShortName: "ZMW",
// 		currency: "ZK",
// 	},
// 	Zimbabwe: {
// 		name: "Zimbabwe",
// 		countryShortName: "ZW",
// 		flag: "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-zimbabwe.png",
// 		code: "+263",
// 		currencyShortName: "BWP",
// 		currency: "P",
// 	},
// };

import { IonApp, IonContent, IonLabel, IonPage, IonButton } from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo } from "redux/container/userSlice";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import User from "services/User";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function SkillVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const ekkUser = useSelector((state) => state.user.allInfo);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// industry specialization
	const [food, setFood] = useState(false);
	const [fashion, setFashion] = useState(false);
	const [pharmacy, setPharmacy] = useState(false);
	const [marketing, setMarketing] = useState(false);
	const [electronics, setElectronics] = useState(false);
	const [digitalServices, setDigitalServices] = useState(false);
	const [IT, setIT] = useState(false);
	const [design, setDesign] = useState(false);
	const [banking, setBanking] = useState(false);
	const [insurance, setInsurance] = useState(false);
	const [cosmetics, setCosmetics] = useState(false);
	const [logistics, setLogistics] = useState(false);
	// skills
	const [drivingSkill, setDrivingSkill] = useState(false);
	const [navigationSkill, setNavigationSkill] = useState(false);
	const [customerService, setCustomerService] = useState(false);
	const [vehicleMaintainance, setVehicleMaintainance] = useState(false);
	const [fitness, setFitness] = useState(false);
	const [problemSolving, setProblemSolving] = useState(false);
	const [trafficLaw, setTrafficLaw] = useState(false);
	const [workAlone, setWorkAlone] = useState(false);

	const allIndustry = [
		food && "Food",
		fashion && "Fashion",
		pharmacy && "Pharmacy",
		marketing && "Marketing",
		electronics && "Electronics",
		digitalServices && "Digital Services",
		IT && "IT",
		design && "Design",
		banking && "Banking",
		insurance && "Insurance",
		cosmetics && "Cosmetics",
		logistics && "Logistics",
	];

	const allSkills = [
		drivingSkill && "Strong Driving Record",
		navigationSkill && "Navigation",
		customerService && "Customer Service",
		vehicleMaintainance && "Vehicle Maintenance Knowledge",
		fitness && "Physical Fitness & Strength",
		problemSolving && "Problem Solving",
		trafficLaw && "Knowledge of Traffic Laws",
		workAlone && "Work Alone Effectively",
	];

	const handleSubmit = () => {
		let industries = [];
		let skills = [];
		for (let industry of allIndustry) {
			if (industry) {
				industries.push(industry);
			}
		}
		for (let skill of allSkills) {
			if (skill) {
				skills.push(skill);
			}
		}
		const skillData = {
			industry_specialisation: `${industries.toString()}`,
			skills: `${skills.toString()}`,
		};
		User.putKycInfo(ekkUser?.id, skillData).then((res) => {
			if (res.success) {
				let res = User.getKycInfo(ekkUser?.id);
				res.then((r) => {
					if (r.status === 200) {
						dispatch(setAllInfo(r.results));
						toast.success(ekkLanguage.skillVerify.successMessage);
						navigate("/driving-verify");
					}
				});
			}
		});
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButtonTitle>
					<div className='pt-20 px-5'>
						{/* page title  */}
						<h6 className='text-18 text-center font-bold text-success mb-[20px]'>
							{ekkLanguage.skillVerify.skillVerifyTitle}{" "}
						</h6>
						{/* Working History */}
						<>
							<IonLabel className='flex items-center font-bold mt-7 mb-[11px]'>
								<img
									className='mr-[10px] h-6 w-6'
									src='/assets/images/ekkhero/Icons/skill-verify/work-history.png'
									alt='work'
								></img>{" "}
								<div>
									<span>{ekkLanguage.skillVerify.industryLabel} </span>
									<p className='font-extralight'>
										{ekkLanguage.skillVerify.industryLabel2}
									</p>
								</div>
							</IonLabel>
							{/* food  */}
							<CustomCheckBox
								currentState={food}
								setCurrentState={setFood}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/food.png'
								title={ekkLanguage.skillVerify.food}
							></CustomCheckBox>
							{/* fashion  */}
							<CustomCheckBox
								currentState={fashion}
								setCurrentState={setFashion}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/fashion.png'
								title={ekkLanguage.skillVerify.fashion}
							></CustomCheckBox>
							{/* pharmacy  */}
							<CustomCheckBox
								currentState={pharmacy}
								setCurrentState={setPharmacy}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/pharmacy.png'
								title={ekkLanguage.skillVerify.pharmacy}
							></CustomCheckBox>
							{/* marketing  */}
							<CustomCheckBox
								currentState={marketing}
								setCurrentState={setMarketing}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/marketing.png'
								title={ekkLanguage.skillVerify.marketing}
							></CustomCheckBox>
							{/* electronics  */}
							<CustomCheckBox
								currentState={electronics}
								setCurrentState={setElectronics}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/electronics.png'
								title={ekkLanguage.skillVerify.electronics}
							></CustomCheckBox>
							{/* digital services  */}
							<CustomCheckBox
								currentState={digitalServices}
								setCurrentState={setDigitalServices}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/digital-services.png'
								title={ekkLanguage.skillVerify.digitalServices}
							></CustomCheckBox>
							{/* IT  */}
							<CustomCheckBox
								currentState={IT}
								setCurrentState={setIT}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/it.png'
								title={ekkLanguage.skillVerify.it}
							></CustomCheckBox>
							{/* design  */}
							<CustomCheckBox
								currentState={design}
								setCurrentState={setDesign}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/design.png'
								title={ekkLanguage.skillVerify.design}
							></CustomCheckBox>
							{/* banking */}
							<CustomCheckBox
								currentState={banking}
								setCurrentState={setBanking}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/banking.png'
								title={ekkLanguage.skillVerify.banking}
							></CustomCheckBox>
							{/* insurance  */}
							<CustomCheckBox
								currentState={insurance}
								setCurrentState={setInsurance}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/insurance.png'
								title={ekkLanguage.skillVerify.insurance}
							></CustomCheckBox>
							{/* cosmetics  */}
							<CustomCheckBox
								currentState={cosmetics}
								setCurrentState={setCosmetics}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/cosmetics.png'
								title={ekkLanguage.skillVerify.cosmetics}
							></CustomCheckBox>
							{/* logistics  */}
							<CustomCheckBox
								currentState={logistics}
								setCurrentState={setLogistics}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/logistics.png'
								title={ekkLanguage.skillVerify.logistics}
							></CustomCheckBox>
						</>
						{/* Skills */}
						<>
							<IonLabel className='flex items-center  mt-7 mb-[11px]'>
								<img
									className='mr-[10px] h-6 w-6'
									src='/assets/images/ekkhero/Icons/skill-verify/skills.png'
									alt='work'
								></img>{" "}
								<div>
									<span className='text-16 font-bold leading-5'>
										{ekkLanguage.skillVerify.skillLabel}{" "}
									</span>
									<p className='font-extralight text-14'>
										{ekkLanguage.skillVerify.skillLabel2}
									</p>
								</div>
							</IonLabel>
							{/* strong driving  */}
							<CustomCheckBox
								currentState={drivingSkill}
								setCurrentState={setDrivingSkill}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/driving.png'
								title={ekkLanguage.skillVerify.driving}
							></CustomCheckBox>
							{/* navigation  */}
							<CustomCheckBox
								currentState={navigationSkill}
								setCurrentState={setNavigationSkill}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/navigation.png'
								title={ekkLanguage.skillVerify.navigation}
							></CustomCheckBox>
							{/* customer service  */}
							<CustomCheckBox
								currentState={customerService}
								setCurrentState={setCustomerService}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/customer-service.png'
								title={ekkLanguage.skillVerify.customerService}
							></CustomCheckBox>
							{/* vehicle maintenance */}
							<CustomCheckBox
								currentState={vehicleMaintainance}
								setCurrentState={setVehicleMaintainance}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/vehicle.png'
								title={ekkLanguage.skillVerify.vehicle}
							></CustomCheckBox>
							{/* Fitness */}
							<CustomCheckBox
								currentState={fitness}
								setCurrentState={setFitness}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/fitness.png'
								title={ekkLanguage.skillVerify.fitness}
							></CustomCheckBox>
							{/* Problem Solving  */}
							<CustomCheckBox
								currentState={problemSolving}
								setCurrentState={setProblemSolving}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/problem-solving.png'
								title={ekkLanguage.skillVerify.problemSolving}
							></CustomCheckBox>
							{/* Traffic Laws  */}
							<CustomCheckBox
								currentState={trafficLaw}
								setCurrentState={setTrafficLaw}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/traffic-law.png'
								title={ekkLanguage.skillVerify.traffic}
							></CustomCheckBox>
							{/* Work Alone  */}
							<CustomCheckBox
								currentState={workAlone}
								setCurrentState={setWorkAlone}
								imgSource='/assets/images/ekkhero/Icons/skill-verify/work-alone.png'
								title={ekkLanguage.skillVerify.work}
							></CustomCheckBox>
						</>

						{/* next button  */}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-8'
						>
							<span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						{/* skip button  */}
						<IonButton
							onClick={() => {
								navigate("/driving-verify");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 my-5 w-full  border-primary'
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

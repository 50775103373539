import Calendar from "assets/images/icon/svg/Calendar";
import React, { forwardRef, useState, useRef } from "react";
import Card from "../Common/Card";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Add from "assets/images/icon/svg/Add";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setNewTask } from "redux/container/taskSlice";

export default function TaskStartTimeCard() {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const [selectedDate, setSelectedDate] = useState(moment(new Date()));

	let dateChanger = (e) => {
		setSelectedDate(e);
		dispatch(setNewTask({ stateDate: e }));
	};

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`bg-white p-3 block rounded-[10px] border example-custom-input ${
				selectedDate ? "border-transparent" : "border-primary"
			} `}
			onClick={onClick}
			style={{
				boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
			}}>
			<div className='flex justify-between items-center'>
				<div className='flex items-center'>
					<span>
						<Calendar color={selectedDate ? "#222222" : "#FF564C"} />
					</span>
					<p
						className={`text-12 ${
							selectedDate ? "border-transparent" : "text-primary"
						} ml-2`}>
						{moment(selectedDate).format("DD-MM-YYYY hh:mm A")}
					</p>
				</div>
				<div>
					<Add />
				</div>
			</div>
		</div>
	));
	return (
		<>
			<div className=''>
				<DatePicker
					showTimeSelect
					// minDate={moment().toDate()}
					selected={
						selectedDate
							? moment(selectedDate)._d
							: moment(new Date()).add(1, "days")._d
					}
					onChange={(date) => dateChanger(date)}
					customInput={<ExampleCustomInput />}
					withPortal
				/>
			</div>
		</>
	);
}

import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonPage,
	IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import BackButton from "components/mobile-components/Common/BackButton";
import { getLanguage } from "config/language";
import { getLocalUser } from "config/user";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAllInfo, setSelectedJob } from "redux/container/userSlice";
import User from "services/User";

export default function JobSelect() {
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [selected, setSelected] = useState(false);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const localUserPhone = getLocalUser()?.user?.phone_number;
	console.log(localUserPhone);
	const lang = getLanguage();
	const contentRef = useRef();
	const [initHeight] = useState(72);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [salesSelected, setSalesSelected] = useState(false);
	const [deliverySelected, setDeliverySelected] = useState(false);
	const [othersSelected, setOthersSelected] = useState(false);

	const allJobs = [
		salesSelected && "Sales",
		deliverySelected && "Delivery",
		othersSelected && "Others",
	];

	const handleNext = () => {
		let interestedJobs = [];
		for (let job of allJobs) {
			if (job) {
				interestedJobs.push(job);
			}
		}
		User.postKycInfo({
			interested_job: `${interestedJobs.toString()}`,
			phone_number: `${localUserPhone}`,
		}).then((response) => {
			if (response.success) {
				dispatch(setAllInfo(response.results));
				navigate("/home");
			}
		});
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}
				>
					<BackButton
						title={ekkLanguage.jobSelect.navLabel}
						sticky={true}
					></BackButton>
					{/* <LeftArrow></LeftArrow> */}
					<div className='pt-5 px-4 flex justify-start items-center flex-col'>
						<h1 className='font-bold mb-5'>
							{lang === "en" ? (
								<span>
									What kind of jobs{" "}
									<span className='text-[#00B224]'>are you looking for?</span>
								</span>
							) : (
								<span>
									আপনি কি ধরনের{" "}
									<span className='text-[#00B224]'> চাকরি খুঁজছেন?</span>
								</span>
							)}
						</h1>
						<div className='h-[620px]'>
							<IonRow className='w-full'>
								<IonCol
									className={`rounded-md border-2 ml-3 mr-5 relative w-[200px] h-[100px] ${
										salesSelected
											? "border-[#00B224] bg-white opacity-100"
											: "opacity-50"
									}`}
								>
									<div
										onClick={() => {
											setSalesSelected(!salesSelected);
											setSelected(false);
											if (salesSelected || deliverySelected || othersSelected) {
												setSelected(true);
											}
										}}
										className='text-center block p-3'
									>
										<img
											src='/assets/images/ekkhero/Icons/sales.png'
											alt='sales'
											className={`w-[50px] mb-3 m-auto`}
										/>
										<h6
											className='font-bold absolute bottom-1 left-[50%] w-full text-14'
											style={{ transform: "translateX(-50%)" }}
										>
											{ekkLanguage.jobSelect.sales}
										</h6>
									</div>
								</IonCol>
								<IonCol
									className={`rounded-md border-2 mr-5 relative  ${
										deliverySelected
											? "border-[#00B224] bg-white opacity-100"
											: "opacity-50"
									}`}
								>
									<div
										onClick={() => {
											setDeliverySelected(!deliverySelected);
											setSelected(false);
											if (salesSelected || deliverySelected || othersSelected) {
												setSelected(true);
											}
										}}
										className='text-center block p-3'
									>
										<img
											src='/assets/images/ekkhero/Icons/delivery.png'
											alt='delivery'
											className='m-auto  mb-2'
										/>
										<h6
											className='font-bold absolute bottom-1 left-[50%] w-full text-14'
											style={{ transform: "translateX(-50%)" }}
										>
											{ekkLanguage.jobSelect.delivery}
										</h6>
									</div>
								</IonCol>
								<IonCol
									className={`rounded-md border-2 relative mr-3 ${
										othersSelected
											? "border-[#00B224] bg-white opacity-100"
											: "opacity-50"
									}`}
								>
									<div
										onClick={() => {
											setOthersSelected(!othersSelected);
											setSelected(false);
											if (salesSelected || deliverySelected || othersSelected) {
												setSelected(true);
											}
										}}
										className={`text-center block p-3 `}
									>
										<img
											src='/assets/images/ekkhero/Icons/others.png'
											alt='others'
											className='m-auto mb-2'
										/>
										<h6
											className='font-bold absolute bottom-1 left-[50%] w-full text-14'
											style={{ transform: "translateX(-50%)" }}
										>
											{ekkLanguage.jobSelect.others}
										</h6>
									</div>
								</IonCol>
							</IonRow>
						</div>
						<div className='flex  items-end justify-center w-full mx-5'>
							<IonButton
								disabled={
									salesSelected || deliverySelected || othersSelected
										? false
										: true
								}
								onClick={() => {
									handleNext();
								}}
								color='success'
								className='font-bold h-10 w-full'
							>
								{/* onClick={nextHandler}> */}
								{ekkLanguage.jobSelect.btnLabel}
								<span className='pl-2'>
									<ArrayRight />
								</span>
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

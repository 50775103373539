import {
  IonApp,
  IonContent,
  IonLabel,
  IonPage,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo } from "redux/container/userSlice";
import ImageUploader from "components/mobile-components/VerifyBusiness/ImageUploader";
import User from "services/User";
import { toast } from "react-toastify";
import { Input } from "components/global-components/common/Input";
import CustomRadioButton from "components/global-components/common/CustomRadioButton";
import CustomRadioButton2 from "components/global-components/common/CustomRadioButton2";
import { districts } from "data/districts";
import { upazilas } from "data/upazillas";
import { getLanguage } from "config/language";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";

export default function WorkVerify() {
  const [districtList, setDistrictList] = useState(
    districts.map((dis) => {
      return { ...dis, value: dis.name };
    })
  );
  const [districtUpazila, setDistrictUpazila] = useState([]);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const ekkUser = useSelector((state) => state.user.allInfo);
  const [loading, setLoading] = useState(false);
  const localLanguage = getLanguage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [haveWorkTypeEx, setHaveWorkTypeEx] = useState(
    ekkUser?.employee_type !== null ? ekkUser?.employee_type : null
  );
  const [workDistrict, setWorkDistrict] = useState(
    districtList.find((dis) => {
      if (dis.value === ekkUser?.work_area_district) {
        return { ...dis, value: dis?.name };
      }
    })
  );
  const [workUpazila, setWorkUpazila] = useState("");
  const [haveWorkEx, setHaveWorkEx] = useState(
    ekkUser?.previous_experience !== null ? ekkUser?.previous_experience : null
  );
  const [partTime, setPartTime] = useState(false);
  const [fullTime, setFullTime] = useState(false);
  const [previousEx, setPreviousEx] = useState(false);
  const [notPreviousEx, setNotPreviousEx] = useState(false);
  const [expectedSalary, setExpectedSalary] = useState(
    ekkUser?.monthly_salary == 0 ? null : ekkUser?.monthly_salary
  );
  const [education, setEducation] = useState(ekkUser?.degree?.toLowerCase());
  const [psc, setPsc] = useState(false);
  const [jsc, setJsc] = useState(false);
  const [ssc, setSsc] = useState(false);
  const [hsc, setHsc] = useState(false);
  const [others, setOthers] = useState(false);
  const [files2, setFiles2] = useState("");

  const handleDistrictUpazilla = async (name) => {
    setWorkUpazila("");
    let newArr = [];
    upazilas.map((res) => {
      if (res.district_name === name) {
        let obj = {
          ...res,
          value: res.name,
        };
        newArr.push(obj);
      }
    });
    setDistrictUpazila(newArr);
  };

  useEffect(() => {
    const handleDistrictUpazilla = async (name) => {
      let newArr = [];
      upazilas.map((res) => {
        if (res.district_name === name) {
          let obj = {
            ...res,
            value: res.name,
          };
          newArr.push(obj);
        }
      });
      setDistrictUpazila(newArr);
      setWorkUpazila(
        newArr?.find((up) => {
          if (up.name === ekkUser?.work_area_subdistrict) {
            return { ...up, value: up?.name };
          }
        })
      );
    };
    handleDistrictUpazilla(ekkUser?.work_area_district);
  }, [ekkUser?.work_area_district, ekkUser?.work_area_subdistrict]);

  const handleSubmit = async () => {
    if (haveWorkTypeEx !== null) {
      if (haveWorkEx !== null) {
        if (workDistrict) {
          if (workUpazila) {
            setError("");
            if (files2) {
              let CV = new FormData();
              CV.append("key", files2[0]);
              setLoading(true);
              if (files2.length !== 0) {
                let response = await User.toBucketImage(CV);
                if (response.status === 201) {
                  const workData = {
                    employee_type: haveWorkTypeEx,
                    previous_experience: haveWorkEx,
                    work_area_district: workDistrict?.value,
                    work_area_subdistrict: workUpazila?.value,
                    monthly_salary: expectedSalary,
                    degree: education?.toUpperCase()
                      ? education.toUpperCase()
                      : "",
                    cv: response.results[0],
                  };
                  User.putKycInfo(ekkUser?.id, workData).then((res) => {
                    if (res.success) {
                      let res = User.getKycInfo(ekkUser?.id);
                      res.then((r) => {
                        if (r.status === 200) {
                          setLoading(false);
                          dispatch(setAllInfo(r.results));
                          toast.success(ekkLanguage.workVerify.successMessage);
                          navigate("/skill-verify");
                        }
                      });
                    }
                  });
                }
              }
            } else {
              const workData = {
                employee_type: haveWorkTypeEx,
                previous_experience: haveWorkEx,
                work_area_district: workDistrict?.value,
                work_area_subdistrict: workUpazila?.value,
                monthly_salary: expectedSalary,
                degree: education?.toUpperCase() ? education.toUpperCase() : "",
              };
              User.putKycInfo(ekkUser.id, workData).then((res) => {
                if (res.success) {
                  let res = User.getKycInfo(ekkUser?.id);
                  res.then((r) => {
                    if (r.status === 200) {
                      setLoading(false);
                      dispatch(setAllInfo(r.results));
                      toast.success(ekkLanguage.workVerify.successMessage);
                      navigate("/skill-verify");
                    }
                  });
                }
              });
            }
          } else {
            setError(ekkLanguage.workVerify.upazillaError);
          }
        } else {
          setError(ekkLanguage.workVerify.districtError);
        }
      } else {
        setError(ekkLanguage.workVerify.experienceError);
      }
    } else {
      setError(ekkLanguage.workVerify.workError);
    }
  };
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.addressVerify.mainTitle}
            sticky={true}
          ></BackButtonTitle>
          <div className="px-4 pt-20 pb-5">
            <h6 className="text-18 text-center font-bold text-success mb-[20px]">
              {ekkLanguage.workVerify.workVerifyTitle}{" "}
            </h6>
            <CustomRadioButton
              currentState1={partTime}
              currentState2={fullTime}
              value1="Part Time"
              value2="Full Time"
              setCurrentState1={setPartTime}
              setCurrentState2={setFullTime}
              imgSource1="/assets/images/ekkhero/Icons/time 1.png"
              imgSource2="/assets/images/ekkhero/Icons/full-time 1.png"
              title1={ekkLanguage.workVerify.workTypeOption1}
              title2={ekkLanguage.workVerify.workTypeOption2}
              label={ekkLanguage.workVerify.workTypeLabel}
              labelImg="/assets/images/ekkhero/Icons/work-type.png"
              star
              setTypeOfState={setHaveWorkTypeEx}
              typeOfState={haveWorkTypeEx}
            ></CustomRadioButton>
            <CustomRadioButton
              currentState1={previousEx}
              currentState2={notPreviousEx}
              value1={true}
              value2={false}
              setCurrentState1={setPreviousEx}
              setCurrentState2={setNotPreviousEx}
              title1={ekkLanguage.workVerify.previousWorkExperienceOption1}
              title2={ekkLanguage.workVerify.previousWorkExperienceOption2}
              label={ekkLanguage.workVerify.previousWorkExperienceLabel}
              labelImg="/assets/images/ekkhero/Icons/experience.png"
              star
              setTypeOfState={setHaveWorkEx}
              typeOfState={haveWorkEx}
            ></CustomRadioButton>

            <Input
              label={{
                label: ekkLanguage.workVerify.monthlySalaryLabel,
                source: "/assets/images/ekkhero/Icons/salary.png",
              }}
              onChange={(event) => {
                setExpectedSalary(event.target.value);
              }}
              extraLabel={ekkLanguage.workVerify.monthlySalaryLabel2}
              type="number"
              name="salary"
              value={expectedSalary}
              placeholder={ekkLanguage.workVerify.monthlySalaryPlaceholder}
            ></Input>
            <NewCustomSelect
              options={districtList}
              setOption={setWorkDistrict}
              mainLabel={ekkLanguage.workVerify.workAreaLabel}
              extraLabel={ekkLanguage.workVerify.workAreaLabel2}
              labelImage="/assets/images/ekkhero/Icons/location-pin1.png"
              star={true}
              option={workDistrict}
              selectPlaceholder={ekkLanguage.workVerify.workAreaPlaceholder}
              type="zilla-selection"
              localLanguage={localLanguage}
              handleDistrictUpazilla={handleDistrictUpazilla}
              errorMessage
            ></NewCustomSelect>
            {/* <CustomSelect
              type="zilla-selection"
              handleDistrictUpazilla={handleDistrictUpazilla}
              localLanguage={localLanguage}
              places={districts}
              setOption={setWorkDistrict}
              option={workDistrict}
              mainLabel={ekkLanguage.workVerify.workAreaLabel}
              extraLabel={ekkLanguage.workVerify.workAreaLabel2}
              star={true}
              labelImage="/assets/images/ekkhero/Icons/location-pin1.png"
              selectPlaceholder={ekkLanguage.workVerify.workAreaPlaceholder}
            ></CustomSelect> */}
            <NewCustomSelect
              disable={workDistrict ? false : true}
              options={districtUpazila}
              setOption={setWorkUpazila}
              mainLabel={ekkLanguage.workVerify.workAreaLabel3}
              extraLabel={ekkLanguage.workVerify.workAreaLabel2}
              labelImage="/assets/images/ekkhero/Icons/location-pin2.png"
              star={true}
              option={workUpazila}
              selectPlaceholder={ekkLanguage.workVerify.workAreaPlaceholder}
              selectPlaceholderWarning={
                ekkLanguage.workVerify.workAreaPlaceholderWarning
              }
              type="upazilla-selection"
              localLanguage={localLanguage}
              handleDistrictUpazilla={handleDistrictUpazilla}
              errorMessage
            ></NewCustomSelect>

            {/* <CustomSelect
              disable={workDistrict ? false : true}
              localLanguage={localLanguage}
              type="upazilla-selection"
              places={districtUpazila}
              setOption={setWorkUpazila}
              option={workUpazila}
              mainLabel={ekkLanguage.workVerify.workAreaLabel3}
              extraLabel={ekkLanguage.workVerify.workAreaLabel2}
              star={true}
              labelImage="/assets/images/ekkhero/Icons/location-pin2.png"
              selectPlaceholder={ekkLanguage.workVerify.workAreaPlaceholder}
              selectPlaceholderWarning={
                ekkLanguage.workVerify.workAreaPlaceholderWarning
              }
            ></CustomSelect> */}

            <CustomRadioButton2
              setCurrentState={{
                setCurrentState1: setPsc,
                setCurrentState2: setJsc,
                setCurrentState3: setSsc,
                setCurrentState4: setHsc,
                setCurrentState5: setOthers,
              }}
              currentState={{
                currentState1: psc,
                currentState2: jsc,
                currentState3: ssc,
                currentState4: hsc,
                currentState5: others,
              }}
              value={{
                value1: "psc",
                value2: "jsc",
                value3: "ssc",
                value4: "hsc",
                value5: "others",
              }}
              title={{
                title1: ekkLanguage.workVerify.academiOption1,
                title2: ekkLanguage.workVerify.academiOption2,
                title3: ekkLanguage.workVerify.academiOption3,
                title4: ekkLanguage.workVerify.academiOption4,
                title5: ekkLanguage.workVerify.academiOption5,
              }}
              setTypeOfState={setEducation}
              imgSource={{}}
              typeOfState={education}
              label1={ekkLanguage.workVerify.academicQualificationLabel}
              label2={ekkLanguage.workVerify.academicQualificationLabel2}
              labelImg="/assets/images/ekkhero/Icons/education.png"
            ></CustomRadioButton2>

            <IonLabel className="flex items-center font-bold mt-5">
              <img
                className="mr-[10px]"
                src="/assets/images/ekkhero/Icons/file.png"
                alt="work"
              ></img>{" "}
              <span>{ekkLanguage.workVerify.addFileLabel}</span>
            </IonLabel>

            <div className="pb-5 px-4">
              <ImageUploader
                files={files2}
                setFiles={setFiles2}
                bHeight={200}
                placeHolder2={ekkLanguage.workVerify.addFilePlaceholder}
              />
            </div>
            {error && (
              <p className="font-semibold text-12 text-primary mt-5">{error}</p>
            )}
            <IonButton
              disabled={loading}
              type="submit"
              onClick={() => {
                !loading && handleSubmit();
              }}
              color="success"
              expand="full"
              className="font-bold h-10 w-full mt-5"
            >
              {loading ? (
                <>
                  <IonSpinner />
                </>
              ) : (
                <>
                  <span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>{" "}
                  <span className="pl-2">
                    <ArrayRight />
                  </span>
                </>
              )}
            </IonButton>

            <IonButton
              onClick={() => {
                navigate("/skill-verify");
              }}
              fill="outline"
              color="primary"
              className="font-bold h-10 mt-5 w-full border-primary "
            >
              {ekkLanguage.addressVerify.cancel}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import {
	IonButton,
	IonCol,
	IonGrid,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from "@ionic/react";
import { getLanguage } from "config/language";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { useNavigate } from "react-router-dom";

export default function ChangeLanguage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let dispatch = useDispatch();
	const [selectedLan, setSelectedLan] = useState(getLanguage());
	let navigate = useNavigate();

	const languageSubmitHandler = () => {
		if (selectedLan !== "") {
			setLanguage(selectedLan);
			dispatch(selectLan());
			navigate("/home", { replace: true });
		}
	};
	return (
		<div className='px-2 pt-20 relative change-password-screen change-language'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div>
							<IonRadioGroup
								value={selectedLan}
								onIonChange={(e) => {
									setSelectedLan(e.detail.value);
								}}>
								<IonItem
									lines='none'
									className='bg-white border rounded-[10px] mb-5 relative'>
									<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium absolute left-8 w-full'>
										<div className='flex'>
											<span span className='ml-2'>
												বাংলা
											</span>
										</div>
									</IonLabel>
									<IonRadio value='bn' color='success' />
								</IonItem>

								<IonItem
									lines='none'
									className='bg-white border rounded-[10px] mb-5 relative'>
									<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium absolute left-8 w-full'>
										<div className='flex'>
											<span span className='ml-2'>
												English
											</span>
										</div>
									</IonLabel>
									<IonRadio value='en' color='success' />
								</IonItem>
								{/* <IonItem
									lines='none'
									className='bg-white border rounded-[10px] mb-5 relative'>
									<IonLabel className=' border-0 font-sans text-14 text-black-500 font-medium absolute left-8 w-full'>
										<div className='flex'>
											<span span className='ml-2'>
												Vietnames
											</span>
										</div>
									</IonLabel>
									<IonRadio  value='vn' color='success' />
								</IonItem> */}
							</IonRadioGroup>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper mt-[415px]'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									onClick={languageSubmitHandler}
									expand='full'
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'>
									{ekkLanguage.changeLanguages.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}

import { baseUrl } from "config/apiUrl";
import React from "react";
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
} from "react-share";

const Share = ({ id, setShowShareOptions, showShareOptions, ekkLanguage }) => {
	console.log(id);
	const shareUrl = `${baseUrl?.webUrl}/task-details-public/${id}`;
	const title = "Share on your social media!";

	function copyShareUrl() {
		const tempInput = document.createElement("input");
		tempInput.value = shareUrl;
		document.body.appendChild(tempInput);
		tempInput.select();
		tempInput.setSelectionRange(0, 99999);
		document.execCommand("copy");
		document.body.removeChild(tempInput);
		console.log("URL copied to clipboard!");
		setShowShareOptions(!showShareOptions);
	}

	return (
		<div className='absolute bottom-10 right-0 py-5 px-4 z-50 bg-gray-300 rounded-lg'>
			<h6 className='font-semibold mb-2'>
				{" "}
				{ekkLanguage.task?.shareSocial}{" "}
			</h6>
			<FacebookShareButton url={shareUrl} quote={title}>
				<img
					src='/assets/images/facebook_new.png'
					className='w-[30px] h-[30px] mr-2'
					alt='Facebook'
				/>
			</FacebookShareButton>
			<LinkedinShareButton url={shareUrl} title={title}>
				<img
					className='w-[30px] h-[30px] mr-2'
					src='/assets/images/linkedin_new.png'
					alt='LinkedIn'
				/>
			</LinkedinShareButton>
			<TwitterShareButton url={shareUrl} title={title}>
				<img
					className='w-[30px] h-[30px] mr-2'
					src='/assets/images/twitter_new.png'
					alt='Twitter'
				/>
			</TwitterShareButton>
			<button onClick={copyShareUrl}>
				<img
					className='w-[30px] h-[30px] bg-gray-100 rounded-lg p-0.5'
					src='/assets/images/ekkhero/Icons/copy.png'
					alt='Copy URL'
				/>
			</button>
		</div>
	);
};

export default Share;

import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import OrderForcastForm from "components/mobile-components/OrderForecastForm";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedSupplier } from "redux/container/customerSlice";
import FutureOrder from "services/FutureOrder";

export default function OrderForecastFormPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const relationDetails = useSelector(
    (state) => state.customer.relationDetails
  );
  const selectedSupplier = useSelector(
    (state) => state.customer.selectedSupplier
  );
  const [loading, setLoading] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const handleSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    let response = await FutureOrder.createOrder(data);
    if (response.status === 200 || response.status === 201) {
      setLoading(false);
      dispatch(setSelectedSupplier({}));
      navigate("/success-page/order-forecast-success");
    } else {
      setLoading(false);
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.customerList.orderForecast}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="orderForecast"
            isHide={false}
          />
          <section className="pt-20 px-4 ">
            <OrderForcastForm
              ekkLanguage={ekkLanguage}
              handleSubmit={handleSubmit}
              loading={loading}
              selectedSupplier={selectedSupplier}
              buyerId={relationDetails?.business?.id}
            />
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

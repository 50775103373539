import { IonButton } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { Input } from "components/global-components/common/Input";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";
import { getLocalUser } from "config/user";
import React, { useEffect, useState } from "react";

export default function CreateContact({
  ekkLanguage,
  createdInfo,
  role,
  setRole,
  roleError,
  setRoleError
}) {
  // const [name, setName] = useState(
  //   createdInfo?.business?.business_name
  // );
  // const [number, setNumber] = useState(createdInfo?.business_info?.business?.phone_number);
  // const [roleError, setRoleError] = useState("");
  const localLanguage = getLocalUser();
  const [roles, setRoles] = useState([
    {
      label: ekkLanguage.createBusinessInfo.owner,
      value: "Owner",
    },
    {
      label: ekkLanguage.createBusinessInfo.manager,
      value: "Manager",
    },
    {
      label: ekkLanguage.createBusinessInfo.stuff,
      value: "Stuff",
    },
  ]);

  return (
    <div>
      <h5 className="text-[#00B224] font-bold text-18 mb-5 text-center">
        {ekkLanguage.createContact.pageHeadline}
      </h5>
      <Input
        label={{
          label: ekkLanguage.createContact.nameLabel,
          //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
        }}
        // onChange={(event) => {
        //   setName(event.target.value);
        // }}
        // star
        type="text"
        name="name"
        value={createdInfo?.business?.business_name}
        placeholder={ekkLanguage.createContact.namePlaceholder}
        disabled
      ></Input>

      <Input
        label={{
          label: ekkLanguage.createContact.numberLabel,
          //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
        }}
        // onChange={(event) => {
        //   setNumber(event.target.value);
        // }}
        // star
        type="text"
        name="number"
        value={createdInfo?.business?.phone_number}
        placeholder={ekkLanguage.createContact.numberPlaceholder}
        disabled
      ></Input>
      <NewCustomSelect
        type="role-selection2"
        localLanguage={localLanguage}
        options={roles}
        setOption={setRole}
        option={role}
        mainLabel={ekkLanguage.newCustomer.role}
        selectPlaceholder={ekkLanguage.newCustomer.selectRole}
        errorMessage={roleError}
        star
      ></NewCustomSelect>
      <div className="mb-8"></div>
      {/* <IonButton
        type="submit"
        onClick={() => {
          if (role?.value) {
            setRoleError("");
            const query = {
              business: Number(createdInfo?.business?.id),
              role: role.value,
            };
            handleSubmit(query);
          } else {
            setRoleError(ekkLanguage.createContact.roleError);
          }
        }}
        color="success"
        expand="full"
        className="font-bold h-10 w-full mt-72 mb-5"
      >
        {ekkLanguage.jobSelect.btnLabel}
        <span className="pl-2">
          <ArrayRight />
        </span>
      </IonButton> */}
    </div>
  );
}

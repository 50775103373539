import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Supplier = {
  getAllSupplierList: (lon, lat, page = 1, limit = 20) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkhero/businesses/?page=${page}&limit=${limit}&longitude=${lon}&latitude=${lat}`
    );
  },
  getAllSupplierListSearch: (query = "", lon, lat, page = 1, limit = 20) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkhero/businesses/search/?page=${page}&limit=${limit}&term=${query}&longitude=${lon}&latitude=${lat}`
    );
  },
};
export default Supplier;

import { digitChanger, monthChanger } from "helpers/helper";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function TaskDetailsPublic({ loading, singleBlog }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let checkStatus = (status) => {
		if (status === "Completed") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-F27900'
					style={{ borderRadius: "0" }}>
					<p className='text-F27900'>{ekkLanguage.task.completed}</p>
				</div>
			);
		} else if (status === "Active") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-success'
					style={{ borderRadius: "0" }}>
					<p className='text-success'>{ekkLanguage.task.onGoing}</p>
				</div>
			);
		} else if (status === "Cancelled") {
			return (
				<div
					className='absolute top-0 bg-white py-1 px-3 border border-primary'
					style={{ borderRadius: "0" }}>
					<p className='text-primary'>{ekkLanguage.task.cancelled}</p>
				</div>
			);
		} else {
			return "";
		}
	};
	return (
		<>
			{!loading ? (
				<div className='pt-[100px] sm:pt-[120px] pb-24'>
					<div className='container relative'>
						<div className='m-auto w-full lg:w-8/12 relative'>
							{checkStatus(singleBlog?.status)}
							{singleBlog?.images?.length ? (
								<div className='h-[350px] overflow-hidden mb-8'>
									<img
										src={singleBlog?.images[0]}
										alt='blogImage'
										className='object-cover w-full h-full'
									/>
								</div>
							) : (
								<div className='h-[350px] overflow-hidden mb-8'>
									<img
										src='/assets/images/logo.png'
										alt='blogImage'
										className='w-[80%]'
									/>
								</div>
							)}
							<div>
								<h2 className='lg:text-40 md:text-32 text-22 text-black-1000 font-bold pb-3'>
									{singleBlog?.title}
								</h2>
								<div className='flex justify-between py-4'>
									<div>
										<div className='flex items-center'>
											<h6 className='font-bold text-808080 text-12'>
												{ekkLanguage.task.taskStart}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														monthChanger(
															moment(
																singleBlog?.start_date
															).format("DD MMM, YY")
														)
													)}
												</span>
											</div>
										</div>{" "}
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.taskEnd}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														monthChanger(
															moment(
																singleBlog?.end_date
															).format("DD MMM, YY")
														)
													)}
												</span>
											</div>
										</div>
									</div>
									<div>
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.position}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														singleBlog?.required_volunteer
													)}
												</span>
											</div>
										</div>
										<div className='flex items-center'>
											<h6 className='font-bold text-12 text-808080'>
												{ekkLanguage.task.jointed}:{" "}
											</h6>
											<div>
												<span className='text-black-1000 font-semibold text-12'>
													{" "}
													{digitChanger(
														singleBlog?.enrolled_volunteer
													)}
												</span>
											</div>
										</div>
									</div>
								</div>

								<p className='text-12 sm:text-16 font-normal text-808080 pb-5'>
									{singleBlog?.description}{" "}
								</p>

								{singleBlog?.status !== "Cancelled" && (
									<div className='text-center pt-14 lg:mb-0 mb-10 pb-5'>
										<Link
											to='/'
											className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}>
											{ekkLanguage.task.joinTask}
										</Link>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='pt-[100px] sm:pt-[150px] pb-24'>
					<div className='m-auto w-full lg:w-8/12'>
						<div className='flex h-full justify-center items-center'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import MyTaskBottomSheet from "components/mobile-components/TaskList/MyTaskBottomSheet";
import { useEffect, useState } from "react";
import MyTaskBtn from "components/mobile-components/TaskList/MyTaskBtn";
import TaskList from "components/mobile-components/TaskList";
import { useDispatch, useSelector } from "react-redux";
import Task from "services/Task";
import { setPostList } from "redux/container/learningSlice";

function MyTaskPage() {
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const dispatch = useDispatch();
	const [learningList, setLearningList] = useState([]);
	//   const learningList = useSelector((state) => state.learning.postList);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [taskType, setTaskType] = useState("Creator");
	const [query, setQuery] = useState("Creator");
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const getTaskList = async (q) => {
		setLearningList([]);
		let response = await Task.getMyTask(q, 1, 10);
		if (response.status === 200) {
			setLearningList(response?.results);
			setLoading(false);
		}
	};

	let loadMoreData = async () => {
		if (hasMoreData) {
			let response = await Task.getMyTask(query, pageCount, 10);
			if (response.status === 200) {
				if (response?.results?.length > 0) {
					setPageCount((prev) => prev + 1);
					setLearningList([...learningList, ...response?.results]);
				} else {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	useEffect(() => {
		getTaskList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.task.myTask}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from='my-task'
					/>
					<div className='mt-[70px] px-5'>
						<MyTaskBtn
							taskType={taskType}
							setTaskType={setTaskType}
							setQuery={setQuery}
							getTaskList={getTaskList}
							setReachedEnd={setReachedEnd}
							setHasMoreData={setHasMoreData}
							ekkLanguage={ekkLanguage}
						/>
						<TaskList
							taskType={taskType}
							learningList={learningList}
							ekkLanguage={ekkLanguage}
						/>
					</div>
					<MyTaskBottomSheet />
					<IonInfiniteScroll
						onIonInfinite={(ev) => {
							if (reachedEnd) {
								ev.target.complete();
								return;
							}
							loadMoreData();
							setTimeout(() => {
								ev.target.complete();
								if (!hasMoreData) {
									setReachedEnd(true);
								}
							}, 2000);
						}}>
						<IonInfiniteScrollContent
							loadingText={ekkLanguage.learning.loader}
							loadingSpinner={
								hasMoreData ? "bubbles" : null
							}></IonInfiniteScrollContent>
					</IonInfiniteScroll>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default MyTaskPage;

import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Search from "components/mobile-components/Common/Search";
import CMSFilter from "components/mobile-components/CustomerList/CMSFilter";
import CustomerBottomSheet from "components/mobile-components/CustomerList/CustomerBottomSheet";
import CustomerList from "components/mobile-components/CustomerList/CustomerList";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Contract from "services/Contract";
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    width: "300px",
    transition: ".4s all ease",
    borderRadius: 12,
    // height: "calc(100% - 60px)",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: ".4s all ease",
    backgroundColor: "rgba(0,0,0,.5)",
  },
};

function CMSListPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const userType = useSelector((state) => state.customer.userType);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchQ, setSearchQ] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [pageCount, setPageCount] = useState(2);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [initHeight] = useState(72);
  const [CMSType, setCMSType] = useState(true); // todo: Assigned CMSType -->  False, CreatedBy CMSType --> True
  const [initStickyClass, setInitStikyClass] = useState("issticky");
  const [filterType, setFilterType] = useState("created");
  const [searched, setSearched] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState({});
  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);
  const contentRef = useRef();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  // * Create By Filter List
  const createdCustomerList = async () => {
    setCustomerData([]);
    setLoading(true);
    if (!searchQ) {
      let res = await Contract.getCreatedBusinessInfoList();
      if (res.status === 200) {
        setCustomerData(res.results);
        setPageCount(2);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      let response = await Contract.getBusinessInfoListSearch(
        searchQ,
        "created_by"
      );
      if (response.status === 200 || response.status === 201) {
        setCustomerData(response?.results);
        setPageCount(2);
        setSearched(true);
        setLoading(false);
      }
    }
  };

  // Assigned Filter List
  const assignedCustomerList = async () => {
    setCustomerData([]);
    setLoading(true);
    if (!searchQ) {
      // Assigned EkkHero Hub Staff List
      if (userType === "EkkHero Hub Staff") {
        let res = await Contract.getAssignedHSBusinessInfoList();
        if (res.status === 200) {
          setCustomerData(res.results);
          setPageCount(2);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
      // Assigned Account Executive List
      else {
        let res = await Contract.getAssignedBusinessInfoList();
        if (res.status === 200) {
          setCustomerData(res.results);
          setPageCount(2);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    } else {
      // Assigned EkkHero Hub Staff List Search
      if (userType === "EkkHero Hub Staff") {
        let response = await Contract.getBusinessInfoListSearch(
          searchQ,
          "hub_staff"
        );
        if (response.status === 200 || response.status === 201) {
          setCustomerData(response?.results);
          setPageCount(2);
          setSearched(true);
          setLoading(false);
        }
      }
      // Assigned Account Executive List Search
      else {
        let response = await Contract.getBusinessInfoListSearch(
          searchQ,
          "account_executive"
        );
        if (response.status === 200 || response.status === 201) {
          setCustomerData(response?.results);
          setPageCount(2);
          setSearched(true);
          setLoading(false);
        }
      }
    }
  };

  let lodeMoreData = async () => {
    if (hasMoreData) {
      if (searchQ) {
        const response = await Contract.getBusinessInfoListSearch(
          pageCount,
          searchQ
        );
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          setCustomerData([...customerData, ...response.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        if (CMSType) {
          const response = await Contract.getCreatedBusinessInfoList(pageCount);
          if (response.status === 200) {
            setPageCount((prev) => prev + 1);
            setCustomerData([...customerData, ...response.results]);
          } else {
            setHasMoreData(false);
          }
        } else {
          if (userType === "EkkHero Hub Staff") {
            const response = await Contract.getAssignedHSBusinessInfoList(
              pageCount
            );
            if (response.status === 200) {
              setPageCount((prev) => prev + 1);
              setCustomerData([...customerData, ...response.results]);
            } else {
              setHasMoreData(false);
            }
          } else {
            const response = await Contract.getAssignedBusinessInfoList(
              pageCount
            );
            if (response.status === 200) {
              setPageCount((prev) => prev + 1);
              setCustomerData([...customerData, ...response.results]);
            } else {
              setHasMoreData(false);
            }
          }
        }
      }
    }
  };

  let singleDistributorSearch = async (search) => {
    setLoading(true);
    if (search) {
      if (filterType === "created") {
        let response = await Contract.getBusinessInfoListSearch(
          search,
          "created_by"
        );
        if (response.status === 200 || response.status === 201) {
          setCustomerData(response?.results);
          setPageCount(2);
          setSearched(true);
          setLoading(false);
        }
      } else if (filterType === "assigned") {
        if (userType === "EkkHero Hub Staff") {
          let response = await Contract.getBusinessInfoListSearch(
            search,
            "hub_staff"
          );
          if (response.status === 200 || response.status === 201) {
            setCustomerData(response?.results);
            setPageCount(2);
            setSearched(true);
            setLoading(false);
          }
        } else {
          let response = await Contract.getBusinessInfoListSearch(
            search,
            "account_executive"
          );
          if (response.status === 200 || response.status === 201) {
            setCustomerData(response?.results);
            setPageCount(2);
            setSearched(true);
            setLoading(false);
          }
        }
      }
    } else {
      console.log("query not set");
    }
  };

  useEffect(() => {
    if (searchQ === "" && userType !== "EkkHero Hub Staff") {
      setCMSType(true);
      setFilterType("created");
      createdCustomerList();
      setSearched(false);
    }
    if (searchQ === "" && userType === "EkkHero Hub Staff") {
      setCMSType(false);
      setFilterType("assigned");
      assignedCustomerList();
      setSearched(false);
    }
  }, [searchQ, userType]);

  let filterHandler = () => {
    if (!selectedVisit) {
      onOpenModal();
    } else {
      setSelectedVisit("");
      // getProductListWithBrand("");
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent
          scrollEvents={true}
          ref={contentRef}
          onIonScroll={async (ev) => {
            const scrollPosition = ev.detail.scrollTop;
            if (scrollPosition > initHeight) {
              setInitStikyClass("issticky");
            } else {
              setInitStikyClass("issticky");
            }
          }}
        >
          <BackButtonTitle
            title={ekkLanguage.letNav.analyticsMenuLabel}
            // burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="customerListPage"
            userType={userType}
          />
          <section>
            <div className="mb-1 pt-16">
              <div className="my-1 px-4">
                <Search
                  search={searchQ}
                  setSearch={setSearchQ}
                  ekkLanguage={ekkLanguage}
                  placeholder={ekkLanguage.customerList.searchPlaceHolder}
                  singleSearch={singleDistributorSearch}
                  filter={false}
                  filterHandler={filterHandler}
                  // selectedFilter={selectedFilter}
                  // setTempSearchParams={setTempSearchParams}
                  setReachedEnd={setReachedEnd}
                  setHasMoreData={setHasMoreData}
                />
              </div>
              <div className="my-3 px-4">
                <CMSFilter
                  setCMSType={setCMSType}
                  createdCustomerList={createdCustomerList}
                  assignedCustomerList={assignedCustomerList}
                  ekkLanguage={ekkLanguage}
                  setReachedEnd={setReachedEnd}
                  setHasMoreData={setHasMoreData}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  userType={userType}
                ></CMSFilter>
              </div>
              {loading ? (
                <IonLoading
                  isOpen={true}
                  message={ekkLanguage.customerList.loader}
                ></IonLoading>
              ) : (
                <CustomerList
                  loading={loading}
                  customerData={customerData}
                  link={"customer-details"}
                  ekkLanguage={ekkLanguage}
                  identity="customer"
                ></CustomerList>
              )}
            </div>
            {/* <Modal
              style={customModalStyles}
              closeTimeoutMS={200}
              ariaHideApp={false}
              isOpen={isOpenModal}
              onRequestClose={onCloseModal}
              classNames="bottom-sheet-modal"
            >
              <div>
                <Card title="Visits" onHide closeModal={onCloseModal}>
                  <div>
                    <IonRow>
                      {brandList.length > 0 ? (
												brandList.map((item, i) => {
													return (
														<IonCol size='6' key={i}>
															<div className='flex items-center justify-between'>
																<div
																	className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer'
																	onClick={() => {
																		getProductListWithBrand(
																			item.id
																		);
																		setSelectedBrand(item.id);
																		onCloseModalBrandModal();
																	}}>
																	{item?.images?.length ? (
																		<div className='h-8 w-8 rounded-full overflow-hidden'>
																			<img
																				src={
																					item?.images[0]
																				}
																				alt='logo'></img>
																		</div>
																	) : (
																		<div className='h-8 w-8 flex justify-center items-center bg-black-333 rounded-full'>
																			<h3 className='font-bold text-16 text-white'>
																				{item?.brand_name[0].toUpperCase()}
																			</h3>
																		</div>
																	)}

																	<div className='ml-4'>
																		<h4 className='text-12 text-black-1000 font-bold pb-[2px]'>
																			{item?.brand_name}
																		</h4>

																		<p className='text-10 text-black-500'>
																			{item?.role ===
																				"Imported" &&
																				"Imported"}
																		</p>
																	</div>
																</div>
															</div>
														</IonCol>
													);
												})
											) : (
												<>
													<IonCol size='2'></IonCol>
													<IonCol size='8'>
														<div
															className='text-center flex items-center justify-center'
															style={{
																height: "calc(100vh - 420px)",
															}}>
															<div>
																<img
																	className='m-auto mb-[10px] mt-5'
																	src='/assets/images/not-found-file.png'
																	alt='emptyCard'
																/>
																<p className='text-16 font-normal text-222222 mb-[35px]'>
																	{
																		ekkLanguage.orderList
																			.notFound
																	}
																</p>
															</div>
														</div>
													</IonCol>
												</>
											)}
                    </IonRow>
                  </div>
                </Card>
              </div>
            </Modal> */}
          </section>

          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              //controll loading
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              lodeMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.customerList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          <CustomerBottomSheet toRedirect="my-distributor" />
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

export default CMSListPage;

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function FooterV2() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='lg:fixed bg-white w-full z-50 bottom-0 left-0 right-0'>
			<hr className='border border-[#0000001A]' />
			<div className='container lg:py-3 py-2'>
				<div className=''>
					<div className='md:flex justify-between items-center text-center'>
						<p className='text-[#808080] font-normal text-16 text-center'>
							&copy;{" "}
							<a
								className='underline font-semibold'
								href='https://www.linkedin.com/in/enamanc/'
								target='_blank'
								alt=''>
								{ekkLanguage.navFooter.copyWriteName},{" "}
							</a>
							{ekkLanguage.navFooter.copyWriteText}
						</p>
						<Link to='/faq' className='text-delivered underline'>
							FAQ
						</Link>
					</div>
				</div>
				{/* <div className='flex flex-col'>
					<p className='text-[#000000] font-bold text-16'>
						{ekkLanguage.landing.downloadApp}
					</p>
					<div className='flex justify-center lg:justify-start mt-1'>
						<a
							className='mr-4'
							// target='_blank'
							href='#!'
							// rel='noreferrer'
						>
							<img
								src='/assets/images/play-store.png'
								alt='play'
								className='h-5 w-[66px]'
							/>
						</a>
						<a
							className=''
							// target='_blank'
							href='#!'
							// rel='noreferrer'
						>
							<img
								src='/assets/images/app-store.png'
								alt='app'
								className='h-5 w-[66px]'
							/>
						</a>
					</div>
				</div> */}
			</div>
		</div>
	);
}

import Pen from "assets/images/icon/svg/Pen";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import User from "services/User";
import { IonSpinner } from "@ionic/react";

const DropzoneInput = ({ name = "image", user, setUserInfo }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [name, files]);

  useEffect(() => {
    const uploadFile = async () => {
      if (files.length !== 0) {
        setLoading(true);
        let formData = new FormData();
        formData.append("key", files[0]);

        try {
          let response = await User.toBucketImage(formData);
          if (response.status === 201 || response.status === 200) {
            var reqbody = {
              profile_image: response?.results[0],
            };
            let resp = await User.basicInfoUpdate(user?._id, reqbody);
            if (resp.status === 200 || resp.status === 201) {
              let res = await User.getBasicInfoUpdate();
              if (res.status === 200 || res.status === 201) {
                setUserInfo(res?.data);
                setLoading(false);
              }
            }
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    };

    uploadFile();
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <section>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 rounded-full">
            <IonSpinner />
          </div>
        )}
        {!loading && (
          <span
            className="absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-1 bottom-0 border-4 border-white"
            style={{ background: "#5A7EFF", lineHeight: "28px" }}
          >
            <Pen />
          </span>
        )}
      </div>
    </section>
  );
};

export default DropzoneInput;

import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Search from "components/mobile-components/Common/Search";
import SupplierSelect from "components/mobile-components/SupplierSelect";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Supplier from "services/Supplier";

export default function SelectSupplierPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const selectedSupplier = useSelector(
    (state) => state.customer.selectedSupplier
  );
  const [loading, setLoading] = useState(true);
  const [searchQ, setSearchQ] = useState("");
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [location, setLocation] = useState({});
  const [pageCount, setPageCount] = useState(2);
  const [supplierList, setSupplierList] = useState([]);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const getAllSupplierList = async (lon, lat) => {
    setLoading(true);
    let response = await Supplier.getAllSupplierList(lon, lat);
    if (response.status === 200 || response.status === 201) {
      setSupplierList(response.results);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          console.error("Error getting location: ", error.message);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  }, []);

  let loadMoreData = async () => {
    if (hasMoreData) {
      if (searchQ) {
        const response = await Supplier.getAllSupplierListSearch(
          searchQ,
          location?.longitude,
          location?.latitude,
          pageCount
        );
        if (response.status === 200) {
          if (response.results.length) {
            setPageCount((prev) => prev + 1);
            setSupplierList([...supplierList, ...response?.results]);
          } else {
            setHasMoreData(false);
          }
        } else {
          setHasMoreData(false);
        }
      } else {
        const response = await Supplier.getAllSupplierList(
          location?.longitude,
          location?.latitude,
          pageCount
        );
        if (response.status === 200) {
          if (response.results.length) {
            setPageCount((prev) => prev + 1);
            setSupplierList([...supplierList, ...response?.results]);
          } else {
            setHasMoreData(false);
          }
        } else {
          setHasMoreData(false);
        }
      }
    }
  };

  const singleSupplierSearch = async () => {
    if (searchQ) {
      const response = await Supplier.getAllSupplierListSearch(
        searchQ,
        location?.longitude,
        location?.latitude,
        1
      );
      if (response.status === 200) {
        if (response?.results?.length) {
          setPageCount(2);
          setSupplierList(response?.results);
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(false);
      }
    }
  };
  useEffect(() => {
    if (searchQ === "" && (location?.longitude || location?.latitude)) {
      getAllSupplierList(location?.longitude, location?.latitude);
    }
  }, [searchQ, location]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.orderForecast.selectSupplierPageTitle}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="supplierSelection"
            isHide={false}
          />
          <section className="pt-20 px-4">
            <Search
              search={searchQ}
              setSearch={setSearchQ}
              ekkLanguage={ekkLanguage}
              placeholder={ekkLanguage.orderForecast.supplierSearchPlaceholder}
              singleSearch={singleSupplierSearch}
              filter={false}
              // filterHandler={filterHandler}
              // selectedFilter={selectedFilter}
              // setTempSearchParams={setTempSearchParams}
              setReachedEnd={setReachedEnd}
              setHasMoreData={setHasMoreData}
            />
            {loading ? (
              <IonLoading
                isOpen={true}
                message={ekkLanguage.customerList.loader}
              ></IonLoading>
            ) : (
              <SupplierSelect
                ekkLanguage={ekkLanguage}
                loading={loading}
                supplierList={supplierList}
                selectedSupplier={selectedSupplier}
              />
            )}
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.learning.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

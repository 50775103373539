import React, { useState, forwardRef } from "react";
import Sheet from "react-modal-sheet";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerDetails } from "redux/container/userSlice";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import moment from "moment";
import { setBusinessCreation } from "redux/container/customerSlice";
import { getLocalUser } from "config/user";
import Contract from "services/Contract";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";
import { getCheckInDetails } from "config/localContract";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import Calendar from "assets/images/icon/svg/Calendar";
import Add from "assets/images/icon/svg/Add";

export default function CustomerDetailsBottomSheet({
  id,
  contactDetails,
  getBusinessInfoDetails,
  businessInfoData,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const localLanguage = getLocalUser();
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);
  const checkInDetails = getCheckInDetails();
  const [expectedCloseDate, setExpectedCloseDate] = useState(
    contactDetails?.contractinfo?.expected_close_date
  );
  const [expectedCloseDateError, setExpectedCloseDateError] = useState("");
  const ref = React.useRef();
  const [statuses, setStatuses] = useState([
    {
      label: "Identified (Not visited yet)",
      value: "Identified",
    },
    {
      label: "Engaged (Visited and need follow up)",
      value: "Engaged",
    },
    {
      label: "Negotiation (Interested)",
      value: "Negotiation",
    },
    {
      label: "Win - Usage Started",
      value: "Win",
    },
    {
      label: "Lost - Closed",
      value: "Lost",
    },
  ]);
  const [status, setStatus] = useState(
    contactDetails.contractinfo.status
      ? statuses.find(
          (status) => status.value === contactDetails.contractinfo.status
        )
      : ""
  );
  const isOpenBottomSheet = useSelector((state) => state.user.customerDetails);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "330px",
      height: "auto",
      overflow: "visible",
      transition: ".4s all ease",
      borderRadius: 12,
      backgroundColor: "#FFFFFF",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };
  const handleUpdateStatus = async function () {
    setLoader(true);
    let data = {
      contract_info: {
        id: contactDetails?.contractinfo?.id,
        status: status.value,
        expected_close_date: moment(expectedCloseDate).format("YYYY-MM-DD"),
      },
    };

    let response = await Contract.updateBusinessContractStatus(
      contactDetails?.id,
      data
    );
    if (response.status === 200 || response.status === 201) {
      onCloseModal();
      setLoader(false);
      getBusinessInfoDetails();
      navigate(`/customer-details/${id}`);
      toast.success(ekkLanguage.customerList.closeDateSuccessMessage);
    } else {
      setLoader(false);
    }
  };
  let dateChanger = (e) => {
    setExpectedCloseDate(e);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      className={`bg-white pl-3 py-[15px] block rounded-md mt-2 border example-custom-input ${
        expectedCloseDate ? "border-1 border-success" : "border-primary"
      } `}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span>
            <Calendar color={expectedCloseDate ? "#36a94d" : "#FF564C"} />
          </span>
          <p
            className={`text-14  ${
              expectedCloseDate ? "text-success font-bold" : "text-primary"
            } ml-2`}
          >
            {expectedCloseDate
              ? moment(expectedCloseDate).format("DD/MM/YYYY")
              : ekkLanguage.createContract.expectedCloseDatePlaceholder}
          </p>
        </div>
        <div>
          <Add />
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <Sheet
        className="bottom-sheet"
        initialSnap={1}
        ref={ref}
        isOpen={isOpenBottomSheet}
        snapPoints={[220, 220, 100, 0]}
        onClose={() => dispatch(setCustomerDetails(false))}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            {Number(checkInDetails?.businessinfo) !== Number(id) && (
              <IonItem
                button
                onClick={() => {
                  // let date = new Date();
                  // let data = {
                  //   check_in_time: moment(date).format("YYYY-MM-DD hh:mm:ss"),
                  // };
                  // dispatch(
                  //   setBusinessCreation({
                  //     visit: data,
                  //   })
                  // );
                  navigate("/customer-check-in");
                }}
                lines="none"
              >
                <div className="flex items-center justify-center py-[5px]">
                  {" "}
                  <span className="mr-[10px]">
                    <img
                      src="/assets/images/checkIn.png"
                      className="h-10 w-10"
                      alt=""
                    />
                  </span>
                  <p className="text-16 font-bold text-black-1000 pl-2">
                    {ekkLanguage.customerList.addCustomPrice}
                  </p>
                </div>
              </IonItem>
            )}
            <IonItem
              button
              onClick={() => {
                // setUpdateCloseDate(false);
                onOpenModal();
                dispatch(setCustomerDetails(false));
              }}
              lines="none"
            >
              <div className="flex items-center justify-center py-[7px]">
                {" "}
                <span className="mr-[10px]">
                  <img
                    src="/assets/images/contract-status.png"
                    className="h-10 w-10"
                    alt=""
                  />
                </span>
                <p className="text-16 font-bold text-black-1000 pl-2 pt-1">
                  {ekkLanguage.customerList.updateStatus}
                </p>
              </div>
            </IonItem>
            {businessInfoData?.interested_area === "Software" ? (
              ""
            ) : (
              <IonItem
                button
                onClick={() => {
                  navigate("/future-order-form");
                }}
                lines="none"
              >
                <div className="flex items-center justify-center py-[7px]">
                  {" "}
                  <span className="mr-[10px]">
                    <img
                      src="/assets/images/order_forcast.png"
                      className="h-10 w-10"
                      alt=""
                    />
                  </span>
                  <p className="text-16 font-bold text-black-1000 pl-2 pt-1">
                    {ekkLanguage.customerList.orderForecast}
                  </p>
                </div>
              </IonItem>
            )}
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop onClick={() => dispatch(setCustomerDetails(false))} />
      </Sheet>

      {/* Delete Modal  */}
      <Modal
        style={customStyles}
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          {/* <span className=" inline-block  rounded-full">
            <img
              src="/assets/images/contract-status2.png"
              className="h-[50px] w-[50px]"
              alt=""
            />
          </span>
          <h6 className="text-16 font-bold ">
            {ekkLanguage.customerList.warningMsg}
          </h6> */}
          {/* Status Type  */}
          <div className="mb-8">
            <NewCustomSelect
              type="status-selection"
              localLanguage={localLanguage}
              options={statuses}
              setOption={setStatus}
              option={status}
              mainLabel={ekkLanguage.createContract.statusLabel}
              selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
            ></NewCustomSelect>
            <div className="flex pt-[15px]">
              <label className="flex tracking-wide text-grey-darker text-16 font-bold  ml-[3px]">
                {ekkLanguage.customerList.closeDate}
              </label>
            </div>
            <DatePicker
              minDate={moment().toDate()}
              selected={
                expectedCloseDate
                  ? moment(expectedCloseDate)._d
                  : moment(new Date()).add(1, "days")._d
              }
              onChange={(date) => dateChanger(date)}
              customInput={<ExampleCustomInput />}
              // withPortal
            />
          </div>

          <div className="flex mt-12">
            <button
              style={{ border: "2px solid #00000026" }}
              className="rounded-md h-[45px] w-1/2 bg-[#FFFFFF] text-black font-bold mr-2"
              onClick={onCloseModal}
            >
              {ekkLanguage.customerList.cancel}
            </button>
            <button
              color="primary"
              className={`${
                loader && "select-none cursor-no-drop"
              } font-bold h-[45px] rounded-[7px] w-1/2 ml-2 border-2 bg-success text-white flex justify-center items-center`}
              onClick={!loader && handleUpdateStatus}
            >
              {loader ? (
                <ClipLoader
                  sizeUnit={"px"}
                  size={20}
                  color={"white"}
                  loading={true}
                />
              ) : (
                ekkLanguage.customerList.update
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

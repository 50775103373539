import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
	name: "task",
	initialState: {
		data: {},
		newTask: {},
	},
	reducers: {
		setNewTask: (state, action) => {
			state.newTask = action.payload;
		},
		emptyTask: (state, action) => {
			state.newTask = {};
		},
	},
});

export const { actions, reducer } = taskSlice;

export const setNewTask = (values) => (dispatch, getState) => {
	let data = { ...getState().task.newTask, ...values };
	dispatch(actions.setNewTask(data));
};

export const emptyTask = () => (dispatch) => {
	dispatch(actions.emptyTask());
};
export default reducer;

import { IonCol, IonRow, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";

function MyTaskBtn({
	taskType,
	setTaskType,
	setReachedEnd,
	setHasMoreData,
	getTaskList,
	setQuery,
	ekkLanguage,
}) {
	return (
		<IonRow>
			<IonCol size='12'>
				<IonSegment
					value=''
					scrollable
					className='orderBtnWrapper'
					onIonChange={(e) => {
						setTaskType(e.detail.value);
						setQuery(e.detail.value);
						getTaskList(e.detail.value, e.detail.value);
						setReachedEnd(false);
						setHasMoreData(true);
					}}>
					<IonSegmentButton value='Creator' className='paid relative'>
						<button
							className={`${
								taskType === "Creator"
									? "text-[#00B224]"
									: "text-[#00000054]"
							} font-sans p-0 font-bold shadow-none text-14`}>
							{ekkLanguage.task.myTask}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Invited'
						className='partial-delivery relative'>
						<button
							className={`${
								taskType === "Invited"
									? "text-[#66D9FF]"
									: "text-[#00000054]"
							} font-sans p-0 font-bold shadow-none text-14 flex`}>
							{ekkLanguage.task.invited}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Participant'
						className='cancel relative'>
						<button
							className={`${
								taskType === "Participant"
									? "text-primary"
									: "text-[#00000054]"
							} font-sans p-0 font-bold shadow-none text-14 flex`}>
							{ekkLanguage.task.jointed}
						</button>
					</IonSegmentButton>
				</IonSegment>
			</IonCol>
		</IonRow>
	);
}
export default memo(MyTaskBtn);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__portal {
	width: 100%;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--selected {
	background-color: #ff564c;
}
.react-datepicker__day--today {
	border: 2px solid rgb(249 75 65 / var(--tw-bg-opacity));
	border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/mobile-components/CustomerDetails/style.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;AACA;;;CAGC,yBAAyB;AAC1B;AACA;CACC,uDAAuD;CACvD,kBAAkB;AACnB","sourcesContent":[".react-datepicker__portal {\n\twidth: 100%;\n}\n.react-datepicker__day--keyboard-selected,\n.react-datepicker__day--selected:hover,\n.react-datepicker__day--selected {\n\tbackground-color: #ff564c;\n}\n.react-datepicker__day--today {\n\tborder: 2px solid rgb(249 75 65 / var(--tw-bg-opacity));\n\tborder-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

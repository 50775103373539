import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";
import React from "react";

export default function CustomRadioButton2({
  currentState,
  value,
  setCurrentState,
  setTypeOfState,
  typeOfState,
  imgSource,
  title,
  label1,
  label2,
  labelImg,
  star,
}) {
  const {
    currentState1,
    currentState2,
    currentState3,
    currentState4,
    currentState5,
  } = currentState;
  const {
    setCurrentState1,
    setCurrentState2,
    setCurrentState3,
    setCurrentState4,
    setCurrentState5,
  } = setCurrentState;
  const { value1, value2, value3, value4, value5 } = value;
  const { title1, title2, title3, title4, title5 } = title;
  const { imgSource1, imgSource2, imgSource3, imgSource4, imgSource5 } =
    imgSource;
  return (
    <>
      <IonLabel
        className={`flex items-center font-bold text-16 mt-5 ${
          !label2 && "mb-[10px]"
        }`}
      >
        <img className="mr-[10px]" src={labelImg} alt="work"></img>{" "}
        <span>{label1}</span>
      </IonLabel>
      {label2 && (
        <IonLabel className="flex items-center mb-[10px] ml-6">
          <p className="text-12 text-[#00000034]">({label2})</p>
        </IonLabel>
      )}
      <IonRadioGroup
        value={typeOfState}
        onIonChange={(e) => {
          const selectedOption = e.detail.value;
          setCurrentState1(selectedOption === value1);
          setCurrentState2(selectedOption === value2);
          setCurrentState3(selectedOption === value3);
          setCurrentState4(selectedOption === value4);
          setCurrentState5(selectedOption === value5);
          setTypeOfState(selectedOption);
          console.log(selectedOption);
        }}
      >
        <IonItem
          lines="none"
          className={`border mb-3 relative ${!currentState1 && "opacity-60"} ${
            currentState1 && "border-success font-bold"
          }`}
        >
          <IonLabel className=" text-14 text-black-400 absolute left-8 w-full">
            <div className="flex items-center">
              {imgSource1 && (
                <img className="ml-1 mr-[10px] w-5" src={imgSource1} alt="" />
              )}
              <span className="ml-1">{title1}</span>
            </div>
          </IonLabel>
          <IonRadio value={value1} color="success" />
        </IonItem>
        <IonItem
          lines="none"
          className={`border mb-3 relative ${!currentState2 && "opacity-60"} ${
            currentState2 && "border-success font-bold"
          }`}
        >
          <IonLabel className=" text-14 text-black-400 absolute left-8 w-full">
            <div className="flex items-center">
              {imgSource2 && (
                <img className="ml-1 mr-[10px] w-5" src={imgSource2} alt="" />
              )}
              <span className="ml-1">{title2}</span>
            </div>
          </IonLabel>
          <IonRadio value={value2} color="success" />
        </IonItem>
        <IonItem
          lines="none"
          className={`border mb-3 relative ${!currentState3 && "opacity-60"} ${
            currentState3 && "border-success font-bold"
          }`}
        >
          <IonLabel className=" text-14 text-black-400 absolute left-8 w-full">
            <div className="flex items-center">
              {imgSource3 && (
                <img className="ml-1 mr-[10px] w-5" src={imgSource3} alt="" />
              )}
              <span className="ml-1">{title3}</span>
            </div>
          </IonLabel>
          <IonRadio value={value3} color="success" />
        </IonItem>
        <IonItem
          lines="none"
          className={`border mb-3 relative ${!currentState4 && "opacity-60"} ${
            currentState4 && "border-success font-bold"
          }`}
        >
          <IonLabel className=" text-14 text-black-400 absolute left-8 w-full">
            <div className="flex items-center">
              {imgSource4 && (
                <img className="ml-1 mr-[10px] w-5" src={imgSource4} alt="" />
              )}
              <span className="ml-1">{title4}</span>
            </div>
          </IonLabel>
          <IonRadio value={value4} color="success" />
        </IonItem>
        <IonItem
          lines="none"
          className={`border mb-3 relative ${!currentState5 && "opacity-60"} ${
            currentState5 && "border-success font-bold"
          }`}
        >
          <IonLabel className=" text-14 text-black-400 absolute left-8 w-full">
            <div className="flex items-center">
              {imgSource5 && (
                <img className="ml-1 mr-[10px] w-5" src={imgSource5} alt="" />
              )}
              <span className="ml-1">{title5}</span>
            </div>
          </IonLabel>
          <IonRadio value={value5} color="success" />
        </IonItem>
      </IonRadioGroup>
    </>
  );
}

import React from "react";

export default function LeftArrow({ color = "#222222" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
    >
      <path
        d="M1.3125 8.3429L20.0625 8.3429"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.87451 15.8734L1.31201 8.34338L8.87451 0.812134"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    data: true,
    location: {},
    relationDetails: {},
    businessCreation: {},
    userType: "",
    brandList: [],
    selectedBrands: [],
    selectedSupplier: {}
  },
  reducers: {
    setCustomer: (state, action) => {
      state.data = action.payload;
    },
    setCustomerLocation: (state, action) => {
      state.location = action.payload;
    },
    setRelationDetails: (state, action) => {
      state.relationDetails = action.payload;
    },
    setBusinessCreation: (state, action) => {
      state.businessCreation = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload;
    },
    setSelectedBrands: (state, action) => {
      state.selectedBrands = action.payload;
    },
    setSelectedSupplier: (state, action) => {
      state.selectedSupplier = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = customerSlice;

export const setBusinessCreation = (values) => async (dispatch, getState) => {
  let data = { ...getState().customer.businessCreation, ...values };
  dispatch(actions.setBusinessCreation(data));
};

// Export the reducer, either as a default or named export
export const {
  setCustomer,
  setCustomerLocation,
  setRelationDetails,
  setUserType,
  setBrandList,
  setSelectedBrands,
  setSelectedSupplier
} = customerSlice.actions;

export default reducer;

import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";

const Contract = {
  checkBusiness: (number) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/crm_business_check/?phone_number=${number}`
    );
  },
  checkBusinessInfo: (id) => {
    return SuperFetch(`${baseUrl.contract}/businessinfo/?business_id=${id}`);
  },
  createBusiness: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/crm_business_create/`, {
      method: "POST",
      data: data,
    });
  },
  getRegionList: () => {
    return SuperFetch(`${baseUrl.contract}/region/`);
  },
  getAreaList: (id) => {
    return SuperFetch(`${baseUrl.contract}/area/${id}/`);
  },
  getCreatedBusinessInfoList: (page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.contract}/businessinfo/?created_by=True&page=${page}&limit=${limit}`
    );
  },
  getAssignedBusinessInfoList: (page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.contract}/businessinfo/?account_executive=True&page=${page}&limit=${limit}`
    );
  },
  getAssignedHSBusinessInfoList: (page = 1, limit = 30) => {
    return SuperFetch(
      `${baseUrl.contract}/businessinfo/?hub_staff=True&page=${page}&limit=${limit}`
    );
  },
  getBusinessInfoListSearch: (searchQ, type) => {
    return SuperFetch(
      `${baseUrl.contract}/businessinfo_search/?q=${searchQ}&${type}=${true}`
    );
  },
  createBusinessInfo: (data) => {
    return SuperFetch(`${baseUrl.contract}/businessinfo/`, {
      method: "POST",
      data: data,
    });
  },
  createBusinessContact: (data) => {
    return SuperFetch(`${baseUrl.contract}/contact/`, {
      method: "POST",
      data: data,
    });
  },
  createBusinessContract: (data) => {
    return SuperFetch(`${baseUrl.contract}/contract/`, {
      method: "POST",
      data: data,
    });
  },
  createBusinessVisit: (data) => {
    return SuperFetch(`${baseUrl.contract}/visit/`, {
      method: "POST",
      data: data,
    });
  },
  updateBusinessVisit: (id, data) => {
    return SuperFetch(`${baseUrl.contract}/visit/${id}/`, {
      method: "PUT",
      data: data,
    });
  },
  getServiceTypeList: (page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.contract}/service/?page=${page}&limit=${limit}`
    );
  },
  getRevenueTierList: (page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.contract}/revenue/?page=${page}&limit=${limit}`
    );
  },
  getBusinessInfoDetails: (id) => {
    return SuperFetch(`${baseUrl.contract}/businessinfo/${id}/`);
  },
  getBusinessContactDetails: (id) => {
    return SuperFetch(`${baseUrl.contract}/contact/?business_id=${id}`);
  },
  updateBusinessContractStatus: (id, data) => {
    return SuperFetch(`${baseUrl.contract}/contact/${id}/`, {
      method: "PUT",
      data: data,
    });
  },
  getBusinessContractDetails: (id) => {
    return SuperFetch(`${baseUrl.contract}/contract/${id}/`);
  },
  getVisitDetails: (id, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.contract}/visit/?businessinfo_id=${id}&page=${page}&limit=${limit}`
    );
  },
  getBusinessInfoDashboard: () => {
    return SuperFetch(`${baseUrl.contract}/businessinfo_dashboard/`);
  },
};

export default Contract;

import {
  IonApp,
  IonButton,
  IonContent,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import { useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import User from "services/User";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { setCheckInDetails } from "config/localContract";
import UploadImage from "components/mobile-components/Common/UploadImage";
import Contract from "services/Contract";
import { setCustomerDetails } from "redux/container/userSlice";

export default function CustomerCheckInPage() {
  const userType = useSelector((state) => state.customer.userType);
  const [files1, setFiles1] = useState("");
  const [loading, setLoading] = useState(false);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const businessInfo = useSelector((state) => state.customer.relationDetails);
  const dispatch = useDispatch();
  const [location, setLocation] = useState({});
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const handleSubmit = async () => {
    let zone = moment.tz.guess();
    let formData = new FormData();
    if (typeof files1 === "string") {
      function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString =
          splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
      }
      const cameraFile = DataURIToBlob(files1);
      formData.append("key", cameraFile, "image.jpg");
    } else {
      formData.append("key", files1[0]);
    }
    let response = await User.toBucketImage(formData);
    if (response.status === 201 || response.status === 200) {
      let data = {
        check_in_image: response.results[0],
        check_in_time: moment().utc(date).tz(zone).format(),
        latitude: location?.latitude,
        longitude: location?.longitude,
        businessinfo: businessInfo?.id,
        visit_type: userType === "EkkHero Hub Staff" ? "Support" : "Sales",
      };
      let res = await Contract.createBusinessVisit(data);
      if (res.status === 200 || res.status === 201) {
        setLoading(false);
        setCheckInDetails(res.results);
        toast.success(ekkLanguage.checkIn.checkInSuccessMessage);
        dispatch(setCustomerDetails(false));
        navigate(`/customer-details/${businessInfo?.id}`);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position);
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function (error) {
          console.error("Error getting location: ", error.message);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.checkIn.pageTitle}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            // from="customerDetails"
          />
          <section className="pt-20 px-4 verifyBusiness-content">
            <div className="mt-[0px]">
              <UploadImage
                cameraImage={files1}
                setCameraImage={setFiles1}
                withOutBusinessType={true}
                label={ekkLanguage.checkIn.imageTitle}
                placeholder={ekkLanguage.checkIn.takeYourImage}
              ></UploadImage>
            </div>
            <div className="mt-[250px]">
              <IonButton
                disabled={loading}
                type="submit"
                onClick={() => {
                  !loading && handleSubmit();
                }}
                color="success"
                expand="full"
                className="font-bold h-10 w-full mt-5 text-white"
              >
                {loading ? (
                  <IonSpinner />
                ) : (
                  <span className="text-white flex">
                    {ekkLanguage.checkIn.pageTitle}
                    <span className="pl-2">
                      <ArrayRight />
                    </span>
                  </span>
                )}
              </IonButton>
            </div>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

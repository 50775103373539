import React from "react";
import Banner from "./Banner";
import CustomerNameTitle from "./CustomerNameTitle";
import { IonLoading } from "@ionic/react";
import BusinessInfo from "./BusinessInfo";
import ContactInfo from "./ContactInfo";
import ContractInfo from "./ContractInfo";
import VisitInfo from "./VisitInfo";

export default function CustomerDetail({
  loading,
  businessData,
  contactData,
  contractData,
  visitData,
  visitCount,
  ekkLanguage,
}) {
  return (
    <>
      {loading ? (
        <IonLoading
          isOpen={loading}
          message={ekkLanguage.customerList.loader}
        />
      ) : (
        <div className="pt-14">
          <Banner businessData={businessData?.business} />
          <div className="px-2 py-4">
            <CustomerNameTitle
              businessData={businessData?.business}
            ></CustomerNameTitle>
            <BusinessInfo
              businessData={businessData}
              ekkLanguage={ekkLanguage}
            ></BusinessInfo>
            <div className="mt-5">
              <ContactInfo
                ekkLanguage={ekkLanguage}
                contactData={contactData}
                businessData={businessData?.business}
              ></ContactInfo>
            </div>
            <div className="mt-5">
              <ContractInfo
                ekkLanguage={ekkLanguage}
                contractData={contactData?.contractinfo}
                businessData={businessData}
              ></ContractInfo>
            </div>
            <div className="mt-5">
              <VisitInfo
                ekkLanguage={ekkLanguage}
                visitData={visitData}
                visitCount={visitCount}
              ></VisitInfo>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React from "react";
import { motion } from "framer-motion";
import Tik from "assets/images/icon/svg/Tik";
import { useDispatch } from "react-redux";
import { setSelectedSupplier } from "redux/container/customerSlice";
import { useNavigate } from "react-router-dom";
import { digitChanger } from "helpers/helper";

export default function SupplierCard({
  id,
  supplierName,
  distance,
  address,
  selectedSupplier,
  ekkLanguage,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(
      setSelectedSupplier({
        id: id,
        supplierName: supplierName,
        distance: distance,
        address: address,
      })
    );
    setTimeout(() => {
      navigate("/future-order-form");
    }, 500);
  };
  return (
    <div
      className="bg-[#FFFFFF] rounded-md p-2 drop-shadow-md relative cursor-pointer"
      onClick={handleClick}
    >
      <p className="text-12 font-bold mb-1">{supplierName}</p>
      <div className="absolute top-[-5px] right-[-5px]">
        {selectedSupplier?.id === id && (
          <motion.div
            initial="hidden"
            animate={{ scale: [0.75, 1, 0.75] }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="p-1.5 rounded-full bg-success"
          >
            <Tik height={20} width={20} color={"white"} />
          </motion.div>
        )}
      </div>
      <p className="text-10 text-[#00000080] mb-1">{address}</p>
      <span className="flex">
        <img className="w-3 h-3" src="/assets/images/Icon/pin.png" alt="" />
        <p className="text-primary text-10 pl-1 font-bold">
          {digitChanger(distance)} {ekkLanguage.home.km}
        </p>
      </span>
    </div>
  );
}

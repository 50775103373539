import {
  IonApp,
  IonButton,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getLanguage } from "config/language";
import { getLocalUser } from "config/user";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setBusinessCreation } from "redux/container/customerSlice";
import { setAllInfo, setCreateCustomerBS } from "redux/container/userSlice";
import Contract from "services/Contract";
import User from "services/User";

export default function InterestedAreaPage() {
  const [initStickyClass, setInitStickyClass] = useState("issticky");
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const language = getLanguage();
  const contentRef = useRef();
  const [initHeight] = useState(72);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [softwareSelected, setSoftwareSelected] = useState(false);
  const [financeSelected, setFinanceSelected] = useState(false);

  const handleNext = () => {
    const interestedJobs = [
      softwareSelected && "Software",
      financeSelected && "Financing",
    ].filter(Boolean);
    dispatch(
      setBusinessCreation({ interested_area: interestedJobs?.toString() })
    );
    navigate("/create-business-info");
  };

  const handleSoftwareToggle = () => {
    setSoftwareSelected(true);
    setFinanceSelected(false);
  };

  const handleFinanceToggle = () => {
    setFinanceSelected(true);
    setSoftwareSelected(false);
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent
          scrollEvents={true}
          ref={contentRef}
          onIonScroll={async (ev) => {
            const scrollPosition = ev.detail.scrollTop;
            if (scrollPosition > initHeight) {
              setInitStickyClass("issticky");
            } else {
              setInitStickyClass("");
            }
          }}
        >
          <BackButtonTitle
            title={ekkLanguage.createBusinessInfo.interestedAreaPageTitle}
            from="interestedAreaPage"
            isHide={true}
          />
          <div className="pt-[70px] px-4 flex justify-start items-center flex-col">
            <p className="font-semibold mb-5">
              {ekkLanguage.createBusinessInfo.interestedAreaQuestionTitle}
            </p>
            <div className="h-[620px]">
              <IonRow className="w-full">
                <IonCol
                  className={`rounded-md border-2 ml-3 mr-5 relative w-[150px] h-[100px] ${
                    softwareSelected
                      ? "border-[#00B224] bg-white opacity-100"
                      : "opacity-50"
                  }`}
                >
                  <div
                    onClick={handleSoftwareToggle}
                    className="text-center block p-3"
                  >
                    <img
                      src="/assets/images/ekkhero/Icons/software.png"
                      alt="software"
                      className="w-[45px] mb-3 m-auto"
                    />
                    <h6
                      className="font-bold absolute bottom-1 left-[50%] w-full text-14"
                      style={{ transform: "translateX(-50%)" }}
                    >
                      {ekkLanguage.createBusinessInfo.software}
                    </h6>
                  </div>
                </IonCol>
                <IonCol
                  className={`rounded-md border-2 mr-5 relative ${
                    financeSelected
                      ? "border-[#00B224] bg-white opacity-100"
                      : "opacity-50"
                  }`}
                >
                  <div
                    onClick={handleFinanceToggle}
                    className="text-center block p-3"
                  >
                    <img
                      src="/assets/images/ekkhero/Icons/finance.png"
                      alt="finance"
                      className="m-auto mb-2 w-[45px]"
                    />
                    <h6
                      className="font-bold absolute bottom-1 left-[50%] w-full text-14"
                      style={{ transform: "translateX(-50%)" }}
                    >
                      {ekkLanguage.createBusinessInfo.financing}
                    </h6>
                  </div>
                </IonCol>
              </IonRow>
            </div>
            <div className="flex items-end justify-center w-full mx-5">
              <IonButton
                disabled={!(softwareSelected || financeSelected)}
                onClick={handleNext}
                color="success"
                className="font-bold h-10 w-full"
              >
                <span className="flex text-white">
                  {ekkLanguage.newCustomer.nextBtn}{" "}
                  <span className="pl-2">
                    <ArrayRight />
                  </span>
                </span>
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

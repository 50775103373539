import Trash from "assets/images/icon/svg/Trash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";
import "./style.scss";
import capture from "assets/images/icon/camera-icon.png";
import cameraSwitch from "assets/images/icon/camera-switch.png";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

export default function UploadImage({ cameraImage, setCameraImage, withOutBusinessType, label, placeholder }) {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const webcamRef = React.useRef(null);

  const cameraOpenHandler = () => {
    setIsCameraOpen(true);
  };
  let videoConstraints = {
    facingMode: facingMode,
    width: "100%",
    height: "100%",
    minWidth: "320px",
  };
  const trashImageHandler = () => {
    setCameraImage(null);
    setIsCameraOpen(false);
  };

  const handleTakePhoto = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCameraImage(imageSrc);
    setIsCameraOpen(false);
  }, [webcamRef, setCameraImage]);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  return (
    <div className="pt-7">
      <div className="flex items-center justify-between pb-[10px]">
        <p className="text-16 text-black-1000 font-semibold">
          {withOutBusinessType
            ? label
            : ekkLanguage?.newCustomer?.businessPhoto}{" "}
        </p>

        <span
          onClick={trashImageHandler}
          className="h-10 w-10 bg-white rounded-[7px] text-center flex items-center justify-center"
          style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)" }}
        >
          <Trash />
        </span>
      </div>

      <div className="text-left rounded-[10px] overflow-hidden">
        <div
          className={`rounded-[10px] flex items-center justify-center ${
            !isCameraOpen ? "border-2 border-dashed border-[#00000054]" : ""
          }`}
          style={{
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
            background: "#E9EFFF",
            height: !isCameraOpen && "300px",
            width: "100%",
          }}
        >
          {isCameraOpen ? (
            <div className="relative photo-camera">
              <Webcam
                className="webcam"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                screenshotQuality={1}
              />
              <div className="bg-black-500 absolute bottom-0 w-full flex items-center justify-between">
                <span
                  onClick={handleClick}
                  className=" rounded-[7px] text-center flex items-center justify-center"
                  style={{
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <img
                    src={cameraSwitch}
                    alt="Camera"
                    className="z-1 w-8 ml-4"
                  />
                </span>
                <span
                  onClick={handleTakePhoto}
                  className="cursor-pointer rounded-[7px] text-center flex items-center justify-center"
                  style={{
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <img src={capture} alt="Camera" className="z-1" />
                </span>
                <span></span>
              </div>
            </div>
          ) : (
            <>
              {cameraImage ? (
                <img className="w-full lg:h-[230px]" src={cameraImage} alt="avatar" />
              ) : (
                <div
                  onClick={cameraOpenHandler}
                  className="text-center rounded-[5px] inline-block px-9 py-5 cursor-pointer"
                  // style={{
                  //   boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
                  // }}
                >
                  <img
                    className="m-auto"
                    src="/assets/images/add-image.png"
                    alt="camera"
                  />
                  <p className="text-14 pt-1 text-black-1000">
                    {withOutBusinessType
                      ? placeholder
                      : ekkLanguage?.newCustomer?.uploadPhoto}
                  </p>
                </div>
              )}
            </>
          )}{" "}
        </div>
      </div>
    </div>
  );
}
import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useRef, useState } from "react";
import HomePageBanner from "../../components/mobile-components/HomePage/HomePageBanner";
import RegisterProcess from "../../components/mobile-components/HomePage/RegisterProcess";
import { useDispatch, useSelector } from "react-redux";
import Blog from "services/Blog";
import NavFooter from "components/mobile-components/Common/NavFooter";
import { setPostList } from "redux/container/learningSlice";
import { getLanguage } from "config/language";
import Learning from "components/mobile-components/Learning";
import { setAllInfo } from "redux/container/userSlice";
import User from "services/User";
import Contract from "services/Contract";
import { setUserType } from "redux/container/customerSlice";
import Tasks from "components/mobile-components/Task";
import Task from "services/Task";
import TaskLocation from "components/mobile-components/Task/TaskLocation";
import locationIcon from "assets/images/icon/location.png";

function Home() {
	const contentRef = useRef();
	const localLanguage = getLanguage();
	const learningList = useSelector((state) => state.learning.postList);
	const [pageCount, setPageCount] = useState(2);
	const [dashboard, setDashboard] = useState("");
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const ekkUser = useSelector((state) => state.user.allInfo);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const [hasMoreData, setHasMoreData] = useState(true);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [userTypeData, setUserTypeData] = useState({});
	const location = useSelector((state) => state.user.locationData);
	const myLocation = useSelector(
		(state) => state.user?.locationData?.coordinates
	);
	const [dataForMap, setDataForMap] = useState([]);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const [taskView, setTaskView] = useState(true);

	let loadMoreData = async () => {
		if (hasMoreData) {
			let response = await Task.getTasks(pageCount, 9);
			if (response.status === 200 || response.status === 201) {
				if (response.results.length) {
					setPageCount((prev) => prev + 1);
					dispatch(setPostList([...learningList, ...response?.results]));
				} else {
					setHasMoreData(false);
				}
			} else {
				setHasMoreData(false);
			}
		}
	};

	useEffect(() => {
		const getAllData = async () => {
			let response = await Task.getTasks();
			if (response.status === 200) {
				dispatch(setPostList(response.results));
				setLoading(false);
				const filteredResults = response.results
					?.filter((el) => el?.latitude && el?.longitude) // Filter out entries without latitude or longitude
					.map((el) => ({
						lat: el.latitude,
						lng: el.longitude,
						id: el.id,
					}));
				setDataForMap(filteredResults);
			} else {
				setLoading(false);
			}
		};
		getAllData();
	}, [localLanguage, dispatch]);

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}>
					<BackButtonTitle
						title={ekkLanguage.letNav.homeMenuLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<HomePageBanner
						userTypeData={userTypeData}
						ekkUser={ekkUser}
						ekkLanguage={ekkLanguage}
						dashboard={dashboard}></HomePageBanner>
					{loading && (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.learning.loader}
						/>
					)}
					<section className='px-4 mb-4'>
						<div className='flex justify-between mb-4'>
							<div className='flex items-center'>
								<img
									className='w-6 h-6'
									src='/assets/images/learning.png'
									alt=''
								/>
								<h6 className='font-bold text-20 ml-2'>
									{ekkLanguage.task.latestTask}
								</h6>
							</div>
							<div>
								<span
									onClick={() => setTaskView(!taskView)}
									className='flex h-[40px] w-[51px] bg-white ml-[10px] rounded-[5px] cursor-pointer'
									style={{
										border: "2px solid rgba(52, 168, 83, 0.50)",
										boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
									}}>
									<img
										src={locationIcon}
										className='m-auto w-7'
										alt='location'
									/>
								</span>
							</div>
						</div>
						{taskView ? (
							<Tasks
								ekkLanguage={ekkLanguage}
								learningList={learningList}></Tasks>
						) : (
							<TaskLocation
								taskList={dataForMap}
								center={
									myLocation
										? { lat: myLocation[1], lng: myLocation[0] }
										: {
												lat: 23.5204,
												lng: 90.399452,
										  }
								}
								height='400px'
								zoom={6.5}
								location={location}
								from='pickLocation'
								// to={to}
							/>
						)}
					</section>
					{taskView && (
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								if (reachedEnd) {
									ev.target.complete();
									return;
								}

								loadMoreData();
								setTimeout(() => {
									ev.target.complete();
									if (!hasMoreData) {
										setReachedEnd(true);
									}
								}, 2000);
							}}>
							<IonInfiniteScrollContent
								loadingText={ekkLanguage.learning.loader}
								loadingSpinner={
									hasMoreData ? "bubbles" : null
								}></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)}
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default Home;

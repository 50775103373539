import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import LearningDetails from "components/mobile-components/LearningDetails";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Blog from "services/Blog";

export default function LearningDetailsPage() {
  const [loading, setLoading] = useState(true);
  const [learningDetails, setLearningDetails] = useState({});
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [count, setCount] = useState("");
  const ekkLanguage = useSelector((state) => state.lan.lan);

  const getLearningDetails = async () => {
    let response = await Blog.learningDetails(id);
    if (response.status === 200) {
      setLearningDetails(response.results);
      setCount(response.results.comments.length);
      setComments(response.results.comments);
      setLoading(false);
    }
  };

  const getCommentList = async () => {
    let response = await Blog.getCommentList(id);
    if (response.status === 200) {
      setCount(response.meta.count);
      setComments(response.comments);
    }
  };

  // let lodeMoreComments = async () => {
  // 	let response = await Blog.getCommentList(id, pageCount);
  // 	if (response.status === 200) {
  // 		if (response?.comments?.length > 0) {
  // 			setPageCount((prev) => prev + 1);
  // 			setComments([...comments, ...response.comments]);
  // 		}
  // 	}
  // };

  useEffect(() => {
    getLearningDetails();
    // getCommentList();
  }, []);

  return (
    <IonApp style={{ "--ion-background-color": "#E6E5E5" }}>
      <IonPage>
        <IonContent scrollEvents={true}>
          <BackButtonTitle title={ekkLanguage.learning.learningDetailsTitle} />
          {loading && <IonLoading isOpen={true} />}
          <section className="pt-14">
            <LearningDetails
              id={id}
              comments={comments}
              setComments={setComments}
              learningDetails={learningDetails}
              setLearningDetails={setLearningDetails}
              count={count}
              setCount={setCount}
              ekkLanguage={ekkLanguage}
            />
          </section>
          {/* 
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreComments();
								setTimeout(() => ev.target.complete(), 1000);
							}}
						>
							<IonInfiniteScrollContent
							// loadingText={ekkLanguage.orderList.loader}
							// loadingSpinner='bubbles'
							></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					 */}
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

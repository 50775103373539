import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
import { getUser } from "config/user";
let user = getUser();

const Brand = {
  getBrandList: (page = 1, limit = 10) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brands/?page=${page}&limit=${limit}`);
  },
  singleBrandDetails: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brands/${id}`);
  },
  brandProducts: (id, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/brands/${id}/products/?page=${page}&limit=${limit}`
    );
  },
  getSellerData: (id, page = 1, limit = 10) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brands/${id}/sellers`);
  },
  getSellerProduct: (id, sellerId, page = 1) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/brands/${id}/sellers/${sellerId}/products?page=${page}`
    );
  },
  brandsSearch: (query = "", page = 1) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/brands/search?term=${query}&page=${page}`
    );
  },
};
export default Brand;

import React from "react";

export default function Home({ color = "rgba(0, 178, 36, 1)" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			style={{ margin: "auto" }}>
			<g id='Iconly/Bold/Home'>
				<g id='Home'>
					<path
						id='Home_2'
						d='M8.37387 19.0422V16.2393C8.37387 15.5238 8.95815 14.9438 9.67889 14.9438H12.3136C12.6597 14.9438 12.9916 15.0803 13.2363 15.3232C13.4811 15.5662 13.6186 15.8957 13.6186 16.2393V19.0422C13.6164 19.3397 13.7339 19.6257 13.945 19.8368C14.1561 20.0479 14.4434 20.1666 14.743 20.1666H16.5405C17.38 20.1688 18.1859 19.8393 18.7802 19.2507C19.3746 18.6622 19.7087 17.863 19.7087 17.0297V9.0446C19.7086 8.3714 19.4081 7.73283 18.8879 7.30093L12.7732 2.45286C11.7095 1.60283 10.1855 1.63027 9.1536 2.51804L3.17842 7.30093C2.63367 7.7201 2.30808 8.36056 2.29199 9.0446V17.0215C2.29199 18.7585 3.71043 20.1666 5.46015 20.1666H7.21659C7.83895 20.1667 8.34474 19.6682 8.34925 19.0504L8.37387 19.0422Z'
						fill={color}
					/>
				</g>
			</g>
		</svg>
		
	);
}

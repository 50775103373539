import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
  getBusiness: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business_details/${id}/`);
  },
  getBranchList: (page = 1, limit = "50") => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
    );
  },
  getPaymentData: (id) => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/bank-info/details/${id}`
    );
  },
  updateUserBusiness: (id, query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business_update/${id}/`, {
      method: "PUT",
      data: query,
    });
  },
  updateBusinessLocation: (id, query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/business/edit/${id}/`, {
      method: "PUT",
      data: query,
    });
  },
  customerCheck: (phoneNo) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brand/distributor_check/${phoneNo}/`);
  },
  getRelationList: () => {
    return SuperFetch(`${baseUrl.BizNpdt}/brand/relation_types/`);
  },
  createNewBusiness: (query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/brand/distributor_create/`, {
      method: "POST",
      data: query,
    });
  },
  getBusinessType: (page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkhero/business_types/?page=${page}&limit=${limit}`
    );
  },
};

export default Business;

import React, { useEffect, useState } from "react";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import PhoneNumber from "./PhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
	actions,
	selectors,
	setAppUrl,
	setPreUrl,
} from "redux/container/authSlice";
import { setLanguage } from "config/language";
import FooterV2 from "./FooterV2";
import { baseUrl } from "config/apiUrl";
import SentOtpV2 from "./SentOtpV2";
import SectionHeader from "pages/Landing/SectionHeader";
import Blog from "services/Blog";
import SingleTask from "./SingleTask";
import Task from "services/Task";

export default function PhoneNumberCheck() {
	const { app, link } = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [countryCode, setCountryCode] = useState("+88");
	const [country, setCountry] = useState("Bangladesh");
	const [phoneNo, setPhoneNo] = useState("");
	var sSize = window.matchMedia("(max-width: 599px)");
	const { loginStatus } = useSelector(selectors.getAuth);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	var parts = location?.search?.split("?");
	useEffect(() => {
		if (app === "ekkhero_pilot_log_in") {
			navigate(loginStatus ? "/home" : `/${app}`);
			dispatch(setAppUrl({ app: app, link: baseUrl.ehp }));
		} else if (parts[1]) {
			dispatch(actions.logout());
			localStorage.removeItem("master_token");
			localStorage.removeItem("_u");
			localStorage.removeItem("_b");
			localStorage.removeItem("cb");
			localStorage.setItem("auth", "false");
			dispatch(setPreUrl(parts[1]));
			navigate(
				loginStatus ? "/home" : `/${parts[1] ? `?${parts[1]}` : "/"}`
			);
		} else {
			console.log("Login true");
			dispatch(setPreUrl(""));
			navigate(loginStatus ? "/home" : "/");
		}
	}, [loginStatus]);

	if (localStorage.getItem("lan") === null) {
		setLanguage("bn");
	}

	const [taskList, setTaskList] = useState([]);
	const getTaskList = async () => {
		let blogList = await Task.getTasksPublic();
		if (blogList.status === 200) {
			setTaskList(blogList.results);
		}
	};

	useEffect(() => {
		getTaskList();
	}, []);

	return (
		<>
			<LandingNavBar />
			<div className='container'>
				<div className='flex items-center pt-[200px]'>
					<TextBannerArea ekkLanguage={ekkLanguage} />
					<PhoneNumber
						sSize={sSize}
						phoneNo={phoneNo}
						setPhoneNo={setPhoneNo}
						country={country}
						setCountry={setCountry}
						countryCode={countryCode}
						setCountryCode={setCountryCode}
					/>
				</div>
				<div>
					<div className='mt-20' style={{ paddingBottom: 100 }}>
						{" "}
						<SectionHeader title={ekkLanguage.task.latestTask} />
						<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3'>
							{taskList?.map((el, i) => {
								return <SingleTask item={el} key={i} />;
							})}
						</div>
						<div className='text-center pt-14 lg:mb-0 mb-10 pb-5'>
							<Link
								to='/task-list'
								className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}>
								{ekkLanguage.landing.seeMore}
							</Link>
						</div>
					</div>
				</div>
			</div>
			<FooterV2 />
		</>
	);
}

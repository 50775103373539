import { IonApp, IonContent, IonPage } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessCreation } from "redux/container/customerSlice";
import { getUser } from "config/user";
import CreateContact from "components/mobile-components/CreateContact";

export default function CreateContactPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const createdInfo = useSelector((state) => state.customer.businessCreation);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localUser = getUser();

  const handleSubmit = async (query) => {
    dispatch(setBusinessCreation({ business_contact: query }));
    navigate("/create-contract");
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.createBusinessInfo.pageTitle}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="businessInfoPage"
          />
          <section className="pt-20 px-4 ">
            <CreateContact
              handleSubmit={handleSubmit}
              ekkLanguage={ekkLanguage}
              createdInfo={createdInfo}
              user={localUser}
            ></CreateContact>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

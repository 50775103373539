import React from "react";

export default function Pen({ color = "white" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='13'
			height='14'
			viewBox='0 0 13 14'
			style={{ margin: "auto" }}
			fill='none'>
			<path
				d='M12.045 4.49668C12.2652 4.33226 12.2393 3.98947 12.045 3.79514L9.69925 1.44939C9.50492 1.25507 9.19204 1.25507 8.99771 1.44939L5.19334 5.24716L1.39407 9.03981C1.19802 10.2561 0.996842 11.4774 0.800781 12.6937L4.44091 12.0867C6.4088 10.1188 7.33873 9.19236 8.27732 8.2573C9.2159 7.32223 10.139 6.40265 12.045 4.49668V4.49668Z'
				stroke={color}
				strokeWidth='0.7'
				strokeMiterlimit='6.2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M10.8703 5.67333L10.9117 5.63194C11.0403 5.50335 11.0403 5.29487 10.9117 5.16628L8.29372 2.54827C8.16513 2.41968 7.95665 2.41968 7.82807 2.54827L7.78668 2.58966C7.65809 2.71824 7.65809 2.92673 7.78668 3.05531L10.4047 5.67333C10.5332 5.80192 10.7417 5.80192 10.8703 5.67333Z'
				fill={color}
			/>
			<path
				d='M2.97611 12.6403L0.88345 10.5476L0.800781 12.6937L2.97611 12.6403Z'
				fill={color}
			/>
			<path
				d='M4.72383 11.7798L4.76522 11.7384C4.89381 11.6099 4.89381 11.4014 4.76522 11.2728L2.14723 8.65477C2.01865 8.52618 1.81017 8.52618 1.68158 8.65477L1.64019 8.69616C1.51161 8.82475 1.51161 9.03323 1.64019 9.16182L4.25818 11.7798C4.38677 11.9084 4.59525 11.9084 4.72383 11.7798Z'
				fill={color}
			/>
		</svg>
	);
}

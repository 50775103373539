import { digitChanger, monthChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import Card from "../Common/Card";
import moment from "moment";

export default function BusinessInfo({ businessData, ekkLanguage }) {
  // console.log(businessData);
  return (
    <Card title={ekkLanguage.customerDetails.businessCardTitle}>
      <div className="px-1 flex flex-col w-full">
        {businessData?.interested_area === "Software" ? (
          <>
            <div className="flex justify-between w-full py-[7px] mt-1">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.interestedAreaPageTitle}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.interested_area}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.usedSoftware}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.software_using}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.usedSoftwareProblem}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.pain_point}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.AEUserLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.account_executive?.full_name}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.createdBy}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.created_by_user?.full_name}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.createdAt}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.createdAt
                  ? digitChanger(
                      monthChanger(
                        moment(businessData?.createdAt).format("DD MMM, YY")
                      )
                    )
                  : ""}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between w-full py-[7px] mt-1">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.interestedAreaPageTitle}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.interested_area ?? "Financing"}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold w-1/3">
                {ekkLanguage.createBusinessInfo.brandsLabel}
              </div>

              <div className="text-000080 text-14 font-medium">
                {Array.isArray(businessData?.brands) &&
                businessData?.brands.length > 0
                  ? businessData?.brands
                      .map((brand) => brand.brand_name)
                      .toString()
                  : businessData?.brands}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.skusLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(numberWithCommas(businessData?.total_sku))}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.srsLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(businessData?.total_sr)}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.driversLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(businessData?.total_driver)}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.customersLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(businessData?.total_customer)}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.salesLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(
                  numberWithCommas(businessData?.monthly_sales_amount)
                )}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.fsLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(
                  numberWithCommas(businessData?.monthly_fs_support)
                )}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.pwFsLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.pwfs}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.operationLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {digitChanger(businessData?.business_operation_duration)}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.AEUserLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.account_executive?.full_name}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.HSuserLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.hub_staff?.full_name}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.regionLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.region?.title}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.createBusinessInfo.areaLabel}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.area?.title}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.createdBy}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.created_by_user?.full_name}
              </div>
            </div>
            <div className="flex justify-between w-full py-[7px]">
              <div className="text-000000 text-14 font-semibold">
                {ekkLanguage.customerDetails.createdAt}
              </div>
              <div className="text-000080 text-14 font-medium">
                {businessData?.createdAt
                  ? digitChanger(
                      monthChanger(
                        moment(businessData?.createdAt).format("DD MMM, YY")
                      )
                    )
                  : ""}
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

import {
  IonButton,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { Input } from "components/global-components/common/Input";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import User from "services/User";
import moment from "moment";
import { DayPicker, MonthPicker, YearPicker } from "react-dropdown-date";

export default function Contact() {
  const userData = useSelector((state) => state.user.data);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [birthDay, setBirthDay] = useState({
    year: userData?.dob ? moment(userData?.dob).format("YYYY") : "",
    month: userData?.dob ? Number(moment(userData?.dob).format("MM") - 1) : "",
    day: userData?.dob ? moment(userData?.dob).format("DD") : "",
  });
  const [email, setEmail] = useState(userData.contact_and_basic_info?.email);
  const [nid, setNid] = useState(userData.contact_and_basic_info?.nid_number);
  const [name, setName] = useState(userData?.full_name);
  const [gender, setGender] = useState(userData?.gender);
  const history = useNavigate();
  const [changingMonth, setChangingMonth] = useState(false);

  let contactUpdateHandler = async () => {
    if ((email && email.includes("@")) || nid || name || gender || birthDay) {
      var reqbody = {
        id_number: nid,
        email: email,
        display_name: name,
        gender: gender,
        dob: moment(
          `${birthDay?.year}-${
            changingMonth ? Number(birthDay?.month) + 1 : birthDay?.month
          }-${birthDay?.day}`
        ).format("YYYY-MM-DD"),
      };
      try {
        let response = await User.basicInfoUpdate(userData?._id, reqbody);
        if (response.status === 200 || response.status === 201) {
          toast.success(ekkLanguage.contact.successLabel);
          history("/profile");
        }
      } catch (e) {
        console.log(e);
      }
    } else {
    }
  };
  let permissionToChange = () => {
    if (
      userData.contact_and_basic_info?.email === email &&
      userData.contact_and_basic_info?.nid_number === nid &&
      userData.contact_and_basic_info?.full_name === name &&
      userData?.gender === gender &&
      userData?.dob
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="px-4 relative pt-20">
      <Input
        label={{
          label: ekkLanguage.contact.nameLabel,
          source: "/assets/images/user_name.jpg",
        }}
        onChange={(event) => {
          setName(event.target.value);
          permissionToChange();
        }}
        // star
        type="text"
        name="name"
        value={name}
        placeholder={ekkLanguage.contact.namePlaceholder}
      ></Input>
      <div className="mt-4">
        <Input
          label={{
            label: ekkLanguage.contact.emailLabel,
            source: "/assets/images/email.jpg",
          }}
          onChange={(event) => {
            setEmail(event.target.value);
            permissionToChange();
          }}
          // star
          type="email"
          name="email"
          value={email}
          placeholder={ekkLanguage.contact.emailPlaceholder}
        ></Input>
      </div>
      <div className="mt-4 mb-1">
        <Input
          label={{
            label: ekkLanguage.contact.nidLabel,
            source: "/assets/images/nid.jpg",
          }}
          onChange={(event) => {
            setNid(event.target.value);
            permissionToChange();
          }}
          // star
          type="text"
          name="nid"
          value={nid}
          placeholder={ekkLanguage.contact.nidPlaceholder}
        ></Input>
      </div>
      {/* Label for Gender  */}
      <div className="flex my-2">
        <img
          className="mr-[10px] w-5 h-5 pl-1"
          src="/assets/images/gender.png"
          alt=""
        />
        <label className="font-bold text-16 pl-[1px] tracking-wide text-grey-darker">
          {ekkLanguage.registration.genderLabel}
        </label>
      </div>
      <div className="pb-2 pt-1">
        <IonRadioGroup
          className="grid grid-cols-2 gap-2"
          value={gender}
          disabled={true}
          onIonChange={(e) => {
            setGender(e.detail.value);
          }}
        >
          <IonRadio
            className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
            value="Male"
            justify="start"
            labelPlacement="end"
            color="success"
          >
            {ekkLanguage.registration.male}
          </IonRadio>
          <IonRadio
            className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
            value="Female"
            justify="start"
            labelPlacement="end"
            color="success"
          >
            {ekkLanguage.registration.female}
          </IonRadio>
          {/* <IonRadio
            className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
            value="Others"
            justify="start"
            labelPlacement="end"
            color="success"
          >
            {ekkLanguage.registration.others}
          </IonRadio> */}
        </IonRadioGroup>
      </div>
      {/* label for Date of Birth  */}
      <div className="flex my-2">
        <img
          className="mr-[10px] w-5 h-5 pl-1"
          src="/assets/images/dob.png"
          alt=""
        />
        <label className="font-bold text-16 pl-[1px] tracking-wide text-grey-darker">
          {ekkLanguage.registration.dobLabel}
        </label>
      </div>
      <div className="grid grid-cols-3 gap-2 py-1">
        <div className="relative">
          <DayPicker
            defaultValue={
              userData?.dob ? birthDay?.day : ekkLanguage.registration.day
            }
            year={birthDay?.year}
            month={birthDay?.month}
            endYearGiven // mandatory if end={} is given in YearPicker
            required={true}
            value={birthDay?.day}
            onChange={(day) => {
              setBirthDay({ ...birthDay, day: day });
            }}
            id={"day"}
            name={"day"}
            classes={`py-3 ${
              birthDay?.day ? "text-gray-darker" : "text-gray-400"
            } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
            optionClasses={"option classes"}
          />
          <img
            className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
            src="/assets/images/rightArrow.png"
            alt=""
          />
        </div>
        <div className="relative">
          {" "}
          <MonthPicker
            defaultValue={
              userData?.dob ? birthDay?.month : ekkLanguage.registration.month
            }
            short
            endYearGiven // mandatory if end={} is given in YearPicker
            year={birthDay?.year}
            required={true}
            value={birthDay?.month}
            onChange={(month) => {
              setBirthDay({ ...birthDay, month: month });
              setChangingMonth(true);
            }}
            id={"month"}
            name={"month"}
            classes={`py-3 ${
              birthDay?.month ? "text-gray-darker" : "text-gray-400"
            } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
            optionClasses={"option classes"}
          />
          <img
            className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
            src="/assets/images/rightArrow.png"
            alt=""
          />
        </div>
        <div className="relative">
          <YearPicker
            defaultValue={
              userData?.dob ? birthDay?.year : ekkLanguage.registration.year
            }
            start={1970}
            end={2006}
            reverse
            required={true}
            value={birthDay?.year}
            onChange={(year) => {
              setBirthDay({ ...birthDay, year: year });
            }}
            id={"year"}
            name={"year"}
            classes={`py-3 ${
              birthDay?.year ? "text-gray-darker" : "text-gray-400"
            } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
            optionClasses={"option classes"}
          />
          <img
            className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
            src="/assets/images/rightArrow.png"
            alt=""
          />
        </div>
      </div>

      <div className="submit-button-wrapper mt-[180px]">
        <IonRow>
          <IonCol size="12">
            <div className="text-center">
              <IonButton
                disabled={permissionToChange()}
                expand="full"
                className="bg-primary rounded-[7px] font-extrabold text-12 h-10"
                onClick={contactUpdateHandler}
              >
                {ekkLanguage.contact.btnLabel}
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessCreation } from "redux/container/customerSlice";
import Contract from "services/Contract";
import { getUser } from "config/user";
import CreateContract from "components/mobile-components/CreateContract";
import { toast } from "react-toastify";
import { setCreateCustomerBS } from "redux/container/userSlice";
import CreateContact from "components/mobile-components/CreateContact";
import {
  getBusinessCreationType,
  getCustomerRole,
  removeBusinessCreationType,
  removeCustomerRole,
} from "config/localContract";

export default function CreateContractPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  let businessType = getBusinessCreationType();
  let newCustomerRole = getCustomerRole();
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const createdInfo = useSelector((state) => state.customer.businessCreation);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localUser = getUser();
  const businessInterestedArea = useSelector(
    (state) => state.customer.businessCreation.interested_area
  );

  const handleSubmit = async (query) => {
    console.log("this is hitted");
    let data;
    dispatch(setBusinessCreation({ contract_info: query }));
    if (businessType === "old") {
      data = {
        business: Number(createdInfo?.business?.id),
        role: role.value,
      };
    } else {
      data = {
        business: Number(createdInfo?.business?.id),
        role: newCustomerRole,
      };
    }
    let response = await Contract.createBusinessContact({
      business_contact: data,
      contract_info: query,
    });
    if (response.status === 200 || response.status === 201) {
      dispatch(setCreateCustomerBS(false));
      toast.success(ekkLanguage.createContract.successMessage);
      removeBusinessCreationType();
      if (businessType === "new") {
        removeCustomerRole();
      }
      navigate("/contract-management");
      dispatch(setBusinessCreation({}));
    } else {
      dispatch(setCreateCustomerBS(false));
      removeBusinessCreationType();
      dispatch(setBusinessCreation({}));
      // toast.error(response?.message?.business[0]);
      navigate("/contract-management");
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.createBusinessInfo.pageTitle}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="businessInfoPage"
            isHide={true}
          />
          <section className="pt-20 px-4 ">
            {businessType === "old" && (
              <CreateContact
                ekkLanguage={ekkLanguage}
                // handleSubmit={handleSubmit}
                role={role}
                setRole={setRole}
                createdInfo={createdInfo}
                roleError={roleError}
              ></CreateContact>
            )}
            <CreateContract
              businessType={businessType}
              handleSubmit={handleSubmit}
              ekkLanguage={ekkLanguage}
              createdInfo={createdInfo}
              user={localUser}
              setRoleError={setRoleError}
              role={role}
              interestedArea={businessInterestedArea}
            ></CreateContract>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import { IonButton } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Calendar from "assets/images/icon/svg/Calendar";
import { Input } from "components/global-components/common/Input";
import { getLocalUser } from "config/user";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Contract from "services/Contract";
import "./style.css";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";
import { convertToNumber, formatAndFilterInput } from "helpers/helper";
import { getLanguage } from "config/language";

export default function CreateContract({
  ekkLanguage,
  handleSubmit,
  createdInfo,
  setRoleError,
  user,
  role,
  businessType,
  interestedArea,
}) {
  const localLanguage = getLanguage();
  const [orderTypes, setOrderTypes] = useState([
    {
      label: ekkLanguage.createContract.primaryOrder,
      value: "Primary",
    },
    {
      label: ekkLanguage.createContract.secondaryOrder,
      value: "Secondary",
    },
    {
      label: ekkLanguage.createContract.tertiaryOrder,
      value: "Tertiary",
    },
    {
      label: `${
        ekkLanguage.createContract.primaryOrder +
        `${localLanguage === "bn" ? " এবং " : " and "}` +
        ekkLanguage.createContract.secondaryOrder
      }`,
      value: "Primary & Secondary",
    },
  ]);
  const [statuses, setStatuses] = useState([
    {
      label: "Identified (Not visited yet)",
      value: "Identified",
    },
    {
      label: "Engaged (Visited and need follow up)",
      value: "Engaged",
    },
    {
      label: "Negotiation (Interested)",
      value: "Negotiation",
    },
  ]);
  const [orderType, setOrderType] = useState("");
  const [orderTypeError, setOrderTypeError] = useState("");
  const [expectedMonthlyRevenue, setExpectedMonthlyRevenue] = useState("");
  const [expectedMonthlyRevenueError, setExpectedMonthlyRevenueError] =
    useState("");
  const [expectedCloseDate, setExpectedCloseDate] = useState();
  const [expectedCloseDateError, setExpectedCloseDateError] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const [revenueTier, setRevenueTier] = useState("");
  const [revenueTierError, setRevenueTierError] = useState("");
  const [revenueTiers, setRevenueTiers] = useState([]);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState("");

  useEffect(() => {
    const getServiceAndRevenueTiers = async () => {
      let response = await Contract.getServiceTypeList();
      if (response.status === 200 || response.status === 201) {
        let newArr = [];
        response.results.map((res) => {
          let obj = {
            ...res,
            value: res.id,
          };
          newArr.push(obj);
        });
        setServiceTypes(newArr);
      }
      let response2 = await Contract.getRevenueTierList();
      if (response2.status === 200 || response2.status === 201) {
        let newRevenue = [];
        response2.results.map((res) => {
          let obj = {
            ...res,
            value: res.id,
          };
          newRevenue.push(obj);
        });
        setRevenueTiers(newRevenue);
      }
    };
    getServiceAndRevenueTiers();
  }, []);
  let dateChanger = (e) => {
    setExpectedCloseDate(e);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      className={`bg-white pl-3 py-[15px] block rounded-md mt-2 border example-custom-input ${
        expectedCloseDate ? "border-1 border-success" : "border-primary"
      } `}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span>
            <Calendar color={expectedCloseDate ? "#36a94d" : "#FF564C"} />
          </span>
          <p
            className={`text-14  ${
              expectedCloseDate ? "text-success font-bold" : "text-primary"
            } ml-2`}
          >
            {expectedCloseDate
              ? moment(expectedCloseDate).format("DD-MM-YYYY")
              : ekkLanguage.createContract.expectedCloseDatePlaceholder}
          </p>
        </div>
        <div>
          <Add />
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <h5 className="text-[#00B224] font-bold text-18 mb-5 text-center">
        {ekkLanguage.createContract.pageHeadline}
      </h5>
      {/* Order Type  */}
      {interestedArea === "Software" ? (
        ""
      ) : (
        <NewCustomSelect
          type="orderType-selection"
          localLanguage={localLanguage}
          options={orderTypes}
          setOption={setOrderType}
          option={orderType}
          mainLabel={ekkLanguage.createContract.orderTypeLabel}
          selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
          errorMessage={orderTypeError}
          star
        ></NewCustomSelect>
      )}
      {/* Service Type  */}
      <div className="mt-3">
        {" "}
        <NewCustomSelect
          type="serviceType-selection"
          localLanguage={localLanguage}
          options={serviceTypes}
          setOption={setServiceType}
          option={serviceType}
          mainLabel={ekkLanguage.createContract.serviceTypeLabel}
          selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
          errorMessage={serviceTypeError}
          star
        ></NewCustomSelect>
      </div>

      {/* Revenue Tier Type  */}
      <div className="mt-3">
        {" "}
        <NewCustomSelect
          type="revenueType-selection"
          localLanguage={localLanguage}
          options={revenueTiers}
          setOption={setRevenueTier}
          option={revenueTier}
          mainLabel={ekkLanguage.createContract.revenueTierLabel}
          selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
          errorMessage={revenueTierError}
          star
        ></NewCustomSelect>
      </div>
      {/* Expected Monthly Revenue */}
      {/* <div className="mt-[15px]">
        <Input
          label={{
            label: ekkLanguage.createContract.monthlyRevenueLabel,
            //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
          }}
          onChange={(event) => {
            const inputValue = event.target.value;
            const formattedValue = formatAndFilterInput(inputValue);
            setExpectedMonthlyRevenue(formattedValue);
          }}
          star
          type="text"
          name="revenue"
          value={expectedMonthlyRevenue}
          placeholder={ekkLanguage.createContract.monthlyRevenuePlaceholder}
          errorMessage={expectedMonthlyRevenueError}
          currencyShow
        ></Input>
      </div> */}
      {/* Expected Close Date */}
      <div className="flex pt-[13px]">
        <label className="flex tracking-wide text-grey-darker text-16 font-bold  ml-[3px]">
          {ekkLanguage.createContract.expectedCloseDateLabel}
        </label>
        <span className="text-primary mx-1 font-bold">*</span>
      </div>
      <DatePicker
        minDate={moment().toDate()}
        selected={
          expectedCloseDate
            ? moment(expectedCloseDate)._d
            : moment(new Date()).add(1, "days")._d
        }
        onChange={(date) => dateChanger(date)}
        customInput={<ExampleCustomInput />}
        withPortal
      />
      <div className="flex items-center pl-1">
        {expectedCloseDateError && (
          <p className="text-primary mt-1 text-xs font-bold">
            {expectedCloseDateError}{" "}
          </p>
        )}
      </div>
      {/* Status Type  */}
      <div className="mt-3">
        <NewCustomSelect
          type="status-selection"
          localLanguage={localLanguage}
          options={statuses}
          setOption={setStatus}
          option={status}
          mainLabel={ekkLanguage.createContract.statusLabel}
          selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
          errorMessage={statusError}
          star
        ></NewCustomSelect>
      </div>

      <IonButton
        type="submit"
        onClick={() => {
          if (
            (businessType === "old" && typeof role !== "string") ||
            businessType === "new"
          ) {
            setRoleError("");
            if (
              (interestedArea === "Software" && !orderType) ||
              (interestedArea === "Financing" && orderType)
            ) {
              setOrderTypeError("");
              if (serviceType) {
                setServiceTypeError("");
                if (revenueTier) {
                  setRevenueTierError("");
                  // if (expectedMonthlyRevenue) {
                  //   setExpectedMonthlyRevenueError("");
                  if (expectedCloseDate) {
                    setExpectedCloseDateError("");
                    if (status) {
                      setStatusError("");
                      const query = {
                        order_type:
                          interestedArea === "Software" ? "" : orderType?.value,
                        service_type: Number(serviceType?.id),
                        revenue_tier: Number(revenueTier?.id),
                        status: status?.value,
                        expected_monthly_revenue: convertToNumber(
                          expectedMonthlyRevenue
                        ),
                        expected_close_date:
                          moment(expectedCloseDate).format("YYYY-MM-DD"),
                      };
                      handleSubmit(query);
                    } else {
                      setStatusError(ekkLanguage.createContract.statusError);
                    }
                  } else {
                    setExpectedCloseDateError(
                      ekkLanguage.createContract.closeDateError
                    );
                  }
                  // } else {
                  //   setExpectedMonthlyRevenueError(
                  //     ekkLanguage.createContract.monthlyRevenueError
                  //   );
                  // }
                } else {
                  setRevenueTierError(
                    ekkLanguage.createContract.revenueTierError
                  );
                }
              } else {
                setServiceTypeError(
                  ekkLanguage.createContract.serviceTypeError
                );
              }
            } else {
              setOrderTypeError(ekkLanguage.createContract.orderTypeError);
            }
          } else {
            setRoleError(ekkLanguage.createContact.roleError);
          }
        }}
        color="success"
        expand="full"
        className="font-bold h-10 w-full mt-20 mb-5"
      >
        <span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
        <span className="pl-2">
          <ArrayRight />
        </span>
      </IonButton>
    </div>
  );
}

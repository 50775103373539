import { Button } from "components/global-components/common/button";
import { Input } from "components/global-components/common/Input";
import React, { useState } from "react";
import Box from "./Box";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { updateToken } from "config/SuperFetch";
import { setLocalUser } from "config/user";
import { loginFromSignUp } from "redux/container/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import { IonRadio, IonRadioGroup } from "@ionic/react";
import moment from "moment";

export default function Registration({
  loading,
  phoneNo,
  country,
  countryCode,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [lengthError, setLengthError] = useState("");
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [birthDay, setBirthDay] = useState({ year: "", month: "", day: "" });
  const [gender, setGender] = useState("");

  let profileCreateHandler = async () => {
    if (name.length > 3) {
      if (password.length > 3 && confirmPassword.length > 3) {
        if (!(password && confirmPassword !== password)) {
          let req_body = {
            phone_number: (countryCode + phoneNo).trim(),
            full_name: name,
            email: email,
            gender: gender,
            dob: moment(
              `${birthDay.year}-${Number(birthDay.month) + 1}-${birthDay.day}`
            ).format("YYYY-MM-DD"),
            password: password,
            country: country,
            user_type: "EkkHero",
          };
          // console.log(req_body);
          try {
            const response = await Auth.createNewUser(req_body);
            if (response.status === 200) {
              updateToken(response);
              setLocalUser(response?.data);
              dispatch(loginFromSignUp(response));
              navigate("/select-job");
              toast.success(ekkLanguage.registration.successMessage);
              localStorage.setItem("auth", true);
            } else {
            }
          } catch (error) {
            console.log(error);
            toast.error(ekkLanguage.registration.errorMessage);
          }
        } else {
          setError(ekkLanguage.registration.passNotMatch);
        }
      } else {
        setLengthError(ekkLanguage.registration.passLengthValidLabel);
      }
    } else {
      setNameError(ekkLanguage.registration.validationLabel);
    }
  };

  return (
    <Box>
      <div className="flex flex-col justify-center items-center pb-[-10px]">
        <p className="font-bold text-18">
          {ekkLanguage?.registration?.createProfile}
        </p>
        <p className="font-medium text-12 mb-6">
          {ekkLanguage?.registration?.helpUs}
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          profileCreateHandler();
        }}
      >
        <Input
          source={`/src/assets/images/icon/name.png`}
          onChange={(event) => setName(event.target.value)}
          type="text"
          label={{ label: ekkLanguage.registration?.nameLabel }} //${<User></User>}
          placeholder={ekkLanguage.registration.namePlaceholder}
          required
        />
        {nameError && <span className="text-primary mt-1">{nameError}</span>}

        {/* Date of Birth  */}
        <label className="font-bold text-16 pl-[1px] tracking-wide text-grey-darker">
          {ekkLanguage.registration.dobLabel}
        </label>
        <div className="grid grid-cols-3 gap-2 py-1">
          <div className="relative">
            <DayPicker
              defaultValue={ekkLanguage.registration.day}
              year={birthDay?.year}
              month={birthDay?.month}
              endYearGiven // mandatory if end={} is given in YearPicker
              required={true}
              value={birthDay?.day}
              onChange={(day) => {
                setBirthDay({ ...birthDay, day: day });
              }}
              id={"day"}
              name={"day"}
              classes={`py-3 ${
                birthDay?.day ? "text-gray-darker" : "text-gray-400"
              } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
              optionClasses={"option classes"}
            />
            <img
              className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
              src="/assets/images/rightArrow.png"
              alt=""
            />
          </div>
          <div className="relative">
            {" "}
            <MonthPicker
              defaultValue={ekkLanguage.registration.month}
              short
              endYearGiven // mandatory if end={} is given in YearPicker
              year={birthDay?.year}
              required={true}
              value={birthDay?.month}
              onChange={(month) => {
                setBirthDay({ ...birthDay, month: month });
              }}
              id={"month"}
              name={"month"}
              classes={`py-3 ${
                birthDay?.month ? "text-gray-darker" : "text-gray-400"
              } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
              optionClasses={"option classes"}
            />
            <img
              className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
              src="/assets/images/rightArrow.png"
              alt=""
            />
          </div>
          <div className="relative">
            <YearPicker
              defaultValue={ekkLanguage.registration.year}
              start={1970}
              end={2006}
              reverse
              required={true}
              value={birthDay?.year}
              onChange={(year) => {
                setBirthDay({ ...birthDay, year: year });
              }}
              id={"year"}
              name={"year"}
              classes={`py-3 ${
                birthDay?.year ? "text-gray-darker" : "text-gray-400"
              } text-14 px-4 appearance-none border-gray-300 border-[1px] rounded w-full font-semibold`}
              optionClasses={"option classes"}
            />
            <img
              className="absolute w-2.5 h-2.5 top-[40%] right-3 rotate-90"
              src="/assets/images/rightArrow.png"
              alt=""
            />
          </div>
        </div>
        <div>
          <label className="font-bold text-16 pl-[1px] tracking-wide text-grey-darker">
            {ekkLanguage.registration.genderLabel}
          </label>
          <IonRadioGroup
            className="grid grid-cols-2 gap-2"
            value={gender}
            onIonChange={(e) => {
              setGender(e.detail.value);
            }}
          >
            <IonRadio
              className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
              value="Male"
              justify="start"
              labelPlacement="end"
              color="success"
            >
              {ekkLanguage.registration.male}
            </IonRadio>
            <IonRadio
              className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
              value="Female"
              justify="start"
              labelPlacement="end"
              color="success"
            >
              {ekkLanguage.registration.female}
            </IonRadio>
            {/* <IonRadio
              className="w-full bg-white text-14 text-black-500 border rounded-[5px] flex justify-center items-center py-3 px-2"
              value="Others"
              justify="start"
              labelPlacement="end"
              color="success"
            >
              {ekkLanguage.registration.others}
            </IonRadio> */}
          </IonRadioGroup>
        </div>
        <Input
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          label={{ label: ekkLanguage.registration.emailLabel }}
          placeholder={ekkLanguage.registration.emailPlaceholder}
        />
        <Input
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          label={{ label: ekkLanguage.registration.passwordLabel }}
          placeholder={ekkLanguage.registration.passwordPlaceholder}
          required
        />
        {lengthError && (
          <span className="text-primary mt-1">{lengthError}</span>
        )}
        <Input
          onChange={(event) => setConfirmPassword(event.target.value)}
          type="password"
          label={{ label: ekkLanguage.registration.confirmPassLabel }}
          placeholder={ekkLanguage.registration.confirmPassPlaceholder}
          required
        />
        {error && <span className="text-primary mt-1">{error}</span>}

        <div className="flex justify-center">
          {name && password === confirmPassword ? (
            <Button
              title={ekkLanguage.registration.btnLabel}
              loading={loading}
              propsRef={(input) => input}
              type="submit"
            />
          ) : (
            <Button
              title={ekkLanguage.registration.btnLabel}
              disabled={true}
              // onClick={profileCreateHandler}
              propsRef={(input) => input}
              type="submit"
            />
          )}
        </div>
      </form>
    </Box>
  );
}

import { IonLabel } from "@ionic/react";
import React, { useId } from "react";
import { useDispatch } from "react-redux";
import { setNewBusiness } from "redux/container/businessSettingsSlice";
import Select from "react-select";

export default function NewCustomSelect({
  options,
  setOption,
  mainLabel,
  extraLabel,
  labelImage,
  star,
  option,
  selectPlaceholder,
  type,
  disable,
  selectPlaceholderWarning,
  localLanguage,
  handleDistrictUpazilla,
  errorMessage,
}) {
  const dispatch = useDispatch();
  const id = useId();

  return (
    <>
      <IonLabel className="flex items-center mb-[8px]  mt-[10px]">
        <img src={labelImage} alt=""></img>
        <span
          className={`text-16 font-bold ${
            labelImage ? "ml-[10px]" : "ml-[2px]"
          }`}
        >
          {mainLabel}
        </span>
        {star && <span className="text-primary mx-1 font-bold">*</span>}
        {extraLabel && (
          <p className="ml-1 text-12 text-[#00000080]">({extraLabel})</p>
        )}
      </IonLabel>

      <Select
        key={id}
        onFocus={(e) => (e.target.readOnly = true)}
        value={
          (type === "role-selection" ||
            type === "role-selection2" ||
            type === "orderType-selection" ||
            type === "status-selection" ||
            type === "serviceType-selection" ||
            type === "revenueType-selection" ||
            type === "region-selection" ||
            type === "area-selection" ||
            type === "week-selection" ||
            type === "upazilla-selection" ||
            type === "zilla-selection") &&
          options.filter((el) => el.value === option?.value)
          // (options.length > 0 &&
          //   (type === "upazilla-selection" || type === "zilla-selection") &&
          //   options.filter((el) => el.value === option.value))
        }
        getOptionLabel={(option) =>
          ((type === "role-selection" ||
            type === "role-selection2" ||
            type === "orderType-selection" ||
            type === "status-selection" ||
            type === "week-selection") &&
            `${option.label}`) ||
          ((type === "serviceType-selection" ||
            type === "revenueType-selection" ||
            type === "region-selection" ||
            type === "area-selection") &&
            `${option.title}`) ||
          ((type === "upazilla-selection" || type === "zilla-selection") &&
          localLanguage === "bn"
            ? `${option.bn_name}`
            : `${option.name}`)
        }
        options={options}
        onChange={(selectedOption) => {
          setOption(selectedOption);
          if (type === "role-selection") {
            dispatch(setNewBusiness({ role: selectedOption.value }));
          }
          if (type === "zilla-selection") {
            handleDistrictUpazilla(selectedOption.name);
          }
          if (type === "region-selection") {
            handleDistrictUpazilla(selectedOption.id);
          }
        }}
        placeholder={!disable ? selectPlaceholder : selectPlaceholderWarning}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            height: 50,
            border: option ? "1px solid #18A558" : "1px solid #FF4500",
            outLine: "none",
            fontWeight: 500,
            fontSize: 14,
            color: "blue",
            borderRadius: 5,
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: "#FF4500",
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: "#18A558",
            fontWeight: 700,
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            color: option ? "#18A558" : "#FF4500",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: "rgb(204, 255, 214)",
            primary: "#18A558",
          },
        })}
      />
      <div className="flex items-center pl-1">
        {errorMessage && (
          <p className="text-primary mt-1 text-xs font-bold">{errorMessage} </p>
        )}
      </div>
    </>
  );
}

import { IonButton, IonCol, IonRow } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Calendar from "assets/images/icon/svg/Calendar";
import { Input } from "components/global-components/common/Input";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";
import SelectInput from "components/global-components/common/SelectInput";
import { getLanguage } from "config/language";
import { convertToNumber, formatAndFilterInput } from "helpers/helper";
import moment from "moment";
import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Contract from "services/Contract";

export default function BusinessInfo({
  ekkLanguage,
  handleSubmit,
  createdInfo,
  user,
  selectedBrandList,
  interestedArea,
}) {
  const localLanguage = getLanguage();
  // const [brands, setBrands] = useState("");
  const [brandsError, setBrandsError] = useState("");
  const [sku, setSku] = useState("");
  const [skuError, setSkuError] = useState("");
  const [sr, setSr] = useState("");
  const [srError, setSrError] = useState("");
  const [driver, setDriver] = useState("");
  const [driverError, setDriverError] = useState("");
  const [customer, setCustomer] = useState("");
  const [customerError, setCustomerError] = useState("");
  const [sales, setSales] = useState("");
  const [salesError, setSalesError] = useState("");
  const [fs, setFs] = useState("");
  const [fsError, setFsError] = useState("");
  const [operations, setOperations] = useState();
  const [operationsError, setOperationsError] = useState("");
  const [pwFs, setPwFs] = useState("");
  const [pwFsError, setPwFsError] = useState("");
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [regionError, setRegionError] = useState("");
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [usedSoftware, setUsedSoftware] = useState("");
  const [usedSoftwareError, setUsedSoftwareError] = useState("");
  const [usedSoftwareProblem, setUsedSoftwareProblem] = useState("");
  const [usedSoftwareProblemError, setUsedSoftwareProblemError] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const inputStyle = {
    outline: "none",
    border: isFocused ? "2px solid #00B224" : "2px solid #00000040",
    boxShadow: isFocused ? "0 0 10px #FFFFFF" : "none",
  };
  const inputStyle2 = {
    outline: "none",
    border: isFocused2 ? "2px solid #00B224" : "2px solid #00000040",
    boxShadow: isFocused2 ? "0 0 10px #FFFFFF" : "none",
  };
  const pwFsOptions = [
    { label: "1st Week", value: "1st Week" },
    { label: "2nd Week", value: "2nd Week" },
    { label: "3rd Week", value: "3rd Week" },
    { label: "4th Week", value: "4th Week" },
  ];

  let dateChanger = (e) => {
    setOperations(e);
  };
  let brands = selectedBrandList.map((brand) => brand?.brand_name).toString();
  let brandListForSendToAPI = selectedBrandList.map((brand) => {
    return {
      id: brand?.id,
      brand_name: brand?.brand_name,
      image: brand?.images[0],
    };
  });

  useEffect(() => {
    const getRegions = async () => {
      let response = await Contract.getRegionList();
      if (response.status === 200 || response.status === 201) {
        let newArr = [];
        response.results.map((res) => {
          let obj = {
            ...res,
            value: res.id,
          };
          newArr.push(obj);
        });
        setRegions(newArr);
      }
    };
    getRegions();
  }, []);
  const getAreaList = async (id) => {
    setArea("");
    let response = await Contract.getAreaList(id);
    if (response.status === 200 || response.status === 201) {
      let newArr = [];
      response.results.map((res) => {
        let obj = {
          ...res,
          value: res.id,
        };
        newArr.push(obj);
      });
      setAreas(newArr);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      className={`bg-white pl-3 py-[15px] block rounded-md mt-2 border example-custom-input ${
        operations ? " border-[#18A558]" : "border-primary"
      } `}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span>
            <Calendar color={operations ? "#18A558" : "#FF564C"} />
          </span>
          <p
            className={`text-14 pt-[1px] ${
              operations ? "text-[#18A558] font-bold" : "text-primary"
            } ml-2`}
          >
            {operations
              ? moment(operations).format("YYYY")
              : ekkLanguage.createBusinessInfo.operationPlaceholder}
          </p>
        </div>
        <div>
          <Add />
        </div>
      </div>
    </div>
  ));
  return (
    <div>
      <h5 className="text-[#00B224] font-bold text-18 mb-5 text-center">
        {ekkLanguage.createBusinessInfo.title}
      </h5>
      {interestedArea === "Software" ? (
        <>
          <h6 className="font-bold mb-[10px]">
            {ekkLanguage.createBusinessInfo.usedSoftware}
          </h6>
          <textarea
            style={inputStyle}
            className="rounded-md p-[10px] w-full "
            placeholder={ekkLanguage.createBusinessInfo.usedSoftwarePlaceholder}
            id="usedSoftware"
            name="usedSoftware"
            rows="6"
            value={usedSoftware}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => setUsedSoftware(e.target.value)}
          ></textarea>
          <div className="flex items-center ">
            {usedSoftwareError && (
              <p className="text-primary mt-1 text-xs font-bold pl-1">
                {usedSoftwareError}{" "}
              </p>
            )}
          </div>

          <h6 className="font-bold mb-[10px] mt-5">
            {ekkLanguage.createBusinessInfo.usedSoftwareProblem}
          </h6>
          <textarea
            style={inputStyle2}
            className="rounded-md p-[10px] w-full "
            placeholder={
              ekkLanguage.createBusinessInfo.usedSoftwareProblemPlaceholder
            }
            id="usedSoftware"
            name="usedSoftware"
            rows="6"
            value={usedSoftwareProblem}
            onFocus={() => setIsFocused2(true)}
            onBlur={() => setIsFocused2(false)}
            onChange={(e) => setUsedSoftwareProblem(e.target.value)}
          ></textarea>
          <div className="flex items-center ">
            {usedSoftwareProblemError && (
              <p className="text-primary mt-1 text-xs font-bold pl-1">
                {usedSoftwareProblemError}{" "}
              </p>
            )}
          </div>
          <IonButton
            className="font-bold h-10 w-full mt-40 mb-5"
            type="submit"
            onClick={() => {
              if (usedSoftware) {
                setUsedSoftwareError("");
                if (usedSoftwareProblem) {
                  setUsedSoftwareProblemError("");
                  const query = {
                    software_using: usedSoftware,
                    pain_point: usedSoftwareProblem,
                    business: createdInfo?.business?.id,
                    interested_area: interestedArea,
                  };
                  handleSubmit(query);
                } else {
                  setUsedSoftwareProblemError(
                    ekkLanguage.createBusinessInfo.usedSoftwareProblemError
                  );
                }
              } else {
                setUsedSoftwareError(
                  ekkLanguage.createBusinessInfo.usedSoftwareError
                );
              }
            }}
            color="success"
            expand="full"
          >
            <span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
            <span className="pl-2">
              <ArrayRight />
            </span>
          </IonButton>
        </>
      ) : (
        <>
          {" "}
          <SelectInput
            label={{
              label: ekkLanguage.createBusinessInfo.brandsLabel,
              //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
            }}
            navigationLink="/select-brands"
            star
            type="text"
            name="brands"
            value={brands}
            placeholder={ekkLanguage.createBusinessInfo.brandsPlaceholder}
            errorMessage={brandsError}
          />
          <div className="grid grid-cols-2 gap-x-3">
            {" "}
            <Input
              label={{
                label: ekkLanguage.createBusinessInfo.skusLabel,
                //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
              }}
              onChange={(event) => {
                setSku(event.target.value);
              }}
              star
              type="number"
              name="sku"
              value={sku}
              placeholder={ekkLanguage.createBusinessInfo.skusPlaceholder}
              errorMessage={skuError}
            ></Input>{" "}
            <Input
              label={{
                label: ekkLanguage.createBusinessInfo.srsLabel,
                //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
              }}
              onChange={(event) => {
                setSr(event.target.value);
              }}
              star
              type="number"
              name="sr"
              value={sr}
              placeholder={ekkLanguage.createBusinessInfo.srsPlaceholder}
              errorMessage={srError}
            ></Input>
          </div>
          <div className="grid grid-cols-2 gap-x-3">
            <div>
              <Input
                label={{
                  label: ekkLanguage.createBusinessInfo.driversLabel,
                  //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
                }}
                onChange={(event) => {
                  setDriver(event.target.value);
                }}
                star
                type="number"
                name="driver"
                value={driver}
                placeholder={ekkLanguage.createBusinessInfo.driversPlaceholder}
                errorMessage={driverError}
              ></Input>
            </div>
            <div>
              <Input
                label={{
                  label: ekkLanguage.createBusinessInfo.customersLabel,
                  //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
                }}
                onChange={(event) => {
                  setCustomer(event.target.value);
                }}
                star
                type="number"
                name="customer"
                value={customer}
                placeholder={
                  ekkLanguage.createBusinessInfo.customersPlaceholder
                }
                errorMessage={customerError}
              ></Input>
            </div>
          </div>
          <Input
            label={{
              label: ekkLanguage.createBusinessInfo.salesLabel,
              //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
            }}
            onChange={(event) => {
              const inputValue = event.target.value;
              const formattedValue = formatAndFilterInput(inputValue);
              setSales(formattedValue);
            }}
            star
            type="text"
            name="sales"
            value={sales}
            placeholder={ekkLanguage.createBusinessInfo.salesPlaceholder}
            errorMessage={salesError}
            currencyShow
          ></Input>
          <Input
            label={{
              label: ekkLanguage.createBusinessInfo.fsLabel,
              //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
            }}
            onChange={(event) => {
              const inputValue = event.target.value;
              const formattedValue = formatAndFilterInput(inputValue);
              setFs(formattedValue);
            }}
            star
            type="text"
            name="fs"
            value={fs}
            placeholder={ekkLanguage.createBusinessInfo.fsPlaceholder}
            errorMessage={fsError}
            currencyShow
          ></Input>
          <div className="mt-3">
            <NewCustomSelect
              star
              type="week-selection"
              localLanguage={localLanguage}
              options={pwFsOptions}
              setOption={setPwFs}
              option={pwFs}
              mainLabel={ekkLanguage.createBusinessInfo.pwFsLabel}
              selectPlaceholder={ekkLanguage.createBusinessInfo.pwFsPlaceholder}
              errorMessage={pwFsError}
            ></NewCustomSelect>
          </div>
          <label className="flex tracking-wide text-grey-darker text-16 font-bold ml-1 mt-3">
            {ekkLanguage.createBusinessInfo.operationLabel}{" "}
            <span className="text-primary ml-1">*</span>
          </label>
          <div className="mb-1 mt-1">
            <DatePicker
              minDate={moment().subtract(100, "years").toDate()}
              maxDate={moment(new Date()).add(1, "days")._d}
              selected={
                operations
                  ? moment(operations)._d
                  : moment(new Date()).add(1, "days")._d
              }
              onChange={(date) => dateChanger(date)}
              customInput={<ExampleCustomInput />}
              withPortal
              yearItemNumber={10}
              showYearPicker
              dateFormat="yyyy"
            />
          </div>
          <div className="flex items-center pl-1">
            {operationsError && (
              <p className="text-primary mt-1 text-xs font-bold">
                {operationsError}{" "}
              </p>
            )}
          </div>
          <NewCustomSelect
            type="region-selection"
            localLanguage={localLanguage}
            options={regions}
            setOption={setRegion}
            option={region}
            mainLabel={ekkLanguage.createBusinessInfo.regionLabel}
            selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
            errorMessage={regionError}
            handleDistrictUpazilla={getAreaList}
            star
          ></NewCustomSelect>
          <div className="mt-3">
            <NewCustomSelect
              disable={region?.id ? false : true}
              star
              type="area-selection"
              localLanguage={localLanguage}
              options={areas}
              setOption={setArea}
              option={area}
              mainLabel={ekkLanguage.createBusinessInfo.areaLabel}
              selectPlaceholder={ekkLanguage.createContract.selectPlaceholder}
              selectPlaceholderWarning={
                ekkLanguage.createBusinessInfo.areaPlaceholderWarning
              }
              errorMessage={areaError}
            ></NewCustomSelect>
          </div>
          {/* <Input
        label={{
          label: ekkLanguage.createBusinessInfo.aeLabel,
          //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
        }}
        // extraLabel="Years"
        onChange={(event) => {
          setFeedback(event.target.value);
        }}
        // star
        type="text"
        name="feedback"
        value={feedback}
        placeholder={ekkLanguage.createBusinessInfo.aePlaceholder}
        errorMessage={feedbackError}
      ></Input> */}
          <IonButton
            className="font-bold h-10 w-full mt-20 mb-5"
            type="submit"
            onClick={() => {
              if (brands !== "") {
                setBrandsError("");
                if (sku) {
                  setSkuError("");
                  if (sr) {
                    setSrError("");
                    if (driver) {
                      setDriverError("");
                      if (customer) {
                        setCustomerError("");
                        if (sales) {
                          setSalesError("");
                          if (fs) {
                            setFsError("");
                            if (pwFs) {
                              setPwFsError("");
                              if (operations) {
                                setOperationsError("");

                                if (region) {
                                  setRegionError("");
                                  if (area) {
                                    setAreaError("");
                                    const query = {
                                      brands: brandListForSendToAPI,
                                      // brands: brands,
                                      total_sku: Number(sku),
                                      total_sr: Number(sr),
                                      total_driver: Number(driver),
                                      total_customer: Number(customer),
                                      monthly_sales_amount:
                                        convertToNumber(sales),
                                      monthly_fs_support: convertToNumber(fs),
                                      business_operation_duration: Number(
                                        moment(operations).format("YYYY")
                                      ),
                                      pwfs: pwFs?.value,
                                      // ae_feedback: feedback,
                                      business: createdInfo?.business?.id,
                                      account_executive: user?._id,
                                      region: Number(region?.id),
                                      area: Number(area?.id),
                                      interested_area: interestedArea,
                                    };
                                    handleSubmit(query);
                                  } else {
                                    setAreaError(
                                      ekkLanguage.createBusinessInfo.areaError
                                    );
                                  }
                                } else {
                                  setRegionError(
                                    ekkLanguage.createBusinessInfo.regionError
                                  );
                                }
                              } else {
                                setOperationsError(
                                  ekkLanguage.createBusinessInfo.operationError
                                );
                              }
                            } else {
                              setPwFsError(
                                ekkLanguage.createBusinessInfo.pwFsError
                              );
                            }
                          } else {
                            setFsError(ekkLanguage.createBusinessInfo.fsError);
                          }
                        } else {
                          setSalesError(
                            ekkLanguage.createBusinessInfo.salesError
                          );
                        }
                      } else {
                        setCustomerError(
                          ekkLanguage.createBusinessInfo.customerError
                        );
                      }
                    } else {
                      setDriverError(
                        ekkLanguage.createBusinessInfo.driverError
                      );
                    }
                  } else {
                    setSrError(ekkLanguage.createBusinessInfo.srError);
                  }
                } else {
                  setSkuError(ekkLanguage.createBusinessInfo.skuError);
                }
              } else {
                setBrandsError(ekkLanguage.createBusinessInfo.brandError);
              }
            }}
            color="success"
            expand="full"
          >
            <span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
            <span className="pl-2">
              <ArrayRight />
            </span>
          </IonButton>
        </>
      )}
    </div>
  );
}

import TaskDetailsPublic from "components/mobile-components/Task/TaskDetailsPublic";
import FooterV2 from "pages/LandingPageV2/FooterV2";
import LandingNavBar from "pages/LandingPageV2/LandingNavBar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Task from "services/Task";

export default function TaskDetailsPublicPage() {
	let { id } = useParams();
	const [singleTask, setSingleTask] = useState({});
	const [loading, setLoading] = useState(false);

	const getSingleTask = async () => {
		setLoading(true);
		let response = await Task.getTaskPublic(id);

		if (response.status === 200) {
			setLoading(false);
			setSingleTask(response.results);
		} else {
			setLoading(false);
		}
	};
	// const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getSingleTask();
		// document.documentElement.scrollTo({
		// 	top: 0,
		// 	left: 0,
		// 	behavior: "instant",
		// });
	}, [id]);

	return (
		<>
			<LandingNavBar />
			<div className='container'>
				<TaskDetailsPublic loading={loading} singleBlog={singleTask} />
			</div>
			<FooterV2 />
		</>
	);
}

import {
	IonApp,
	IonContent,
	IonPage,
	IonRow,
	IonCol,
	IonButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import { getLanguage } from "config/language";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo } from "redux/container/userSlice";
import { toast } from "react-toastify";
import { Input } from "components/global-components/common/Input";
import User from "services/User";
import { parseNumber } from "libphonenumber-js";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function AddressVerify() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const ekkUser = useSelector((state) => state.user.allInfo);
	const [phoneNo, setPhoneNo] = useState(null);
	const [error, setError] = useState("");
	const lang = getLanguage();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [presentAddress, setPresentAddress] = useState(ekkUser?.address);
	const [city, setCity] = useState(ekkUser?.city);
	const [postalCode, setPostalCode] = useState(ekkUser?.postal_code);
	const [gaurdianName, setGaurdianName] = useState(ekkUser?.guardian_name);
	const [gaurdianNumber, setGaurdianNumber] = useState(ekkUser?.guardian_phone);

	const handleSubmit = () => {
		if (presentAddress) {
			if (city) {
				if (postalCode) {
					if (gaurdianNumber) {
						setError("");
						const allData = {
							address: presentAddress,
							city: city,
							postal_code: postalCode,
							guardian_name: gaurdianName,
							guardian_phone: gaurdianNumber,
						};
						User.putKycInfo(ekkUser?.id, allData).then((res) => {
							if (res.status === 200) {
								let response = User.getKycInfo(ekkUser?.id);
								response.then((r) => {
									if (r.status === 200) {
										dispatch(setAllInfo(r.results));
										toast.success(ekkLanguage.addressVerify.successMessage);
										navigate("/work-verify");
									}
								});
							}
						});
					} else {
						setError(ekkLanguage.addressVerify.gaurdianNumberError);
					}
				} else {
					setError(ekkLanguage.addressVerify.postalCodeError);
				}
			} else {
				setError(ekkLanguage.addressVerify.cityError);
			}
		} else {
			setError(ekkLanguage.addressVerify.presentAddressError);
		}
	};

	// useEffect(() => {
	// 	if (phoneNo) {
	// 		if (
	// 			!(
	// 				phoneNo.trim().length > 10 &&
	// 				phoneNo.trim().length < 12 &&
	// 				phoneNo[0] == 0
	// 			)
	// 		) {
	// 			setError(ekkLanguage?.login.validationLabel);
	// 		} else {
	// 			setError("");
	// 		}
	// 	}
	// }, [phoneNo]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.addressVerify.mainTitle}
						sticky={true}
					></BackButtonTitle>
					<div className='pt-20 px-5'>
						<h6 className='text-18 text-center mb-[30px]'>
							{ekkLanguage.addressVerify.addressTitlePart1}{" "}
							<span className='font-bold text-success'>
								{ekkLanguage.addressVerify.addressTitlePart2}
							</span>
							{lang === "bn" && " দিন"}
						</h6>

						<Input
							label={{
								label: ekkLanguage.addressVerify.presentAddressLabel,
								source: "/assets/images/ekkhero/Icons/location-pin1.png",
							}}
							onChange={(event) => {
								setPresentAddress(event.target.value);
								console.log(event.target.value);
							}}
							star
							type='text'
							name='address'
							value={presentAddress}
							placeholder={ekkLanguage.addressVerify.presentAddressPlaceholder}
						></Input>

						<IonRow className='mt-5'>
							<IonCol>
								<Input
									label={{
										label: ekkLanguage.addressVerify.presentCityLabel,
										source: "/assets/images/ekkhero/Icons/present-address.png",
									}}
									onChange={(event) => {
										setCity(event.target.value);
									}}
									star
									type='text'
									name='address'
									value={city}
									placeholder={ekkLanguage.addressVerify.presentCityPlaceholder}
								></Input>
							</IonCol>
							<IonCol>
								<Input
									label={{
										label: ekkLanguage.addressVerify.postalCodeLabel,
										source: "/assets/images/ekkhero/Icons/postal-code.png",
									}}
									onChange={(event) => {
										setPostalCode(event.target.value);
									}}
									star
									type='number'
									name='address'
									value={postalCode}
									placeholder={ekkLanguage.addressVerify.postalCodePlaceholder}
								></Input>
							</IonCol>
						</IonRow>
						<Input
							label={{
								label: ekkLanguage.addressVerify.nameLabel,
								source: "/assets/images/ekkhero/Icons/user.png",
							}}
							extraLabel={ekkLanguage.addressVerify.extraLabel}
							onChange={(event) => {
								setGaurdianName(event.target.value);
							}}
							type='text'
							name='name'
							value={gaurdianName}
							placeholder={ekkLanguage.addressVerify.namePlaceholder}
						></Input>

						<Input
							label={{
								label: ekkLanguage.addressVerify.numberLabel,
								source: "/assets/images/ekkhero/Icons/phone.png",
							}}
							extraLabel={ekkLanguage.addressVerify.extraLabel}
							onChange={(event) => {
								setPhoneNo(event.target.value);
								setGaurdianNumber(event.target.value);
							}}
							star
							type='number'
							name='name'
							value={gaurdianNumber}
							placeholder={ekkLanguage.addressVerify.numberPlaceholder}
						></Input>

						<div className='mt-4'>
							{error && (
								<p className='font-semibold text-xs text-primary'>{error}</p>
							)}
						</div>
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-28'
						>
							<span className="text-white">{ekkLanguage.jobSelect.btnLabel}</span>
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						<IonButton
							onClick={() => {
								navigate("/work-verify");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 my-5 w-full  border-primary'
						>
							{ekkLanguage.addressVerify.cancel}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

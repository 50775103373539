const webUrl = document.location.origin;
export const DEVELOPMENT = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	BizNpdt: "https://dbiznpdtapi.azurewebsites.net",
	social: "https://dsocialapi.azurewebsites.net",
	rtc: "https://drtc2api.azurewebsites.net",
	kyc: "https://dpasskycapi.azurewebsites.net",
	contract: "https://dcontractapi.azurewebsites.net",
	futureOrder: "https://dorder2api.azurewebsites.net",
	sitekey: "6LfhgFgoAAAAAHC53gxp5WsnA_aKeAZR60QsKXXs",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	domain: ".azurewebsites.net",
	// domain: ".localhost",
	ehp: "https://ehp2t.azurewebsites.net/login",
	// ehp: "http://localhost:3001/login"
	task: "https://dcosifiedapi.azurewebsites.net",
	webUrl: webUrl,
	faqEn: 633,
	faqBn: 634,
};

// export var baseUrl = DEVELOPMENT;

export const PRODUCTION = {
	pass: "https://ppassapi.azurewebsites.net",
	business: "https://pbusiness.ekkbaz.com",
	product: "https://pproduct.ekkbaz.com",
	order: "https://porderapi.azurewebsites.net",
	bucket: "https://pfileapi.azurewebsites.net",
	BizNpdt: "https://pbiznpdtapi.azurewebsites.net",
	social: "https://psocialapi.azurewebsites.net",
	rtc: "https://prtcapi.azurewebsites.net",
	kyc: "https://ppasskycapi.azurewebsites.net",
	contract: "https://pcontractapi.azurewebsites.net",
	futureOrder: "https://porder2api.azurewebsites.net",
	sitekey: "6LfhgFgoAAAAAHC53gxp5WsnA_aKeAZR60QsKXXs",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	domain: ".ekkbaz.com",
	ehp: "https://pilot.ekkbaz.com/login",
	task: "https://pcosifiedapi.azurewebsites.net",
	webUrl: webUrl,
	faqEn: 715,
	faqBn: 717,
};

export var baseUrl = PRODUCTION;

export const upazilas = [
	{
		id: "1",
		district_id: "34",
		district_name: "Barguna",
		name: "Amtali",
		bn_name: "আমতলী",
	},
	{
		id: "2",
		district_id: "34",
		district_name: "Barguna",
		name: "Bamna",
		bn_name: "বামনা",
	},
	{
		id: "3",
		district_id: "34",
		district_name: "Barguna",
		name: "Barguna Sadar",
		bn_name: "বরগুনা সদর",
	},
	{
		id: "4",
		district_id: "34",
		district_name: "Barguna",
		name: "Betagi",
		bn_name: "বেতাগি",
	},
	{
		id: "5",
		district_id: "34",
		district_name: "Barguna",
		name: "Patharghata",
		bn_name: "পাথরঘাটা",
	},
	{
		id: "6",
		district_id: "34",
		district_name: "Barguna",
		name: "Taltali",
		bn_name: "তালতলী",
	},
	{
		id: "7",
		district_id: "35",
		district_name: "Barishal",
		name: "Muladi",
		bn_name: "মুলাদি",
	},
	{
		id: "8",
		district_id: "35",
		district_name: "Barishal",
		name: "Babuganj",
		bn_name: "বাবুগঞ্জ",
	},
	{
		id: "9",
		district_id: "35",
		district_name: "Barishal",
		name: "Agailjhara",
		bn_name: "আগাইলঝরা",
	},
	{
		id: "10",
		district_id: "35",
		district_name: "Barishal",
		name: "Barisal Sadar",
		bn_name: "বরিশাল সদর",
	},
	{
		id: "11",
		district_id: "35",
		district_name: "Barishal",
		name: "Bakerganj",
		bn_name: "বাকেরগঞ্জ",
	},
	{
		id: "12",
		district_id: "35",
		district_name: "Barishal",
		name: "Banaripara",
		bn_name: "বানাড়িপারা",
	},
	{
		id: "13",
		district_id: "35",
		district_name: "Barishal",
		name: "Gaurnadi",
		bn_name: "গৌরনদী",
	},
	{
		id: "14",
		district_id: "35",
		district_name: "Barishal",
		name: "Hizla",
		bn_name: "হিজলা",
	},
	{
		id: "15",
		district_id: "35",
		district_name: "Barishal",
		name: "Mehendiganj",
		bn_name: "মেহেদিগঞ্জ ",
	},
	{
		id: "16",
		district_id: "35",
		district_name: "Barishal",
		name: "Wazirpur",
		bn_name: "ওয়াজিরপুর",
	},
	{
		id: "17",
		district_id: "36",
		district_name: "Bhola",
		name: "Bhola Sadar",
		bn_name: "ভোলা সদর",
	},
	{
		id: "18",
		district_id: "36",
		district_name: "Bhola",
		name: "Burhanuddin",
		bn_name: "বুরহানউদ্দিন",
	},
	{
		id: "19",
		district_id: "36",
		district_name: "Bhola",
		name: "Char Fasson",
		bn_name: "চর ফ্যাশন",
	},
	{
		id: "20",
		district_id: "36",
		district_name: "Bhola",
		name: "Daulatkhan",
		bn_name: "দৌলতখান",
	},
	{
		id: "21",
		district_id: "36",
		district_name: "Bhola",
		name: "Lalmohan",
		bn_name: "লালমোহন",
	},
	{
		id: "22",
		district_id: "36",
		district_name: "Bhola",
		name: "Manpura",
		bn_name: "মনপুরা",
	},
	{
		id: "23",
		district_id: "36",
		district_name: "Bhola",
		name: "Tazumuddin",
		bn_name: "তাজুমুদ্দিন",
	},
	{
		id: "24",
		district_id: "37",
		district_name: "Jhalokati",
		name: "Jhalokati Sadar",
		bn_name: "ঝালকাঠি সদর",
	},
	{
		id: "25",
		district_id: "37",
		district_name: "Jhalokati",
		name: "Kathalia",
		bn_name: "কাঁঠালিয়া",
	},
	{
		id: "26",
		district_id: "37",
		district_name: "Jhalokati",
		name: "Nalchity",
		bn_name: "নালচিতি",
	},
	{
		id: "27",
		district_id: "37",
		district_name: "Jhalokati",
		name: "Rajapur",
		bn_name: "রাজাপুর",
	},
	{
		id: "28",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Bauphal",
		bn_name: "বাউফল",
	},
	{
		id: "29",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Dashmina",
		bn_name: "দশমিনা",
	},
	{
		id: "30",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Galachipa",
		bn_name: "গলাচিপা",
	},
	{
		id: "31",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Kalapara",
		bn_name: "কালাপারা",
	},
	{
		id: "32",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Mirzaganj",
		bn_name: "মির্জাগঞ্জ ",
	},
	{
		id: "33",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Patuakhali Sadar",
		bn_name: "পটুয়াখালী সদর",
	},
	{
		id: "34",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Dumki",
		bn_name: "ডুমকি",
	},
	{
		id: "35",
		district_id: "38",
		district_name: "Patuakhali",
		name: "Rangabali",
		bn_name: "রাঙ্গাবালি",
	},
	{
		id: "36",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Bhandaria",
		bn_name: "ভ্যান্ডারিয়া",
	},
	{
		id: "37",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Kaukhali",
		bn_name: "কাউখালি",
	},
	{
		id: "38",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Mathbaria",
		bn_name: "মাঠবাড়িয়া",
	},
	{
		id: "39",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Nazirpur",
		bn_name: "নাজিরপুর",
	},
	{
		id: "40",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Nesarabad",
		bn_name: "নেসারাবাদ",
	},
	{
		id: "41",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Pirojpur Sadar",
		bn_name: "পিরোজপুর সদর",
	},
	{
		id: "42",
		district_id: "39",
		district_name: "Pirojpur",
		name: "Zianagar",
		bn_name: "জিয়ানগর",
	},
	{
		id: "43",
		district_id: "40",
		district_name: "Bandarban",
		name: "Bandarban Sadar",
		bn_name: "বান্দরবন সদর",
	},
	{
		id: "44",
		district_id: "40",
		district_name: "Bandarban",
		name: "Thanchi",
		bn_name: "থানচি",
	},
	{
		id: "45",
		district_id: "40",
		district_name: "Bandarban",
		name: "Lama",
		bn_name: "লামা",
	},
	{
		id: "46",
		district_id: "40",
		district_name: "Bandarban",
		name: "Naikhongchhari",
		bn_name: "নাইখংছড়ি ",
	},
	{
		id: "47",
		district_id: "40",
		district_name: "Bandarban",
		name: "Ali kadam",
		bn_name: "আলী কদম",
	},
	{
		id: "48",
		district_id: "40",
		district_name: "Bandarban",
		name: "Rowangchhari",
		bn_name: "রউয়াংছড়ি ",
	},
	{
		id: "49",
		district_id: "40",
		district_name: "Bandarban",
		name: "Ruma",
		bn_name: "রুমা",
	},
	{
		id: "50",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Brahmanbaria Sadar",
		bn_name: "ব্রাহ্মণবাড়িয়া সদর",
	},
	{
		id: "51",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Ashuganj",
		bn_name: "আশুগঞ্জ",
	},
	{
		id: "52",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Nasirnagar",
		bn_name: "নাসির নগর",
	},
	{
		id: "53",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Nabinagar",
		bn_name: "নবীনগর",
	},
	{
		id: "54",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Sarail",
		bn_name: "সরাইল",
	},
	{
		id: "55",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Shahbazpur Town",
		bn_name: "শাহবাজপুর টাউন",
	},
	{
		id: "56",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Kasba",
		bn_name: "কসবা",
	},
	{
		id: "57",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Akhaura",
		bn_name: "আখাউরা",
	},
	{
		id: "58",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Bancharampur",
		bn_name: "বাঞ্ছারামপুর",
	},
	{
		id: "59",
		district_id: "41",
		district_name: "Brahmanbaria",
		name: "Bijoynagar",
		bn_name: "বিজয় নগর",
	},
	{
		id: "60",
		district_id: "42",
		district_name: "Chandpur",
		name: "Chandpur Sadar",
		bn_name: "চাঁদপুর সদর",
	},
	{
		id: "61",
		district_id: "42",
		district_name: "Chandpur",
		name: "Faridganj",
		bn_name: "ফরিদগঞ্জ",
	},
	{
		id: "62",
		district_id: "42",
		district_name: "Chandpur",
		name: "Haimchar",
		bn_name: "হাইমচর",
	},
	{
		id: "63",
		district_id: "42",
		district_name: "Chandpur",
		name: "Haziganj",
		bn_name: "হাজীগঞ্জ",
	},
	{
		id: "64",
		district_id: "42",
		district_name: "Chandpur",
		name: "Kachua",
		bn_name: "কচুয়া",
	},
	{
		id: "65",
		district_id: "42",
		district_name: "Chandpur",
		name: "Matlab Uttar",
		bn_name: "মতলব উত্তর",
	},
	{
		id: "66",
		district_id: "42",
		district_name: "Chandpur",
		name: "Matlab Dakkhin",
		bn_name: "মতলব দক্ষিণ",
	},
	{
		id: "67",
		district_id: "42",
		district_name: "Chandpur",
		name: "Shahrasti",
		bn_name: "শাহরাস্তি",
	},
	{
		id: "68",
		district_id: "43",
		district_name: "Chattogram",
		name: "Anwara",
		bn_name: "আনোয়ারা",
	},
	{
		id: "69",
		district_id: "43",
		district_name: "Chattogram",
		name: "Banshkhali",
		bn_name: "বাশখালি",
	},
	{
		id: "70",
		district_id: "43",
		district_name: "Chattogram",
		name: "Boalkhali",
		bn_name: "বোয়ালখালি",
	},
	{
		id: "71",
		district_id: "43",
		district_name: "Chattogram",
		name: "Chandanaish",
		bn_name: "চন্দনাইশ",
	},
	{
		id: "72",
		district_id: "43",
		district_name: "Chattogram",
		name: "Fatikchhari",
		bn_name: "ফটিকছড়ি",
	},
	{
		id: "73",
		district_id: "43",
		district_name: "Chattogram",
		name: "Hathazari",
		bn_name: "হাঠহাজারী",
	},
	{
		id: "74",
		district_id: "43",
		district_name: "Chattogram",
		name: "Lohagara",
		bn_name: "লোহাগারা",
	},
	{
		id: "75",
		district_id: "43",
		district_name: "Chattogram",
		name: "Mirsharai",
		bn_name: "মিরসরাই",
	},
	{
		id: "76",
		district_id: "43",
		district_name: "Chattogram",
		name: "Patiya",
		bn_name: "পটিয়া",
	},
	{
		id: "77",
		district_id: "43",
		district_name: "Chattogram",
		name: "Rangunia",
		bn_name: "রাঙ্গুনিয়া",
	},
	{
		id: "78",
		district_id: "43",
		district_name: "Chattogram",
		name: "Raozan",
		bn_name: "রাউজান",
	},
	{
		id: "79",
		district_id: "43",
		district_name: "Chattogram",
		name: "Sandwip",
		bn_name: "সন্দ্বীপ",
	},
	{
		id: "80",
		district_id: "43",
		district_name: "Chattogram",
		name: "Satkania",
		bn_name: "সাতকানিয়া",
	},
	{
		id: "81",
		district_id: "43",
		district_name: "Chattogram",
		name: "Sitakunda",
		bn_name: "সীতাকুণ্ড",
	},
	{
		id: "82",
		district_id: "44",
		district_name: "Cumilla",
		name: "Barura",
		bn_name: "বড়ুরা",
	},
	{
		id: "83",
		district_id: "44",
		district_name: "Cumilla",
		name: "Brahmanpara",
		bn_name: "ব্রাহ্মণপাড়া",
	},
	{
		id: "84",
		district_id: "44",
		district_name: "Cumilla",
		name: "Burichong",
		bn_name: "বুড়িচং",
	},
	{
		id: "85",
		district_id: "44",
		district_name: "Cumilla",
		name: "Chandina",
		bn_name: "চান্দিনা",
	},
	{
		id: "86",
		district_id: "44",
		district_name: "Cumilla",
		name: "Chauddagram",
		bn_name: "চৌদ্দগ্রাম",
	},
	{
		id: "87",
		district_id: "44",
		district_name: "Cumilla",
		name: "Daudkandi",
		bn_name: "দাউদকান্দি",
	},
	{
		id: "88",
		district_id: "44",
		district_name: "Cumilla",
		name: "Debidwar",
		bn_name: "দেবীদ্বার",
	},
	{
		id: "89",
		district_id: "44",
		district_name: "Cumilla",
		name: "Homna",
		bn_name: "হোমনা",
	},
	{
		id: "90",
		district_id: "44",
		district_name: "Cumilla",
		name: "Comilla Sadar",
		bn_name: "কুমিল্লা সদর",
	},
	{
		id: "91",
		district_id: "44",
		district_name: "Cumilla",
		name: "Laksam",
		bn_name: "লাকসাম",
	},
	{
		id: "92",
		district_id: "44",
		district_name: "Cumilla",
		name: "Monohorgonj",
		bn_name: "মনোহরগঞ্জ",
	},
	{
		id: "93",
		district_id: "44",
		district_name: "Cumilla",
		name: "Meghna",
		bn_name: "মেঘনা",
	},
	{
		id: "94",
		district_id: "44",
		district_name: "Cumilla",
		name: "Muradnagar",
		bn_name: "মুরাদনগর",
	},
	{
		id: "95",
		district_id: "44",
		district_name: "Cumilla",
		name: "Nangalkot",
		bn_name: "নাঙ্গালকোট",
	},
	{
		id: "96",
		district_id: "44",
		district_name: "Cumilla",
		name: "Comilla Sadar South",
		bn_name: "কুমিল্লা সদর দক্ষিণ",
	},
	{
		id: "97",
		district_id: "44",
		district_name: "Cumilla",
		name: "Titas",
		bn_name: "তিতাস",
	},
	{
		id: "98",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Chakaria",
		bn_name: "চকরিয়া",
	},
	{
		id: "100",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Cox's Bazar Sadar",
		bn_name: "কক্স বাজার সদর",
	},
	{
		id: "101",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Kutubdia",
		bn_name: "কুতুবদিয়া",
	},
	{
		id: "102",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Maheshkhali",
		bn_name: "মহেশখালী",
	},
	{
		id: "103",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Ramu",
		bn_name: "রামু",
	},
	{
		id: "104",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Teknaf",
		bn_name: "টেকনাফ",
	},
	{
		id: "105",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Ukhia",
		bn_name: "উখিয়া",
	},
	{
		id: "106",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Pekua",
		bn_name: "পেকুয়া",
	},
	{
		id: "107",
		district_id: "46",
		district_name: "Feni",
		name: "Feni Sadar",
		bn_name: "ফেনী সদর",
	},
	{
		id: "108",
		district_id: "46",
		district_name: "Feni",
		name: "Chagalnaiya",
		bn_name: "ছাগল নাইয়া",
	},
	{
		id: "109",
		district_id: "46",
		district_name: "Feni",
		name: "Daganbhyan",
		bn_name: "দাগানভিয়া",
	},
	{
		id: "110",
		district_id: "46",
		district_name: "Feni",
		name: "Parshuram",
		bn_name: "পরশুরাম",
	},
	{
		id: "111",
		district_id: "46",
		district_name: "Feni",
		name: "Fhulgazi",
		bn_name: "ফুলগাজি",
	},
	{
		id: "112",
		district_id: "46",
		district_name: "Feni",
		name: "Sonagazi",
		bn_name: "সোনাগাজি",
	},
	{
		id: "113",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Dighinala",
		bn_name: "দিঘিনালা ",
	},
	{
		id: "114",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Khagrachhari",
		bn_name: "খাগড়াছড়ি",
	},
	{
		id: "115",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Lakshmichhari",
		bn_name: "লক্ষ্মীছড়ি",
	},
	{
		id: "116",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Mahalchhari",
		bn_name: "মহলছড়ি",
	},
	{
		id: "117",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Manikchhari",
		bn_name: "মানিকছড়ি",
	},
	{
		id: "118",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Matiranga",
		bn_name: "মাটিরাঙ্গা",
	},
	{
		id: "119",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Panchhari",
		bn_name: "পানছড়ি",
	},
	{
		id: "120",
		district_id: "47",
		district_name: "Khagrachari",
		name: "Ramgarh",
		bn_name: "রামগড়",
	},
	{
		id: "121",
		district_id: "48",
		district_name: "Lakshmipur",
		name: "Lakshmipur Sadar",
		bn_name: "লক্ষ্মীপুর সদর",
	},
	{
		id: "122",
		district_id: "48",
		district_name: "Lakshmipur",
		name: "Raipur",
		bn_name: "রায়পুর",
	},
	{
		id: "123",
		district_id: "48",
		district_name: "Lakshmipur",
		name: "Ramganj",
		bn_name: "রামগঞ্জ",
	},
	{
		id: "124",
		district_id: "48",
		district_name: "Lakshmipur",
		name: "Ramgati",
		bn_name: "রামগতি",
	},
	{
		id: "125",
		district_id: "48",
		district_name: "Lakshmipur",
		name: "Komol Nagar",
		bn_name: "কমল নগর",
	},
	{
		id: "126",
		district_id: "49",
		district_name: "Noakhali",
		name: "Noakhali Sadar",
		bn_name: "নোয়াখালী সদর",
	},
	{
		id: "127",
		district_id: "49",
		district_name: "Noakhali",
		name: "Begumganj",
		bn_name: "বেগমগঞ্জ",
	},
	{
		id: "128",
		district_id: "49",
		district_name: "Noakhali",
		name: "Chatkhil",
		bn_name: "চাটখিল",
	},
	{
		id: "129",
		district_id: "49",
		district_name: "Noakhali",
		name: "Companyganj",
		bn_name: "কোম্পানীগঞ্জ",
	},
	{
		id: "130",
		district_id: "49",
		district_name: "Noakhali",
		name: "Shenbag",
		bn_name: "শেনবাগ",
	},
	{
		id: "131",
		district_id: "49",
		district_name: "Noakhali",
		name: "Hatia",
		bn_name: "হাতিয়া",
	},
	{
		id: "132",
		district_id: "49",
		district_name: "Noakhali",
		name: "Kobirhat",
		bn_name: "কবিরহাট ",
	},
	{
		id: "133",
		district_id: "49",
		district_name: "Noakhali",
		name: "Sonaimuri",
		bn_name: "সোনাইমুরি",
	},
	{
		id: "134",
		district_id: "49",
		district_name: "Noakhali",
		name: "Suborno Char",
		bn_name: "সুবর্ণ চর ",
	},
	{
		id: "135",
		district_id: "50",
		district_name: "Rangamati",
		name: "Rangamati Sadar",
		bn_name: "রাঙ্গামাটি সদর",
	},
	{
		id: "136",
		district_id: "50",
		district_name: "Rangamati",
		name: "Belaichhari",
		bn_name: "বেলাইছড়ি",
	},
	{
		id: "137",
		district_id: "50",
		district_name: "Rangamati",
		name: "Bagaichhari",
		bn_name: "বাঘাইছড়ি",
	},
	{
		id: "138",
		district_id: "50",
		district_name: "Rangamati",
		name: "Barkal",
		bn_name: "বরকল",
	},
	{
		id: "139",
		district_id: "50",
		district_name: "Rangamati",
		name: "Juraichhari",
		bn_name: "জুরাইছড়ি",
	},
	{
		id: "140",
		district_id: "50",
		district_name: "Rangamati",
		name: "Rajasthali",
		bn_name: "রাজাস্থলি",
	},
	{
		id: "141",
		district_id: "50",
		district_name: "Rangamati",
		name: "Kaptai",
		bn_name: "কাপ্তাই",
	},
	{
		id: "142",
		district_id: "50",
		district_name: "Rangamati",
		name: "Langadu",
		bn_name: "লাঙ্গাডু",
	},
	{
		id: "143",
		district_id: "50",
		district_name: "Rangamati",
		name: "Nannerchar",
		bn_name: "নান্নেরচর ",
	},
	{
		id: "144",
		district_id: "50",
		district_name: "Rangamati",
		name: "Kaukhali",
		bn_name: "কাউখালি",
	},
	{
		id: "496",
		district_id: "1",
		district_name: "Dhaka",
		name: "Adabor",
		bn_name: "আদাবর",
	},
	{
		id: "497",
		district_id: "1",
		district_name: "Dhaka",
		name: "Uttar Khan",
		bn_name: "উত্তরখান",
	},
	{
		id: "498",
		district_id: "1",
		district_name: "Dhaka",
		name: "Uttara",
		bn_name: "উত্তরা",
	},
	{
		id: "499",
		district_id: "1",
		district_name: "Dhaka",
		name: "Kadamtali",
		bn_name: "কদমতলী",
	},
	{
		id: "500",
		district_id: "1",
		district_name: "Dhaka",
		name: "Kalabagan",
		bn_name: "কলাবাগান",
	},
	{
		id: "501",
		district_id: "1",
		district_name: "Dhaka",
		name: "Kafrul",
		bn_name: "কাফরুল",
	},
	{
		id: "502",
		district_id: "1",
		district_name: "Dhaka",
		name: "Kamrangirchar",
		bn_name: "কামরাঙ্গীরচর",
	},
	{
		id: "503",
		district_id: "1",
		district_name: "Dhaka",
		name: "Cantonment",
		bn_name: "ক্যান্টনমেন্ট",
	},
	{
		id: "504",
		district_id: "1",
		district_name: "Dhaka",
		name: "Kotwali",
		bn_name: "কোতোয়ালী",
	},
	{
		id: "505",
		district_id: "1",
		district_name: "Dhaka",
		name: "Khilkhet",
		bn_name: "খিলক্ষেত",
	},
	{
		id: "507",
		district_id: "1",
		district_name: "Dhaka",
		name: "Khilgaon",
		bn_name: "খিলগাঁও",
	},
	{
		id: "508",
		district_id: "1",
		district_name: "Dhaka",
		name: "Gulshan",
		bn_name: "গুলশান",
	},
	{
		id: "509",
		district_id: "1",
		district_name: "Dhaka",
		name: "Gendaria",
		bn_name: "গেন্ডারিয়া",
	},
	{
		id: "510",
		district_id: "1",
		district_name: "Dhaka",
		name: "Chawkbazar Model",
		bn_name: "চকবাজার",
	},
	{
		id: "511",
		district_id: "1",
		district_name: "Dhaka",
		name: "Demra",
		bn_name: "ডেমরা",
	},
	{
		id: "512",
		district_id: "1",
		district_name: "Dhaka",
		name: "Turag",
		bn_name: "তুরাগ",
	},
	{
		id: "513",
		district_id: "1",
		district_name: "Dhaka",
		name: "Tejgaon",
		bn_name: "তেজগাঁও",
	},
	{
		id: "514",
		district_id: "1",
		district_name: "Dhaka",
		name: "Tejgaon I/A",
		bn_name: "তেজগাঁও শিল্পাঞ্চল এলাকা",
	},
	{
		id: "515",
		district_id: "1",
		district_name: "Dhaka",
		name: "Dakshinkhan",
		bn_name: "দক্ষিণখান",
	},
	{
		id: "516",
		district_id: "1",
		district_name: "Dhaka",
		name: "Darus Salam",
		bn_name: "দারুস সালাম",
	},
	{
		id: "517",
		district_id: "1",
		district_name: "Dhaka",
		name: "Dhanmondi",
		bn_name: "ধানমন্ডি",
	},
	{
		id: "518",
		district_id: "1",
		district_name: "Dhaka",
		name: "New Market",
		bn_name: "নিউ মার্কেট",
	},
	{
		id: "519",
		district_id: "1",
		district_name: "Dhaka",
		name: "Paltan",
		bn_name: "পল্টন",
	},
	{
		id: "520",
		district_id: "1",
		district_name: "Dhaka",
		name: "Pallabi",
		bn_name: "পল্লবী",
	},
	{
		id: "521",
		district_id: "1",
		district_name: "Dhaka",
		name: "Bangshal",
		bn_name: "বংশাল",
	},
	{
		id: "522",
		district_id: "1",
		district_name: "Dhaka",
		name: "Badda",
		bn_name: "বাড্ডা",
	},
	{
		id: "523",
		district_id: "1",
		district_name: "Dhaka",
		name: "Bimanbandar",
		bn_name: "বিমানবন্দর",
	},
	{
		id: "524",
		district_id: "1",
		district_name: "Dhaka",
		name: "Motijheel",
		bn_name: "মতিঝিল",
	},
	{
		id: "525",
		district_id: "1",
		district_name: "Dhaka",
		name: "Mirpur Model",
		bn_name: "মিরপুর মডেল",
	},
	{
		id: "526",
		district_id: "1",
		district_name: "Dhaka",
		name: "Mohammadpur",
		bn_name: "মোহাম্মদপুর",
	},
	{
		id: "527",
		district_id: "1",
		district_name: "Dhaka",
		name: "Jatrabari",
		bn_name: "যাত্রাবাড়ী",
	},
	{
		id: "528",
		district_id: "1",
		district_name: "Dhaka",
		name: "Ramna",
		bn_name: "রমনা",
	},
	{
		id: "529",
		district_id: "1",
		district_name: "Dhaka",
		name: "Rampura",
		bn_name: "রামপুরা",
	},
	{
		id: "530",
		district_id: "1",
		district_name: "Dhaka",
		name: "Lalbagh",
		bn_name: "লালবাগ",
	},
	{
		id: "531",
		district_id: "1",
		district_name: "Dhaka",
		name: "Shah Ali",
		bn_name: "শাহ আলী",
	},
	{
		id: "532",
		district_id: "1",
		district_name: "Dhaka",
		name: "Shahbagh",
		bn_name: "শাহবাগ",
	},
	{
		id: "533",
		district_id: "1",
		district_name: "Dhaka",
		name: "Sher-e-Bangla Nagar",
		bn_name: "শেরে-বাংলা নগর",
	},
	{
		id: "534",
		district_id: "1",
		district_name: "Dhaka",
		name: "Shyampur",
		bn_name: "শ্যামপুর",
	},
	{
		id: "537",
		district_id: "1",
		district_name: "Dhaka",
		name: "Sabujbagh",
		bn_name: "সবুজবাগ",
	},
	{
		id: "535",
		district_id: "1",
		district_name: "Dhaka",
		name: "Sutrapur",
		bn_name: "সূত্রাপুর",
	},
	{
		id: "536",
		district_id: "1",
		district_name: "Dhaka",
		name: "Hazaribagh",
		bn_name: "হাজারীবাগ",
	},
	{
		id: "506",
		district_id: "1",
		district_name: "Dhaka",
		name: "Wari",
		bn_name: "ওয়ারী",
	},
	

	{
		id: "145",
		district_id: "1",
		district_name: "Dhaka",
		name: "Dhamrai",
		bn_name: "ধামরাই",
	},
	{
		id: "146",
		district_id: "1",
		district_name: "Dhaka",
		name: "Dohar",
		bn_name: "দোহার",
	},
	{
		id: "147",
		district_id: "1",
		district_name: "Dhaka",
		name: "Keraniganj",
		bn_name: "কেরানীগঞ্জ",
	},
	{
		id: "148",
		district_id: "1",
		district_name: "Dhaka",
		name: "Nawabganj",
		bn_name: "নবাবগঞ্জ",
	},
	{
		id: "149",
		district_id: "1",
		district_name: "Dhaka",
		name: "Savar",
		bn_name: "সাভার",
	},
	{
		id: "150",
		district_id: "2",
		district_name: "Faridpur",
		name: "Faridpur Sadar",
		bn_name: "ফরিদপুর সদর",
	},
	{
		id: "151",
		district_id: "2",
		district_name: "Faridpur",
		name: "Boalmari",
		bn_name: "বোয়ালমারী",
	},
	{
		id: "152",
		district_id: "2",
		district_name: "Faridpur",
		name: "Alfadanga",
		bn_name: "আলফাডাঙ্গা",
	},
	{
		id: "153",
		district_id: "2",
		district_name: "Faridpur",
		name: "Madhukhali",
		bn_name: "মধুখালি",
	},
	{
		id: "154",
		district_id: "2",
		district_name: "Faridpur",
		name: "Bhanga",
		bn_name: "ভাঙ্গা",
	},
	{
		id: "155",
		district_id: "2",
		district_name: "Faridpur",
		name: "Nagarkanda",
		bn_name: "নগরকান্ড",
	},
	{
		id: "156",
		district_id: "2",
		district_name: "Faridpur",
		name: "Charbhadrasan",
		bn_name: "চরভদ্রাসন ",
	},
	{
		id: "157",
		district_id: "2",
		district_name: "Faridpur",
		name: "Sadarpur",
		bn_name: "সদরপুর",
	},
	{
		id: "158",
		district_id: "2",
		district_name: "Faridpur",
		name: "Shaltha",
		bn_name: "শালথা",
	},
	{
		id: "159",
		district_id: "3",
		district_name: "Gazipur",
		name: "Gazipur Sadar-Joydebpur",
		bn_name: "গাজীপুর সদর",
	},
	{
		id: "160",
		district_id: "3",
		district_name: "Gazipur",
		name: "Kaliakior",
		bn_name: "কালিয়াকৈর",
	},
	{
		id: "161",
		district_id: "3",
		district_name: "Gazipur",
		name: "Kapasia",
		bn_name: "কাপাসিয়া",
	},
	{
		id: "162",
		district_id: "3",
		district_name: "Gazipur",
		name: "Sripur",
		bn_name: "শ্রীপুর",
	},
	{
		id: "163",
		district_id: "3",
		district_name: "Gazipur",
		name: "Kaliganj",
		bn_name: "কালীগঞ্জ",
	},
	{
		id: "164",
		district_id: "3",
		district_name: "Gazipur",
		name: "Tongi",
		bn_name: "টঙ্গি",
	},
	{
		id: "165",
		district_id: "4",
		district_name: "Gopalganj",
		name: "Gopalganj Sadar",
		bn_name: "গোপালগঞ্জ সদর",
	},
	{
		id: "166",
		district_id: "4",
		district_name: "Gopalganj",
		name: "Kashiani",
		bn_name: "কাশিয়ানি",
	},
	{
		id: "167",
		district_id: "4",
		district_name: "Gopalganj",
		name: "Kotalipara",
		bn_name: "কোটালিপাড়া",
	},
	{
		id: "168",
		district_id: "4",
		district_name: "Gopalganj",
		name: "Muksudpur",
		bn_name: "মুকসুদপুর",
	},
	{
		id: "169",
		district_id: "4",
		district_name: "Gopalganj",
		name: "Tungipara",
		bn_name: "টুঙ্গিপাড়া",
	},
	{
		id: "170",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Dewanganj",
		bn_name: "দেওয়ানগঞ্জ",
	},
	{
		id: "171",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Baksiganj",
		bn_name: "বকসিগঞ্জ",
	},
	{
		id: "172",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Islampur",
		bn_name: "ইসলামপুর",
	},
	{
		id: "173",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Jamalpur Sadar",
		bn_name: "জামালপুর সদর",
	},
	{
		id: "174",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Madarganj",
		bn_name: "মাদারগঞ্জ",
	},
	{
		id: "175",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Melandaha",
		bn_name: "মেলানদাহা",
	},
	{
		id: "176",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Sarishabari",
		bn_name: "সরিষাবাড়ি ",
	},
	{
		id: "177",
		district_id: "5",
		district_name: "Jamalpur",
		name: "Narundi Police I.C",
		bn_name: "নারুন্দি",
	},
	{
		id: "178",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Astagram",
		bn_name: "অষ্টগ্রাম",
	},
	{
		id: "179",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Bajitpur",
		bn_name: "বাজিতপুর",
	},
	{
		id: "180",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Bhairab",
		bn_name: "ভৈরব",
	},
	{
		id: "181",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Hossainpur",
		bn_name: "হোসেনপুর ",
	},
	{
		id: "182",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Itna",
		bn_name: "ইটনা",
	},
	{
		id: "183",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Karimganj",
		bn_name: "করিমগঞ্জ",
	},
	{
		id: "184",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Katiadi",
		bn_name: "কতিয়াদি",
	},
	{
		id: "185",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Kishoreganj Sadar",
		bn_name: "কিশোরগঞ্জ সদর",
	},
	{
		id: "186",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Kuliarchar",
		bn_name: "কুলিয়ারচর",
	},
	{
		id: "187",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Mithamain",
		bn_name: "মিঠামাইন",
	},
	{
		id: "188",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Nikli",
		bn_name: "নিকলি",
	},
	{
		id: "189",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Pakundia",
		bn_name: "পাকুন্ডা",
	},
	{
		id: "190",
		district_id: "6",
		district_name: "Kishoreganj",
		name: "Tarail",
		bn_name: "তাড়াইল",
	},
	{
		id: "191",
		district_id: "7",
		district_name: "Madaripur",
		name: "Madaripur Sadar",
		bn_name: "মাদারীপুর সদর",
	},
	{
		id: "192",
		district_id: "7",
		district_name: "Madaripur",
		name: "Kalkini",
		bn_name: "কালকিনি",
	},
	{
		id: "193",
		district_id: "7",
		district_name: "Madaripur",
		name: "Rajoir",
		bn_name: "রাজইর",
	},
	{
		id: "194",
		district_id: "7",
		district_name: "Madaripur",
		name: "Shibchar",
		bn_name: "শিবচর",
	},
	{
		id: "195",
		district_id: "8",
		district_name: "Manikganj",
		name: "Manikganj Sadar",
		bn_name: "মানিকগঞ্জ সদর",
	},
	{
		id: "196",
		district_id: "8",
		district_name: "Manikganj",
		name: "Singair",
		bn_name: "সিঙ্গাইর",
	},
	{
		id: "197",
		district_id: "8",
		district_name: "Manikganj",
		name: "Shibalaya",
		bn_name: "শিবালয়",
	},
	{
		id: "198",
		district_id: "8",
		district_name: "Manikganj",
		name: "Saturia",
		bn_name: "সাটুরিয়া",
	},
	{
		id: "199",
		district_id: "8",
		district_name: "Manikganj",
		name: "Harirampur",
		bn_name: "হরিরামপুর",
	},
	{
		id: "200",
		district_id: "8",
		district_name: "Manikganj",
		name: "Ghior",
		bn_name: "ঘিওর",
	},
	{
		id: "201",
		district_id: "8",
		district_name: "Manikganj",
		name: "Daulatpur",
		bn_name: "দৌলতপুর",
	},
	{
		id: "202",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Lohajang",
		bn_name: "লোহাজং",
	},
	{
		id: "203",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Sreenagar",
		bn_name: "শ্রীনগর",
	},
	{
		id: "204",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Munshiganj Sadar",
		bn_name: "মুন্সিগঞ্জ সদর",
	},
	{
		id: "205",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Sirajdikhan",
		bn_name: "সিরাজদিখান",
	},
	{
		id: "206",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Tongibari",
		bn_name: "টঙ্গিবাড়ি",
	},
	{
		id: "207",
		district_id: "9",
		district_name: "Munshiganj",
		name: "Gazaria",
		bn_name: "গজারিয়া",
	},
	{
		id: "208",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Bhaluka",
		bn_name: "ভালুকা",
	},
	{
		id: "209",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Trishal",
		bn_name: "ত্রিশাল",
	},
	{
		id: "210",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Haluaghat",
		bn_name: "হালুয়াঘাট",
	},
	{
		id: "211",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Muktagachha",
		bn_name: "মুক্তাগাছা",
	},
	{
		id: "212",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Dhobaura",
		bn_name: "ধবারুয়া",
	},
	{
		id: "213",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Fulbaria",
		bn_name: "ফুলবাড়িয়া",
	},
	{
		id: "214",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Gaffargaon",
		bn_name: "গফরগাঁও",
	},
	{
		id: "215",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Gauripur",
		bn_name: "গৌরিপুর",
	},
	{
		id: "216",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Ishwarganj",
		bn_name: "ঈশ্বরগঞ্জ",
	},
	{
		id: "217",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Mymensingh Sadar",
		bn_name: "ময়মনসিং সদর",
	},
	{
		id: "218",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Nandail",
		bn_name: "নন্দাইল",
	},
	{
		id: "219",
		district_id: "10",
		district_name: "Mymensingh",
		name: "Phulpur",
		bn_name: "ফুলপুর",
	},
	{
		id: "220",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Araihazar",
		bn_name: "আড়াইহাজার",
	},
	{
		id: "221",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Sonargaon",
		bn_name: "সোনারগাঁও",
	},
	{
		id: "222",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Bandar",
		bn_name: "বান্দার",
	},
	{
		id: "223",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Naryanganj Sadar",
		bn_name: "নারায়ানগঞ্জ সদর",
	},
	{
		id: "224",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Rupganj",
		bn_name: "রূপগঞ্জ",
	},
	{
		id: "225",
		district_id: "11",
		district_name: "Narayanganj",
		name: "Siddirgonj",
		bn_name: "সিদ্ধিরগঞ্জ",
	},
	{
		id: "226",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Belabo",
		bn_name: "বেলাবো",
	},
	{
		id: "227",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Monohardi",
		bn_name: "মনোহরদি",
	},
	{
		id: "228",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Narsingdi Sadar",
		bn_name: "নরসিংদী সদর",
	},
	{
		id: "229",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Palash",
		bn_name: "পলাশ",
	},
	{
		id: "230",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Raipura, Narsingdi",
		bn_name: "রায়পুর",
	},
	{
		id: "231",
		district_id: "12",
		district_name: "Narsingdi",
		name: "Shibpur",
		bn_name: "শিবপুর",
	},
	{
		id: "232",
		district_id: "13",
		district_name: "Netrokona",
		name: "Kendua Upazilla",
		bn_name: "কেন্দুয়া",
	},
	{
		id: "233",
		district_id: "13",
		district_name: "Netrokona",
		name: "Atpara Upazilla",
		bn_name: "আটপাড়া",
	},
	{
		id: "234",
		district_id: "13",
		district_name: "Netrokona",
		name: "Barhatta Upazilla",
		bn_name: "বরহাট্টা",
	},
	{
		id: "235",
		district_id: "13",
		district_name: "Netrokona",
		name: "Durgapur Upazilla",
		bn_name: "দুর্গাপুর",
	},
	{
		id: "236",
		district_id: "13",
		district_name: "Netrokona",
		name: "Kalmakanda Upazilla",
		bn_name: "কলমাকান্দা",
	},
	{
		id: "237",
		district_id: "13",
		district_name: "Netrokona",
		name: "Madan Upazilla",
		bn_name: "মদন",
	},
	{
		id: "238",
		district_id: "13",
		district_name: "Netrokona",
		name: "Mohanganj Upazilla",
		bn_name: "মোহনগঞ্জ",
	},
	{
		id: "239",
		district_id: "13",
		district_name: "Netrokona",
		name: "Netrakona-S Upazilla",
		bn_name: "নেত্রকোনা সদর",
	},
	{
		id: "240",
		district_id: "13",
		district_name: "Netrokona",
		name: "Purbadhala Upazilla",
		bn_name: "পূর্বধলা",
	},
	{
		id: "241",
		district_id: "13",
		district_name: "Netrokona",
		name: "Khaliajuri Upazilla",
		bn_name: "খালিয়াজুরি",
	},
	{
		id: "242",
		district_id: "14",
		district_name: "Rajbari",
		name: "Baliakandi",
		bn_name: "বালিয়াকান্দি",
	},
	{
		id: "243",
		district_id: "14",
		district_name: "Rajbari",
		name: "Goalandaghat",
		bn_name: "গোয়ালন্দ ঘাট",
	},
	{
		id: "244",
		district_id: "14",
		district_name: "Rajbari",
		name: "Pangsha",
		bn_name: "পাংশা",
	},
	{
		id: "245",
		district_id: "14",
		district_name: "Rajbari",
		name: "Kalukhali",
		bn_name: "কালুখালি",
	},
	{
		id: "246",
		district_id: "14",
		district_name: "Rajbari",
		name: "Rajbari Sadar",
		bn_name: "রাজবাড়ি সদর",
	},
	{
		id: "247",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Shariatpur Sadar -Palong",
		bn_name: "শরীয়তপুর সদর ",
	},
	{
		id: "248",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Damudya",
		bn_name: "দামুদিয়া",
	},
	{
		id: "249",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Naria",
		bn_name: "নড়িয়া",
	},
	{
		id: "250",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Jajira",
		bn_name: "জাজিরা",
	},
	{
		id: "251",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Bhedarganj",
		bn_name: "ভেদারগঞ্জ",
	},
	{
		id: "252",
		district_id: "15",
		district_name: "Shariatpur",
		name: "Gosairhat",
		bn_name: "গোসাইর হাট ",
	},
	{
		id: "253",
		district_id: "16",
		district_name: "Sherpur",
		name: "Jhenaigati",
		bn_name: "ঝিনাইগাতি",
	},
	{
		id: "254",
		district_id: "16",
		district_name: "Sherpur",
		name: "Nakla",
		bn_name: "নাকলা",
	},
	{
		id: "255",
		district_id: "16",
		district_name: "Sherpur",
		name: "Nalitabari",
		bn_name: "নালিতাবাড়ি",
	},
	{
		id: "256",
		district_id: "16",
		district_name: "Sherpur",
		name: "Sherpur Sadar",
		bn_name: "শেরপুর সদর",
	},
	{
		id: "257",
		district_id: "16",
		district_name: "Sherpur",
		name: "Sreebardi",
		bn_name: "শ্রীবরদি",
	},
	{
		id: "258",
		district_id: "17",
		district_name: "Tangail",
		name: "Tangail Sadar",
		bn_name: "টাঙ্গাইল সদর",
	},
	{
		id: "259",
		district_id: "17",
		district_name: "Tangail",
		name: "Sakhipur",
		bn_name: "সখিপুর",
	},
	{
		id: "260",
		district_id: "17",
		district_name: "Tangail",
		name: "Basail",
		bn_name: "বসাইল",
	},
	{
		id: "261",
		district_id: "17",
		district_name: "Tangail",
		name: "Madhupur",
		bn_name: "মধুপুর",
	},
	{
		id: "262",
		district_id: "17",
		district_name: "Tangail",
		name: "Ghatail",
		bn_name: "ঘাটাইল",
	},
	{
		id: "263",
		district_id: "17",
		district_name: "Tangail",
		name: "Kalihati",
		bn_name: "কালিহাতি",
	},
	{
		id: "264",
		district_id: "17",
		district_name: "Tangail",
		name: "Nagarpur",
		bn_name: "নগরপুর",
	},
	{
		id: "265",
		district_id: "17",
		district_name: "Tangail",
		name: "Mirzapur",
		bn_name: "মির্জাপুর",
	},
	{
		id: "266",
		district_id: "17",
		district_name: "Tangail",
		name: "Gopalpur",
		bn_name: "গোপালপুর",
	},
	{
		id: "267",
		district_id: "17",
		district_name: "Tangail",
		name: "Delduar",
		bn_name: "দেলদুয়ার",
	},
	{
		id: "268",
		district_id: "17",
		district_name: "Tangail",
		name: "Bhuapur",
		bn_name: "ভুয়াপুর",
	},
	{
		id: "269",
		district_id: "17",
		district_name: "Tangail",
		name: "Dhanbari",
		bn_name: "ধানবাড়ি",
	},
	{
		id: "270",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Bagerhat Sadar",
		bn_name: "বাগেরহাট সদর",
	},
	{
		id: "271",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Chitalmari",
		bn_name: "চিতলমাড়ি",
	},
	{
		id: "272",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Fakirhat",
		bn_name: "ফকিরহাট",
	},
	{
		id: "273",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Kachua",
		bn_name: "কচুয়া",
	},
	{
		id: "274",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Mollahat",
		bn_name: "মোল্লাহাট ",
	},
	{
		id: "275",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Mongla",
		bn_name: "মংলা",
	},
	{
		id: "276",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Morrelganj",
		bn_name: "মরেলগঞ্জ",
	},
	{
		id: "277",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Rampal",
		bn_name: "রামপাল",
	},
	{
		id: "278",
		district_id: "55",
		district_name: "Bagerhat",
		name: "Sarankhola",
		bn_name: "স্মরণখোলা",
	},
	{
		id: "279",
		district_id: "56",
		district_name: "Chuadanga",
		name: "Damurhuda",
		bn_name: "দামুরহুদা",
	},
	{
		id: "280",
		district_id: "56",
		district_name: "Chuadanga",
		name: "Chuadanga-S",
		bn_name: "চুয়াডাঙ্গা সদর",
	},
	{
		id: "281",
		district_id: "56",
		district_name: "Chuadanga",
		name: "Jibannagar",
		bn_name: "জীবন নগর ",
	},
	{
		id: "282",
		district_id: "56",
		district_name: "Chuadanga",
		name: "Alamdanga",
		bn_name: "আলমডাঙ্গা",
	},
	{
		id: "283",
		district_id: "57",
		district_name: "Jashore",
		name: "Abhaynagar",
		bn_name: "অভয়নগর",
	},
	{
		id: "284",
		district_id: "57",
		district_name: "Jashore",
		name: "Keshabpur",
		bn_name: "কেশবপুর",
	},
	{
		id: "285",
		district_id: "57",
		district_name: "Jashore",
		name: "Bagherpara",
		bn_name: "বাঘের পাড়া ",
	},
	{
		id: "286",
		district_id: "57",
		district_name: "Jashore",
		name: "Jessore Sadar",
		bn_name: "যশোর সদর",
	},
	{
		id: "287",
		district_id: "57",
		district_name: "Jashore",
		name: "Chaugachha",
		bn_name: "চৌগাছা",
	},
	{
		id: "288",
		district_id: "57",
		district_name: "Jashore",
		name: "Manirampur",
		bn_name: "মনিরামপুর ",
	},
	{
		id: "289",
		district_id: "57",
		district_name: "Jashore",
		name: "Jhikargachha",
		bn_name: "ঝিকরগাছা",
	},
	{
		id: "290",
		district_id: "57",
		district_name: "Jashore",
		name: "Sharsha",
		bn_name: "সারশা",
	},
	{
		id: "291",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Jhenaidah Sadar",
		bn_name: "ঝিনাইদহ সদর",
	},
	{
		id: "292",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Maheshpur",
		bn_name: "মহেশপুর",
	},
	{
		id: "293",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Kaliganj",
		bn_name: "কালীগঞ্জ",
	},
	{
		id: "294",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Kotchandpur",
		bn_name: "কোট চাঁদপুর ",
	},
	{
		id: "295",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Shailkupa",
		bn_name: "শৈলকুপা",
	},
	{
		id: "296",
		district_id: "58",
		district_name: "Jhenaidah",
		name: "Harinakunda",
		bn_name: "হাড়িনাকুন্দা",
	},
	{
		id: "297",
		district_id: "59",
		district_name: "Khulna",
		name: "Terokhada",
		bn_name: "তেরোখাদা",
	},
	{
		id: "298",
		district_id: "59",
		district_name: "Khulna",
		name: "Batiaghata",
		bn_name: "বাটিয়াঘাটা ",
	},
	{
		id: "299",
		district_id: "59",
		district_name: "Khulna",
		name: "Dacope",
		bn_name: "ডাকপে",
	},
	{
		id: "300",
		district_id: "59",
		district_name: "Khulna",
		name: "Dumuria",
		bn_name: "ডুমুরিয়া",
	},
	{
		id: "301",
		district_id: "59",
		district_name: "Khulna",
		name: "Dighalia",
		bn_name: "দিঘলিয়া",
	},
	{
		id: "302",
		district_id: "59",
		district_name: "Khulna",
		name: "Koyra",
		bn_name: "কয়ড়া",
	},
	{
		id: "303",
		district_id: "59",
		district_name: "Khulna",
		name: "Paikgachha",
		bn_name: "পাইকগাছা",
	},
	{
		id: "304",
		district_id: "59",
		district_name: "Khulna",
		name: "Phultala",
		bn_name: "ফুলতলা",
	},
	{
		id: "305",
		district_id: "59",
		district_name: "Khulna",
		name: "Rupsa",
		bn_name: "রূপসা",
	},
	{
		id: "306",
		district_id: "60",
		district_name: "Kushtia",
		name: "Kushtia Sadar",
		bn_name: "কুষ্টিয়া সদর",
	},
	{
		id: "307",
		district_id: "60",
		district_name: "Kushtia",
		name: "Kumarkhali",
		bn_name: "কুমারখালি",
	},
	{
		id: "308",
		district_id: "60",
		district_name: "Kushtia",
		name: "Daulatpur",
		bn_name: "দৌলতপুর",
	},
	{
		id: "309",
		district_id: "60",
		district_name: "Kushtia",
		name: "Mirpur",
		bn_name: "মিরপুর",
	},
	{
		id: "310",
		district_id: "60",
		district_name: "Kushtia",
		name: "Bheramara",
		bn_name: "ভেরামারা",
	},
	{
		id: "311",
		district_id: "60",
		district_name: "Kushtia",
		name: "Khoksa",
		bn_name: "খোকসা",
	},
	{
		id: "312",
		district_id: "61",
		district_name: "Magura",
		name: "Magura Sadar",
		bn_name: "মাগুরা সদর",
	},
	{
		id: "313",
		district_id: "61",
		district_name: "Magura",
		name: "Mohammadpur",
		bn_name: "মোহাম্মাদপুর",
	},
	{
		id: "314",
		district_id: "61",
		district_name: "Magura",
		name: "Shalikha",
		bn_name: "শালিখা",
	},
	{
		id: "315",
		district_id: "61",
		district_name: "Magura",
		name: "Sreepur",
		bn_name: "শ্রীপুর",
	},
	{
		id: "316",
		district_id: "62",
		district_name: "Meherpur",
		name: "angni",
		bn_name: "আংনি",
	},
	{
		id: "317",
		district_id: "62",
		district_name: "Meherpur",
		name: "Mujib Nagar",
		bn_name: "মুজিব নগর",
	},
	{
		id: "318",
		district_id: "62",
		district_name: "Meherpur",
		name: "Meherpur-S",
		bn_name: "মেহেরপুর সদর",
	},
	{
		id: "319",
		district_id: "63",
		district_name: "Narail",
		name: "Narail Sadar",
		bn_name: "নড়াইল সদর",
	},
	{
		id: "320",
		district_id: "63",
		district_name: "Narail",
		name: "Lohagara Upazilla",
		bn_name: "লোহাগাড়া",
	},
	{
		id: "321",
		district_id: "63",
		district_name: "Narail",
		name: "Kalia Upazilla",
		bn_name: "কালিয়া",
	},
	{
		id: "322",
		district_id: "64",
		district_name: "Satkhira",
		name: "Satkhira Sadar",
		bn_name: "সাতক্ষীরা সদর",
	},
	{
		id: "323",
		district_id: "64",
		district_name: "Satkhira",
		name: "Assasuni",
		bn_name: "আসসাশুনি ",
	},
	{
		id: "324",
		district_id: "64",
		district_name: "Satkhira",
		name: "Debhata",
		bn_name: "দেভাটা",
	},
	{
		id: "325",
		district_id: "64",
		district_name: "Satkhira",
		name: "Tala",
		bn_name: "তালা",
	},
	{
		id: "326",
		district_id: "64",
		district_name: "Satkhira",
		name: "Kalaroa",
		bn_name: "কলরোয়া",
	},
	{
		id: "327",
		district_id: "64",
		district_name: "Satkhira",
		name: "Kaliganj",
		bn_name: "কালীগঞ্জ",
	},
	{
		id: "328",
		district_id: "64",
		district_name: "Satkhira",
		name: "Shyamnagar",
		bn_name: "শ্যামনগর",
	},
	{
		id: "329",
		district_id: "18",
		district_name: "Bogura",
		name: "Adamdighi",
		bn_name: "আদমদিঘী",
	},
	{
		id: "330",
		district_id: "18",
		district_name: "Bogura",
		name: "Bogra Sadar",
		bn_name: "বগুড়া সদর",
	},
	{
		id: "331",
		district_id: "18",
		district_name: "Bogura",
		name: "Sherpur",
		bn_name: "শেরপুর",
	},
	{
		id: "332",
		district_id: "18",
		district_name: "Bogura",
		name: "Dhunat",
		bn_name: "ধুনট",
	},
	{
		id: "333",
		district_id: "18",
		district_name: "Bogura",
		name: "Dhupchanchia",
		bn_name: "দুপচাচিয়া",
	},
	{
		id: "334",
		district_id: "18",
		district_name: "Bogura",
		name: "Gabtali",
		bn_name: "গাবতলি",
	},
	{
		id: "335",
		district_id: "18",
		district_name: "Bogura",
		name: "Kahaloo",
		bn_name: "কাহালু",
	},
	{
		id: "336",
		district_id: "18",
		district_name: "Bogura",
		name: "Nandigram",
		bn_name: "নন্দিগ্রাম",
	},
	{
		id: "337",
		district_id: "18",
		district_name: "Bogura",
		name: "Sahajanpur",
		bn_name: "শাহজাহানপুর",
	},
	{
		id: "338",
		district_id: "18",
		district_name: "Bogura",
		name: "Sariakandi",
		bn_name: "সারিয়াকান্দি",
	},
	{
		id: "339",
		district_id: "18",
		district_name: "Bogura",
		name: "Shibganj",
		bn_name: "শিবগঞ্জ",
	},
	{
		id: "340",
		district_id: "18",
		district_name: "Bogura",
		name: "Sonatala",
		bn_name: "সোনাতলা",
	},
	{
		id: "341",
		district_id: "19",
		district_name: "Joypurhat",
		name: "Joypurhat S",
		bn_name: "জয়পুরহাট সদর",
	},
	{
		id: "342",
		district_id: "19",
		district_name: "Joypurhat",
		name: "Akkelpur",
		bn_name: "আক্কেলপুর",
	},
	{
		id: "343",
		district_id: "19",
		district_name: "Joypurhat",
		name: "Kalai",
		bn_name: "কালাই",
	},
	{
		id: "344",
		district_id: "19",
		district_name: "Joypurhat",
		name: "Khetlal",
		bn_name: "খেতলাল",
	},
	{
		id: "345",
		district_id: "19",
		district_name: "Joypurhat",
		name: "Panchbibi",
		bn_name: "পাঁচবিবি",
	},
	{
		id: "346",
		district_id: "20",
		district_name: "Naogaon",
		name: "Naogaon Sadar",
		bn_name: "নওগাঁ সদর",
	},
	{
		id: "347",
		district_id: "20",
		district_name: "Naogaon",
		name: "Mohadevpur",
		bn_name: "মহাদেবপুর",
	},
	{
		id: "348",
		district_id: "20",
		district_name: "Naogaon",
		name: "Manda",
		bn_name: "মান্দা",
	},
	{
		id: "349",
		district_id: "20",
		district_name: "Naogaon",
		name: "Niamatpur",
		bn_name: "নিয়ামতপুর",
	},
	{
		id: "350",
		district_id: "20",
		district_name: "Naogaon",
		name: "Atrai",
		bn_name: "আত্রাই",
	},
	{
		id: "351",
		district_id: "20",
		district_name: "Naogaon",
		name: "Raninagar",
		bn_name: "রাণীনগর",
	},
	{
		id: "352",
		district_id: "20",
		district_name: "Naogaon",
		name: "Patnitala",
		bn_name: "পত্নীতলা",
	},
	{
		id: "353",
		district_id: "20",
		district_name: "Naogaon",
		name: "Dhamoirhat",
		bn_name: "ধামইরহাট ",
	},
	{
		id: "354",
		district_id: "20",
		district_name: "Naogaon",
		name: "Sapahar",
		bn_name: "সাপাহার",
	},
	{
		id: "355",
		district_id: "20",
		district_name: "Naogaon",
		name: "Porsha",
		bn_name: "পোরশা",
	},
	{
		id: "356",
		district_id: "20",
		district_name: "Naogaon",
		name: "Badalgachhi",
		bn_name: "বদলগাছি",
	},
	{
		id: "357",
		district_id: "21",
		district_name: "Natore",
		name: "Natore Sadar",
		bn_name: "নাটোর সদর",
	},
	{
		id: "358",
		district_id: "21",
		district_name: "Natore",
		name: "Baraigram",
		bn_name: "বড়াইগ্রাম",
	},
	{
		id: "359",
		district_id: "21",
		district_name: "Natore",
		name: "Bagatipara",
		bn_name: "বাগাতিপাড়া",
	},
	{
		id: "360",
		district_id: "21",
		district_name: "Natore",
		name: "Lalpur",
		bn_name: "লালপুর",
	},
	{
		id: "361",
		district_id: "21",
		district_name: "Natore",
		name: "Natore Sadar",
		bn_name: "নাটোর সদর",
	},
	{
		id: "362",
		district_id: "21",
		district_name: "Natore",
		name: "Baraigram",
		bn_name: "বড়াই গ্রাম",
	},
	{
		id: "363",
		district_id: "22",
		district_name: "Nawabganj",
		name: "Bholahat",
		bn_name: "ভোলাহাট",
	},
	{
		id: "364",
		district_id: "22",
		district_name: "Nawabganj",
		name: "Gomastapur",
		bn_name: "গোমস্তাপুর",
	},
	{
		id: "365",
		district_id: "22",
		district_name: "Nawabganj",
		name: "Nachole",
		bn_name: "নাচোল",
	},
	{
		id: "366",
		district_id: "22",
		district_name: "Nawabganj",
		name: "Nawabganj Sadar",
		bn_name: "নবাবগঞ্জ সদর",
	},
	{
		id: "367",
		district_id: "22",
		district_name: "Nawabganj",
		name: "Shibganj",
		bn_name: "শিবগঞ্জ",
	},
	{
		id: "368",
		district_id: "23",
		district_name: "Pabna",
		name: "Atgharia",
		bn_name: "আটঘরিয়া",
	},
	{
		id: "369",
		district_id: "23",
		district_name: "Pabna",
		name: "Bera",
		bn_name: "বেড়া",
	},
	{
		id: "370",
		district_id: "23",
		district_name: "Pabna",
		name: "Bhangura",
		bn_name: "ভাঙ্গুরা",
	},
	{
		id: "371",
		district_id: "23",
		district_name: "Pabna",
		name: "Chatmohar",
		bn_name: "চাটমোহর",
	},
	{
		id: "372",
		district_id: "23",
		district_name: "Pabna",
		name: "Faridpur",
		bn_name: "ফরিদপুর",
	},
	{
		id: "373",
		district_id: "23",
		district_name: "Pabna",
		name: "Ishwardi",
		bn_name: "ঈশ্বরদী",
	},
	{
		id: "374",
		district_id: "23",
		district_name: "Pabna",
		name: "Pabna Sadar",
		bn_name: "পাবনা সদর",
	},
	{
		id: "375",
		district_id: "23",
		district_name: "Pabna",
		name: "Santhia",
		bn_name: "সাথিয়া",
	},
	{
		id: "376",
		district_id: "23",
		district_name: "Pabna",
		name: "Sujanagar",
		bn_name: "সুজানগর",
	},
	{
		id: "377",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Bagha",
		bn_name: "বাঘা",
	},
	{
		id: "378",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Bagmara",
		bn_name: "বাগমারা",
	},
	{
		id: "379",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Charghat",
		bn_name: "চারঘাট",
	},
	{
		id: "380",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Durgapur",
		bn_name: "দুর্গাপুর",
	},
	{
		id: "381",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Godagari",
		bn_name: "গোদাগারি",
	},
	{
		id: "382",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Mohanpur",
		bn_name: "মোহনপুর",
	},
	{
		id: "383",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Paba",
		bn_name: "পবা",
	},
	{
		id: "384",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Puthia",
		bn_name: "পুঠিয়া",
	},
	{
		id: "385",
		district_id: "24",
		district_name: "Rajshahi",
		name: "Tanore",
		bn_name: "তানোর",
	},
	{
		id: "386",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Sirajganj Sadar",
		bn_name: "সিরাজগঞ্জ সদর",
	},
	{
		id: "387",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Belkuchi",
		bn_name: "বেলকুচি",
	},
	{
		id: "388",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Chauhali",
		bn_name: "চৌহালি",
	},
	{
		id: "389",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Kamarkhanda",
		bn_name: "কামারখান্দা",
	},
	{
		id: "390",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Kazipur",
		bn_name: "কাজীপুর",
	},
	{
		id: "391",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Raiganj",
		bn_name: "রায়গঞ্জ",
	},
	{
		id: "392",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Shahjadpur",
		bn_name: "শাহজাদপুর",
	},
	{
		id: "393",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Tarash",
		bn_name: "তারাশ",
	},
	{
		id: "394",
		district_id: "25",
		district_name: "Sirajgonj",
		name: "Ullahpara",
		bn_name: "উল্লাপাড়া",
	},
	{
		id: "395",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Birampur",
		bn_name: "বিরামপুর",
	},
	{
		id: "396",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Birganj",
		bn_name: "বীরগঞ্জ",
	},
	{
		id: "397",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Biral",
		bn_name: "বিড়াল",
	},
	{
		id: "398",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Bochaganj",
		bn_name: "বোচাগঞ্জ",
	},
	{
		id: "399",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Chirirbandar",
		bn_name: "চিরিরবন্দর",
	},
	{
		id: "400",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Phulbari",
		bn_name: "ফুলবাড়ি",
	},
	{
		id: "401",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Ghoraghat",
		bn_name: "ঘোড়াঘাট",
	},
	{
		id: "402",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Hakimpur",
		bn_name: "হাকিমপুর",
	},
	{
		id: "403",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Kaharole",
		bn_name: "কাহারোল",
	},
	{
		id: "404",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Khansama",
		bn_name: "খানসামা",
	},
	{
		id: "405",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Dinajpur Sadar",
		bn_name: "দিনাজপুর সদর",
	},
	{
		id: "406",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Nawabganj",
		bn_name: "নবাবগঞ্জ",
	},
	{
		id: "407",
		district_id: "26",
		district_name: "Dinajpur",
		name: "Parbatipur",
		bn_name: "পার্বতীপুর",
	},
	{
		id: "408",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Fulchhari",
		bn_name: "ফুলছড়ি",
	},
	{
		id: "409",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Gaibandha sadar",
		bn_name: "গাইবান্ধা সদর",
	},
	{
		id: "410",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Gobindaganj",
		bn_name: "গোবিন্দগঞ্জ",
	},
	{
		id: "411",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Palashbari",
		bn_name: "পলাশবাড়ী",
	},
	{
		id: "412",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Sadullapur",
		bn_name: "সাদুল্যাপুর",
	},
	{
		id: "413",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Saghata",
		bn_name: "সাঘাটা",
	},
	{
		id: "414",
		district_id: "27",
		district_name: "Gaibandha",
		name: "Sundarganj",
		bn_name: "সুন্দরগঞ্জ",
	},
	{
		id: "415",
		district_id: "28",
		district_name: "Kurigram",
		name: "Kurigram Sadar",
		bn_name: "কুড়িগ্রাম সদর",
	},
	{
		id: "416",
		district_id: "28",
		district_name: "Kurigram",
		name: "Nageshwari",
		bn_name: "নাগেশ্বরী",
	},
	{
		id: "417",
		district_id: "28",
		district_name: "Kurigram",
		name: "Bhurungamari",
		bn_name: "ভুরুঙ্গামারি",
	},
	{
		id: "418",
		district_id: "28",
		district_name: "Kurigram",
		name: "Phulbari",
		bn_name: "ফুলবাড়ি",
	},
	{
		id: "419",
		district_id: "28",
		district_name: "Kurigram",
		name: "Rajarhat",
		bn_name: "রাজারহাট",
	},
	{
		id: "420",
		district_id: "28",
		district_name: "Kurigram",
		name: "Ulipur",
		bn_name: "উলিপুর",
	},
	{
		id: "421",
		district_id: "28",
		district_name: "Kurigram",
		name: "Chilmari",
		bn_name: "চিলমারি",
	},
	{
		id: "422",
		district_id: "28",
		district_name: "Kurigram",
		name: "Rowmari",
		bn_name: "রউমারি",
	},
	{
		id: "423",
		district_id: "28",
		district_name: "Kurigram",
		name: "Char Rajibpur",
		bn_name: "চর রাজিবপুর",
	},
	{
		id: "424",
		district_id: "29",
		district_name: "Lalmonirhat",
		name: "Lalmanirhat Sadar",
		bn_name: "লালমনিরহাট সদর",
	},
	{
		id: "425",
		district_id: "29",
		district_name: "Lalmonirhat",
		name: "Aditmari",
		bn_name: "আদিতমারি",
	},
	{
		id: "426",
		district_id: "29",
		district_name: "Lalmonirhat",
		name: "Kaliganj",
		bn_name: "কালীগঞ্জ",
	},
	{
		id: "427",
		district_id: "29",
		district_name: "Lalmonirhat",
		name: "Hatibandha",
		bn_name: "হাতিবান্ধা",
	},
	{
		id: "428",
		district_id: "29",
		district_name: "Lalmonirhat",
		name: "Patgram",
		bn_name: "পাটগ্রাম",
	},
	{
		id: "429",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Nilphamari Sadar",
		bn_name: "নীলফামারী সদর",
	},
	{
		id: "430",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Saidpur",
		bn_name: "সৈয়দপুর",
	},
	{
		id: "431",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Jaldhaka",
		bn_name: "জলঢাকা",
	},
	{
		id: "432",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Kishoreganj",
		bn_name: "কিশোরগঞ্জ",
	},
	{
		id: "433",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Domar",
		bn_name: "ডোমার",
	},
	{
		id: "434",
		district_id: "30",
		district_name: "Nilphamari",
		name: "Dimla",
		bn_name: "ডিমলা",
	},
	{
		id: "435",
		district_id: "31",
		district_name: "Panchagarh",
		name: "Panchagarh Sadar",
		bn_name: "পঞ্চগড় সদর",
	},
	{
		id: "436",
		district_id: "31",
		district_name: "Panchagarh",
		name: "Debiganj",
		bn_name: "দেবীগঞ্জ",
	},
	{
		id: "437",
		district_id: "31",
		district_name: "Panchagarh",
		name: "Boda",
		bn_name: "বোদা",
	},
	{
		id: "438",
		district_id: "31",
		district_name: "Panchagarh",
		name: "Atwari",
		bn_name: "আটোয়ারি",
	},
	{
		id: "439",
		district_id: "31",
		district_name: "Panchagarh",
		name: "Tetulia",
		bn_name: "তেঁতুলিয়া",
	},
	{
		id: "440",
		district_id: "32",
		district_name: "Rangpur",
		name: "Badarganj",
		bn_name: "বদরগঞ্জ",
	},
	{
		id: "441",
		district_id: "32",
		district_name: "Rangpur",
		name: "Mithapukur",
		bn_name: "মিঠাপুকুর",
	},
	{
		id: "442",
		district_id: "32",
		district_name: "Rangpur",
		name: "Gangachara",
		bn_name: "গঙ্গাচরা",
	},
	{
		id: "443",
		district_id: "32",
		district_name: "Rangpur",
		name: "Kaunia",
		bn_name: "কাউনিয়া",
	},
	{
		id: "444",
		district_id: "32",
		district_name: "Rangpur",
		name: "Rangpur Sadar",
		bn_name: "রংপুর সদর",
	},
	{
		id: "445",
		district_id: "32",
		district_name: "Rangpur",
		name: "Pirgachha",
		bn_name: "পীরগাছা",
	},
	{
		id: "446",
		district_id: "32",
		district_name: "Rangpur",
		name: "Pirganj",
		bn_name: "পীরগঞ্জ",
	},
	{
		id: "447",
		district_id: "32",
		district_name: "Rangpur",
		name: "Taraganj",
		bn_name: "তারাগঞ্জ",
	},
	{
		id: "448",
		district_id: "33",
		district_name: "Thakurgaon",
		name: "Thakurgaon Sadar",
		bn_name: "ঠাকুরগাঁও সদর",
	},
	{
		id: "449",
		district_id: "33",
		district_name: "Thakurgaon",
		name: "Pirganj",
		bn_name: "পীরগঞ্জ",
	},
	{
		id: "450",
		district_id: "33",
		district_name: "Thakurgaon",
		name: "Baliadangi",
		bn_name: "বালিয়াডাঙ্গি",
	},
	{
		id: "451",
		district_id: "33",
		district_name: "Thakurgaon",
		name: "Haripur",
		bn_name: "হরিপুর",
	},
	{
		id: "452",
		district_id: "33",
		district_name: "Thakurgaon",
		name: "Ranisankail",
		bn_name: "রাণীসংকইল",
	},
	{
		id: "453",
		district_id: "51",
		district_name: "Habiganj",
		name: "Ajmiriganj",
		bn_name: "আজমিরিগঞ্জ",
	},
	{
		id: "454",
		district_id: "51",
		district_name: "Habiganj",
		name: "Baniachang",
		bn_name: "বানিয়াচং",
	},
	{
		id: "455",
		district_id: "51",
		district_name: "Habiganj",
		name: "Bahubal",
		bn_name: "বাহুবল",
	},
	{
		id: "456",
		district_id: "51",
		district_name: "Habiganj",
		name: "Chunarughat",
		bn_name: "চুনারুঘাট",
	},
	{
		id: "457",
		district_id: "51",
		district_name: "Habiganj",
		name: "Habiganj Sadar",
		bn_name: "হবিগঞ্জ সদর",
	},
	{
		id: "458",
		district_id: "51",
		district_name: "Habiganj",
		name: "Lakhai",
		bn_name: "লাক্ষাই",
	},
	{
		id: "459",
		district_id: "51",
		district_name: "Habiganj",
		name: "Madhabpur",
		bn_name: "মাধবপুর",
	},
	{
		id: "460",
		district_id: "51",
		district_name: "Habiganj",
		name: "Nabiganj",
		bn_name: "নবীগঞ্জ",
	},
	{
		id: "461",
		district_id: "51",
		district_name: "Habiganj",
		name: "Shaistagonj",
		bn_name: "শায়েস্তাগঞ্জ",
	},
	{
		id: "462",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Moulvibazar Sadar",
		bn_name: "মৌলভীবাজার",
	},
	{
		id: "463",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Barlekha",
		bn_name: "বড়লেখা",
	},
	{
		id: "464",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Juri",
		bn_name: "জুড়ি",
	},
	{
		id: "465",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Kamalganj",
		bn_name: "কামালগঞ্জ",
	},
	{
		id: "466",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Kulaura",
		bn_name: "কুলাউরা",
	},
	{
		id: "467",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Rajnagar",
		bn_name: "রাজনগর",
	},
	{
		id: "468",
		district_id: "52",
		district_name: "Maulvibazar",
		name: "Sreemangal",
		bn_name: "শ্রীমঙ্গল",
	},
	{
		id: "469",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Bishwamvarpur",
		bn_name: "বিসশম্ভারপুর",
	},
	{
		id: "470",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Chhatak",
		bn_name: "ছাতক",
	},
	{
		id: "471",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Derai",
		bn_name: "দেড়াই",
	},
	{
		id: "472",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Dharampasha",
		bn_name: "ধরমপাশা",
	},
	{
		id: "473",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Dowarabazar",
		bn_name: "দোয়ারাবাজার",
	},
	{
		id: "474",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Jagannathpur",
		bn_name: "জগন্নাথপুর",
	},
	{
		id: "475",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Jamalganj",
		bn_name: "জামালগঞ্জ",
	},
	{
		id: "476",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Sulla",
		bn_name: "সুল্লা",
	},
	{
		id: "477",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Sunamganj Sadar",
		bn_name: "সুনামগঞ্জ সদর",
	},
	{
		id: "478",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Shanthiganj",
		bn_name: "শান্তিগঞ্জ",
	},
	{
		id: "479",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Tahirpur",
		bn_name: "তাহিরপুর",
	},
	{
		id: "480",
		district_id: "54",
		district_name: "Sylhet",
		name: "Sylhet Sadar",
		bn_name: "সিলেট সদর",
	},
	{
		id: "481",
		district_id: "54",
		district_name: "Sylhet",
		name: "Beanibazar",
		bn_name: "বেয়ানিবাজার",
	},
	{
		id: "482",
		district_id: "54",
		district_name: "Sylhet",
		name: "Bishwanath",
		bn_name: "বিশ্বনাথ",
	},
	{
		id: "483",
		district_id: "54",
		district_name: "Sylhet",
		name: "Dakshin Surma",
		bn_name: "দক্ষিণ সুরমা",
	},
	{
		id: "484",
		district_id: "54",
		district_name: "Sylhet",
		name: "Balaganj",
		bn_name: "বালাগঞ্জ",
	},
	{
		id: "485",
		district_id: "54",
		district_name: "Sylhet",
		name: "Companiganj",
		bn_name: "কোম্পানিগঞ্জ",
	},
	{
		id: "486",
		district_id: "54",
		district_name: "Sylhet",
		name: "Fenchuganj",
		bn_name: "ফেঞ্চুগঞ্জ",
	},
	{
		id: "487",
		district_id: "54",
		district_name: "Sylhet",
		name: "Golapganj",
		bn_name: "গোলাপগঞ্জ",
	},
	{
		id: "488",
		district_id: "54",
		district_name: "Sylhet",
		name: "Gowainghat",
		bn_name: "গোয়াইনঘাট",
	},
	{
		id: "489",
		district_id: "54",
		district_name: "Sylhet",
		name: "Jointapur",
		bn_name: "জৈন্তাপুর",
	},
	{
		id: "490",
		district_id: "54",
		district_name: "Sylhet",
		name: "Kanaighat",
		bn_name: "কানাইঘাট",
	},
	{
		id: "491",
		district_id: "54",
		district_name: "Sylhet",
		name: "Zakiganj",
		bn_name: "জাকিগঞ্জ",
	},
	{
		id: "492",
		district_id: "54",
		district_name: "Sylhet",
		name: "Nobigonj",
		bn_name: "নবীগঞ্জ",
	},
	{
		id: "493",
		district_id: "45",
		district_name: "Cox's Bazar",
		name: "Eidgaon",
		bn_name: "ঈদগাঁও",
	},
	{
		id: "494",
		district_id: "53",
		district_name: "Sunamganj",
		name: "Modhyanagar",
		bn_name: "মধ্যনগর",
	},
	{
		id: "495",
		district_id: "7",
		district_name: "Madaripur",
		name: "Dasar",
		bn_name: "দশর",
	},
];

export function setCheckInDetails(details) {
  localStorage.setItem("checkInDetails", JSON.stringify(details));
}
export function getCheckInDetails() {
  return JSON.parse(localStorage.getItem("checkInDetails"));
}
export function removeCheckInDetails() {
  localStorage.removeItem("checkInDetails");
}
export function setBusinessCreationType(type) {
  localStorage.setItem("new_business", JSON.stringify(type));
}
export function getBusinessCreationType() {
  return JSON.parse(localStorage.getItem("new_business"));
}
export function removeBusinessCreationType() {
  localStorage.removeItem("new_business");
}
export function setCustomerRole(type) {
  localStorage.setItem("new_customer_role", JSON.stringify(type));
}
export function getCustomerRole() {
  return JSON.parse(localStorage.getItem("new_customer_role"));
}
export function removeCustomerRole() {
  localStorage.removeItem("new_customer_role");
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import withLocation from "components/HOC/withLocation";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NewCustomer from "components/mobile-components/NewCustomer";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function CreateNewCustomerPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let { to } = useParams();

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.customerList.createCustomer} from="createCustomer"/>
					<section className='px-2 pb-24 pt-14'>
						<NewCustomer to={to} />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default withLocation(CreateNewCustomerPage);
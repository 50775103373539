import {
	IonApp,
	IonButton,
	IonContent,
	IonLoading,
	IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Blog from "services/Blog";

function JobDetails() {
	const contentRef = useRef();
	const { id } = useParams();
	const [jobDetails, setJobDetails] = useState({});
	const [loading, setLoading] = useState(true);
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("issticky");
	const [menuCollapse, setMenuCollapse] = useState(true);
	const ekkLanguage = useSelector((state) => state.lan.lan);


	useEffect(() => {
		Blog.singleJobPost(id).then((res) => {
			if (res.success) {
				setLoading(false);
				setJobDetails(res.results);
			}
		});
	}, [id]);

	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("issticky");
						}
					}}
				>
					<BackButtonTitle
						title={ekkLanguage.home.jobDetailsTitle}
					></BackButtonTitle>
					{loading ? (
						<IonLoading isOpen={loading} message={ekkLanguage.others.loader} />
					) : (
						<div className='py-5 px-4 pt-16'>
							<div className='flex items-center mb-[10px]'>
								<img
									className='w-[50px] h-[50px] p-2  bg-white rounded-lg'
									src='/assets/images/ekkhero/Icons/ekkbaz-logo.png'
									alt='ekkbaz'
								/>
								<p className='font-bold text-16 ml-[10px]'>
									{jobDetails?.title}
								</p>
							</div>
							<img
								className='rounded-lg w-full h-full'
								src={jobDetails?.thumbnail_image}
								alt='job-poster'
							/>
							<div className='mt-[20px] '>
								<p
									dangerouslySetInnerHTML={{
										__html: jobDetails?.content,
									}}
									className='mt-[10px]'
								></p>
								{/* <p className='font-semibold mt-[10px]'>
								{ekkLanguage.home.clickInfoLabel}
							</p>
							<div className='flex justify-between mt-5'>
								<p className='font-bold text-primary'>End Date: 28 May 2023</p>
								<p className='font-bold text-primary'>{jobDetails?.bazz_count} Candidates</p>
							</div>
							<IonButton
								onClick={() => {
									navigate("/home");
								}}
								color='success'
								className='font-bold h-10 w-full mt-5'
							>
								{ekkLanguage.home.applyNowBtnLabel}
								<span className='pl-2'>
									<img
										src='/assets/images/ekkhero/Icons/apply.svg'
										alt='apply'
									/>
								</span>
							</IonButton> */}
							</div>
						</div>
					)}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

export default JobDetails;

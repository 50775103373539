import React, { useState } from "react";
import { useSelector } from "react-redux";
import PhoneNoCheck from "./PhoneNoCheck";
import { Transition, animated } from "react-spring/renderprops";
import { authBox_Block } from "Constants";
import Password from "./Password";
import TokenBlock from "./TokenBlock";
import SubmitPassBlock from "./SubmitPassBlock";
import ChangedCongressBlock from "./ChangedCongressBlock";
import VerificationBox from "./VerificationBox";

export default function BannerContent({ blockIndex, setBlockIndex }) {
  const [loading, setLoading] = useState(false);
  let userInfo = useSelector((state) => state.user);
  const [countryCode, setCountryCode] = useState("+88");
  const [country, setCountry] = useState("Bangladesh");
  const [phoneNo, setPhoneNo] = useState("");
  var sSize = window.matchMedia("(max-width: 599px)");

  const [verifyNumber, setVerifyNumber] = useState(
    userInfo?.createUserData.verify
  );
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // const handleClose = () => {
  //   setIsOpen(false);
  // };
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  return (
    <div
      className={`${
        isOpen ? "pt-[80px] lg:pt-[120px]" : "lg:pt-[185px] pt-[120px]"
      }`}
    >
      <div
        className={`flex w-full justify-center items-center ${
          isOpen ? "lg:my-5 md:my-4 my-10" : "lg:my-5 md:my-4 my-10"
        }`}
      >
        <img
          src="/assets/images/ekkhero_full_logo.png"
          alt="logo"
          className="w-[175px] h-[100px]"
        />
      </div>
      <div>
        {/* {isOpen && (
          <div className="bg-[#EFE0BE] flex justify-between items-center p-5 rounded-lg my-10">
            <img src="/assets/images/warning.png" alt="" />
            <p className="lg:text-18 text-14 text-[#00000080] pl-4">
              {localLanguage === "en"
                ? "Kindly note, transactions will be impacted on 1st March 2024 as system is being upgraded."
                : localLanguage === "bn"
                ? "দয়া করে মনে রাখবেন, সিস্টেমটি আপগ্রেডের পরিণামে ১লা মার্চ ২০২৪ পর্যন্ত লেনদেনে প্রভাব পড়তে পারে।"
                : "Xin lưu ý, việc nâng cấp hệ thống có thể ảnh hưởng đến các giao dịch cho đến ngày 1 tháng 3 năm 2024."}
            </p>
            <img
              src="/assets/images/cross.png"
              className="p-2"
              onClick={handleClose}
              alt=""
            ></img>
          </div>
        )} */}
      </div>

      <div className={`${isOpen ? "lg:pt-5 pt-4" : "pt-12"} relative`}>
      <Transition
					native
					reset
					unique
					items={blockIndex}
					from={{
						opacity: 0,
						transform: "translate3d(30%,0,0)",
					}}
					enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
					leave={{
						opacity: 0,
						transform: "translate3d(-30%,0,0)",
					}}>
          {(index) => {
            if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <PhoneNoCheck
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    country={country}
                    setCountry={setCountry}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    sSize={sSize}
                    setBlockIndex={setBlockIndex}
                    setVerifyNumber={setVerifyNumber}
                  />
                </animated.div>
              );
            }
            if (index === authBox_Block.PASSWORD_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <Password
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.REGISTRATION_BLOCK) {
              return (style) => (
                <animated.div className=" w-full" style={{ ...style }}>
                  <div>
                    <VerificationBox
                      loading={loading}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      setBlockIndex={setBlockIndex}
                      setVerifyNumber={setVerifyNumber}
                      sSize={sSize}
                    />
                    {/* {!verifyNumber ? (
											<RegistrationBlock
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												sSize={sSize}
												setBlockIndex={setBlockIndex}
											/>
										) : (
											<VerificationBox
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												setBlockIndex={setBlockIndex}
												setVerifyNumber={setVerifyNumber}
												sSize={sSize}
											/>
										)} */}
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.TOKEN) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <TokenBlock
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.CHANGE_PASS_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <SubmitPassBlock
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
            if (index === authBox_Block.CONGRESS_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <ChangedCongressBlock />
                  </div>
                </animated.div>
              );
            }
          }}
        </Transition>
      </div>
    </div>
  );
}

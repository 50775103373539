import React from "react";
import BannerContent from "./BannerContent";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import { useSelector } from "react-redux";
import ForgotPassContent from "./ForgotePassContent";

export default function BannerArea({ blockIndex, setBlockIndex }) {
  let contentType = useSelector((state) => state.auth.preUrl);

  return (
    <div className="relative banner-area-wrapper">
      <div className="pb-110 sm:pb-[100px] container m-auto  bg-F2F3F7">
        <div className="flex md:space-x-0 lg:space-x-10 relative z-10">
          <div className="w-5/12 hidden lg:block">
            <BannerSlider />
          </div>
          <div className="lg:w-7/12 w-full banner-content pb-[70px] lg:pb-[0]">
            {contentType ? (
              <ForgotPassContent />
            ) : (
              <BannerContent
                blockIndex={blockIndex}
                setBlockIndex={setBlockIndex}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

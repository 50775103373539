import {
  IonApp,
  IonButton,
  IonContent,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import User from "services/User";
import Check from "assets/images/icon/svg/Check";
import ImageUploader from "../../components/mobile-components/VerifyBusiness/ImageUploader";
import BackButton from "../../components/mobile-components/Common/BackButton";
import { setAllInfo } from "redux/container/userSlice";
import { toast } from "react-toastify";
import { getLocalUser } from "config/user";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function Nid() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const ekkUser = useSelector((state) => state.user.allInfo);
  const localUserPhone = getLocalUser()?.user?.phone_number;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [files1, setFiles1] = useState(ekkUser.nid_front_img);
  const [files2, setFiles2] = useState(ekkUser.nid_back_img);
  const dispatch = useDispatch();

  const nextHandler = async () => {
    let frontPart = new FormData();
    let backPart = new FormData();
    frontPart.append("key", files1[0]);
    backPart.append("key", files2[0]);
    setLoading(true);
    if (files1.length !== 0 && files2.length !== 0) {
      let response = await User.toBucketImage(frontPart);
      if (response.status === 201) {
        let response2 = await User.toBucketImage(backPart);
        if (response2.status === 201) {
          let data = {
            nidFront_image: response.results[0],
            nidBack_image: response2.results[0],
          };
          if (ekkUser?.id) {
            if (ekkUser?.phone_number) {
              User.putKycInfo(ekkUser.id, {
                nid_back_img: data.nidBack_image,
                nid_front_img: data.nidFront_image,
              }).then((res) => {
                if (res.success) {
                  let res = User.getKycInfo(ekkUser?.id);
                  res.then((r) => {
                    if (r.status === 200) {
                      setLoading(false);
                      dispatch(setAllInfo(r.results));
                      toast.success(
                        ekkLanguage.addressVerify.nidSuccessMessage
                      );
                      navigate("/address-verify");
                    }
                  });
                }
              });
            } else {
              User.putKycInfo(ekkUser.id, {
                nid_back_img: data.nidBack_image,
                nid_front_img: data.nidFront_image,
                phone_number: `${localUserPhone}`,
              }).then((res) => {
                if (res.success) {
                  let res = User.getKycInfo(ekkUser?.id);
                  res.then((r) => {
                    if (r.status === 200) {
                      setLoading(false);
                      dispatch(setAllInfo(r.results));
                      toast.success(
                        ekkLanguage.addressVerify.nidSuccessMessage
                      );
                      navigate("/address-verify");
                    }
                  });
                }
              });
            }
          } else {
            User.postKycInfo({
              nid_back_img: data.nidBack_image,
              nid_front_img: data.nidFront_image,
              phone_number: `${localUserPhone}`,
            }).then((response) => {
              if (response.success) {
                dispatch(setAllInfo(response.results));
                toast.success(ekkLanguage.addressVerify.nidSuccessMessage);
                navigate("/home");
              }
            });
          }
        }
      }
    }
  };
  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.addressVerify.mainTitle}
            sticky={true}
          ></BackButtonTitle>
          <div className="pt-20 pb-5 px-4 verifyBusiness-content">
            <div className="mb-5">
              <ImageUploader
                files={files1}
                setFiles={setFiles1}
                bHeight={200}
                placeHolder={ekkLanguage.addressVerify.nidPlaceholder1}
                placeHolder2={ekkLanguage.addressVerify.nidPlaceholder3}
                placeHolder3={ekkLanguage.addressVerify.nidPlaceholder4}
              />
            </div>
            <div>
              <ImageUploader
                files={files2}
                setFiles={setFiles2}
                bHeight={200}
                placeHolder={ekkLanguage.addressVerify.nidPlaceholder2}
                placeHolder2={ekkLanguage.addressVerify.nidPlaceholder3}
                placeHolder3={ekkLanguage.addressVerify.nidPlaceholder4}
              />
            </div>
            <div className="pt-3 px-4">
              {files1?.length && files2?.length ? (
                <div
                  className="rounded-[5px] text-center py-4"
                  style={{
                    background: "#CCFFD6",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <p
                    className="font-normal text-14 flex justify-center mb-[10px]"
                    style={{ color: "#808080" }}
                  >
                    <Check></Check> Upload Complete.
                  </p>
                  <p
                    className="font-normal text-14"
                    style={{ color: "#808080" }}
                  >
                    Please click "<span style={{ color: "#00B224" }}>NEXT</span>
                    " button
                  </p>
                </div>
              ) : (
                <div
                  className="rounded-[5px]"
                  style={{
                    background: "#FFFFCC",
                    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <p
                    className="font-normal p-[14px] text-14"
                    style={{ color: "#808080" }}
                  >
                    Please, upload your{" "}
                    <b className="text-primary font-semibold">“NID”</b> copy.
                  </p>
                </div>
              )}

              <div className="pt-20 text-center pb-10">
                <IonButton
                  disabled={loading ? true : false}
                  color="primary"
                  className="font-bold h-10 w-full"
                  onClick={!loading && nextHandler}
                >
                  {loading ? (
                    <>
                      <IonSpinner />
                    </>
                  ) : (
                    <>
                      {ekkLanguage.jobSelect.btnLabel}{" "}
                      <span className="pl-2">
                        <ArrayRight />
                      </span>
                    </>
                  )}
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

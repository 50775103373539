import React, { useRef, useState } from "react";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { useDispatch, useSelector } from "react-redux";
import { parseNumber } from "libphonenumber-js";
import Auth from "services/Auth";
import { useNavigate } from "react-router-dom";
import { setCreateUser } from "redux/container/userSlice";
import { toast } from "react-toastify";

export default function SentOtpV2({
  sSize,
  phoneNo,
  setPhoneNo,
  country,
  setCountry,
  countryCode,
  setCountryCode,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState("");

  var countryList = Object.values(COUNTRYINFO).map((val) => ({
    countryCode: val.code,
    country: val.name,
    value: val.code,
    label: (
      <div className="flex items-center">
        <img
          src={val.flag}
          className="sm:h-[32px] sm:w-[50px] h-auto w-5 rounded-[5px]"
          alt="flag"
        ></img>{" "}
        <p className="text-16 pl-2">{val.code}</p>
      </div>
    ),
  }));
  const handleSelectCountry = (e) => {
    setCountryCode(e.countryCode);
    setCountry(e.country);
    setWarning("");
  };
  let clickBtnRef = useRef(null);
  const _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      clickBtnRef.click();
    }
  };
  let customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 10,
      paddingLeft: 30,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      borderWidth: 0,
      height: sSize.matches ? 50 : 60,
      borderRadius: 0,
      display: "flex",
      fontSize: sSize.matches ? 16 : 24,
    }),
    control: (provided, state) => ({
      ...provided,
      color: "red",
      borderRadius: 15,
      borderWidth: 1,
      border: "1px solid #00000026",
      outLine: "none",
      height: sSize.matches ? 50 : 60,
      paddingLeft: sSize.matches ? 10 : 20,
      fontSize: sSize.matches ? 16 : 24,
      //   borderBottomLeftRadius: 5,
      //   borderTopLeftRadius: 5,
    }),
  };

  const errorMessage = () => {
    if (phoneNo) {
      if (country === "Bangladesh") {
        if (
          !(
            phoneNo.trim().length > 10 &&
            phoneNo.trim().length < 12 &&
            phoneNo[0] == 0
          )
        ) {
          return ekkLanguage?.login.validationLabel;
        } else {
          return "";
        }
      } else {
        if (
          !Object.keys(parseNumber(countryCode + phoneNo)).length &&
          phoneNo.trim().length > 0
        ) {
          return ekkLanguage?.lan.login.validationLabel;
        } else {
          return "";
        }
      }
    }
  };

  //submit country verificarion handler, it's verify first country and phone no.
  const submitPhoneNumberCountry = async (
    countryCode,
    country,
    phoneNumber
  ) => {
    if (country === "Bangladesh") {
      if (
        !(
          phoneNo.trim().length > 10 &&
          phoneNo.trim().length < 12 &&
          phoneNo[0] == 0
        )
      ) {
        // toast.error(ekkLanguage?.login.validationLabel);
        return;
      }
    }

    try {
      // startLoading();
      let req_body = {
        phone_number: (countryCode + phoneNo).trim(),
        country,
      };
      try {
        let response = await Auth.resendOptToken(req_body);
        if (response.success) {
          let data = {
            countryCode,
            country,
            phoneNumber,
            verify: false,
          };
          dispatch(setCreateUser(data));
          toast.success(ekkLanguage?.password.otpSendLabel);
          navigate("/submit-token");
        }
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      // stopLoading();
      setWarning(e.errorData.message);
      console.log(e);
    }
  };
  return (
    <div className="bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md lg:w-1/2 w-full">
      <label
        className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] block`}
      >
        {ekkLanguage?.landing.phoneNumber}
      </label>
      <div
        className="flex z-10 relative"
        // style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}
      >
        <Select
          styles={customStyles}
          className="basic-single w-[250px]"
          placeholder="+88"
          name="color"
          options={countryList}
          onChange={(e) => handleSelectCountry(e)}
          components={{
            IndicatorSeparator: () => null,
          }}
          style={{ fontSize: sSize.matches ? 14 : 20 }}
          defaultValue={{
            countryCode: "+88",
            country: "Bangladesh",
            value: "+88",
            label: (
              <div className="flex items-center">
                <img
                  src="https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png"
                  className="h-[20px] lg:h-auto lg:w-10 w-5 rounded-[5px]"
                  alt="flag"
                ></img>{" "}
                <p className="text-14 pl-2">+88</p>
              </div>
            ),
          }}
        />

        <input
          //   ref={numberInputRef}
          type="number"
          onKeyPress={_handleKeyPress}
          onChange={(event) => {
            // setSpamE("");
            setPhoneNo(event.target.value);
          }}
          placeholder={ekkLanguage?.landing.phoneNumberPlaceHolder}
          id={"ekk-input"}
          style={{
            marginLeft: 10,
            paddingLeft: 10,
            height: sSize.matches ? 50 : 60,
            flex: "flex: 1 0 auto",
            borderRadius: 15,
            borderWidth: 1,
            border: "1px solid #00000026",
          }}
          className="appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white"
        />
      </div>
      <div className="pt-4">
        <p className="text-red text-xs text-primary">{errorMessage()}</p>
        <p className="text-red text-xs text-primary">{warning}</p>
      </div>

      <button
        onClick={() => {
          submitPhoneNumberCountry(countryCode, country, phoneNo);
        }}
        // propsRef={(input) => {
        // 	clickBtnRef = input;
        // }}
        style={{
          background:
            "linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
        }}
        className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] mt-4`}
      >
        {ekkLanguage?.landing.requestOtp}
      </button>
      {/* <div className="text-left pt-4">
        <p className="font-bold text-14">
          {ekkLanguage?.login?.langChange}
          <span
            className={`${
              getLanguage() == "en" ? "text-success" : "text-black-1000"
            }  mx-1 cursor-pointer`}
            onClick={() => {
              setLanguage("en");
              dispatch(selectLan());
            }}
          >
            English
          </span>{" "}
          <span
            className={`${
              getLanguage() == "bn" ? "text-success" : "text-black-1000"
            }  mx-1 cursor-pointer`}
            onClick={() => {
              setLanguage("bn");
              dispatch(selectLan());
            }}
          >
            বাংলা
          </span>
        </p>
      </div> */}
    </div>
  );
}

import React, { useEffect, useState } from "react";

export default function Banner({ businessData }) {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (businessData?.images && businessData?.images.length) {
      setImage(`${businessData?.images[0]}`);
    } else {
      setImage(`${businessData?.business_type_obj?.image}`);
    }
  }, [businessData]);

  return (
    <div className="relative bg-white">
      {image ? (
        <img
          src={image}
          alt=""
          style={{ height: 250, objectFit: "contain", margin: "auto" }}
        />
      ) : (
        <img
          src="/assets/images/business/brand.png"
          alt=""
          style={{ height: 100, objectFit: "contain", margin: "auto" }}
        />
      )}
    </div>
  );
}

import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import { Input } from "components/global-components/common/Input";
import { updateToken } from "config/SuperFetch";
import { getLocalUser, getUser } from "config/user";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "services/Auth";

export default function ChangePassword() {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [error, setError] = useState("");
	const history = useNavigate();
	const appUser = useSelector((state) => state.user.data);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let passwordSubmitHandler = async () => {
		console.log(password, confirmPassword);
		if (password && confirmPassword) {
			if (password === confirmPassword) {
				let req_body = {
					password: password,
					country: appUser.country,
					phone_number: appUser.phone_number.trim(),
				};

				try {
					let response = await Auth.resetPasswordWhenLogdIn(req_body);
					if (response.status === 200 || response.status === 201) {
						toast.success(ekkLanguage.changePassword.successLabel);
						// updateToken(response);
						history("/changed-password-success");
						setError("");
					}
				} catch (e) {
					console.log(e);
				}
			} else {
				// toast.warn("Your password and confirm password not match.");
				setError(ekkLanguage.changePassword.passNotMatchLabel);
			}
		}
	};

	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-10'>
							<img src='assets/images/password.png' alt='' className='m-auto' />
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex '>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 mb-1 font-semibold'>
									{ekkLanguage.changePassword.passwordLabel}
								</h5>
								
									<Input
										type={!showPass ? "password" : "text"}
										value={password}
										name='password'
										placeholder={ekkLanguage.changePassword.passwordPlaceholder}
										onChange={(e) => setPassword(e.target.value)}
										
									></Input>
								
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex '>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 mb-1 font-semibold'>
									{ekkLanguage.changePassword.confirmPassLabel}
								</h5>

								<Input
									type={!showConfirmPass ? "password" : "text"}
									value={confirmPassword}
									placeholder={
										ekkLanguage.changePassword.confirmPassPlaceholder
									}
									name='confirmPassword'
									onChange={(e) => setConfirmPassword(e.target.value)}
								></Input>
								{/* <IonIcon
										name={!showConfirmPass ? "eye-off" : "eye"}
										onClick={() => {
											setShowConfirmPass(!showConfirmPass);
										}}
									></IonIcon> */}

								{error && (
									<div className='text-primary mt-2 text-right'>{error}</div>
								)}
							</div>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper mt-[180px]'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									onClick={passwordSubmitHandler}
									expand='full'
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'
								>
									{ekkLanguage.changePassword.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}

import { IonButton, IonSpinner } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import Calendar from "assets/images/icon/svg/Calendar";
import { Input } from "components/global-components/common/Input";
import SelectInput from "components/global-components/common/SelectInput";
import { convertToNumber, formatAndFilterInput } from "helpers/helper";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function OrderForcastForm({
  ekkLanguage,
  handleSubmit,
  loading,
  selectedSupplier,
  buyerId,
}) {
  const [supplierError, setSupplierError] = useState("");
  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryDateError, setDeliveryDateError] = useState("");
  const [orderAmount, setOrderAmount] = useState("");
  const [orderAmountError, setOrderAmountError] = useState("");
  const [financeSupport, setFinanceSupport] = useState("");
  const [financeSupportError, setFinanceSupportError] = useState("");
  let dateChanger = (e) => {
    setDeliveryDate(e);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      ref={ref}
      className={`bg-white pl-3 py-[15px] block rounded-md mt-2 border example-custom-input cursor-pointer ${
        deliveryDate ? "border-1 border-success" : "border"
      } `}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <span>
            <Calendar color={deliveryDate ? "#36a94d" : "black"} />
          </span>
          <p
            className={`text-14  ${
              deliveryDate ? "text-success font-bold" : "text-gray-400"
            } ml-2`}
          >
            {deliveryDate
              ? moment(deliveryDate).format("DD-MM-YYYY")
              : ekkLanguage.orderForecast.deliveryDatePlaceholder}
          </p>
        </div>
        <div>
          <Add />
        </div>
      </div>
    </div>
  ));
  return (
    <>
      {/* Supplier Selection  */}
      <SelectInput
        label={{
          label: ekkLanguage.orderForecast.supplierLabel,
          //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
        }}
        navigationLink="/select-supplier"
        // star
        type="text"
        name="selectedSupplier"
        value={selectedSupplier?.supplierName}
        placeholder={ekkLanguage.orderForecast.supplierPlaceholder}
        errorMessage={supplierError}
        showInputIcon={true}
        inputIcon={
          selectedSupplier?.id
            ? "/assets/images/supplier_for_input_green.png"
            : "/assets/images/supplier_for_input.png"
        }
      />
      {/* Delivery Date  */}
      <div className="flex mt-4">
        <label className="flex tracking-wide text-grey-darker text-16 font-bold  ml-[3px]">
          {ekkLanguage.orderForecast.deliveryDateLabel}
        </label>
      </div>
      <DatePicker
        minDate={moment().toDate()}
        selected={
          deliveryDate
            ? moment(deliveryDate)._d
            : moment(new Date()).add(1, "days")._d
        }
        onChange={(date) => dateChanger(date)}
        customInput={<ExampleCustomInput />}
        withPortal
      />
      <div className="flex items-center pl-1">
        {deliveryDateError && (
          <p className="text-primary mt-1 text-xs font-bold">
            {deliveryDateError}{" "}
          </p>
        )}
      </div>
      {/* Order Amount  */}
      <div className="mt-4">
        <Input
          label={{
            label: ekkLanguage.orderForecast.orderAmountLabel,
            //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
          }}
          onChange={(event) => {
            const inputValue = event.target.value;
            const formattedValue = formatAndFilterInput(inputValue);
            setOrderAmount(formattedValue);
          }}
          // star
          type="text"
          name="orderAmount"
          value={orderAmount}
          placeholder={ekkLanguage.orderForecast.orderAmountPlaceholder}
          errorMessage={orderAmountError}
          currencyShow
        />
      </div>
      {/* Finance Support  */}
      <div className="mt-4">
        <Input
          label={{
            label: ekkLanguage.orderForecast.financeSupportLabel,
            //   source: "/assets/images/ekkhero/Icons/location-pin1.png",
          }}
          onChange={(event) => {
            const inputValue = event.target.value;
            const formattedValue = formatAndFilterInput(inputValue);
            setFinanceSupport(formattedValue);
          }}
          // star
          type="text"
          name="financeSupport"
          value={financeSupport}
          placeholder={ekkLanguage.orderForecast.financeSupportPlaceholder}
          errorMessage={financeSupportError}
          currencyShow
        />
      </div>{" "}
      <IonButton
        type="submit"
        disabled={loading ? true : false}
        onClick={() => {
          if (selectedSupplier) {
            setSupplierError("");
            if (deliveryDate) {
              setDeliveryDateError("");
              if (orderAmount) {
                setOrderAmountError("");
                if (financeSupport) {
                  setFinanceSupportError("");
                  const data = {
                    order_forecast: {
                      order_type: "Future Order",
                      supplier: Number(selectedSupplier?.id),
                      buyer: Number(buyerId),
                      order_amount: convertToNumber(orderAmount),
                      financial_support_amount: convertToNumber(financeSupport),
                      status: "Scheduled",
                      expected_order_date:
                        moment(deliveryDate).format("YYYY-MM-DD"),
                      origin: "EkkHero",
                    },
                  };
                  handleSubmit(data);
                } else {
                  setFinanceSupportError(
                    ekkLanguage.orderForecast.financeSupportError
                  );
                }
              } else {
                setOrderAmountError(ekkLanguage.orderForecast.orderAmountError);
              }
            } else {
              setDeliveryDateError(ekkLanguage.orderForecast.deliveryDateError);
            }
          } else {
            setSupplierError(ekkLanguage.orderForecast.supplierError);
          }
        }}
        color={"success"}
        expand="full"
        className="font-bold h-10 mt-64 mb-5 w-full"
      >
        {loading ? (
          <IonSpinner></IonSpinner>
        ) : (
          <span className="text-white">{ekkLanguage.orderForecast.btnLabel}</span>
        )}
      </IonButton>
    </>
  );
}

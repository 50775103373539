import React from "react";

export default function EditBusinessProfile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_1728_254)">
        <path
          d="M19.2502 3.66667H16.4085C16.1957 2.63213 15.6328 1.70257 14.8146 1.03466C13.9965 0.366749 12.973 0.00133331 11.9168 0L10.0835 0C9.02731 0.00133331 8.00387 0.366749 7.18568 1.03466C6.3675 1.70257 5.80458 2.63213 5.59183 3.66667H2.75016C2.50705 3.66667 2.27389 3.76324 2.10198 3.93515C1.93007 4.10706 1.8335 4.34022 1.8335 4.58333C1.8335 4.82645 1.93007 5.05961 2.10198 5.23151C2.27389 5.40342 2.50705 5.5 2.75016 5.5H3.66683V17.4167C3.66829 18.6318 4.15164 19.7967 5.01086 20.656C5.87009 21.5152 7.03503 21.9985 8.25016 22H13.7502C14.9653 21.9985 16.1302 21.5152 16.9895 20.656C17.8487 19.7967 18.332 18.6318 18.3335 17.4167V5.5H19.2502C19.4933 5.5 19.7264 5.40342 19.8983 5.23151C20.0703 5.05961 20.1668 4.82645 20.1668 4.58333C20.1668 4.34022 20.0703 4.10706 19.8983 3.93515C19.7264 3.76324 19.4933 3.66667 19.2502 3.66667ZM10.0835 1.83333H11.9168C12.4854 1.83403 13.0399 2.01059 13.5041 2.33882C13.9684 2.66705 14.3198 3.13087 14.5101 3.66667H7.49025C7.68053 3.13087 8.03191 2.66705 8.49618 2.33882C8.96046 2.01059 9.51491 1.83403 10.0835 1.83333ZM16.5002 17.4167C16.5002 18.146 16.2104 18.8455 15.6947 19.3612C15.179 19.8769 14.4795 20.1667 13.7502 20.1667H8.25016C7.52082 20.1667 6.82134 19.8769 6.30562 19.3612C5.78989 18.8455 5.50016 18.146 5.50016 17.4167V5.5H16.5002V17.4167Z"
          fill="#F94B41"
        />
        <path
          d="M9.16667 16.5C9.40978 16.5 9.64294 16.4034 9.81485 16.2315C9.98676 16.0596 10.0833 15.8265 10.0833 15.5833V10.0833C10.0833 9.84022 9.98676 9.60707 9.81485 9.43516C9.64294 9.26325 9.40978 9.16667 9.16667 9.16667C8.92355 9.16667 8.69039 9.26325 8.51849 9.43516C8.34658 9.60707 8.25 9.84022 8.25 10.0833V15.5833C8.25 15.8265 8.34658 16.0596 8.51849 16.2315C8.69039 16.4034 8.92355 16.5 9.16667 16.5Z"
          fill="#F94B41"
        />
        <path
          d="M12.8332 16.5C13.0763 16.5 13.3094 16.4034 13.4814 16.2315C13.6533 16.0596 13.7498 15.8265 13.7498 15.5833V10.0833C13.7498 9.84022 13.6533 9.60707 13.4814 9.43516C13.3094 9.26325 13.0763 9.16667 12.8332 9.16667C12.5901 9.16667 12.3569 9.26325 12.185 9.43516C12.0131 9.60707 11.9165 9.84022 11.9165 10.0833V15.5833C11.9165 15.8265 12.0131 16.0596 12.185 16.2315C12.3569 16.4034 12.5901 16.5 12.8332 16.5Z"
          fill="#F94B41"
        />
      </g>
      <defs>
        <clipPath id="clip0_1728_254">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

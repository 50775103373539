import { digitChanger } from "helpers/helper";
import React from "react";
import Card from "../Common/Card";

export default function ContactInfo({
  businessData,
  ekkLanguage,
  contactData,
}) {
  return (
    <Card title={ekkLanguage.customerDetails.contactCardTitle}>
      <div className="px-1 flex flex-col w-full">
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.number}
          </div>
          <div className="text-000080 text-14 font-medium">
            {digitChanger(businessData?.phone_number)}
          </div>
        </div>
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.role}
          </div>
          <div className="text-000080 text-14 font-medium">
            {contactData?.role}
          </div>
        </div>
      </div>
    </Card>
  );
}

import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CustomerDetail from "components/mobile-components/CustomerDetails";
import CustomerDetailsBottomSheet from "components/mobile-components/CustomerDetails/CustomerDetailsBottomSheet";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setRelationDetails } from "redux/container/customerSlice";
import { setCustomerDetails } from "redux/container/userSlice";
import Contract from "services/Contract";

export default function CustomerDetailsPage() {
  const { id } = useParams();
  let [businessInfoData, setBusinessInfoData] = useState({});
  const [contactData, setContactData] = useState({});
  const [contractData, setContractData] = useState({});
  const [visitData, setVisitData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [visitPage, setVisitPage] = useState(2);
  const [visitCount, setVisitCount] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const dispatch = useDispatch();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const ekkLanguage = useSelector((state) => state.lan.lan);

  let getBusinessInfoDetails = async () => {
    setLoading(true);
    let response = await Contract.getBusinessInfoDetails(id);
    if (response.status === 200 || response.status === 201) {
      setBusinessInfoData(response?.results);
      dispatch(setRelationDetails(response.results));
      let response2 = await Contract.getBusinessContactDetails(
        response?.results?.business?.id
      );
      if (response2.status === 200 || response2.status === 201) {
        setContactData(response2.results);
      } else {
        setLoading(false);
      }
      let response4 = await Contract.getVisitDetails(id);
      if (response4.status === 200 || response4.status === 201) {
        setVisitData(response4.results);
        setVisitCount(response4.count);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBusinessInfoDetails();
    dispatch(setCustomerDetails(false));
  }, []);

  let loadMoreData = async () => {
    if (hasMoreData) {
      let response = await Contract.getVisitDetails(id, visitPage);
      if (response.status === 200 || response.status === 201) {
        if (response?.results?.length) {
          setVisitPage((prev) => prev + 1);
          setVisitData([...visitData, ...response?.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(false);
      }
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={ekkLanguage.customerList.detailsPageTitle}
            //   burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="customerDetails"
          />
          <section>
            <CustomerDetail
              loading={loading}
              businessData={businessInfoData}
              contactData={contactData}
              contractData={contractData}
              visitData={visitData}
              visitCount={visitCount}
              ekkLanguage={ekkLanguage}
            />
          </section>

          {contactData?.contractinfo && (
            <CustomerDetailsBottomSheet
              id={id}
              contactDetails={contactData}
              getBusinessInfoDetails={getBusinessInfoDetails}
              businessInfoData={businessInfoData}
            />
          )}
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.learning.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import React from "react";

export default function Bell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_540_353)">
        <path
          d="M22.7545 16.2447L20.2109 6.92657C19.6677 4.93331 18.4842 3.18198 16.8486 1.95131C15.2131 0.720648 13.2196 0.0813704 11.1853 0.135224C9.15102 0.189078 7.19292 0.932969 5.62264 2.2485C4.05236 3.56404 2.96013 5.37561 2.5198 7.39491L0.552385 16.3938C0.457186 16.8298 0.459508 17.2819 0.559179 17.7168C0.65885 18.1518 0.853327 18.5584 1.12826 18.9067C1.4032 19.2551 1.75157 19.5362 2.14769 19.7295C2.5438 19.9227 2.97755 20.0231 3.41692 20.0232H6.94574C7.17029 21.1471 7.77021 22.1575 8.64387 22.8833C9.51752 23.6091 10.6112 24.0056 11.7395 24.0056C12.8679 24.0056 13.9616 23.6091 14.8352 22.8833C15.7089 22.1575 16.3088 21.1471 16.5333 20.0232H19.9281C20.3802 20.023 20.8262 19.9166 21.2311 19.7124C21.6361 19.5082 21.9892 19.2117 22.2629 18.8459C22.5366 18.4802 22.7234 18.0551 22.8089 17.6039C22.8943 17.1527 22.8761 16.6875 22.7555 16.2447H22.7545ZM11.7395 22.0119C11.1347 22.0093 10.5454 21.8169 10.0522 21.4609C9.55908 21.105 9.18619 20.6028 8.98458 20.0232H14.4945C14.2929 20.6028 13.92 21.105 13.4269 21.4609C12.9337 21.8169 12.3444 22.0093 11.7395 22.0119ZM20.7059 17.6417C20.6146 17.7647 20.4963 17.8643 20.3604 17.9325C20.2245 18.0007 20.0748 18.0356 19.9232 18.0345H3.41692C3.27043 18.0345 3.12581 18.001 2.99375 17.9366C2.86168 17.8721 2.74553 17.7784 2.65388 17.6622C2.56223 17.5461 2.49741 17.4105 2.46422 17.2655C2.43102 17.1204 2.43029 16.9697 2.46208 16.8244L4.42949 7.82546C4.77616 6.24067 5.63411 4.81914 6.86695 3.78685C8.09978 2.75456 9.63675 2.17075 11.2335 2.12824C12.8303 2.08572 14.3952 2.58693 15.6796 3.5522C16.9639 4.51747 17.8939 5.8914 18.3217 7.45557L20.8654 16.7736C20.9069 16.921 20.9141 17.0761 20.8864 17.2268C20.8588 17.3775 20.7969 17.5195 20.7059 17.6417Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_540_353">
          <rect
            width="23.4798"
            height="23.8645"
            fill="white"
            transform="translate(0 0.13562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

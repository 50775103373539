import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectors } from "redux/container/authSlice";

export default function PrivateOutlet({ children }) {
	const { loginStatus } = useSelector(selectors.getAuth);
	// const { loginStatus } = false;
	return loginStatus ? <Outlet /> : <Navigate to='/' />;
}

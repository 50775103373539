import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonButton,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useDispatch, useSelector } from "react-redux";
import BrandList from "components/mobile-components/BrandList";
import Search from "components/mobile-components/Common/Search";
import Brand from "services/Brand";
import { setBrandList } from "redux/container/customerSlice";
import { useNavigate } from "react-router-dom";

export default function BrandListPage() {
  const navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const brandList = useSelector((state) => state.customer.brandList);
  const selectedBrandList = useSelector(
    (state) => state.customer.selectedBrands
  );
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [searchQ, setSearchQ] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [pageCount, setPageCount] = useState(2);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const getAllBrandList = async () => {
    setReachedEnd(false);
    setHasMoreData(true);
    setPageCount(2);
    if (searchQ) {
      let response = await Brand.brandsSearch(searchQ);
      if (response.status === 200) {
        setLoading(false);
        dispatch(setBrandList(response.results));
      } else {
        setLoading(false);
      }
    } else {
      let response = await Brand.getBrandList();
      if (response.status === 200) {
        dispatch(setBrandList(response.results));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  let lodeMoreData = async () => {
    if (hasMoreData) {
      if (searchQ) {
        const response = await Brand.brandsSearch(searchQ, pageCount);
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          dispatch(setBrandList([...brandList, ...response.results]));
        } else {
          setHasMoreData(false);
        }
      } else {
        const response = await Brand.getBrandList(pageCount);
        if (response.status === 200) {
          setPageCount((prev) => prev + 1);
          dispatch(setBrandList([...brandList, ...response.results]));
        } else {
          setHasMoreData(false);
        }
      }
    }
  };

  let singleBrandSearch = async () => {
    let response = await Brand.brandsSearch(searchQ);
    if (response.status === 200) {
      dispatch(setBrandList(response.results));
    }
  };

  useEffect(() => {
    if (searchQ === "") {
      getAllBrandList();
    }
  }, [searchQ]);

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <LeftSidebar
            isMobile={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          <BackButtonTitle
            title={"Select Brands"}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
            from="brandListPage"
            isHide={false}
          />
          <section className="pt-20 pb-20 px-4 relative">
            <Search
              search={searchQ}
              setSearch={setSearchQ}
              ekkLanguage={ekkLanguage}
              placeholder={"Search Brand by Name"}
              singleSearch={singleBrandSearch}
              filter={false}
              // filterHandler={filterHandler}
              // selectedFilter={selectedFilter}
              // setTempSearchParams={setTempSearchParams}
              setReachedEnd={setReachedEnd}
              setHasMoreData={setHasMoreData}
            />
            {loading ? (
              <IonLoading
                isOpen={true}
                message={ekkLanguage.customerList.loader}
              ></IonLoading>
            ) : (
              <BrandList
                brandList={brandList}
                ekkLanguage={ekkLanguage}
                selectedBrandList={selectedBrandList}
              ></BrandList>
            )}
            {brandList?.length > 0 && (
              <div className="px-4 fixed bg-white h-[80px] w-full bottom-0 left-0 flex items-center">
                <IonButton
                  onClick={() => {
                    navigate("/create-business-info");
                  }}
                  expand="full"
                  className="bg-success rounded-[7px] font-extrabold text-12 h-10 w-full"
                >
                  {ekkLanguage.changePassword.changePassContinueBtn}
                </IonButton>
              </div>
            )}
          </section>
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              //controll loading
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              lodeMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.customerList.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "redux/container/authSlice";

export default function ChangePasswordSuccess() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-10 text-center'>
							<img
								src='assets/images/mobile-phone.png'
								alt=''
								className='m-auto mb-5'
							/>
							<h6 className='text-16 color-black-1000 font-medium'>
								{ekkLanguage.changePassword.changePassSuccessPara}
							</h6>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper mt-[360px]'>
					<IonRow>
						<IonCol size='12'>
							<Link
								className='text-center'
								onClick={() => {
									dispatch(logout());
								}}
								to='/#'>
								<IonButton
									expand='full'
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'>
									{/* <IonSpinner name='crescent' /> Save */}
									{ekkLanguage.changePassword.changePassContinueBtn}
								</IonButton>
							</Link>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}

import {
  IonApp,
  IonContent,
  IonImg,
  IonPage,
  IonText,
  IonTitle,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import "@ionic/react/css/ionic.bundle.css";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";

export default function Refer() {
  const ekkLanguage = useSelector((state) => state.lan.lan);

  function copyInputValue() {
    // Get the input field by its ID
    var input = document.getElementById("refer-code");

    // Create a temporary input element
    var tempInput = document.createElement("input");

    // Set the value of the temporary input element to the value of the original input field
    tempInput.value = input.value;

    // Append the temporary input element to the DOM
    document.body.appendChild(tempInput);

    // Select the value in the temporary input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text
    document.execCommand("copy");

    // Remove the temporary input element from the DOM
    document.body.removeChild(tempInput);

    // Show the user that the value has been copied
    console.log("Copied & now you can test it by paste");
  }

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <BackButtonTitle
            title={ekkLanguage.profile.referNavTitle}
          ></BackButtonTitle>
          <div className="pt-20 px-5">
            <div className="flex flex-col items-center">
              <IonImg
                className="w-44 h-44"
                src="/assets/images/ekkhero/images/refer.png"
                alt="refer"
              />
              <IonTitle className="mt-10 font-bold text-success text-24">
                {ekkLanguage.profile.referTitle}
              </IonTitle>
              <IonText className="font-semibold text-14 text-[#00000080] mt-[10px]">
                {ekkLanguage.profile.referSubtitle}
              </IonText>
              <div className="relative flex mt-[30px]">
                <input
                  id="refer-code"
                  disabled
                  value={"EkkBaz1204"}
                  type="text"
                  className="w-full h-[50px] rounded-l-md px-4 py-2 focus:outline-none underline underline-offset-3 bg-white font-bold text-24 cursor-pointer"
                />
                <button
                  onClick={() => {
                    copyInputValue();
                  }}
                  className="absolute right-0 top-0 bottom-0 rounded-r-md px-4 flex items-center justify-center"
                >
                  <img
                    className="w-6 h-6"
                    src="/assets/images/ekkhero/Icons/copy.png"
                    alt="copy"
                  />
                </button>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

import FooterV2 from "pages/LandingPageV2/FooterV2";
import LandingNavBar from "pages/LandingPageV2/LandingNavBar";
import SingleTask from "pages/LandingPageV2/SingleTask";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Task from "services/Task";

export default function TaskListPage() {
	const [taskList, setTaskList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const getListBlog = async () => {
		setLoading(true);
		let response = await Task.getTasksPublic(page);
		if (response.status === 200) {
			setLoading(false);
			setPage(page + 1);
			setTaskList([...taskList, ...response.results]);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		getListBlog();
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
	}, []);

	return (
		<>
			<LandingNavBar />

			<div className='mt-20' style={{ paddingBottom: 100 }}>
				{!loading ? (
					<div className='pt-[100px] sm:pt-[120px] pb-24'>
						<div className='container'>
							<div className='grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3'>
								{Array.isArray(taskList) &&
									taskList?.map((el, i) => {
										return <SingleTask item={el} key={i} />;
									})}
							</div>
							<div className='text-center pt-10'>
								<button
									onClick={() => getListBlog(page)}
									className={`pointer text-white text-18 font-extrabold px-5 py-2 rounded-[10px] pwa-download-btn`}>
									{ekkLanguage.landing.seeMore}
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className='pt-[100px] sm:pt-[150px] pb-24'>
						<div className='m-auto w-full lg:w-8/12'>
							<div className='flex h-full justify-center items-center'>
								<div className='lds-facebook'>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<FooterV2 />
		</>
	);
}

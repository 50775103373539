import { digitChanger, monthChanger } from "helpers/helper";
import React from "react";
import Card from "../Common/Card";
import moment from "moment";
import { IonButton } from "@ionic/react";
import { setCheckInDetails } from "config/localContract";
import { useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";

export default function VisitInfo({ visitData, ekkLanguage, visitCount }) {
  const navigate = useNavigate();
  return (
    visitData?.length > 0 && (
      <Card
        title={ekkLanguage.customerDetails.visitCardTitle}
        secondTitle={digitChanger(visitCount) ?? ""}
      >
        <div className="px-1 flex flex-col w-full">
          {visitData?.length > 0
            ? visitData.map((visit) => {
                return (
                  <div key={visit?.id}>
                    <div className="flex justify-between w-full py-[7px]">
                      <div className="text-000000 text-14 font-semibold">
                        {ekkLanguage.customerDetails.checkIn}
                      </div>
                      <div className="text-000080 text-14 font-medium">
                        {digitChanger(
                          moment(visit?.check_in_time).utc().format("h:mm A")
                        )}
                      </div>
                    </div>
                    {visit?.check_out_time && (
                      <div className="flex justify-between w-full py-[7px]">
                        <div className="text-000000 text-14 font-semibold">
                          {ekkLanguage.customerDetails.checkOut}
                        </div>
                        <div className="text-000080 text-14 font-medium">
                          {digitChanger(
                            moment(visit?.check_out_time).utc().format("h:mm A")
                          )}
                        </div>
                      </div>
                    )}
                    {visit?.remark && (
                      <div className="flex justify-between w-full py-[7px]">
                        <div className="text-000000 text-14 font-semibold">
                          {ekkLanguage.customerDetails.remark}
                        </div>
                        <div className="text-000080 text-14 font-medium">
                          {visit?.remark}
                        </div>
                      </div>
                    )}
                    <div className="flex justify-between w-full py-[7px]">
                      <div className="text-000000 text-14 font-semibold">
                        {ekkLanguage.customerDetails.visitType}
                      </div>
                      <div className="text-000080 text-14 font-medium">
                        {visit?.visit_type}
                      </div>
                    </div>
                    <div className="flex justify-between w-full py-[7px]">
                      <div className="text-000000 text-14 font-semibold">
                        {ekkLanguage.customerDetails.createdBy}
                      </div>
                      <div className="text-000080 text-14 font-medium">
                        {visit?.created_by_user?.full_name}
                      </div>
                    </div>
                    <div className="flex justify-between w-full py-[7px]">
                      <div className="text-000000 text-14 font-semibold">
                        {ekkLanguage.customerDetails.createdAt}
                      </div>
                      <div className="text-000080 text-14 font-medium">
                        {digitChanger(
                          monthChanger(
                            moment(visit?.createdAt).format("DD MMM, YY")
                          )
                        )}
                      </div>
                    </div>
                    <div
                      className={`flex ${
                        !visit?.check_out_time
                          ? "justify-between"
                          : "justify-between"
                      } w-full py-[7px] h-full`}
                    >
                      <div className="text-000000 text-14 font-semibold">
                        {ekkLanguage.customerDetails.image}
                      </div>

                      <img
                        src={visit?.check_in_image}
                        alt="visitImage"
                        className="w-24 h-24 rounded-lg ml-4"
                        style={{
                          border: "0.5px solid rgba(0, 0, 0, 0.3)",
                          background: "#E6E6E6",
                        }}
                      />
                      {visit?.check_out_image && (
                        <img
                          src={visit?.check_out_image}
                          alt="visitImage"
                          className="w-24 h-24 ml-4 rounded-lg"
                          style={{
                            border: "0.5px solid rgba(0, 0, 0, 0.3)",
                            background: "#E6E6E6",
                          }}
                        />
                      )}

                      {!visit?.check_out_time && (
                        <div className="h-full flex pt-14 items-center">
                          <IonButton
                            color="primary"
                            className="font-bold rounded-lg flex items-center text-14 h-8"
                            size="small"
                            onClick={() => {
                              setCheckInDetails(visit);
                              navigate("/customer-check-out");
                            }}
                          >
                            {ekkLanguage.checkIn.checkOutBtnTitle}
                            <span className="ml-1">
                              <ArrayRight/>
                            </span>
                          </IonButton>
                        </div>
                      )}
                    </div>
                    {visitData?.length > 1 && (
                      <div className="border-[1px] border-black-333 mt-1 mb-5 rounded-lg w-full"></div>
                    )}
                  </div>
                );
              })
            : ""}
        </div>
      </Card>
    )
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-marker {
  color: #fff;
  background: #4a0089;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/global-components/common/map.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,gBAAA;EACA,YAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".cluster-marker {\n\tcolor: #fff;\n\tbackground: #4a0089;\n\tborder-radius: 50%;\n\tpadding: 10px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.crime-marker {\n\tbackground: none;\n\tborder: none;\n}\n\n.crime-marker img {\n\twidth: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import {
  IonApp,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
} from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import Recommendation from "components/mobile-components/HomePage/Recommendation";
import { getLanguage } from "config/language";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Blog from "services/Blog";

function TaskList() {
  const contentRef = useRef();
  const localLanguage = getLanguage();
  const [initHeight] = useState(72);
  const [initStickyClass, setInitStikyClass] = useState("issticky");
  const [menuCollapse, setMenuCollapse] = useState(true);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [pageCount, setPageCount] = useState(2);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [reachedEnd, setReachedEnd] = useState(false);
  const [loading, setLoading] = useState(true);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };
  const [jobList, setJobList] = useState([]);

  // Job
  useEffect(() => {
    Blog.getLatestJobList(1, localLanguage === "bn" ? "BN" : "EN").then(
      (res) => {
        setJobList(res.results);
        setLoading(false);
      }
    );
  }, [localLanguage]);

  let loadMoreData = async () => {
    if (hasMoreData) {
      const response = await Blog.getLatestJobList(
        pageCount,
        localLanguage === "bn" ? "BN" : "EN"
      );
      if (response.status === 200) {
        if (response.results.length) {
          setPageCount((prev) => prev + 1);
          setJobList([...jobList, ...response?.results]);
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(false);
      }
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent
          scrollEvents={true}
          ref={contentRef}
          onIonScroll={async (ev) => {
            const scrollPosition = ev.detail.scrollTop;
            if (scrollPosition > initHeight) {
              setInitStikyClass("issticky");
            } else {
              setInitStikyClass("issticky");
            }
          }}
        >
          <BackButtonTitle
            title={ekkLanguage.letNav.taskMenuLabel}
            burgerMenu={true}
            menuCollapse={menuCollapse}
            menuIconClick={menuIconClick}
          />
          {/* Job  */}
          {loading && (
            <IonLoading isOpen={true} message={ekkLanguage.learning.loader} />
          )}
          <section className="pt-16">
            <Recommendation jobList={jobList}></Recommendation>
          </section>

          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              if (reachedEnd) {
                ev.target.complete();
                return;
              }
              loadMoreData();
              setTimeout(() => {
                ev.target.complete();
                if (!hasMoreData) {
                  setReachedEnd(true);
                }
              }, 2000);
            }}
          >
            <IonInfiniteScrollContent
              loadingText={ekkLanguage.learning.loader}
              loadingSpinner={hasMoreData ? "bubbles" : null}
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
        <NavFooter />
      </IonPage>
    </IonApp>
  );
}

export default TaskList;

import { IonButton } from "@ionic/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Blog from "services/Blog";

function Recommendation({ jobList }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const navigate = useNavigate();

  return (
    <div className="px-4">
      {jobList?.length > 0 ? (
        jobList?.map((job) => {
          return (
            <div key={job._id} className="mt-3 shadow-md px-2 pb-4 rounded-md">
              <img
                onClick={() => {
                  navigate(`/job-details/${job.id}`);
                }}
                className="rounded-lg w-full cursor-pointer"
                src={job?.thumbnail_image}
                alt="job-poster"
              />
              <div className="mt-[10px] ">
                <p
                  onClick={() => {
                    navigate(`/job-details/${job.id}`);
                  }}
                  className="font-bold text-16 "
                >
                  {job.title}
                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      job?.content.length > 250
                        ? job?.content.slice(0, 250) +
                          `<span class='text-[#0099CC] font-semibold cursor-pointer'> ${ekkLanguage.others.readMore}</span>`
                        : job?.content,
                  }}
                  onClick={() => {
                    if (job?.content?.length > 400) {
                      navigate(`/job-details/${job.id}`);
                    }
                  }}
                  className=" mt-[10px]"
                ></div>
              </div>
            </div>
          );
        })
      ) : (
        <div
          className="text-center w-full  flex items-center justify-center"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <div>
            <img
              className="m-auto mb-[10px] mt-5"
              src="/assets/images/not-found-file.png"
              alt="emptyCard"
            />
            <p className="text-16 font-normal text-222222 mb-[35px]">
              {ekkLanguage.learning.notFound}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Recommendation;

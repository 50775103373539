import { baseUrl } from "config/apiUrl";
import SuperFetch from "config/SuperFetch";
const FutureOrder = {
  createOrder: (data) => {
    return SuperFetch(`${baseUrl.futureOrder}/order_forecast/`, {
      method: "POST",
      data: data,
    });
  },
};
export default FutureOrder;

import React from "react";
import MyTask from "./MyTask";

export default function TaskList({ learningList, ekkLanguage, taskType }) {
	return (
		<div>
			<MyTask
				ekkLanguage={ekkLanguage}
				learningList={learningList}
				taskType={taskType}></MyTask>
		</div>
	);
}

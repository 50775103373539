import {
  checkGetLocalUser,
  getLocalUser,
  setUserBusiness,
  setUser,
  setCreateUserBusiness,
  initBusiness,
} from "config/user";
import { updateToken } from "../config/SuperFetch";

import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Auth from "services/Auth";
export default function CheckBusiness() {
  // check user business local user

  const [business, setBusiness] = useState(getLocalUser());

  if (checkGetLocalUser()) {
    if (business?.associated_businesses.length === 0) {
      setCreateUserBusiness(initBusiness);
      // return <Navigate to='/business-name' />;
    } else if (business?.associated_businesses.length > 1) {
      // return <Navigate to='/select-business' />;
      setUserBusiness(business?.associated_businesses[0]);
      setUser(business?.user);
      localStorage.removeItem("setUser");
      window.location.href = "/home";
    } else if (business?.associated_businesses.length === 1) {
      return <SingleBusiness />;
    }
  }
}

function SingleBusiness() {
  const navigate = useNavigate();
  async function businessSelectEvent(data) {
    try {
      const response = await Auth.businessSelect(data);

      setUserBusiness(response?.business_info);
      setUser(response?.user);
      localStorage.removeItem("setUser");
      //   setLocalUser({});
      updateToken(response);
      window.location.href = "/home";
      // navigate("/home", { replace: true });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    businessSelectEvent(getLocalUser().associated_businesses[0]);
  }, []);
  return <></>;
}

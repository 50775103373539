import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
// import { setCustomer } from "redux/container/customerSlice";

function CMSFilter({
  setCMSType,
  createdCustomerList,
  assignedCustomerList,
  ekkLanguage,
  setReachedEnd,
  setHasMoreData,
  filterType,
  setFilterType,
  userType,
}) {
  return (
    <IonSegment
      scrollable
      className="orderBtnWrapper"
      value={filterType}
      onIonChange={(e) => {
        setCMSType(e.target.value === "created" ? true : false);
      }}
    >
      {userType !== "EkkHero Hub Staff" && (
        <IonSegmentButton
          value="created"
          className="paid relative"
          onClick={() => {
            setFilterType("created");
            createdCustomerList();
            setReachedEnd(false);
            setHasMoreData(true);
          }}
        >
          <button className="text-[#00B224] flex items-center font-sans p-0 font-bold shadow-none text-14">
            {ekkLanguage.customerList.createdFilterLabel}
          </button>
        </IonSegmentButton>
      )}
      <IonSegmentButton
        value="assigned"
        className="cancel relative"
        onClick={() => {
          setFilterType("assigned");
          assignedCustomerList();
          setReachedEnd(false);
          setHasMoreData(true);
        }}
      >
        <button className="text-primary flex items-center font-sans p-0 font-bold shadow-none text-14">
          {ekkLanguage.customerList.assignedFilterLabel}
        </button>
      </IonSegmentButton>
    </IonSegment>
  );
}
export default memo(CMSFilter);

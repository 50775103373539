import { digitChanger, monthChanger, numberWithCommas } from "helpers/helper";
import React from "react";
import Card from "../Common/Card";
import moment from "moment";

export default function ContractInfo({
  businessData,
  ekkLanguage,
  contractData,
}) {
  const statuses = [
    {
      label: "Identified (Not visited yet)",
      value: "Identified",
    },
    {
      label: "Engaged (Visited and need follow up)",
      value: "Engaged",
    },
    {
      label: "Negotiation (Interested)",
      value: "Negotiation",
    },
    {
      label: "Win - Usage Started",
      value: "Win",
    },
    {
      label: "Lost - Closed",
      value: "Lost",
    },
  ];
  return (
    <Card title={ekkLanguage.customerDetails.contractCardTitle}>
      <div className="px-1 flex flex-col w-full">
        {businessData?.interested_area === "Software" ? (
          <div className="mt-1"></div>
        ) : (
          <div className="flex justify-between w-full py-[7px]">
            <div className="text-000000 text-14 font-semibold">
              {ekkLanguage.customerDetails.orderType}
            </div>
            <div className="text-000080 text-14 font-medium">
              {contractData?.order_type}
            </div>
          </div>
        )}
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.serviceType}
          </div>
          <div className="text-000080 text-14 font-medium">
            {contractData?.service_type?.title}
          </div>
        </div>
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.revenueTier}
          </div>
          <div className="text-000080 text-14 font-medium">
            {contractData?.revenue_tier?.title}
          </div>
        </div>
        {/* <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.expectedRevenue}
          </div>
          <div className="text-000080 text-14 font-medium">
            {digitChanger(
              numberWithCommas(contractData?.expected_monthly_revenue)
            )}
          </div>
        </div> */}
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.expectedCloseDate}
          </div>
          <div className="text-000080 text-14 font-medium">
            {contractData?.expected_close_date
              ? digitChanger(
                  monthChanger(
                    moment(contractData?.expected_close_date).format(
                      "DD MMM, YYYY"
                    )
                  )
                )
              : ""}
          </div>
        </div>
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.status}
          </div>
          <div className="text-000080 text-14 font-medium">
            {statuses.map((status) =>
              status?.value === contractData?.status ? status?.label : ""
            )}
          </div>
        </div>
        <div className="flex justify-between w-full py-[7px]">
          <div className="text-000000 text-14 font-semibold">
            {ekkLanguage.customerDetails.createdAt}
          </div>
          <div className="text-000080 text-14 font-medium">
            {contractData?.createdAt
              ? digitChanger(
                  monthChanger(
                    moment(contractData?.createdAt).format("DD MMM, YYYY")
                  )
                )
              : ""}
          </div>
        </div>
      </div>
    </Card>
  );
}

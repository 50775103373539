// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title h5 span {
  position: absolute;
  height: 1.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 16px;
  margin-right: 23px;
  margin-left: 5px;
}

.header-wrapper .short-nav {
  display: block;
}

.header-wrapper .long-nav {
  display: none;
}

.header-wrapper.issticky .short-nav {
  display: none;
}

.header-wrapper.issticky .long-nav {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000000;
}

.header-wrapper.issticky .long-nav .backIcon {
  background-color: rgb(251 118 110/var(--tw-bg-opacity));
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-components/Common/style.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;AACD;;AACA;EACC,cAAA;AAED;;AAAA;EACC,aAAA;AAGD;;AADA;EACC,aAAA;AAID;;AAFA;EACC,cAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,kBAAA;AAKD;;AAHA;EACC,uDAAA;AAMD","sourcesContent":[".section-title h5 span {\n\tposition: absolute;\n\theight: 1.5px;\n\twidth: 100%;\n\tbackground: rgba(0, 0, 0, 0.2);\n\ttop: 16px;\n\tmargin-right: 23px;\n\tmargin-left: 5px;\n}\n.header-wrapper .short-nav {\n\tdisplay: block;\n}\n.header-wrapper .long-nav {\n\tdisplay: none;\n}\n.header-wrapper.issticky .short-nav {\n\tdisplay: none;\n}\n.header-wrapper.issticky .long-nav {\n\tdisplay: block;\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\tz-index: 100000000;\n}\n.header-wrapper.issticky .long-nav .backIcon {\n\tbackground-color: rgb(251 118 110 / var(--tw-bg-opacity));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

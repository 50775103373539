import moment from "moment";
import React, { useEffect } from "react";

import { digitChanger, monthChanger } from "helpers/helper";

export default function LearningDetails({ learningDetails }) {
	useEffect(() => {
		const animationCSS = `
		  @keyframes myAnimation {
			0% {
			  transform: translate(0, 0) scale(1);
			}
			50% {
			  transform: translate(2px, 2px) scale(1.1);
			}
			100% {
			  transform: translate(0, 0) scale(1);
			}
		  }`;

		const styleElement = document.createElement("style");
		styleElement.textContent = animationCSS;

		document.head.appendChild(styleElement);
	}, []);

	return (
		<div className='relative'>
			<div className='py-2 px-4 bg-white '>
				{/* Banner  */}
				<div className='rounded-[8px] mb-[10px]'>
					<img
						src={learningDetails?.thumbnail_image}
						className='w-[60%] m-auto'
						alt='slideImage'
					/>
				</div>
				{/* Title  */}
				<h6
					// onClick={() => navigate(`/learning-details/${learn._id}`)}
					className='font-bold text-16 mb-[5px]'>
					{learningDetails.title}
					{learningDetails?.tags?.length > 4 && <br />}
					{learningDetails.tags &&
						learningDetails.tags.length > 0 &&
						learningDetails.tags.map((tag, index) => {
							return (
								<span key={index} className='text-delivered px-0.5'>
									#{tag}
								</span>
							);
						})}
				</h6>
				{/* Created Date  */}
				<span className='text-808080 font-semibold text-12'>
					{digitChanger(
						monthChanger(
							moment(learningDetails.createdAt).format("DD MMM, YY")
						)
					)}
				</span>
				{/* Content  */}
				<div
					className='text-14 font-medium text-222222 text-justify'
					dangerouslySetInnerHTML={{
						__html: learningDetails.content,
					}}
				/>
			</div>
		</div>
	);
}

import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import LearningDetails from "components/mobile-components/LearningDetails";
import { baseUrl } from "config/apiUrl";
import { getLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Blog from "services/Blog";

function FAQInsidePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [singleBlog, setSingleBlog] = useState({});
	const [loading, setLoading] = useState(false);
	const language = getLanguage();

	let id = language === "en" ? baseUrl.faqEn : baseUrl.faqBn;
	const getSingleBlog = async () => {
		setLoading(true);
		let response = await Blog.singleBlog(id);
		if (response.status === 200) {
			setLoading(false);
			setSingleBlog(response.results);
		} else {
			setLoading(false);
		}
	};
	// const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getSingleBlog();
	}, [id]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.profile.faqMenuLabel} />
					<section className='pt-14'>
						<LearningDetails
							id={id}
							// comments={comments}
							// setComments={setComments}
							learningDetails={singleBlog}
							setLearningDetails={setSingleBlog}
							// count={count}
							// setCount={setCount}
							ekkLanguage={ekkLanguage}
							getLearningDetails={getSingleBlog}
						/>
					</section>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default FAQInsidePage;

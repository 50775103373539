import React, { useEffect, useState } from "react";
import BlogData from "services/Blog";
import moment from "moment";
// import "./style.scss";
import parse from "html-react-parser";
import LandingNavBar from "pages/LandingPageV2/LandingNavBar";
import FooterV2 from "pages/LandingPageV2/FooterV2";
import { getLanguage } from "config/language";
import { baseUrl } from "config/apiUrl";

export default function FAQPage() {
	const [singleBlog, setSingleBlog] = useState({});
	const [loading, setLoading] = useState(false);
	const language = getLanguage();

	let id = language === "en" ? baseUrl.faqEn : baseUrl.faqBn;
	const getSingleBlog = async () => {
		setLoading(true);
		let response = await BlogData.singleBlog(id);
		if (response.status === 200) {
			setLoading(false);
			setSingleBlog(response.results);
		} else {
			setLoading(false);
		}
	};
	// const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getSingleBlog();
	}, [id]);

	return (
		<section>
			<LandingNavBar />
			{!loading ? (
				<div className='pt-[100px] sm:pt-[120px] pb-24'>
					<div className='container'>
						<div className='m-auto w-full lg:w-8/12'>
							<img
								src={singleBlog?.thumbnail_image}
								alt='thumbnail'
								className='pb-10 w-full'
							/>
							<div>
								<h2 className='lg:text-40 md:text-32 text-22 text-black-1000 font-bold pb-3'>
									{singleBlog?.title}
								</h2>
								<p className='text-12 sm:text-16 font-normal text-808080 pb-5'>
									{moment(singleBlog.createdAt, "YYYY-MM-DD").format(
										"DD MMM, YYYY"
									)}
								</p>
								{singleBlog?.content && parse(singleBlog?.content)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='pt-[100px] sm:pt-[150px] pb-24'>
					<div className='m-auto w-full lg:w-8/12'>
						<div className='flex h-full justify-center items-center'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			)}

			<FooterV2 />
		</section>
	);
}

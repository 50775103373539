import { IonButton } from "@ionic/react";
import { getLanguage } from "config/language";
import { useNavigate } from "react-router-dom";
import MainBanner from "./MainBanner";

function HomePageBanner({ ekkLanguage }) {
	const lang = getLanguage();
	const navigate = useNavigate();

	return (
		<div className='pt-20 px-5'>
			<MainBanner ekkLanguage={ekkLanguage} lang={lang}></MainBanner>
			<div className='mt-5 text-center w-full pb-10'>
				<IonButton
					onClick={() => {
						navigate("/create-task");
					}}
					color='success'
					className='font-bold h-10 w-full text-white'>
					<span className='text-white'>{ekkLanguage.task.newTask}</span>
				</IonButton>
			</div>
		</div>
	);
}
// export default withLocation(Home);
export default HomePageBanner;

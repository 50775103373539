import {
	IonApp,
	IonContent,
	IonPage,
	IonButton,
} from "@ionic/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "components/mobile-components/Common/BackButton";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setAllInfo } from "redux/container/userSlice";
import { toast } from "react-toastify";
import { Input } from "components/global-components/common/Input";
import User from "services/User";

export default function ReferCodeSubmit() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const ekkUser = useSelector((state) => state.user.allInfo);
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [referCode, setReferCode] = useState("");

	const handleSubmit = () => {
		if (referCode) {
			setError("");
			const data = {
				refer_code: referCode,
			};
			User.putKycInfo(ekkUser?.id, data).then((res) => {
				if (res.success) {
					let res = User.getKycInfo(ekkUser?.id);
					res.then((r) => {
						if (r.status === 200) {
							dispatch(setAllInfo(r.results));
						}
					});
					toast.success(ekkLanguage.addressVerify.successMessage);
					navigate("/home");
				}
			});
		} else {
			setError(ekkLanguage.refer.referCodeErrorMessage);
		}
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButton
						title={ekkLanguage.refer.pageTitle}
						sticky={true}
					></BackButton>
					<div className='px-5'>
						<img
							className='w-full h-[220px] shrink-0 bg-transparent'
							src='/assets/images/ekkhero/images/referSuccess.png'
							alt=''
						/>
						<h6 className='text-20 font-bold text-success text-center mt-[30px] mb-[10px]'>
							{ekkLanguage.refer.title}
						</h6>
						<p className='text-14 font-semibold text-[#00000080] text-center mb-[30px]'>
							{ekkLanguage.refer.subTitle}
						</p>

						<Input
							onChange={(event) => {
								setReferCode(event.target.value);
								console.log(event.target.value);
							}}
							star
							type='text'
							name='address'
							value={referCode}
							placeholder={ekkLanguage.refer.codePlaceholder}
						></Input>

						{error && (
							<p className='font-semibold text-12 text-primary mt-1'>{error}</p>
						)}
						<IonButton
							type='submit'
							onClick={() => {
								handleSubmit();
							}}
							color='success'
							expand='full'
							className='font-bold h-10 w-full mt-44'
						>
							{ekkLanguage.refer.confirmButtonTitle}
							<span className='pl-2'>
								<ArrayRight />
							</span>
						</IonButton>

						<IonButton
							onClick={() => {
								navigate("/home");
							}}
							fill='outline'
							color='primary'
							className='font-bold h-10 mt-5 w-full  border-primary '
						>
							{ekkLanguage.refer.skipButtonTitle}
						</IonButton>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}

import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Task = {
	createTask: (query) => {
		return SuperFetch(`${baseUrl.task}/task/`, {
			method: "POST",
			data: query,
		});
	},
	getTasks: (page = 1, limit = 50) => {
		return SuperFetch(`${baseUrl.task}/task/?page=${page}&limit=${limit}`);
	},
	getTasksPublic: (page = 1, limit = 10) => {
		return SuperFetch(
			`${baseUrl.task}/task_list/?page=${page}&limit=${limit}`
		);
	},
	getTaskPublic: (id) => {
		return SuperFetch(`${baseUrl.task}/task_details/${id}`);
	},
	getTask: (id) => {
		return SuperFetch(`${baseUrl.task}/task/${id}`);
	},
	getMyTask: (query = "Creator", page = 1, limit = 10) => {
		if (query === "Invited") {
			return SuperFetch(
				`${baseUrl.task}/own_task/?status=${query}&role=Participant&page=${page}&limit=${limit}`
			);
		} else {
			return SuperFetch(
				`${baseUrl.task}/own_task/?role=${query}&page=${page}&limit=${limit}`
			);
		}
	},
	joinTask: (id, query) => {
		return SuperFetch(`${baseUrl.task}/join_task/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	completeTask: (id, query) => {
		return SuperFetch(`${baseUrl.task}/complete_task/${id}/`, {
			data: query,
			method: "PUT",
		});
	},
	addComment: (query) => {
		return SuperFetch(`${baseUrl.task}/comment/`, {
			method: "POST",
			data: query,
		});
	},
	inviteTask: (query) => {
		return SuperFetch(`${baseUrl.task}/invite_task/`, {
			method: "POST",
			data: query,
		});
	},
	acceptReject: (query) => {
		return SuperFetch(`${baseUrl.task}/task_accept_reject/`, {
			data: query,
			method: "PUT",
		});
	},
};

export default Task;

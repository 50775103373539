import { IonButton, IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadImage from "../Common/UploadImage";
import location from "assets/images/icon/location.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  emptyBusiness,
  setNewBusiness,
} from "redux/container/businessSettingsSlice";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Business from "services/Business";
import { setCreateCustomerBS } from "redux/container/userSlice";
import User from "services/User";
import { ClipLoader } from "react-spinners";
import Contract from "services/Contract";
import CustomSelect from "components/global-components/common/CustomSelect";
import { getLanguage } from "config/language";
import { setBusinessCreation } from "redux/container/customerSlice";
import NewCustomSelect from "components/global-components/common/NewCustomSelect";
import { Input } from "components/global-components/common/Input";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import { setBusinessCreationType, setCustomerRole } from "config/localContract";

export default function CustomerForm({
  customerName,
  setCustomerName,
  businessName,
  setBusinessName,
  cameraImage,
  setCameraImage,
  city,
  setCity,
  address,
  setAddress,
  postalCode,
  setPostalCode,
  to,
}) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const localLanguage = getLanguage();
  let newCustomerBusiness = useSelector(
    (state) => state?.businessSettings?.newBusiness
  );
  let newBusinessLocation = useSelector((state) => state?.customer?.location);

  const [text, setText] = useState(newCustomerBusiness?.businessType ?? "");
  const [roles, setRoles] = useState([
    {
      label: ekkLanguage.createBusinessInfo.owner,
      value: "Owner",
    },
    {
      label: ekkLanguage.createBusinessInfo.manager,
      value: "Manager",
    },
    {
      label: ekkLanguage.createBusinessInfo.stuff,
      value: "Stuff",
    },
  ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);
  let [cameraImageURL, setCameraImageURL] = useState(null);
  const [imageLoader, setImageLoader] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [role, setRole] = useState(
    newCustomerBusiness?.role
      ? roles.find((rol) => rol.value === newCustomerBusiness.role)
      : ""
  );

  const getBusinessType = async function () {
    let response = await Business.getBusinessType();
    if (response.status === 200) {
      setBusinessTypes(response.results);
    }
  };

  useEffect(() => {
    getBusinessType();
  }, []);

  function setBusinessType(business) {
    setText(business.id);
    dispatch(setNewBusiness({ businessType: business.id }));
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };

  useEffect(() => {
    dispatch(setNewBusiness({ businessImage: cameraImage }));
  }, [cameraImage]);

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const imageUrlCollect = async () => {
    setImageLoader(true);
    const cameraFile = DataURIToBlob(cameraImage);
    const cameraData = new FormData();
    cameraData.append("key", cameraFile, "image.jpg");
    let cameraResponse = await User.toBucketImage(cameraData);
    if (cameraResponse.status === 201) {
      setCameraImageURL(cameraResponse?.results[0]);
      setImageLoader(false);
      createBusiness(cameraResponse?.results);
    } else {
      setImageLoader(false);
    }
  };

  const createBusiness = async (image) => {
    if (newCustomerBusiness.name) {
      if (newCustomerBusiness.businessName) {
        if (newBusinessLocation?.coordinates?.length > 0) {
          if (newCustomerBusiness.businessType) {
            var query = {
              business_type: newCustomerBusiness.businessType,
              business_name: newCustomerBusiness.businessName,
              address_line: newCustomerBusiness.address,
              postal_code: newCustomerBusiness.postalCode,
              city: newCustomerBusiness.city,
              role: newCustomerBusiness?.role,
              long_lat: {
                type: "Point",
                coordinates: [
                  newBusinessLocation.coordinates[1],
                  newBusinessLocation.coordinates[0],
                ],
              },
              country: newCustomerBusiness.country,
              phone_number:
                newCustomerBusiness.countryCode + newCustomerBusiness.phoneNo,
              customer_name: newCustomerBusiness.name,
              // images: image,
              business_group: "Seller",
            };
            if (query.long_lat) {
              let response = await Contract.createBusiness(query);
              if (response.status === 200 || response.status === 201) {
                setBusinessCreationType("new");
                setCustomerRole(newCustomerBusiness?.role);
                dispatch(setBusinessCreation({ business: response?.results }));
                navigate(`/interested-area`);
                dispatch(emptyBusiness());
                if (to === "my-distributor") {
                  toast.success(
                    ekkLanguage.newCustomer.businessCreationSuccess
                  );
                  navigate(`/interested-area`);
                  dispatch(setCreateCustomerBS(false));
                } else {
                  toast.success(
                    ekkLanguage.newCustomer.businessCreationSuccess
                  );
                  navigate(`/${"my-distributor"}`);
                  dispatch(setCreateCustomerBS(false));
                }
              }
            } else {
              toast.warning(ekkLanguage.newCustomer.businessLocationWarning);
            }
          } else {
            toast.error(ekkLanguage.newCustomer.selectBusiness);
          }
        } else {
          onOpenModal();
        }
      } else {
        toast.error(ekkLanguage.newCustomer.businessNameWarning);
      }
    } else {
      toast.error(ekkLanguage.newCustomer.distributorNameWarning);
    }
  };
  return (
    <>
      <div>
        {/* <label
          className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
        >
          {ekkLanguage?.newCustomer.customerName}
        </label> */}

        <Input
          required
          star
          // className="custom-input"
          value={customerName}
          placeholder={ekkLanguage.newCustomer.customerNameHolder}
          label={{ label: ekkLanguage?.newCustomer.customerName }}
          onChange={(e) => {
            dispatch(setNewBusiness({ name: e.target.value }));
            setCustomerName(e.target.value);
          }}
        ></Input>
      </div>
      <NewCustomSelect
        type="role-selection"
        localLanguage={localLanguage}
        options={roles}
        setOption={setRole}
        option={role}
        mainLabel={ekkLanguage.newCustomer.role}
        selectPlaceholder={ekkLanguage.newCustomer.selectRole}
      ></NewCustomSelect>
      {/* <UploadImage cameraImage={cameraImage} setCameraImage={setCameraImage} /> */}
      <div className="pt-3">
        <Input
          required
          star
          // className="custom-input"
          value={businessName}
          placeholder={ekkLanguage.newCustomer.businessNameHolder}
          label={{ label: ekkLanguage?.newCustomer.businessName }}
          onChange={(e) => {
            dispatch(setNewBusiness({ businessName: e.target.value }));
            setBusinessName(e.target.value);
          }}
        ></Input>
      </div>

      <div className="pt-4">
        <label
          className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
        >
          {ekkLanguage?.newCustomer.address}
        </label>
        <div className="flex items-center justify-between">
          <input
            className="custom-input"
            value={address}
            placeholder={ekkLanguage.newCustomer.addressHolder}
            onChange={(e) => {
              dispatch(setNewBusiness({ address: e.target.value }));
              setAddress(e.target.value);
            }}
          ></input>
          <span
            onClick={() => navigate(`/pick-location/${to}`)}
            className="flex h-[50px] w-[61px] bg-white ml-[10px] rounded-[5px] cursor-pointer"
            style={
              newCustomerBusiness?.coordinates?.length
                ? {
                    border: "2px solid rgba(52, 168, 83, 0.50)",
                    boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
                  }
                : {
                    border: "2px solid rgba(249,75, 65, .50)",
                    boxShadow: "0px 5px 20px rgb(0, 0, 0,.05)",
                  }
            }
          >
            <img src={location} className="m-auto" alt="location" />
          </span>
        </div>

        <div className="pt-4">
          <IonRow>
            <IonCol size="6">
              <div>
                <label
                  className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
                >
                  {ekkLanguage?.newCustomer.city}
                </label>

                <input
                  className="custom-input"
                  value={city}
                  placeholder={ekkLanguage.newCustomer.cityHolder}
                  onChange={(e) => {
                    dispatch(setNewBusiness({ city: e.target.value }));
                    setCity(e.target.value);
                  }}
                ></input>
              </div>
            </IonCol>
            <IonCol size="6">
              <div>
                <label
                  className={`text-16 md:text-16 font-bold text-262626-1000 sm:pb-3 pb-[10px] block`}
                >
                  {ekkLanguage?.newCustomer.postalCode}
                </label>

                <input
                  className="custom-input"
                  value={postalCode}
                  placeholder={ekkLanguage.newCustomer.postalCodeHolder}
                  onChange={(e) => {
                    dispatch(setNewBusiness({ postalCode: e.target.value }));
                    setPostalCode(e.target.value);
                  }}
                ></input>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <div className="pt-4">
          <label
            className={`text-16 md:text-16 font-bold text-[#262626] sm:pb-3 block`}
          >
            {ekkLanguage?.newCustomer.selectBusiness}{" "}
            <span className="text-primary ml-1 font-bold">*</span>
            <br />
          </label>
          {/* <label className="text-16 md:text-16 font-semibold text-[#000] mt-[15px] sm:pb-3 pb-[5px] block">
            {ekkLanguage?.newCustomer.reseller}
          </label> */}
          <div>
            <div className="text-left pb-6">
              <div>
                <IonRow>
                  {businessTypes.length &&
                    businessTypes.map((business, index) => {
                      return (
                        <IonCol
                          className="p-1.5"
                          size="4"
                          key={index}
                          onClick={() => {
                            setBusinessType(business);
                          }}
                        >
                          <div
                            className={
                              business.id === text
                                ? "border-2 border-primary rounded-xl overflow-hidden py-3 text-center mb-4"
                                : "border border-black-200 rounded-xl overflow-hidden py-3  text-center opacity-30 mb-4"
                            }
                            style={{ minHeight: 96 }}
                          >
                            {business.image ? (
                              <img
                                className="w-12 h-12 m-auto"
                                src={business.image}
                                alt="fmcg"
                              />
                            ) : (
                              <h1 className="text-32 font-bold">
                                {business.title
                                  .split(" ")
                                  .map((el) => el[0])
                                  .join("")}
                              </h1>
                            )}

                            <p className="text-10 text-black-1000 font-semibold pt-2">
                              {business?.title}
                            </p>
                          </div>
                        </IonCol>
                      );
                    })}
                </IonRow>
              </div>
            </div>

            <div className="pt-12 text-center">
              {/* <IonButton
                color="success"
                className="font-bold h-10"
                onClick={() => {
                  cameraImage ? imageUrlCollect() : createBusiness(cameraImage);
                }}
              >
                {imageLoader ? (
                  <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={"white"}
                    loading={true}
                  />
                ) : (
                  ekkLanguage.newCustomer.nextBtn
                )}
              </IonButton> */}
              <IonButton
                type="submit"
                onClick={() => {
                  cameraImage ? imageUrlCollect() : createBusiness(cameraImage);
                }}
                color="success"
                expand="full"
                className="font-bold h-10 w-full mt-5"
              >
                {imageLoader ? (
                  <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={"white"}
                    loading={true}
                  />
                ) : (
                  <span className="flex text-white">
                    {ekkLanguage.newCustomer.nextBtn}{" "}
                    <span className="pl-3">
                      <ArrayRight />
                    </span>
                  </span>
                )}
              </IonButton>
            </div>
          </div>
        </div>
      </div>
      {/* Less MOQ modal*/}
      <Modal
        style={customStyles}
        closeTimeoutMS={200}
        isOpen={isOpen}
        onRequestClose={onCloseModal}
        classNames="bottom-sheet-modal"
      >
        <div className="p-5">
          <span className="mb-2 inline-block mx-2">
            <CancelPopUp />
          </span>
          <h6 className="text-16 font-bold pb-[20px]">
            {ekkLanguage.createBusinessInfo.validationLabel}
          </h6>

          <div className="flex justify-end">
            <button
              color="primary"
              className="font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white"
              onClick={onCloseModal}
            >
              {ekkLanguage.createBusinessInfo.okBtnLabel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

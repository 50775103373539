import React from "react";
import Slider from "react-slick";
import "./style.scss";

export default function BannerSlider() {
	const settings = {
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		dotsClass: "slick-dots custom-slick-dots",
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		appendDots: (dots) => <ul>{dots}</ul>,
	};
	return (
		<div className='pt-[150px]'>
			<Slider {...settings}>
				<div>
					<img src='assets/images/h_banner_1.png' alt='banner' />
				</div>
				<div>
					<img src='assets/images/h_banner_2.png' alt='banner' />
				</div>
				<div>
					<img src='assets/images/h_banner_3.png' alt='banner' />
				</div>
				<div>
					<img src='assets/images/h_banner_4.png' alt='banner' />
				</div>
				<div>
					<img src='assets/images/h_banner_5.png' alt='banner' />
				</div>
				<div>
					<img src='assets/images/h_banner_6.png' alt='banner' />
				</div>
			</Slider>
		</div>
	);
}

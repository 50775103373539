import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import React from "react";
import { useSelector } from "react-redux";
import { getCheckInDetails, removeCheckInDetails } from "config/localContract";
import { useParams } from "react-router-dom";

export default function SuccessPage() {
  const { successPageType } = useParams();
  const navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const visitInfo = getCheckInDetails();
  const relationDetails = useSelector(
    (state) => state.customer.relationDetails
  );
  // Handle Next Button Click
  const handleNext = async () => {
    if (successPageType === "check-out") {
      navigate(`/customer-details/${visitInfo?.businessinfo?.id}`);
      removeCheckInDetails();
    } else if (successPageType === "check-in") {
      navigate(`/customer-details/${visitInfo?.businessinfo?.id}`);
    } else if (successPageType === "order-forecast-success") {
      navigate(`/customer-details/${relationDetails?.id}`);
    } else {
      navigate(`/home`);
    }
  };

  return (
    <IonApp>
      <IonPage>
        <IonContent>
          <section className="px-4 h-screen flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <img
                className="w-[250px] h-[250px]"
                src={
                  successPageType === "order-forecast-success"
                    ? "/assets/images/order-forcast-success.png"
                    : "/assets/images/checkout_success.png"
                }
                alt=""
              />
              <h3 className="text-[#00B224] text-32 font-bold my-2">
                {ekkLanguage.checkIn.successTitle}
              </h3>
              <p className="text-[#00000080] text-16 font-semibold">
                {successPageType === "check-in" &&
                  ekkLanguage.checkIn.checkInMessage}
                {successPageType === "check-out" &&
                  ekkLanguage.checkIn.checkOutMessage}
                {successPageType === "order-forecast-success" &&
                  ekkLanguage.orderForecast.successMessage}
              </p>
              <div className="pt-[200px] w-full">
                <IonButton
                  type="submit"
                  onClick={() => {
                    handleNext();
                  }}
                  color="success"
                  expand="full"
                  className="font-bold h-10 w-full mt-5 text-white"
                >
                  <span className="text-white">{ekkLanguage.checkIn.nextBtnTitle}</span>
                  <span className="pl-3">
                    <ArrayRight />
                  </span>
                </IonButton>
              </div>
            </div>
          </section>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}

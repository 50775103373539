import { IonCol, IonGrid, IonLoading, IonRow } from "@ionic/react";
import React from "react";
import CustomerCard from "./CustomerCard";

export default function CustomerList({
  customerData,
  loading,
  ekkLanguage,
  link,
}) {
  return (
    <>
      {loading ? (
        <IonLoading
          isOpen={true}
          message={ekkLanguage.customerList.loader}
        ></IonLoading>
      ) : (
        <div className="w-full flex items-center justify-center px-2">
          <IonGrid classNae="w-full">
            <IonRow className="w-full gap-3">
              {customerData.length > 0 ? (
                customerData.map((customer) => {
                  return (
                    <CustomerCard
                      key={customer?.id}
                      customerId={customer?.id}
                      customerName={customer?.business?.business_name}
                      imgSource={customer?.business?.images}
                      customerAddress={customer?.business?.address_line}
                      rating={
                        customer?.business?.products_count ? customer?.business?.products_count : 4.5
                      }
                      link={link}
                      ekkLanguage={ekkLanguage}
                    ></CustomerCard>
                  );
                })
              ) : (
                <>
                  <IonCol size="2"></IonCol>
                  <IonCol size="8">
                    <div
                      className="text-center flex items-center justify-center"
                      style={{ height: "calc(100vh - 420px)" }}
                    >
                      <div>
                        <img
                          className="m-auto mb-[10px] mt-5"
                          src="/assets/images/not-found-file.png"
                          alt="emptyCard"
                        />
                        <p className="text-16 font-normal text-222222 mb-[35px]">
                          {ekkLanguage.customerList.notFound}
                        </p>
                      </div>
                    </div>
                  </IonCol>
                </>
              )}
            </IonRow>
          </IonGrid>
        </div>
      )}
    </>
  );
}

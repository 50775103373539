import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import NavFooter from "components/mobile-components/Common/NavFooter";
import TaskCreate from "components/mobile-components/TaskCreate/Index";
import { useState } from "react";
import { useSelector } from "react-redux";

function CreateTaskPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.task.createTask}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<div className='mt-[70px] px-5'>
						<TaskCreate />
					</div>
				</IonContent>
				<NavFooter />
			</IonPage>
		</IonApp>
	);
}

export default CreateTaskPage;

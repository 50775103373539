import React from "react";
import LandingNavBar from "./LandingNavBar";
import TextBannerArea from "./TextBannerArea";
import FooterV2 from "./FooterV2";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "config/language";
import { setPreUrl } from "redux/container/authSlice";

export default function ChangePasswordSuccessV2() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const { preUrl } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return (
    <>
      <LandingNavBar />
      <div className="container w-full h-screen flex items-center justify-center lg:flex-row flex-col overflow-y-scroll">
        <TextBannerArea ekkLanguage={ekkLanguage} />
        <div className=" bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md lg:w-1/2 w-full">
          {" "}
          <div className="py-4">
            <h1
              className={`  ${
                getLanguage() === "en"
                  ? "lg:text-24 text-28 md:text-24"
                  : "lg:text-24 text-28 md:text-24"
              } font-bold text-262626-1000 pr-[0px] pb-5 text-center`}
            >
              {ekkLanguage?.changePass.successLabel}
            </h1>
            <button
              onClick={() => {
                window.location.href = preUrl;
                dispatch(setPreUrl(""));
              }}
              style={{
                background:
                  "linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
              }}
              className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}
            >
              {ekkLanguage?.changePass.backBtnLabel}
            </button>
            {/* <div className="text-left pt-4">
              <p className="font-bold text-14">
                {ekkLanguage?.login?.langChange}
                <span
                  className={`${
                    getLanguage() == "en" ? "text-success" : "text-black-1000"
                  }  mx-1 cursor-pointer`}
                  onClick={() => {
                    setLanguage("en");
                    dispatch(selectLan());
                  }}
                >
                  English
                </span>{" "}
                <span
                  className={`${
                    getLanguage() == "bn" ? "text-success" : "text-black-1000"
                  }  mx-1 cursor-pointer`}
                  onClick={() => {
                    setLanguage("bn");
                    dispatch(selectLan());
                  }}
                >
                  বাংলা
                </span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <FooterV2 />
    </>
  );
}
